import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { scaleLinear } from "d3-scale";
import { line as d3line } from "d3-shape";
import uniqueId from "lodash/uniqueId";
import Measure from "react-measure";
import Tooltips from "./tooltips";

import { getGenre as getSomeGenre } from "categoryFunctions";
import {
  formatDollars,
  formatDollarsDelta,
  formatPercentage,
  formatPercentageDelta,
} from "formatDollars.js";
import { setDisplayYear } from "Actions.js";
import HoverTargets from "./HoverTargets.js";
import Legend from "./Legend.js";
import XAxis from "./XAxis.js";
import YAxis from "./YAxis.js";
import Chart from "./chart";
import ChartDropdownControls from "./chart-dropdown-controls";
import ChartButtonControls from "./chart-button-controls";
import ChartDatum from "./chart-datum";

import { CBO_LABEL, YOU_LABEL } from "Constants";

const getGenre = (col, cats) => {
  let genre = getSomeGenre(col, cats);
  //struff that isnt in there
  if (!genre) {
    switch (col) {
      case "TOTAL_REVENUE":
        genre = "revenue";
        break;
      case "TOTAL_SPENDING":
      case "Net Interest":
        genre = "spending";
        break;
      case "Nominal GDP":
      case "Real GDP Growth Rate":
      case "Real GDP":
        genre = "economy";
        break;

      default:
        genre = "debt";
    }
  }
  return genre;
};

const TICKS = 2;
const val = (d) => d.value;

const unitsByColumn = {
  NET_DEBT: "percentofgdp",
  TOTAL_SPENDING: "dollars",
  TOTAL_REVENUE: "dollars",
  SURPLUS: "dollars",
  NOMINAL_GDP: "dollars",
  REAL_GDP: "dollars",
  "Net Interest": "dollars",
  "Nominal GDP": "dollars",
  "Real GDP Growth Rate": "percent",
  TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE: "percent",
  LABOR_FORCE_GROWTH_RATE: "percent",
  UNEMPLOYMENT_RATE: "percent",
  INFLATION_RATE: "percent",
  REAL_INTEREST_RATE: "percent",
};

const unitsDefined = {
  percentofgdp: "% of GDP",
  dollars: "nominal dollars",
};

const quotientValues = (dividend, divisor) => {
  const results = {};
  Object.keys(dividend).forEach((key) => {
    results[key] = dividend[key] / divisor[key];
  });
  return results;
};

const removeEmptyAndTrim = (values, min, max) => {
  return Object.keys(values).reduce((r, d) => {
    if (!isNaN(values[d]) && d >= min && d <= max) {
      r[d] = values[d];
    }
    return r;
  }, {});
};

export class UnconnectedHeroChart extends Component {
  static contextTypes = {
    store: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      mainColumn: this.props.mainColumn,
      hoveredX: null,
      unit: unitsByColumn[this.props.mainColumn] || "dollars",
      width: this.props.width,
      height: this.props.height,
      boxScale: 1,
    };

    this.tooltipIdPrefix = uniqueId();
    this.changeColumn = this.changeColumn.bind(this);
  }

  prepareData(keyValue, transformValue = (d) => d) {
    const { startYear, endYear } = this.props.baseline;
    const data = [];

    Object.keys(keyValue).forEach((key) => {
      if (key >= startYear && key <= endYear) {
        data.push({ year: +key, value: transformValue(keyValue[key]) });
      }
    });

    return data;
  }

  updateHeroChart(
    user,
    baseline,
    mainColumn,
    width,
    height,
    unit,
    boxScale,
    includeZero,
    genre,
    title
  ) {
    const section = Object.keys(user.ECON).includes(mainColumn)
      ? "ECON"
      : "BUDGET";
    const baselineGdp = baseline.ECON["Nominal GDP"].values;
    const userGdp = user.ECON["Nominal GDP"].values;
    let { startYear, endYear } = baseline;
    const userKeyValueDollars = removeEmptyAndTrim(
        user[section][mainColumn].values,
        startYear,
        endYear
      ),
      userKeyValueGdp =
        unit !== "percent"
          ? quotientValues(userKeyValueDollars, userGdp)
          : undefined,
      baselineKeyValueDollars = removeEmptyAndTrim(
        baseline[section][mainColumn].values,
        startYear,
        endYear
      ),
      baselineKeyValueGdp =
        unit !== "percent"
          ? quotientValues(baselineKeyValueDollars, baselineGdp)
          : undefined;

    // recalc start year and end year based on values.  This allows for growth rate charts which start
    // at startYear + 1 but is a more generic approach that could help in other scenarios
    startYear = Math.min(
      ...Object.keys(userKeyValueDollars),
      ...Object.keys(baselineKeyValueDollars)
    );
    endYear = Math.max(
      ...Object.keys(userKeyValueDollars),
      ...Object.keys(baselineKeyValueDollars)
    );

    //For unified scale
    let additionalKeyValue;
    if (mainColumn === "TOTAL_REVENUE")
      additionalKeyValue = user.BUDGET.TOTAL_SPENDING.values;
    if (mainColumn === "TOTAL_SPENDING")
      additionalKeyValue = user.BUDGET.TOTAL_REVENUE.values;

    if (unit === "percentofgdp") {
      if (additionalKeyValue) {
        additionalKeyValue = quotientValues(
          additionalKeyValue,
          user.ECON["Nominal GDP"].values
        );
      }
    }

    let formatValue = formatDollars;
    if (unit === "percent" || unit === "percentofgdp") {
      formatValue = (d) => formatPercentage(d * 100);
    }

    const formatTooltipValue =
      unit === "percent" ? (d) => formatPercentage(d * 100) : formatDollars;
    const formatTooltipDelta =
      unit === "percent"
        ? (d) => formatPercentageDelta(d * 100)
        : formatDollarsDelta;

    const userData = this.prepareData(
        unit === "percentofgdp" ? userKeyValueGdp : userKeyValueDollars
      ),
      baselineData = this.prepareData(
        unit === "percentofgdp" ? baselineKeyValueGdp : baselineKeyValueDollars
      ),
      additionalData = additionalKeyValue
        ? this.prepareData(additionalKeyValue)
        : [];

    let allData = [...userData, ...baselineData, ...additionalData];

    if (includeZero) allData = allData.concat({ value: 0 });

    const x = scaleLinear().rangeRound([0, width]).domain([startYear, endYear]);

    const y = scaleLinear()
      .rangeRound([height, 0])
      .domain([Math.min(...allData.map(val)), Math.max(...allData.map(val))]);

    const line = d3line()
      .x(function (d) {
        return x(d.year);
      })
      .y(function (d) {
        return y(d.value);
      });

    return {
      userPath: line(userData),
      baselinePath: line(baselineData),
      gdp100: y(0.8) / (y.range()[0] - y.range()[1]),
      leftAxis: (
        <YAxis
          xScale={x}
          yScale={y}
          yFormat={formatValue}
          boxScale={boxScale}
          frequency={
            unit === "percentofgdp"
              ? 0.5
              : (Math.abs(y.domain()[1] - y.domain()[0]) * 0.8) / 2
          }
          highlight={unit === "percentofgdp" ? 1 : undefined}
        />
      ),
      axisBottom: (
        <XAxis
          domain={x.domain()}
          tickCount={TICKS}
          hoveredX={this.state.hoveredX}
        />
      ),
      hoverTargets: (
        <HoverTargets
          idPrefix={this.tooltipIdPrefix}
          xScale={x}
          yScale={y}
          userData={userData}
          baselineData={baselineData}
          width={width}
          height={height}
          displayYear={this.props.displayYear}
          onSet={this.props.setDisplayYear}
        />
      ),
      tooltips: (
        <Tooltips
          style={{ width: 0 }}
          idPrefix={this.tooltipIdPrefix}
          xScale={x}
          yScale={y}
          title={title}
          userDataDollars={userKeyValueDollars}
          baselineDataDollars={baselineKeyValueDollars}
          userDataGdp={userKeyValueGdp}
          baselineDataGdp={baselineKeyValueGdp}
          formatValue={formatTooltipValue}
          formatDelta={formatTooltipDelta}
          genre={genre}
        />
      ),
      userKeyValueDollars,
      userKeyValueGdp,
      baselineKeyValueDollars,
      baselineKeyValueGdp,
      formatTooltipValue,
      formatTooltipDelta,
    };
  }

  changeColumn(col) {
    this.setState({
      mainColumn: col,
      unit: unitsByColumn[col],
    });
  }

  render() {
    const {
      className = "",
      user,
      baseline,
      showAxis,
      showLegend,
      showTitle,
      showChoices,
      width,
      margin,
      chartStyle,
      includeZero,
      definitions,
      categories,
      layout,
      isMobile,
      displayYear,
    } = this.props;

    const { boxScale } = this.state;

    if (!baseline) {
      return <div>Loading</div>;
    }

    const genre = getGenre(this.state.mainColumn, categories);
    const baselineClass = "stroke--" + genre;
    const title = definitions[this.state.mainColumn];

    const displayMargin = {
      ...margin,
      left: !showAxis
        ? 0
        : this.state.unit === "dollars"
        ? 70 / boxScale
        : 45 / boxScale,
      top: 5 / boxScale,
    };

    const {
      userPath,
      baselinePath,
      gdp100,
      leftAxis,
      hoverTargets,
      tooltips,
      userKeyValueDollars,
      userKeyValueGdp,
      baselineKeyValueDollars,
      baselineKeyValueGdp,
      formatTooltipValue,
      formatTooltipDelta,
    } = this.updateHeroChart(
      user,
      baseline,
      this.state.mainColumn,
      Math.max(0, this.state.width - displayMargin.left - displayMargin.right),
      Math.max(0, this.state.height - displayMargin.top - displayMargin.bottom),
      this.state.unit,
      boxScale,
      includeZero,
      genre,
      title
    );

    const legendPieces = [
      {
        className: "fill--user",
        label: YOU_LABEL,
      },
      {
        className: baselineClass.replace("stroke", "fill"),
        label: CBO_LABEL,
      },
    ];

    return (
      <article className={`HeroChart ${className} HeroChart--${layout}`}>
        {layout === "large" && !isMobile ? (
          <ChartButtonControls
            onChange={this.changeColumn}
            currentValue={this.state.mainColumn}
            units={unitsDefined[this.state.unit]}
            genre={genre}
          />
        ) : (
          <ChartDropdownControls
            showTitle={showTitle}
            showChoices={showChoices}
            title={title}
            onChange={this.changeColumn}
            value={this.state.mainColumn}
            units={unitsDefined[this.state.unit]}
            genre={genre}
          />
        )}
        {layout === "large" && (
          <div className="HeroChart-values">
            <ChartDatum
              value={displayYear}
              userDataDollars={userKeyValueDollars}
              baselineDataDollars={baselineKeyValueDollars}
              userDataGdp={userKeyValueGdp}
              baselineDataGdp={baselineKeyValueGdp}
              formatValue={formatTooltipValue}
              formatDelta={formatTooltipDelta}
            />
          </div>
        )}
        <Measure
          bounds
          onResize={({ bounds }) => {
            this.setState({
              boxScale: bounds.width / width,
            });
          }}
        >
          {({ measureRef }) => {
            return (
              <div className="chart-wrap" style={chartStyle} ref={measureRef}>
                <Chart
                  column={this.state.mainColumn}
                  unit={this.state.unit}
                  width={this.state.width}
                  height={this.state.height}
                  margin={displayMargin}
                  showAxis={showAxis}
                  axes={leftAxis}
                  userPath={userPath}
                  baselinePath={baselinePath}
                  gdp100={gdp100}
                  boxScale={boxScale}
                  hoverTargets={hoverTargets}
                  baselineClass={baselineClass}
                />
                <div style={{ position: "absolute" }}>
                  {showLegend && <Legend pieces={legendPieces} />}
                  {tooltips}
                </div>
              </div>
            );
          }}
        </Measure>
      </article>
    );
  }
}

UnconnectedHeroChart.defaultProps = {
  shrinkOnScroll: false,
  className: "",
  showLegend: true,
  showAxis: true,
  showTitle: true,
  showChoices: false,
  mainColumn: "NET_DEBT",
  headingClass: "font--h3 bold uppercase",
  width: 450,
  height: 220,
  margin: {
    left: 56,
    right: 1,
    top: 10,
    bottom: 10,
  },
  includeZero: true,
};

const mapStateToProps = (state) => ({
  categories: state.calculator.categories,
  definitions: state.calculator.definitions,
  displayYear: state.session.displayYear,
});
const mapDispatchToProps = (dispatch) => ({
  setDisplayYear: (value) => {
    dispatch(setDisplayYear(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedHeroChart);
