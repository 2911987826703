const Versions = {
  default: "2020_09",
  "2018": "2018",
  "January 2019": "2019_01",
  "June 2019": "2019_06",
  "August 2019": "2019_08",
  "August 2019 with tax calc": "2019_08_tax",
  "January 2020": "2020_01" , 
  "September 2020": "2020_09"
};

export default Versions;

export const selectVersionLabel = (version) => {
  return Object.keys(Versions)
    .filter((d) => d !== "default")
    .find((key) => version === Versions[key]);
};
