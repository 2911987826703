import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class BudgetMeta extends Component {
  static contextTypes = {
    store: PropTypes.object
  }
  render() {
    const { title, description, toggleEditBudgetMeta, className="" } = this.props
    return (
      <div className={`BudgetMeta ${className}`}>
        {title && <div className="title font--h3">{title}</div>}
        {description && <div className="description font--body">{description}</div>}
        <button
          className="button"
          onClick={() => {
            toggleEditBudgetMeta()
          }}>
          {title || description ? "Edit" : "Describe your changes"}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  title: state.calculator.title,
  description: state.calculator.description
})
export default connect(mapStateToProps)(BudgetMeta);