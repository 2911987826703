import React, { Component } from "react";
import { scaleLinear } from "d3-scale";
import { formatDollarsLong } from "../formatDollars";

export default class SegmentedBar extends Component {

  render() {
    const { userData, baselineData, domain, width, height, style, definitions, className } = this.props
    const scale = scaleLinear()
      .domain(domain)
      .range([0, width])

    const keys = Object.keys(userData);
    let userXOffset = 0,
      baselineXOffset = 0

    let bars = []
    Object.values(userData).forEach((d,i) => {
      let
        userValue = scale(d),
        baselineValue = scale(baselineData[Object.keys(baselineData)[i]])

      bars.push(
         <rect
          key={d+'baseline'}
          data-tip={`${definitions[keys[i]] || keys[i]}: ${formatDollarsLong(d)}`}
          width={baselineValue}
          height={height/5}
          x={baselineXOffset}
          y={0}
          stroke={"#fff"}
          strokeWidth={1}
          vectorEffect="non-scaling-stroke"
          opacity={0.25} />,
        <rect
          key={d+'user'}
          data-tip={`${definitions[keys[i]] || keys[i]}: ${formatDollarsLong(d)}`}
          width={userValue}
          height={height}
          x={userXOffset}
          y={height/5}
          stroke={"#fff"}
          strokeWidth={1}
          vectorEffect="non-scaling-stroke" />
      )
      userXOffset += userValue
      baselineXOffset += baselineValue
    })
    const bar = (
      <g>{bars}</g>
    )


    return (
      <svg
        viewBox={` 0 0 ${width} ${height*1.2}`}
        className={className}
        width={"100%"}
        height={height}
        preserveAspectRatio="none"
        shapeRendering="crispedges"
        style={style}>
        {bar}
      </svg>
    )
  }
}

SegmentedBar.defaultProps = {
  width: 100,
  height: 120,
  style: {
    display: "block"
  }
}