import React from "react";
import AnimatedRect from "components/AnimatedRect";
import { scaleLinear } from "d3-scale";
import styles from "./questions.module.scss";
const width = 1000;
const height = 25;
const Progress = ({ current, total }) => {
  const scale = scaleLinear().domain([-1, total]).range([0, width]);

  return (
    <div className={styles.progress}>
      <svg style={{ display: "block" }} viewBox={`0 0 ${width} ${height}`}>
        <rect x={0} y={0} width={width} height={height} fill={"#000"} />
        <AnimatedRect
          width={scale(current)}
          height={height}
          className={`fill--debt`}
        />
      </svg>
    </div>
  );
};

export default Progress;
