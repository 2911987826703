import React from "react";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import CalculatorIcon from "components/icons/CalculatorIcon";
import DownloadIcon from "components/icons/DownloadIcon";
import ShareIcon from "components/icons/ShareIcon";
import "./controls.scss";

const Controls = ({
  loggedInUserIsOwner,
  calcKey,
  startYear,
  endYear,
  displayTitle,
  user,
  baseline,
  definitions,
  isMobile,
  toggleShare,
}) => {
  return (
    <div className="Report-controls bg--widget">
      <div className="Report-control Report-control-buttons">
        {/* {loggedInUserIsOwner && (
          <Link className="button-inline" to={`/${calcKey}/edit`}>
            <CalculatorIcon text="Edit this budget" />
          </Link>
        )} */}
        <Link className="button-inline" to="/new">
          <CalculatorIcon text="Create a new budget" />
        </Link>
        <Link
          className="button-inline"
          to={loggedInUserIsOwner ? `/${calcKey}/edit` : `/newfrom/${calcKey}`}
        >
          <CalculatorIcon
            text={
              loggedInUserIsOwner ? "Edit this budget" : "Modify this budget"
            }
          />
        </Link>
        <button
          className="button-inline download"
          onClick={() => {
            confirmAlert({
              customUI: ({ onClose }) => {
                const baselineTitle = `CBO Projections (${startYear}-${endYear}).csv`;
                const userTitle = `${displayTitle} (${startYear}-${endYear}).csv`;
                return (
                  <div className="AlertModal text-align--center">
                    <h1 className="AlertModal-heading font--h1">
                      Download Data
                    </h1>
                    <DownloadList
                      user={user}
                      baseline={baseline}
                      definitions={definitions}
                      userTitle={userTitle}
                      baselineTitle={baselineTitle}
                    />
                    <div className="AlertModal-buttons">
                      <button
                        className="button button--default"
                        onClick={onClose}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                );
              },
            });
          }}
        >
          <DownloadIcon />
        </button>
        <button className="button-inline" onClick={toggleShare}>
          <ShareIcon />
        </button>
      </div>
    </div>
  );
};

export default Controls;

class DownloadList extends React.Component {
  constructor() {
    super();

    this.state = {
      links: null,
    };
  }
  componentWillMount() {
    const { user, baseline, definitions } = this.props;
    import("downloadVariables").then((response) => {
      const varsToInclude = response.default;
      const links = {
        user: convertCalcToCSV(user, definitions, varsToInclude),
        baseline: convertCalcToCSV(baseline, definitions, varsToInclude),
      };

      this.setState({
        links,
      });
    });
  }
  render() {
    const { userTitle, baselineTitle } = this.props;

    return this.state.links ? (
      <ul className="AlertModal-list font--h3">
        <li>
          <a
            className="color--black"
            href={
              "data:text/csv;charset=utf-8," + encodeURI(this.state.links.user)
            }
            download={userTitle}
          >
            {userTitle}
          </a>
        </li>
        <li>
          <a
            className="color--black"
            href={
              "data:text/csv;charset=utf-8," +
              encodeURI(this.state.links.baseline)
            }
            download={baselineTitle}
          >
            {baselineTitle}
          </a>
        </li>
      </ul>
    ) : (
      "Loading"
    );
  }
}

const ConvertToCSV = (array) => {
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line !== "") line += ",";

      line += array[i][index] !== undefined ? array[i][index] : "";
    }

    str += line + "\r\n";
  }

  return str;
};

const convertCalcToCSV = (calc, definitions, varsToInclude) => {
  const flat = { ...flattenValues(calc.ECON), ...flattenValues(calc.BUDGET) };
  Object.keys(flat).forEach((cat) => {
    if (!varsToInclude.includes(cat)) {
      delete flat[cat];
    }
  });
  const valuesByYear = getValuesByYear(
    Object.values(flat),
    calc.startYear,
    calc.endYear
  );
  const result = [
    [
      "year",
      ...Object.keys(flat).map((cat) =>
        definitions[cat] ? definitions[cat] : cat
      ),
    ],
    ...valuesByYear,
  ];
  const string = ConvertToCSV(result);
  return string;
};

const getValuesByYear = (values, startYear, endYear) => {
  const years = Object.keys(values[0]).filter(
    (d) => d >= startYear && d <= endYear
  );

  const result = years.reduce((allYearValues, year) => {
    const yearValues = Object.values(values).reduce(
      (r, cat) => {
        r.push(cat[year]);
        return r;
      },
      [year]
    );

    allYearValues.push(yearValues);

    return allYearValues;
  }, []);

  return result;
};

const flattenValues = (obj) => {
  return Object.keys(obj).reduce((r, d) => {
    r[d] = obj[d].values;
    return r;
  }, {});
};
