module.exports = {
  "Start year of data (projections will begin in the following year)": 2019,
  "Years Projected": 30,
  "Share of output growth attributed to capital growth": 0.35,
  "Marginal Tax Rate on Labor Income in Start Year": .268,
  "Marginal Tax Rate on Labor Income in final year of 10-year outlook": .303,
  "Marginal Tax Rate on Labor Income in final year of long-term projection": .32,
  "Marginal Tax Rate on Capital Income in Start Year": .157,
  "Marginal Tax Rate on Capital Income in final year of 10-year outlook": .157,
  "Marginal Tax Rate on Capital Income in final year of long-term projection": .157,
  "Elasticity of Labor Supply to After-Tax Wage": 0.19,
  "Elasticity of Private Saving Rate to After-Tax Interest Rate": 0.20,
  "Elasticity of Income Tax Revenue with Respect to Real GDP Growth": 1.25,
  "Elasticity of Other Tax Revenue with Respect to Real GDP Growth": 1.53,
  "Assumed depreciation of capital rate": .1,
  "Elasticity of savings with respect to the deficit": -.1168,
  "Social Security Replacement": .403,
  "Percentage point change in nominal interest rate from one percentage point change in deficit (as a percent of GDP)": .0002,
  "Full Retirement Age (FRA)": 67,
  "Average Retirement Age (ARA)": 64.5,
  "PIT_CAPSHARE": .25,
  "PAYROLL_CAPSHARE": 0,
  "CIT_CAPSHARE": 1,
  "OTHERTAXES_CAPSHARE": 0
}