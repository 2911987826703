import React, { Component } from 'react';

const INTERVAL = 10

export default class Counter extends Component {
  static defaultProps = {
    duration: 500,
    format: (d) => Math.round(d)
  }

  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      if (this.counter) clearInterval(this.counter)

      this.startTime = new Date()
      this.startValue = prevProps.value
      this.counter = setInterval(() => {
        const elapsed = Date.now() - this.startTime

        const newValue = this.state.value + (this.props.value - this.state.value) * Math.min(elapsed/this.props.duration,1)
        this.setState({
          value: newValue
        })
        if (newValue === this.props.value) clearInterval(this.counter)

      }, INTERVAL)
    }
  }

  componentWillUnmount() {
    clearInterval(this.counter)
  }

  render() {
    const { format, ...rest } = this.props
    return (
      <span {...rest}>{format(this.state.value)}</span>
    )
  }
}