export default {
  "SOCIAL_SECURITY_ALL": "Provides cash benefits to retirees, their spouses, and their surviving spouses and dependents. To qualify for the program, a recipient must have worked for several years (typically 10) prior to their retirement. The program is funded through Social Security payroll taxes. These funds are credited to the Old Age and Survivors Insurance Trust Fund (OASI). Provides cash benefits to disabled Americans who were regularly in the workforce prior to their disability. The program is funded through Social Security payroll taxes. These funds are credited to the Disability Insurance Trust Fund (DI).",
  "MEDICARE": "Offers subsidies for the health care expenses of the elderly (65 and older) and the disabled.  Medicare has three parts. Part A is Hospital Insurance, which subsidies inpatient care for Medicare recipients. Part B, Supplemental Medical Insurance (SMI), provides health insurance for routine health care. Part D subsidizes Medicare recipients’ prescription drug costs. Part A is funded through a dedicated payroll tax. Part B and D are paid for through general tax revenue and recipient premiums.",
  "MEDICAID": "Provides health care assistance for low-income families and individuals. States operate their own Medicaid programs, which are subsidized by the Federal Government.",
  "CHIP": "Pays for health care for children of families who do not qualify for Medicaid assistance.",
  "ACA": "Pays for the purchase of private health insurance for low and middle-income families who are not enrolled in Medicaid.",
  "REFUNDABLE_TAX_CREDITS": "Lowers or eliminates the tax liability of some low and middle-income taxpayers. If taxpayers do not owe any income taxes, they can receive a check from the government for any excess credit remaining. These tax credits include the Earned Income Tax Credit (EITC) and the Additional Child Tax Credit.",
  "VA_MANDATORY": "Offers cash assistance for disabled veterans, retirement benefits for low-income veterans, and other benefits and services for veterans. Veterans may also receive health care assistance but this money is funded through discretionary spending.",
  "UNEMPLOYMENT": "Pays workers benefits when they are unemployed and looking for work. States typically administer the program.",
  "SUPPLEMENTAL_SECURITY": "Provides cash assistance for seniors and the disabled who do not qualified for Social Security.",
  "NUTRITIONAL_ASSISTANCE": "Subsidizes the purchase of food for low-income Americans. Nutrition programs include the Supplemental Nutrition Assistance Program (SNAP; formerly called food stamps), child nutrition programs that provide free or reduced priced school lunches and breakfasts, and the Women, Infants, and Children program (WIC).",
  "AGRICULTURE": "Provides farmers with various subsidies including direct subsidies, crop insurance, and other assistance.",
  "OTHER_MANDATORY": "Includes mandatory spending for certain higher education expenses, some low-income assistance programs, deposit insurance, Government Sponsored Enterprises (e.g. Freddie Mac, Fannie Mae), and various other programs.",
  "EDUCATION": "Offers grants and other subsidies for elementary, secondary, and higher education.",
  "ENERGY": "Funds energy research, nuclear security, and other energy related activities.",
  "ENVIRONMENT": "Funds environmental clean-up efforts, enforcement of environmental laws and regulations, and related activities.",
  "TRANSPORTATION": "Provides grants and subsidies for the maintenance of federal highways, public transportation, and related activities.",
  "INTERNATIONAL": "Funds diplomatic activities include the State Department and foreign aid for development and security.",
  "JUSTICE": "Funds the Department of Justice, Homeland Security, and other activities related law-enforcement and border security.",
  "SCIENCE": "Provides grants and subsidies for basic science and technology research.",
  "VA_DISCRETIONARY": "Offers health care assistance for disabled and low-income veterans. Cash assistance for some veterans is also available but is funded through mandatory spending.",
  "OTHER_DISCRETIONARY": "Includes funding for various programs including legislative activities (i.e. Congressional salaries and other expenses), the Judicial Branch, and the budgets of various government agencies.",
  "DEFENSE_OPERATIONS": "Funds overseas military activities and training for the military.",
  "DEFENSE_OTHER": "Includes construction and maintenance for military bases and military family housing.",
  "DEFENSE_PERSONNEL": "Pays for the salary and benefits of active armed service members.",
  "DEFENSE_PROCUREMENT": "Funds the purchases of equipment for the armed services",
  "DEFENSE_RTDE": "Finances the development of new weapons systems, research for future tactics and strategy, and other related military activities.",

  "PERSONAL_INCOME_TAXES": "Taxes paid on personal income. The tax rates vary by income from 10% to 37%.  The tax code includes several provisions that will lower a filer’s tax liability. These include tax credits, tax exclusions, and tax exemptions.",
  "SOCIAL_SECURITY_TAXES": "These taxes funds Social Security’s Old Age, Survivor and Disability Trust Fund which goes to Social Security retirees, disabled workers, their spouses, and their survivors. The tax is split evenly between employers and employees. The total tax rate paid is 12.4% on the first $128,400 of wages and salaries.",
  "MEDICARE_TAXES": "Americans pay a 2.9 percent tax on their earnings to fund Medicare’s Hospital Insurance program which subsidies inpatient hospital care to eligible seniors. There is also an additional tax of 0.9 percent on high income earners.",
  "OTHER_PAYROLL_TAXES": "These include taxes for unemployment insurance that are collected by states but credited as federal revenue, employee and employer contributions to the Railroad Retirement program, and some retirement contributions by federal workers.",
  "CORPORATE_INCOME_TAXES": "Most large corporations must incorporate as a C-corporation, or simply a C-corp.  These businesses pay taxes under different tax rules than other businesses. Prior to 2018, the top tax rate was equal to 35%; Congress lowered the top rate to 21% beginning in 2018.",
  "OTHER_TAXES": "Taxes on the purchase of a variety of goods including gas taxes and tariffs on certain imported goods.",

  "TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE": "The share of economic growth that is not attributed to growth in the labor supply or an increase in capital.",
  "LABOR_FORCE_GROWTH_RATE": "The annual percent increase in the number of people in the labor force.",
  "UNEMPLOYMENT_RATE": "The percent of workers in the labor force who are actively looking for work but do not have a job. ",
  "INFLATION_RATE": "The annual percent increase in average prices. ",
  "REAL_INTEREST_RATE": "The rate of interest in excess of the inflation rate that the government pays on the federal debt."
}