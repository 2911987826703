import React, { Component } from 'react';
import ArticleListItem from "./ArticleListItem.js";

export default class FeaturedArticles extends Component {
  constructor() {
    super()

    this.state = {
      data: null
    }
  }

  componentDidMount() {
    fetch('https://api.americaoffbalance.org/featuredposts_calculator.json')
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          data
        })
      })
  }

  render() {
    return this.state.data ? (
      <div className="FeaturedArticles home-pad">
        <h2 className="font--h2"><a className="reset-link" href="https://budgetmatters.americaoffbalance.org">Budget Matters</a></h2>
        <div className="ArticleList">
          {this.state.data.map((props) => <ArticleListItem key={props.id} variant="medium" {...props} />)}
        </div>
      </div>
    ) : "Loading..."
  }
}
