import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CategoryChanges from "./category-changes";
import Tooltip from "react-tooltip";
import "./budget-changes.scss";

const pullCats = (
  cats,
  changes,
  definitions,
  genre,
  baseline,
  user,
  displayYear
) => {
  return cats.reduce((r, d) => {
    if (!changes[d]) return r;

    r.push(
      <CategoryChanges
        key={d}
        catKey={d}
        changes={changes[d]}
        genre={genre}
        definitions={definitions}
        baseline={baseline}
        user={user}
        displayYear={displayYear}
      />
    );
    return r;
  }, []);
};

const reduceChanges = (
  categories,
  changes,
  definitions,
  genre,
  baseline,
  user,
  displayYear
) => {
  //shallow econ
  if (Array.isArray(categories)) {
    return pullCats(
      categories,
      changes,
      definitions,
      genre,
      baseline,
      user,
      displayYear
    );
  }

  return Object.keys(categories).reduce((r, d) => {
    if (Array.isArray(categories[d])) {
      const pulledCats = pullCats(
        categories[d],
        changes,
        definitions,
        genre,
        baseline,
        user,
        displayYear
      );
      return r.concat(pulledCats);
    } else if (typeof categories[d] === "object") {
      return r.concat(
        reduceChanges(
          categories[d],
          changes,
          definitions,
          baseline,
          user,
          displayYear
        )
      );
    } else {
      console.log("what?", categories[d]);
      return r;
    }
  }, []);
};

class UnconnectedBudgetChanges extends Component {
  static contextTypes = {
    store: PropTypes.object,
  };

  constructor() {
    super();
    this.state = {
      top: null,
    };
  }
  render() {
    const {
      changes,
      categories,
      className = "",
      definitions,
      baseline,
      user,
      displayYear,
    } = this.props;

    const disc = reduceChanges(
        categories.SPENDING.DISCRETIONARY,
        changes,
        definitions,
        "spending",
        baseline,
        user,
        displayYear
      ),
      mand = reduceChanges(
        categories.SPENDING.MANDATORY,
        changes,
        definitions,
        "spending",
        baseline,
        user,
        displayYear
      ),
      rev = reduceChanges(
        categories.REVENUE.ALL_REVENUE,
        changes,
        definitions,
        "revenue",
        baseline,
        user,
        displayYear
      ),
      econ = reduceChanges(
        categories.ECONOMY,
        changes,
        definitions,
        "economy",
        baseline,
        user,
        displayYear
      );

    const noChanges = [];
    const sections = [
      {
        key: "revenue",
        value: rev,
      },
      {
        key: "spending",
        value: [...mand, ...disc],
      },
      {
        key: "economy",
        value: econ,
      },
    ].reduce((r, d) => {
      if (d.value.length) {
        r.push({
          key: d.key,
          count: rev.length,
          content: (
            <section className="ReportChanges-genre">
              <h3 className="ReportChanges-genre-heading">
                {d.key[0].toUpperCase() + d.key.slice(1)}
              </h3>
              <div className="ReportChanges-genre-body">{d.value}</div>
            </section>
          ),
        });
      } else {
        noChanges.push(d.key);
      }
      return r;
    }, []);

    return (
      <div className={`BudgetChanges ${className} report-module`}>
        <h2 className="font--h1">Proposed Budget Reforms</h2>
        {sections
          .sort((a, b) => b.count - a.count)
          .map(({ content, key }) => (
            <React.Fragment key={key}>{content}</React.Fragment>
          ))}
        {noChanges.length && (
          <div className="BudgetChanges-nochanges">
            There are no {noChangesStatement(noChanges)} changes.
          </div>
        )}
        <Tooltip />
      </div>
    );
  }
}
const mapBudgetStateToProps = (state) => ({
  definitions: state.calculator.definitions,
  categories: state.calculator.categories,
  baseline: state.calculator.baseline,
  user: state.calculator.user,
  displayYear: state.session.displayYear,
});

export default connect(mapBudgetStateToProps)(UnconnectedBudgetChanges);

function noChangesStatement(items) {
  items = items.map((item) => {
    return {
      className: item,
      label: item === "economy" ? "economic" : item,
    };
  });

  if (items.length === 1) {
    return (
      <span className={`color--${items[0].className}`}>{items[0].label}</span>
    );
  } else if (items.length === 2) {
    return (
      <>
        <span className={`color--${items[0].className}`}>{items[0].label}</span>{" "}
        or{" "}
        <span className={`color--${items[1].className}`}>{items[1].label}</span>
      </>
    );
  } else {
    return (
      <>
        <span className={`color--${items[0].className}`}>{items[0].label}</span>
        ,{" "}
        <span className={`color--${items[1].className}`}>{items[1].label}</span>{" "}
        or{" "}
        <span className={`color--${items[2].className}`}>{items[2].label}</span>
      </>
    );
  }
}
