module.exports = {
  ECON_BENCH: "Economic Model (orig) - Budget Calculator Benchmark Values",
  ECON_BASE: "Economic Model (orig) - Budget Calculator Baseline Values",
  ECON_MACRO: "Economic Model (orig) - Macroeconomic Feedback Calculations",
  ECON_CBO: "Economic Model (orig) - CBO Assumed",
  SS_OUTLAY: "bc_ss outlay model (orig)",
  HC_COMPONENTS: "bc_hc components calc (orig)",
  OASDHI: "bc_oasdhi rev model (orig)",
  BUDGET_INITIAL: "Budget Model (orig) - Initial Budget Projections",
  BUDGET: "Budget Model (orig) - Budget Projections",
  OTHER_MANDATORY: "bc_other mandatory (orig)",
  CBO_10: "cbo_10_year",
  CBO_ECON: "cbo_lt_econ",
  CBO_BUDGET: "cbo_lt_budget",
  TECHNICAL_ASSUMPTIONS: "Technical Assumptions",
  WEIGHTED_INTEREST_ON_DEBT: "Weighted Interest On Debt",
  TC_BASELINE: "tc_baseline",
  TC_CHANGE_OPTIONS: "tc_change_options",
  TC_CHANGE_GROWTHRATES: "tc_change_growthrates",
};
