import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import ReactResponsiveSelect from "react-responsive-select";
import "react-responsive-select/dist/ReactResponsiveSelect.css";
import ReactTooltip from "react-tooltip";

import ViewIcon from "./icons/ViewIcon";
import EditIcon from "./icons/EditIcon";
import DeleteIcon from "./icons/DeleteIcon";

import { formatPercentage, formatPercentageDelta } from "../formatDollars";

import { newCalc } from "../Actions";

import Versions, { selectVersionLabel } from "../versions.js";

import SelectCaret from "./icons/SelectCaret";

import { confirmAlert } from "react-confirm-alert";

import firebase from "firebase/app";
import "firebase/database";
const { database } = firebase;

// const Plus = () => (
//   <svg
//     width="95"
//     height="95"
//     viewBox="0 0 95 95"
//     xmlns="http://www.w3.org/2000/svg"
//     stroke="#056229"
//     className="Plus">
//     <path d="M47.5 94V1M94 47.5H1"/>
//   </svg>
// )

class Calculators extends Component {
  constructor() {
    super();

    this.state = {
      calcs: null,
      newCalcVersion: Versions.default,
      toNewCalc: false,
    };
    this.newCalcOnClick = this.newCalcOnClick.bind(this);
    this.updateCalcs = this.updateCalcs.bind(this);
    this.onNewCalcVersionChange = this.onNewCalcVersionChange.bind(this);
    this.createNewCalc = this.createNewCalc.bind(this);
  }

  componentWillMount() {
    this.calcRef = database().ref(
      `/users/${this.props.loggedInUser.uid}/calculators`
    );
    this.calcRef.on("value", this.updateCalcs);
    this.versionOptions = [];
    Object.keys(Versions).forEach((key) => {
      if (key !== "default") {
        this.versionOptions.push({
          text: key,
          value: Versions[key],
        });
      }
    });
  }

  componentWillUnmount() {
    this.calcRef.off("value");
  }

  updateCalcs(snapshot) {
    this.setState({
      calcs: snapshot.val() ? Object.values(snapshot.val()) : snapshot.val(),
    });
  }

  newCalcOnClick() {
    this.props.dispatch(newCalc);
  }

  onNewCalcVersionChange(change) {
    this.setState({
      newCalcVersion: change.value,
    });
  }

  createNewCalc() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="AlertModal text-align--center"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="AlertModal-heading font--h2">
              Create a new budget
            </div>
            <div className="AlertModal-list">
              <div className="font--label--alt" style={{ margin: "1em" }}>
                based on CBO projection
              </div>
              <ReactResponsiveSelect
                onChange={this.onNewCalcVersionChange}
                selectedValue={this.state.newCalcVersion}
                options={this.versionOptions}
                caretIcon={<SelectCaret />}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <div className="AlertModal-buttons">
              <button className="font--label button" onClick={() => onClose()}>
                Cancel
              </button>
              <button
                className="font--label button bg--user color--white border-color--user"
                onClick={() => {
                  this.setState(
                    {
                      toNewCalc: true,
                    },
                    () => onClose()
                  );
                }}
              >
                Create
              </button>
            </div>
          </div>
        );
      },
    });
  }

  render() {
    const { deleteCalc } = this.props;

    return this.state.toNewCalc ? (
      <Redirect to={`/new/${this.state.newCalcVersion}`} />
    ) : (
      <div className="Calculators page-pad">
        <h1 className="heading font--h2">Your Budgets</h1>
        <div className="new-calc color--user">
          <button
            className={`font--label button bg--user color--white reset-link border-color--user`}
            onClick={this.createNewCalc}
          >
            Create a new budget
          </button>
        </div>
        <div className="calculator-list">
          {this.state.calcs ? (
            <table className="Calculators-table">
              <tbody>
                <tr className="font--label--small">
                  <th>Created</th>
                  <th>Title</th>
                  <th className="display-on-md">Debt/GDP</th>
                  <th className="display-on-md">Change</th>
                  <th>Version</th>
                  <th>View</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
                {this.state.calcs
                  .sort((a, b) => {
                    a = a.calc.dateCreated;
                    b = b.calc.dateCreated;
                    return a > b ? -1 : a < b ? 1 : 0;
                  })
                  .map((d, i) => {
                    const {
                      title,
                      calcKey,
                      authorUid,
                      dateCreated,
                      userDebtGdp,
                      baselineDebtGdp,
                      version,
                    } = d.calc;

                    const VersionLabel = selectVersionLabel(version);

                    return (
                      <tr key={calcKey}>
                        <td>
                          {dateCreated
                            ? new Date(dateCreated).toLocaleDateString()
                            : ""}
                        </td>
                        <td style={{ width: "100%" }}>
                          {title || <span className="faded">Untitled</span>}
                        </td>
                        <td className="display-on-md font--number">
                          {userDebtGdp ? (
                            formatPercentage(userDebtGdp)
                          ) : (
                            <span className="faded">-</span>
                          )}
                        </td>
                        <td className="display-on-md font--number">
                          {userDebtGdp && baselineDebtGdp ? (
                            formatPercentageDelta(userDebtGdp - baselineDebtGdp)
                          ) : (
                            <span className="faded">-</span>
                          )}
                        </td>
                        <td className="font--number">{VersionLabel}</td>
                        <td>
                          <Link
                            className="table-link"
                            to={`/${calcKey}/view`}
                            data-tip="View Budget"
                          >
                            <ViewIcon color={"#000"} />
                          </Link>
                        </td>
                        <td>
                          <Link
                            className="table-link"
                            to={`/${calcKey}/edit`}
                            data-tip="Edit Budget"
                          >
                            <EditIcon color={"#000"} />
                          </Link>
                        </td>
                        <td>
                          <button
                            className="table-button"
                            onClick={deleteCalc(authorUid, calcKey)}
                            data-tip="Delete Budget"
                          >
                            <DeleteIcon color={"#000"} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

export default connect()(Calculators);
