import React, { useEffect, useState } from "react";
import Final from "components/embed/final";
import EntryPoint from "components/embed/entry-point";
import Questions from "components/embed/questions";
import { connect } from "react-redux";
import { newCalc, resetSession } from "Actions.js";
import useFetch from "hooks/useFetch";
import classes from "./instance.module.scss";

const Instance = ({ newCalc, slug }) => {
  const { loading, error, data } = useFetch(
    `https://api.americaoffbalance.org/embed/${slug}.json`
  );
  useEffect(() => {
    newCalc();
  }, [newCalc]);

  const [splash, setSplash] = useState(true);
  const [index, setIndex] = useState(0);
  const nextIndex = () => setIndex(index + 1);
  const reset = () => {
    newCalc();
    setSplash(true);
    setIndex(0);
  };

  if (loading || error || data.error) {
    return (
      <div className={classes.Embed}>
        <div
          className={`bg--revenue color--white`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {error && "Error"}
          {data && data.error && <div>{data.error.code}</div>}
        </div>
      </div>
    );
  }

  const questions = data.questions.map((d, i) => ({
    ...d,
    key: i + d.heading,
  }));

  return (
    <div className={classes.Embed}>
      {splash ? (
        <EntryPoint
          heading={data.heading}
          description={data.description}
          next={() => setSplash(false)}
        />
      ) : index === questions.length ? (
        <Final reset={reset} />
      ) : (
        <Questions questions={questions} index={index} nextIndex={nextIndex} />
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    newCalc: (version) => {
      dispatch(resetSession);
      dispatch(newCalc(version));
    },
  };
};
export default connect(null, mapDispatchToProps)(Instance);
