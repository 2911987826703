const
	datasets = {},
	fs = require("fs"),
	d3 = require("d3")

const OPTION_LENGTH = 50;

const billions = (d) => d * 1e6;
//racheted down to millions

const
	RAW_DIR = "/raw",
	PROCESSED_DIR = '/processed/'

// const {
//   //ECON_BENCH,
//   //ECON_BASE,
//   //ECON_MACRO,
//   ECON_CBO,
//   //SS_OUTLAY,
//   //HC_COMPONENTS,
//   //OASDHI,
//   //BUDGET_INITIAL,
//   //BUDGET,
//   //OTHER_MANDATORY,
//   CBO_10,
//   CBO_ECON,
//   CBO_BUDGET
// } = require("./sheets.js")

const rawSheets = {
	//cbo_10yr: 										"/cbo_10yr.csv",
	cbo_10yr_accountprojections: 	"/cbo_10yr_accountprojections_2018.csv",
	cbo_lt_econ: 									"/cbo_ltecon_2018.csv",
	//cbo_ltbudget: 								"/cbo_ltbudget_2018.csv",
	//cbo_ltbudgetdetail: 					"/cbo_ltbudgetdetail_2018.csv",
	//cbo_ltdiscretionary: 					"/cbo_ltdiscretionary_2018.csv",
	//cbo_10yr_payrollrev: 					"/cbo_10yr_payrollrev_2018.csv",
	"maturedebt_share":  					"/maturedebt_share.csv",
	"transformed cbo ltecon": 		"/cbo_ltecon_transformed_2018.csv",
	"HISTORICAL_ECON_RATES": 			"/historical econ rates.csv",
	"ssa_6g6": 										"/ssa_6g6_2018.csv",
	"ssa_6g2": 										"/ssa_6g2_2018.csv",
	"ssa_4b3": 										"/ssa_4b3_2018.csv",
	"ssa_5a1": 										"/ssa_5a1_2018.csv",
	"ssa_5a3": 										"/ssa_5a3_2018.csv",
	"cms_medicareenrollment": 		"/cms_medicareenrollment_2018.csv",
	"cbo_lthcexcesscost": 				"/cbo_lthcexcesscost_2018.csv",
	"cbo budget options": 				"/cbo budget options_2018.csv",
}

//Maybe user driven in the future
datasets.TECHNICAL_ASSUMPTIONS = require('./technicalAssumptions.js');

//pull these out for convenience
datasets["startYear"] = +datasets.TECHNICAL_ASSUMPTIONS["Start year of data (projections will begin in the following year)"]
datasets["endYear"] = datasets.startYear + +datasets.TECHNICAL_ASSUMPTIONS["Years Projected"]

//All raw
datasets.CBO_10 = require('./data-structures/cbo_10_year.js')(datasets)
datasets.CBO_LT_ECON = require('./data-structures/cbo_lt_econ.js')(datasets)
//datasets.CBO_LT_BUDGET = require ('./data-structures/cbo_lt_budget.js')(datasets)
datasets.ECON_CBO_ASSUMED = require('./data-structures/Economic Model (orig) - CBO Assumed.js')(datasets)

init()

function init()
{
	const results = []

	Object.keys(rawSheets).forEach(sheet => {
		const data = fs.readFileSync(__dirname + RAW_DIR + rawSheets[sheet], 'utf8'),
			parsed = {}

			parsed[sheet] = d3.csvParse(data)
			results.push(parsed)
	})
	processSheets(results);
}

function processSheets(results)
{

	const processedData = {}

	results.forEach(function(result) {
		Object.keys(result).forEach(key => {
			processedData[key] = result[key]
		})
	})

	processData(processedData);
}

function trimEmptyReducer(result, d) {
	d = d.trim()
	if (d !== "") {
		result.push(d.replace('.','-'))
	}
	return result
}

function pullOptions(parsedData)
{
	const startYear = datasets["startYear"]

	const options = parsedData["cbo budget options"].reduce((r,d) => {
		const option = {}

		const id = d.number;
		option.id = id.replace('.','-')
		option.isPercent = +d["percent"] === 1
		option.hide = +d["hide"] === 1

		option.category = d["annualbudget_variable"]
		if (option.category === undefined) {
			console.log("missing: " + d["annualbudget_variable"])
			return r;
		}

		if (datasets.CBO_10[option.category] === undefined) {
			console.log("missing in projections: " + option.category)
			return r;
		}

		option.growsAfter10 = false
		const additions = {}
		let i = startYear;

		while (i <= startYear + OPTION_LENGTH) {
			if (d[i]) {
				if (option.isPercent) {
					additions[i] = parseFloat(d[i])
				} else {
					additions[i] = billions(parseFloat(d[i]))/datasets.CBO_10[option.category].values[i];
				}
			} else {
				if (i === startYear + 10) {
					option.growsAfter10 = true
				}
				additions[i] = additions[i - 1]
			}
			i++
		}

		option.values = additions
		option.title = d["Title"]
		option.description = d["Description"]
		option.turnOn = d["secondaries_on"].split(',').reduce(trimEmptyReducer,[])
		option.turnOff = d["other_primaries_off"].split(',').reduce(trimEmptyReducer,[])
		option.url = d["url"]

		r.push(option)
		return r;
	}, [])

	return options
}

function processData(parsedData) {

	const
		processors = require('../../processors.js')(datasets,parsedData),
		processFormulas = processors.processFormulas,
		processPulls = processors.processPulls

	Object.keys(datasets).forEach(set => {
		//first process direct value pulls
		processPulls(datasets[set])

		//then functions that rely on direct value pulls
		processFormulas(datasets[set]);
	})

	datasets.HISTORICAL_ECON_RATES = parsedData.HISTORICAL_ECON_RATES[0]

	datasets["budget options"] = parsedData["budget_options"]

	const budgetOptions = pullOptions(parsedData)

	Object.keys(datasets.HISTORICAL_ECON_RATES).forEach(key => {
		datasets.HISTORICAL_ECON_RATES[key] = parseFloat(datasets.HISTORICAL_ECON_RATES[key])
	})

	fs.writeFile(__dirname + PROCESSED_DIR + "data.json", JSON.stringify(parsedData), (error) => {
		if (error) throw error;
		console.log('Parsed data has been saved!');
	});

	fs.writeFile(__dirname + PROCESSED_DIR + "calc.json", JSON.stringify(datasets), (error) => {
		if (error) throw error;
		console.log('Calc has been saved!');
	});

	fs.writeFile(__dirname + PROCESSED_DIR + "policyOptions.json", JSON.stringify(budgetOptions), (error) => {
		if (error) throw error;
		console.log('Budget Options by Category have been saved!');
	});

}
