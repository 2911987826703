import React, { Component } from "react";

export default ({
  xScale,
  yScale,
  userData,
  baselineData,
  width,
  height,
  formatValue,
  formatDelta,
  onSet,
  setAction = "click",
  displayYear,
  idPrefix,
}) => {
  let ticks = [];
  for (let i = xScale.domain()[0]; i <= xScale.domain()[1]; i++) {
    ticks.push(i);
  }
  const bandwidth = width / ticks.length;
  const dataSets = [userData, baselineData].filter((d) => d !== null);
  return (
    <g>
      {ticks.map((d) => (
        <HoverTarget
          idPrefix={idPrefix}
          year={d}
          dataSets={dataSets}
          xScale={xScale}
          yScale={yScale}
          height={height}
          bandwidth={bandwidth}
          key={d}
          formatValue={formatValue}
          formatDelta={formatDelta}
          isCurrentYear={d === displayYear}
          onSet={() => {
            onSet && onSet(d);
          }}
          setAction={setAction}
        />
      ))}
    </g>
  );
};

class HoverTarget extends Component {
  guidelineRef = React.createRef();

  render() {
    const {
      year,
      dataSets,
      xScale,
      yScale,
      height,
      bandwidth,
      onSet,
      isCurrentYear,
      idPrefix,
      setAction,
    } = this.props;

    const yValues = dataSets.map((r) => r.find((d) => d.year === year.value));
    const maxY = Math.max(...yValues);

    const setListener =
      setAction === "click"
        ? {
            onClick: onSet,
          }
        : setAction === "hover"
        ? {
            onMouseEnter: onSet,
          }
        : {};

    return (
      <g className="hover-target">
        {isCurrentYear && (
          <line
            className="display-year-line"
            y1={yScale.range()[0]}
            y2={yScale.range()[1]}
            x1={xScale(year)}
            x2={xScale(year)}
          />
        )}
        <rect
          className="hover-hit"
          x={xScale(year - 0.5)}
          y={0}
          width={bandwidth}
          height={height}
          {...setListener}
          data-tip
          data-for={idPrefix + year}
        />
        <line
          className="hover-guideline"
          ref={this.guidelineRef}
          x1={xScale(year)}
          y1={yScale(maxY)}
          x2={xScale(year)}
          y2={height}
        />
      </g>
    );
  }
}
