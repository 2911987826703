import React, { Component } from 'react';
import Modal from "react-modal";
import EditBudgetMeta from './EditBudgetMeta';

export default class EditBudgetMetaModal extends Component {
  render() {
    const { isOpen, toggle } = this.props
    return (
      <Modal
        className="EditBudgetMetaModal"
        isOpen={isOpen}
        onRequestClose={toggle}>
        <EditBudgetMeta toggleModal={toggle} />
      </Modal>
    );
  }
}