import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setDisplayYear } from '../Actions.js';
import ReactResponsiveSelect from 'react-responsive-select';
import SelectCaret from './icons/SelectCaret';

const DisplayYearSelect = ({ displayYear, setDisplayYear, startYear, endYear }) => {
  const options = []
  for (let i=startYear; i<=endYear; i++) {
    options.push({
      value: i,
      text: i
    })
  }
  return (
    <ReactResponsiveSelect
      selectedValue={displayYear}
      onChange={(option) => {
        if (option.altered) {
            setDisplayYear(option.value)
        }
      }}
      options={options}
      caretIcon={<SelectCaret />} />
  )
}
DisplayYearSelect.contextTypes = {
  store: PropTypes.object
}
const mapStateToProps = (state) => ({
  displayYear: state.session.displayYear,
  startYear: state.calculator.baseline.startYear,
  endYear: state.calculator.baseline.endYear
})
const mapDispatchToProps = (dispatch) => ({
  setDisplayYear: (value) => {
    dispatch(setDisplayYear(value))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(DisplayYearSelect)