import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Colors from "colors";
import find from "lodash/find";
import intersection from "lodash/intersection";
import { SlideToggle } from "react-slide-toggle";
import SlideToggleButton from "components/SlideToggleButton.js";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Flickity from "react-flickity-component";
import "flickity/css/flickity.css";
import {
  formatDollars,
  formatDollarsPercentageCost,
  formatDollarsPercentageGain,
} from "formatDollars.js";
import { getGenre } from "categoryFunctions";
import "./policy.scss";

const percentToDollars = (percentValues, dollarValues) =>
  Object.keys(percentValues).reduce((r, d) => {
    r[d] = percentValues[d] * dollarValues[d];
    return r;
  }, {});

class UnconnectedPolicyInfo extends Component {
  constructor() {
    super();
    this.toggleButtonRef = createRef();
  }
  render() {
    const {
      policy,
      message,
      type,
      startYear,
      endYear,
      categories,
      definitions,
      user,
    } = this.props;

    const { title, description, values, category, url } = policy;

    const categoryValues = user.BUDGET[policy.category].valuesWithoutPolicies;
    const dollarValues = percentToDollars(values, categoryValues);
    const valueYears = Object.keys(dollarValues).filter(
        (d) => d > startYear && d <= endYear
      ),
      totalYears = valueYears.length,
      total =
        valueYears.reduce((result, key) => (result += dollarValues[key]), 0) /
        totalYears;

    const genre = getGenre(category, categories);
    const formatChanges =
      genre === definitions.REVENUE
        ? formatDollarsPercentageGain
        : formatDollarsPercentageCost;

    return (
      <div className="ReportPolicy explainer policy-pad">
        <div>
          {title}. On average,{" "}
          <span className={"color--" + type}>{formatChanges(total)}</span> per
          year
        </div>
        {message && <div>{message}</div>}
        <SlideToggle
          collapsed={true}
          render={({ onToggle, setCollapsibleElement, toggleState }) => {
            return (
              <div className={"slide-toggle"} style={{ overflow: "hidden" }}>
                <SlideToggleButton
                  className="ReportPolicy-moretoggle"
                  buttonRef={this.toggleButtonRef}
                  onClick={() => {
                    this.toggleButtonRef.current.blur();
                    onToggle();
                  }}
                />
                <div ref={setCollapsibleElement}>
                  <div className="Policy-description">{description}</div>
                  <Flickity
                    className="Policy-amounts flickity--arrows-outside"
                    reloadOnUpdate={true}
                    options={{
                      contain: true,
                      freeScroll: true,
                      draggable: true,
                      pageDots: false,
                      groupCells: "100%",
                    }}
                    flickityRef={(c) => (this.flickity = c)}
                  >
                    {valueYears.map((y) => (
                      <div className="Policy-amount font--number" key={y}>
                        <div className="Policy-amount-year">{y}</div>
                        <div className={`Policy-amount-value color--${genre}`}>
                          {formatDollars(dollarValues[y])}
                        </div>
                      </div>
                    ))}
                  </Flickity>
                  {url && (
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="read-more external-link"
                    >
                      <span className="external-link-text font--label">
                        Source
                      </span>
                    </a>
                  )}
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

const mapPolicyInfoStateToProps = (state) => ({
  startYear: state.calculator.baseline.startYear,
  endYear: state.calculator.baseline.endYear,
  definitions: state.calculator.definitions,
  categories: state.calculator.categories,
  user: state.calculator.user,
});
export const PolicyInfo = connect(mapPolicyInfoStateToProps)(
  UnconnectedPolicyInfo
);

const Policy = ({
  policy,
  type,
  onPolicyToggle,
  enactedPolicyIds,
  budgetOptionsById,
}) => {
  const { disabled, id, turnOn, turnOff, category, checked } = policy;

  const labelStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: ".7rem",
    fontFamily: "basis-grotesque-mono",
    height: "100%",
    marginTop: "-1px",
  };

  const yesLabelStyle = {
    color: "white",
    paddingLeft: "2px",
    ...labelStyle,
  };

  const noLabelStyle = {
    color: "black",
    paddingRight: "2px",
    ...labelStyle,
  };

  const note = [];
  if (disabled) {
    let activated;
    const turnedOffBy = find(budgetOptionsById, (o) => {
      if (o.turnOn.indexOf(id) !== -1) {
        activated = true;
        return o;
      } else if (o.turnOff.indexOf(id) !== -1) {
        activated = false;
        return o;
      }
    });

    const verb = activated ? "activated" : "disabled",
      baseUrl = "/edit/" + type + "/" + turnedOffBy.category;

    note.push("This policy has been " + verb + " by ");
    note.push(
      <Link key={0} to={baseUrl + "#" + turnedOffBy.id} className="policy-name">
        {turnedOffBy.title}
      </Link>
    );
    if (turnedOffBy.category !== category) {
      note.push(", which can be found in ");
      note.push(
        <Link key={1} to={baseUrl}>
          {turnedOffBy.category}
        </Link>
      );
    }
  } else {
    if (turnOn && turnOn.length) {
      const options = turnOn.reduce((r, d) => {
        const option = budgetOptionsById[d];
        if (!option) {
          console.warn("Missing related budget option id: " + d);
        } else if (!option.hide) {
          r.push(option);
        }
        return r;
      }, []);

      if (options.length) {
        note.push(<div key={"onLabel"}>Turns on: </div>);
        note.push(
          <ul key={"onList"}>
            {options.map((option) => (
              <li key={option.id}>{option.title}</li>
            ))}
          </ul>
        );
      }
    }
  }

  const message =
    note.length > 0 &&
    (disabled ? (
      <div className="disabled-message">{note}</div>
    ) : (
      <div className="enabled-message">{note}</div>
    ));

  return (
    <li
      className={`Policy font--label ${checked && "is-enacted"}`}
      disabled={disabled}
    >
      <div
        id={id}
        style={{
          opacity: 0,
          height: 0,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        {id}
      </div>
      <PolicyInfo policy={policy} message={message} type={type} />
      <Switch
        className={"Switch"}
        onChange={(nowChecked) => {
          const turnOffsOn = intersection(turnOff, enactedPolicyIds);
          if (turnOffsOn.length) {
            const confirmation = "Enacting this option will deactivate: ";
            const turnOffs = turnOffsOn.map((d) => (
              <li key={d}>{budgetOptionsById[d].title}</li>
            ));

            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="AlertModal text-align--center">
                    <h1 className="AlertModal-heading font--h1">
                      {confirmation}
                    </h1>
                    <ul className="AlertModal-list font--label">{turnOffs}</ul>
                    <div className="AlertModal-buttons">
                      <button
                        className="button button--default"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                      <button
                        className="button button--user border-color--user bg--user color--white"
                        onClick={() => {
                          onPolicyToggle(id, nowChecked, turnOn, turnOff);
                          onClose();
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                );
              },
            });
          } else {
            onPolicyToggle(id, nowChecked, turnOn, turnOff);
          }
        }}
        checked={checked}
        disabled={disabled}
        checkedIcon={<div style={yesLabelStyle}>YES</div>}
        uncheckedIcon={<div style={noLabelStyle}>NO</div>}
        onColor={Colors[type].primary}
        onHandleColor={Colors.user}
        offColor={Colors[type].muted}
        offHandleColor={Colors[type].primary}
        activeBoxShadow={"0px 0px 2px 3px rgba(0,0,0,.25)"}
      />
    </li>
  );
};
Policy.contextTypes = {
  store: PropTypes.object,
};
const mapStateToProps = (state) => ({
  budgetOptionsById: state.calculator.budgetOptionsById,
});
export default connect(mapStateToProps)(Policy);
