import React from "react";
import Policy from './Policy';

export default (props) => {
	const policies = props.policies.map(function(d) {
		return <Policy
      key={d.id}
			policy={d}
			type={props.type}
			enactedPolicyIds={props.enactedPolicyIds}
			onPolicyToggle={props.onPolicyToggle} />
	})
	return (
		<ul className="PolicyList font--body--small">{policies}</ul>	)
}