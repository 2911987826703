import find from "lodash/find";
import filter from "lodash/filter";
import camelCase from "./camelCase.js";

const hasKey = (object, key) => {
  let result = false;
  if (Array.isArray(object)) {
    return object.includes(key);
  } else {
    Object.keys(object).forEach((innerkey) => {
      if (hasKey(object[innerkey], key)) {
        result = true;
      }
    });
  }
  return result;
};

export const categoryValues = (cats, data) => {
  return cats.map((d) => {
    if (data[d] === undefined) {
      throw new Error("Missing: " + d);
    }
    return data[d].values;
  });
};

export const categoryData = (cats, objectCreator) => {
  return cats.reduce((r, d) => {
    r[d] = objectCreator(d);
    return r;
  }, {});
};

export const sumCategories = (cats, data, year) =>
  cats.reduce((r, d) => r + data[d].values[year], 0);

export const childCategories = (cat, flatCats) => {
  let children = filter(flatCats, (d) => d.parent === cat);

  let kids = filter(flatCats, (d) => find(children, (c) => c.key === d.parent));

  children = [...children, ...kids];

  return children;
};

export const grandchildCategories = (cat, flatCats) => {
  return childCategories(cat, flatCats).reduce((r, d) => {
    if (d.bottom) {
      return [...r, d];
    }
    return [...r, ...childCategories(d.key, flatCats).filter((d) => d.bottom)];
  }, []);
};

export const getGenre = (cat, categories) => {
  if (flattenTree(categories.ECONOMY).filter((d) => d.key === cat).length) {
    return "economy";
  } else if (
    flattenTree(categories.REVENUE).filter((d) => d.key === cat).length
  ) {
    return "revenue";
  } else if (
    flattenTree(categories.SPENDING).filter((d) => d.key === cat).length
  ) {
    return "spending";
  }
  return null;
};

export const determineGenre = (category, categories) => {
  let result = null;
  Object.keys(categories).forEach((genre) => {
    if (hasKey(categories[genre], category)) {
      result = genre;
    }
  });
  return result;
};

export const getGenreChanges = (changes, genre, categories) => {
  return changes
    .reduce((results, d) => {
      if (getGenre(d.key, categories) === genre) {
        results.push(d);
      }
      return results;
    }, [])
    .sort((a, b) => {
      //alpha
      if (a.key < b.key) {
        return -1;
      }
      if (a.key > b.key) {
        return 1;
      }
      return 0;
    });
};

export const flattenTree = (obj, parent = null) => {
  if (Array.isArray(obj)) {
    return obj.map((d) => ({
      key: d,
      parent: parent,
      bottom: true,
    }));
  } else {
    return Object.keys(obj).reduce((prev, d) => {
      prev.push({ key: d, parent: parent });
      return prev.concat(flattenTree(obj[d], d));
    }, []);
  }
};

const encodeSlug = (slug) => {
  return slug.replace(/\s/g, "_").replace(/&/g, "+");
};
export const categoryUrl = (calcKey, genre, category) =>
  "/" +
  calcKey +
  "/edit/" +
  genre +
  (genre === "economy"
    ? "#" + camelCase(category)
    : "/" + encodeSlug(category));
