import { databasor } from "fb";

//Caculator
export const resetAll = {
  type: "RESET_USER_OPTIONS",
};

export const loadCalc = (calc) => ({
  type: "LOAD_CALC",
  calc,
});

export const saveCalc = {
  type: "SAVE_CALC",
};

export const deleteCalc = {
  type: "DELETE_CALC",
};

export const newCalc = (version) => ({
  type: "NEW_CALC",
  version,
});

export const newCalcFrom = (calcKey) => {
  console.log(calcKey);
  return (dispatch) => {
    databasor
      .ref(`/calculators/${calcKey}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          const calc = snapshot.val().calc;
          console.log(calc);

          dispatch({
            type: "NEW_CALC_FROM",
            calc,
          });
        }
      });
  };
};

export const updateTitle = (title) => ({
  type: "UPDATE_TITLE",
  title,
});

export const updateDescription = (description) => ({
  type: "UPDATE_DESCRIPTION",
  description,
});

export const addKey = (key) => ({
  type: "ADD_KEY",
  key,
});

export const removeKey = {
  type: "REMOVE_KEY",
};

export const setGrowthRate = (value, category) => ({
  type: "SET_GROWTH_RATE",
  value,
  category,
});

export const setGrowthRates = (values) => ({
  type: "SET_GROWTH_RATES",
  values,
});

export const setGrowthRateAdjustment = (value, category) => ({
  type: "SET_GROWTH_RATE_ADJUSTMENT",
  value,
  category,
});

export const setDollarAddition = (value, category, year) => ({
  type: "SET_DOLLAR_ADDITION",
  value,
  category,
  year,
});

export const addPolicy = (id, disabled) => ({
  type: "ADD_POLICY",
  id,
  disabled,
});

export const removePolicy = (id) => ({
  type: "REMOVE_POLICY",
  id,
});

export const updateGrowthRateAdjustment = (value, category) => ({
  type: "UPDATE_GROWTH_RATE_ADJUSTMENT",
  value,
  category,
});

export const updateGrowthRateAdjustments = (adjustments) => ({
  type: "UPDATE_GROWTH_RATE_ADJUSTMENTS",
  adjustments,
});

export const setGrowthRateAddition = (value, category) => ({
  type: "SET_GROWTH_RATE_ADDITION",
  category,
  value,
});
export const setGrowthRateAdditions = (values) => ({
  type: "SET_GROWTH_RATE_ADDITIONS",
  values,
});

export const updateGrowthRateAddition = (value, category) => ({
  type: "UPDATE_GROWTH_RATE_ADDITION",
  value,
  category,
});
export const updateGrowthRateAdditions = (values) => ({
  type: "UPDATE_GROWTH_RATE_ADDITIONS",
  values,
});

//Tax Calc
export const setMaritalStatus = (value) => ({
  type: "SET_MARITAL_STATUS",
  value,
});

export const setIncome = (value) => ({
  type: "SET_INCOME",
  value,
});

//Session
export const setDisplayYear = (value) => ({
  type: "SET_DISPLAY_YEAR",
  value,
});

export const setSidebarTabIndex = (value) => ({
  type: "SET_SIDEBAR_TAB_INDEX",
  value,
});

export const resetSession = {
  type: "RESET_SESSION",
};

export const toggleTutorial = (value) => ({
  type: "TOGGLE_TUTORIAL",
  value,
});
