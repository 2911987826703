import React, { Component } from "react";
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import find from "lodash/find";
import invert from "lodash/invert";

import { max } from "d3-array";
import { scaleLinear } from "d3-scale";
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import PolicyList from './PolicyList';
import DoneEditing from './DoneEditing';
import CategoryHeader from './CategoryHeader';
import CategorySnapshot from './CategorySnapshot';
import GrowthRatePercentageInput from './GrowthRatePercentageInput';
import ReactTooltip from 'react-tooltip';

import CategoryMeta from '../categorymeta';


import { setGrowthRate, setGrowthRateAdjustment, setDollarAddition, removePolicy, addPolicy } from '../Actions.js';

import ScrollToTopOnMount from './ScrollToTopOnMount';

import {
	formatDollars,
	formatDollarsDelta
} from "../formatDollars.js";

import { flattenTree } from '../categoryFunctions';

//const getKeyByValue = (object, value) => (Object.keys(object).find(key => object[key] === value))

class CategoryDetail extends Component {
	constructor() {
		super()
		this.onGrowthRateChange = this.onGrowthRateChange.bind(this)
		this.onGrowthRateAdjustmentChange = this.onGrowthRateAdjustmentChange.bind(this)
	}

	componentDidMount() {
		ReactTooltip.rebuild()
	}

	onGrowthRateChange(cat) {
		return (value) => {
			this.props.setGrowthRate(value,cat)
		}
	}

	onGrowthRateAdjustmentChange(cat) {
		return (value) => {
			this.props.setGrowthRateAdjustment(value, cat)
		}
	}

	render() {
		const {
			calc,
			addPolicy,
			removePolicy,
			setGrowthRateAddition,
			setDollarAddition,
			match,
			displayYear
		} = this.props;

		//todo.  map these to props
		const {
			user,
			userOptions,
			baseline,
			definitions,
			budgetOptionsByCategory,
		} = calc;

		const reverseDefinitions = invert(definitions);

		const flatCats = flattenTree(calc.categories)

		const
			label = decodeSlug(match.params.category),
			cat = reverseDefinitions[label],
			genre = match.params.genre,
			options = budgetOptionsByCategory[cat] || []

		const policiesEnacted = userOptions.policiesEnacted || []

		const policies = options.map(function(d) {
			const p = find(policiesEnacted, {id: d.id})
			return {
				...d,
				key: d.id,
				checked: p !== undefined,
				disabled: p !== undefined ? p.disabled : false,
				category: cat,
				categoryLabel: definitions[cat]
			}
		})

		const
			enactedPolicyIds = policiesEnacted.map((p) => p.id),
			values = user.BUDGET[cat].values,
			dollarAdditions = userOptions[cat].additions,
			startYear = user.startYear,
			endYear = user.endYear,
			growthRateValue = userOptions[cat].growthRate,
			growthRateAdjustmentValue = userOptions[cat].growthRateAdjustment,
			growthRateAdditionValue = userOptions[cat].growthRateAddition,
			parents = getParents(cat),
			parentGrowthRateAdditionValue = userOptions[find(flatCats, {key: cat}).parent].growthRateAddition

		function getParents(key) {
			let result = [makeParentAddition(key)]
				result = result.concat(makeParentAddition(result[0].key))
				//result = result.concat(makeParentAddition(result[1].key))
			return result;
		}
		function makeParentAddition(key) {
			const parentKey = find(flatCats, {key: key}).parent

			return {
				key: parentKey,
				value: userOptions[parentKey].growthRateAddition
			}
		}
		function onPolicyToggle(id,isChecked, additions=[], subtractions=[])
		{

			if (isChecked) {
				addPolicy(id)
				additions.forEach(function(d) {
					addPolicy(d,true)
				})
				subtractions.forEach(function(d) {
          const sub = policies.find(r => r.id === d)
          if (sub) {
            sub.turnOn.forEach(id => removePolicy(id))
          }
					removePolicy(d)
				})
			} else {
				removePolicy(id)
				additions.forEach(function(d) {
					removePolicy(d)
				})
			}
		}

		function onGrowthSliderChange(value) {
			setGrowthRateAddition(cat, value)
		}

		function onDollarChange(value,year) {
			setDollarAddition(cat, value, year)
		}

		return (
			<article style={this.props.style} className={`CategoryDetail shadow page-pad`}>
				<ScrollToTopOnMount />
				<div className={`CategoryDetail-inner border-color--${genre}`}>
					<DoneEditing to={"./"} />
					<CategoryHeader
						className="text-align--center outer-container"
						title={<h1 className={`font--h1  color--${genre}`}>{label}</h1>}
						description={CategoryMeta[cat]}
						onGrowthRateChange={this.onGrowthRateChange(cat)}
						onGrowthRateAdjustmentChange={this.onGrowthRateAdjustmentChange(cat)}
						growthRateAdjustmentValue={growthRateAdjustmentValue}
						growthRateValue={growthRateValue}
						onGrowthSliderChange={onGrowthSliderChange}
						growthRateAdditionValue={growthRateAdditionValue}
						growthRateAdditionMarks={parents.reduce((a,b) => {
								if (b.value !== 0) {
									a[b.value] = b.key
								}
								return a
							}, {})}
						parentGrowthRateAdditionValue={parentGrowthRateAdditionValue}>
						<CategorySnapshot
							cboValue={baseline.BUDGET[cat].values[displayYear]}
							yourValue={user.BUDGET[cat].values[displayYear]}
							genre={genre}
							year={displayYear} />
					</CategoryHeader>

					<div className="detail-section inner-container">
						<div className={`font--body color--${genre} text-align--center uppercase bold`}>Adjust dollars year by year</div>
						<YearByYear
							key={'yby' + cat}
							genre={genre}
							startYear={startYear}
							endYear={endYear}
							values={values}
							onDollarChange={onDollarChange}
							dollarAdditions={dollarAdditions} />
					</div>
					<div className="detail-section inner-container">
						{policies.length ?
						(<div>
							<div className={`font--body color--${genre} text-align--center uppercase bold`}>Enact proposed policy changes</div>
							<PolicyList
								type={genre}
								policies={policies.filter((d) => !d.hide)}
								enactedPolicyIds={enactedPolicyIds}
								onPolicyToggle={onPolicyToggle} />
						</div>) : undefined}
					</div>
				</div>
			</article>
		)
	}
}
CategoryDetail.contextTypes = {
	store: PropTypes.object
}
const mapStateToProps = (state) => ({
	calc: state.calculator,
	displayYear: state.session.displayYear
})

const mapDispatchToProps = (dispatch) => {
	return {
		setGrowthRate: (value,category) => {
			dispatch(setGrowthRate(value,category))
		},
		addPolicy: (id,disabled=false) => {
			dispatch(addPolicy(id,disabled))
		},
		removePolicy: (id) => {
			dispatch(removePolicy(id))
		},
		setGrowthRateAdjustment: (value, category) => {
			dispatch(setGrowthRateAdjustment(value,category))
		},
		setGrowthRateAddition: (category, value) => {
			dispatch({
				type: "SET_GROWTH_RATE_ADDITION",
				category,
				value
			})
		},
		setDollarAddition: (category, value, year) => {
			dispatch(setDollarAddition(value, category, year))
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CategoryDetail);

class YearByYear extends Component {

	constructor() {
		super()

		this.state = {
			editingYear: null
		}
		this.closeEditor = this.closeEditor.bind(this);
	}

	componentDidMount() {
		this.flickity.on('staticClick', ( event, pointer, cellElement, cellIndex ) => {
			if (cellElement) {
				this.setState({
					editingYear: parseInt(cellElement.getAttribute('data-year'), 10)
				}, () => {
					this.flickity.reposition()
				})
			}
		})
	}

	closeEditor() {
		this.setState({
			editingYear: null
		}, () => {
			this.flickity.reposition()
		})
	}

	render() {
		const {
			dollarAdditions = {},
			values,
			startYear,
			endYear,
			onDollarChange,
			genre
		} = this.props;

		const grownValues = {}
		Object.keys(values).forEach(year => {
			dollarAdditions[year] = dollarAdditions[year] || 0
			grownValues[year] = values[year] - dollarAdditions[year]
		})

		const years = [],
			maximum = max(Object.values(grownValues)),
			scale = scaleLinear()
				.range([0,100])
				.domain([0,maximum*1.5])

		let year = startYear + 1,
			l=endYear

		for (;year<=l;year++) {
			const
				currentValue = values[year],
				baseValue = currentValue

			years.push(
				<Year
					genre={genre}
					year={year}
					key={year}
					scale={scale}
					baseValue={baseValue}
					grownValue={grownValues[year]}
					currentValue={currentValue}
					dollarAddition={dollarAdditions[year]}
					onDollarChange={onDollarChange}
					closeEditor={this.closeEditor}
					isEditing={this.state.editingYear === year}
				/>
			)
		}

		const flickityOptions = {
			pageDots: false,
			contain: true,
			draggable: true,
			freeScroll: true,
			groupCells: '100%',
			cellAlign: 'left',
		}

		return (
			<Flickity
				flickityRef={c => this.flickity = c}
				className="flickity YearByYear"
				options={flickityOptions}
			>
				{years}
			</Flickity>
		)
	}
}

const Year = ({
	year,
	genre,
	dollarAddition,
	currentValue,
	baseValue,
	grownValue,
	scale,
	onDollarChange,
	isEditing,
	closeEditor
}) => {
	const currentStyle =  {
		height: scale(currentValue) + '%'
	},
	baseStyle = {
		bottom: scale(grownValue) + '%'
	},
	formattedDollarAddition = dollarAddition ? formatDollarsDelta(dollarAddition) : formatDollarsDelta(0)

	const editDollars = (<EditDollars
		baseValue={baseValue}
		currentValue={inBillions(dollarAddition)}
		onChange={onDollarChange}
		year={year}
		visible={isEditing}
		onCloseClick={closeEditor} />)

	const hasAddition = dollarAddition !== 0

	return (
		<div
			data-year={year}
			className={"Year font--number" + (hasAddition ? ' has-addition' : '') + (isEditing ? ' is-editing' : '')} >
			<div className="year color--label">{year}</div>
			<div className="bar">
				<div className={`bar-current ${hasAddition ? 'bg--user' : 'bg--' + genre}`} style={currentStyle}>
				</div>
				<div className={`bar-baseline bg--${genre}`} style={baseStyle}></div>
			</div>
			{editDollars}
			<div className="bar-value">{formatDollars(currentValue)}</div>
			<div className="delta color--user">{formattedDollarAddition}</div>
		</div>
	)
}
function inBillions(value)
{
	return value/1e9
}
// function unBillions(value)
// {
// 	return value*1e9
// }

class EditDollars extends Component {

	constructor(props) {
		super(props)

		this.state = {
      currentValue: this.props.currentValue*1e3 || 0
    }
    this.update = this.update.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onInputBlur = this.onInputBlur.bind(this)
    this.onInputKeyPress = this.onInputKeyPress.bind(this)
		this.increment = this.increment.bind(this)
    this.decrement = this.decrement.bind(this)
	}

	update(force=false) {
		let newValue = this.state.currentValue;
    if (isNaN(newValue) || newValue === "") {
      newValue = 0
    }
    this.setState({
      currentValue: newValue
    }, function() {

      this.props.onChange(newValue*1e6, this.props.year)
    })
  }
  onInputChange(event) {
    let
      value = event.target.value,
      valueString = value.toString(),
      decimalIndex = valueString.indexOf('.')

    if (decimalIndex !== -1 && valueString.length > decimalIndex + 5) {
      valueString = valueString.substring(0,decimalIndex + 5)
    }
    this.setState({
      currentValue: valueString === "-" ? valueString : +valueString
    })
  }
  onInputBlur() {
    this.update()
  }
  onInputKeyPress(event) {
    if (event.key === 'Enter') {
      this.update()
    }
  }
	increment() {
    this.setState({
      currentValue: this.state.currentValue + +this.props.incrementAmount
    }, () => {
      this.update(true)
    })
  }
  decrement() {
    this.setState({
      currentValue: this.state.currentValue - +this.props.incrementAmount
    }, () => {
      this.update(true)
    })
  }

	render() {
		const {
			onCloseClick,
			visible
		} = this.props;

		const style = {
			display: visible ? 'flex' : 'none'
		}

		return (
			<div className="EditDollars" style={style}>
				<label>
					<GrowthRatePercentageInput
						currentValue={this.state.currentValue}
						onChange={this.onInputChange}
						onKeyPress={this.onInputKeyPress}
						onBlur={this.onInputBlur}
	          increment={this.increment}
	          decrement={this.decrement}
	          unit={"B"}
	          />
				</label>
				<button
					className="button genre-colors"
					onClick={onCloseClick}>
					Done
				</button>
			</div>
		)
	}
}

EditDollars.defaultProps = {
	incrementAmount: 10
}

function decodeSlug(slug)
{
	return slug.replace(/_/g,' ').replace(/\+/g, '&')
}
