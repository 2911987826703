import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { formatDollarsPercentageCost } from '../formatDollars';

const PolicyRecap = ({
  id,
  budgetOptionsById,
  definitions,
  genre
}) => {

  const option = budgetOptionsById[id];
  // type,
  // category,

  if (!option) {
    console.warn("Missing policy id: " + id)
    return null
  }
  const {
    category,
    description,
    url,
    values
  } = option


  const total = Object.keys(values).reduce((result, key) => {
    return result += values[key]
  }, 0)

  return (
    <li
      className="Policy font--body--small">
      <div className="explainer policy-pad">
        <div className={`category-title font--label color--${genre}`}>{definitions[category]}</div>
        <div>{description}</div>
        <div className="color--user">{formatDollarsPercentageCost(total)}</div>
        <a href={url} target="_blank" rel="noopener noreferrer">Read More</a>
      </div>
    </li>
  )
}

PolicyRecap.contextTypes = {
  store: PropTypes.object
}
const mapStateToProps = (state) => ({
  budgetOptionsById: state.calculator.budgetOptionsById ,
  definitions: state.calculator.definitions
})
export default connect(mapStateToProps)(PolicyRecap);