import React from "react";
import "./report-header.scss";
import { Link } from "react-router-dom";

const defs = {
  debt:
    "This is the amount of money the federal government has borrowed from the public. It does not include money owed to federal trust funds or other intergovernmental debt since the government owes this money to itself.",
  revenue:
    "This is the total amount the government expects to collect in taxes during the fiscal year.",
  spending:
    "This is the amount of outlays the government expects to pay out during the fiscal year. Outlays are only counted when the money is actually spent (as opposed to when Congress gives approval to spend the money).",
  deficit:
    "A Deficit is a shortfall in the government's budget, meaning we have spent more than we have collected in taxes.  A surplus is when the goverment spends less than it has collected in taxes.",
  percentgdp:
    "Gross domestic product or GDP is a measure of the total income earned in a country during the year. Expressing budget numbers as a percent of GDP shows the magnitude of the budget numbers with respect to a country’s ability to pay for it. It allows us to compare budget numbers over time.",
};

const Header = ({
  displayTitle,
  authorName,
  description,
  versionLabel,
  endYear,
  debtGdp,
  deficitSurplus,
  revenueGdp,
  spendingGdp,
  deficitGdp,
  dateCreated,
  dateUpdated,
  modifiedFrom,
}) => {
  return (
    <div className="Report-header report-module">
      <div className="Report-meta font--body">
        <h1 className="Report-header-heading">{displayTitle}</h1>
        {authorName && (
          <aside className="Report-header-author">by {authorName}</aside>
        )}
        {description && (
          <p className="Report-header-description">{description}</p>
        )}
        {versionLabel && (
          <ul className="Report-header-notes faded">
            {dateCreated && <li>Created: {getFormattedDate(dateCreated)}</li>}
            {dateUpdated && <li>Updated: {getFormattedDate(dateUpdated)}</li>}
            <li>Based on CBO Projections from {versionLabel}</li>
            {modifiedFrom && (
              <li>
                Modified From:{" "}
                <Link to={`/${modifiedFrom.calcKey}/view`}>
                  {modifiedFrom.title}
                </Link>
              </li>
            )}
          </ul>
        )}
      </div>
      <div className="Report-outlook">
        <div className="Report-outlook-equation">
          <OutlookItem
            year={endYear}
            label={"Revenue"}
            colorClass={"revenue"}
            value={revenueGdp}
            tooltip={defs.revenue}
          />{" "}
          <div className="Report-outlook-equation-symbol">-</div>{" "}
          <OutlookItem
            year={endYear}
            label={"Spending"}
            colorClass={"spending"}
            value={spendingGdp}
            tooltip={defs.spending}
          />{" "}
          <div className="Report-outlook-equation-symbol">=</div>
          <OutlookItem
            year={endYear}
            label={deficitSurplus}
            colorClass={"debt"}
            value={deficitGdp}
            tooltip={defs.deficit}
          />
        </div>
        <div className="Report-outlook-debt">
          <div className="Report-outlook-debt-explainer">{defs.debt}</div>
          <OutlookItem
            year={endYear}
            label={"Cumulative Debt"}
            colorClass={"debt"}
            value={debtGdp}
          />
        </div>
      </div>
      <div
        className="Report-outlook-tooltip"
        data-tip={defs.percentgdp}
        data-class="tooltip--info"
      >
        Why do we use % of GDP?{" "}
        <span className="GrowthRateSetting-tooltip faded"></span>
      </div>
    </div>
  );
};

export default Header;

const OutlookItem = ({ year, label, value, colorClass, tooltip }) => {
  return (
    <div className="Report-outlook-debt-value">
      <div className="Report-outlook-debt-value-label font--body color--label">
        {`${year} ${label}`}
        {tooltip && (
          <span
            className="GrowthRateSetting-tooltip faded"
            data-tip={tooltip}
            data-class="tooltip--info"
          ></span>
        )}
      </div>
      <div className={`font--number--huge color--${colorClass}`}>{value}</div>
      <div className="font--body color--label">of GDP</div>
    </div>
  );
};

const dtf = new Intl.DateTimeFormat("en", {
  year: "numeric",
  month: "long",
  day: "numeric",
});
function getFormattedDate(dateString) {
  const dateObj = new Date(dateString);
  const parts = dtf.formatToParts(dateObj);
  const [{ value: month }, , { value: day }, , { value: year }] = parts;
  return `${month} ${day}, ${year}`;
}
