import React, { Component, createRef } from "react";

import { SlideToggle } from "react-slide-toggle";
import SlideToggleButton from "./SlideToggleButton.js";
import GrowthRateSettings from './GrowthRateSettings';

export default class CategoryHeader extends Component {
  constructor() {
    super()
    this.slideToggleButtonRef = createRef()
  }
  render() {
    const {
      title,
      description,
      children,
      className="",
      onGrowthRateChange,
      growthRateValue,
      onGrowthRateAdjustmentChange,
      growthRateAdjustmentValue,
      onGrowthSliderChange,
      growthRateAdditionValue,
      parentGrowthRateAdditionValue,
      tooltipClass,
      settings = onGrowthRateChange && onGrowthRateAdjustmentChange ? (
        <GrowthRateSettings
          key={"initial_adjustment__" + growthRateAdjustmentValue}
          onGrowthRateChange={onGrowthRateChange}
          onGrowthRateAdjustmentChange={onGrowthRateAdjustmentChange}
          growthRateAdjustmentValue={growthRateAdjustmentValue}
          growthRateValue={growthRateValue}
          onGrowthSliderChange={onGrowthSliderChange}
          growthRateAdditionValue={growthRateAdditionValue}
          parentGrowthRateAdditionValue={parentGrowthRateAdditionValue}
          category={title}
          tooltipClass={tooltipClass}
        />
      ) : undefined,
      toggleSettings=false
    } = this.props

    return (
      <div className={`category-header ${className}`}>
        <div className="heading-wrap">
          {title}
        </div>
        {description && <div className="category-header-description">{description}</div>}
        {children}
        {settings && <div className="settings-wrap">
          {toggleSettings ? <SlideToggle
            collapsed={growthRateValue === "CBO_GROWTH" && growthRateAdjustmentValue === 0 && growthRateAdditionValue === 0}
            render={({ onToggle, setCollapsibleElement, toggleState }) => {
              return (
                <div className={'slide-toggle'} style={toggleState !== "EXPANDED" ? { overflow: 'hidden' } : undefined}>
                  <div className={`slide-toggle-content ${toggleState === 'EXPANDED' && 'is-open'}`} ref={setCollapsibleElement}>
                    {settings}
                  </div>
                  <SlideToggleButton
                    buttonRef={this.slideToggleButtonRef}
                    className={`slide-toggle-button-user bg--userLight color--label font--label--small ${toggleState !== 'COLLAPSED' && 'is-open'}`}
                    onClick={(event) => {
                      if (event.screenX !== 0 || event.screenY !== 0) {
                        this.slideToggleButtonRef.current.blur()
                      }
                      onToggle()
                    }}
                    label={toggleState === "COLLAPSED" ? <span className="label">Adjust</span> : ""}
                  />
                </div>
              )
            }}
          /> : settings}
        </div>}
      </div>
    )
  }
}