import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./configureStore";

import "./scss/style.scss";

import App from "./components/App.js";
import Embed from "./components/embed";

const store = configureStore();

const appNode = document.getElementById(
  process.env.REACT_APP_BUILD_FOR === "embed" ? "aobcalcembed" : "App"
);
const slug = appNode && appNode.getAttribute("data-slug");

ReactDOM.render(
  <Provider store={store}>
    {process.env.REACT_APP_BUILD_FOR === "embed" ? (
      <Embed slug={slug} />
    ) : (
      <App />
    )}
  </Provider>,
  appNode
);
