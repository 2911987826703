export function getAuthoredCalcs() {
  const rawAuthoredCalcs = window.localStorage.getItem("authoredCalcs");
  console.log(rawAuthoredCalcs);
  if (rawAuthoredCalcs) {
    return JSON.parse(rawAuthoredCalcs);
  }
  return null;
}

export function addAuthoredCalc(key) {
  const authoredCalcs = getAuthoredCalcs();
  let newAuthoredCalcs;
  if (authoredCalcs && authoredCalcs.indexOf(key) === -1) {
    newAuthoredCalcs = [...authoredCalcs, key];
  } else {
    newAuthoredCalcs = [key];
  }
  window.localStorage.setItem(
    "authoredCalcs",
    JSON.stringify(newAuthoredCalcs)
  );
}

export function isAuthoredCalc(key) {
  const authoredCalcs = getAuthoredCalcs();
  console.log(authoredCalcs);
  return authoredCalcs && authoredCalcs.indexOf(key >= 0);
}
