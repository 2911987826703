import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { DEFAULT_BUDGET_TITLE } from '../Constants.js';

import ReactTooltip from "react-tooltip";
import ViewIcon from './icons/ViewIcon';
import EditIcon from './icons/EditIcon';
import DeleteIcon from './icons/DeleteIcon';

import firebase from "firebase/app";
import 'firebase/database';
import { formatPercentageDelta } from '../formatDollars';

const { database } = firebase;

const ICON_FILL = "#fff";

class CalculatorTile extends Component {
  constructor() {
    super()

    this.deleteOnClick = this.deleteOnClick.bind(this)
  }

  deleteOnClick(authorUid, calcKey) {
    return () => {
      database().ref(`users/${authorUid}/calculators/${calcKey}`).remove()
      database().ref(`calculators/${calcKey}`).remove()
      database().ref(`meta/featuredCalculators/${calcKey}`).remove()
    }
  }

  render() {
    const { showTools } = this.props
    const {
      calcKey,
      authorUid,
      userDebtGdp,
      baselineDebtGdp,
      endYear
    } = this.props.calc

    const debtDiff = userDebtGdp - baselineDebtGdp;

    return (
      <div className="CalculatorTile" data-tip="blue" data-for="TooltipCalculators">
        <div
          className="CalculatorTile-inner bg--widget">
          <Link to={`/${calcKey}/view`} className="meta">
            <div className="title font--h3">{this.props.title ? this.props.title : DEFAULT_BUDGET_TITLE}</div>
            <div className="author font--label--small">by {this.props.author}</div>

          </Link>
          <Link to={`/${calcKey}/view`} style={{ position: 'relative' }}>

            {this.props.calc.tileImage && (
              <div className="CalculatorTile-chart-wrap"
                dangerouslySetInnerHTML={{ __html: this.props.calc.tileImage }} />
            )}
            <div className="CalculatorTile-info">
              <div className="CalculatorTile-debt">
                <div className="font--number">{formatPercentageDelta(debtDiff,0)} {endYear} Federal Debt</div>
              </div>
            </div>
          </Link>
          {showTools &&
            (<div className="CalculatorTile-ActionBar">
              <Link
                className="action-link"
                to={`/${calcKey}/view`}
                data-tip="View Budget">
                <ViewIcon color={ICON_FILL} /></Link>
              <Link
                className="action-link"
                to={`/${calcKey}/edit`}
                data-tip="Edit Budget">
                <EditIcon color={ICON_FILL} /></Link>
              <button
                className="action-button"
                onClick={this.deleteOnClick(authorUid,calcKey)}
                data-tip="Delete Budget">
                <DeleteIcon color={ICON_FILL} /></button>
            </div>)}
            <ReactTooltip effect="solid" />
        </div>

      </div>
    )
  }
}

export default connect()(CalculatorTile)