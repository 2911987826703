import React, { useState, useRef } from "react";
import Eagle from "assets/16.2.png";
import "./calc-header.scss";
import carrot from "./carrot.svg";
import VideoModal from "components/VideoModal";
import { animated, useSpring, useChain } from "react-spring";
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";

const initial =
  "Concerned about the budget? So are we. How can we fix it?";
const more = (
  <>
    <p>
	By 2050, federal debt will reach 195 percent of GDP. Federal spending 
	will be nearly a third of the economy and annual deficits will approach 13% of GDP.
    </p>
    <p>
      Avoiding the debt crisis will not be easy, but there are options. The 
      America Off Balance Budget Calculator puts you in control. 
      What changes will you make to fix the budget? 
    </p>
  </>
);

const CalcHeader = () => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);

  return (
    <div className="CalcHeader">
      <div className="CalcHeader-inner bg--user">
        <img
          className="CalcHeader-icon"
          alt="American Bald Eagle with a calculator strapped to its chest"
          src={Eagle}
        />
        <div className="CalcHeader-text">
          <div className="CalcHeader-text-heading uppercase">
            The Budget Calculator
          </div>
          <p className="CalcHeader-text-body font--body">{initial}</p>
          <More text={more} show={show} />
        </div>
        <button
          className={`CalcHeader-toggle ${show ? "active" : ""}`}
          onClick={toggle}
        >
          {show ? "Close" : "Learn More"}
          <img src={carrot} alt="" />
        </button>
      </div>
    </div>
  );
};

export default CalcHeader;

const More = ({ show, text }) => {
  const heightRef = useRef();
  const opacityRef = useRef();
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });

  const hprops = useSpring({
    from: { height: "0px" },
    to: { height: show ? bounds.height : 0 },
    ref: heightRef,
  });
  const oprops = useSpring({
    from: { opacity: 0 },
    to: { opacity: show ? 1 : 0 },
    ref: opacityRef,
  });

  useChain(show ? [heightRef, opacityRef] : [opacityRef, heightRef], [
    0,
    show ? 0.5 : 0.1,
  ]);

  return (
    <animated.div
      style={{
        ...hprops,
        overflow: "hidden",
      }}
    >
      <animated.div ref={ref} style={oprops}>
        <div className="CalcHeader-text-more font--body">{text}</div>

        <div style={{ alignItems: "center", display: "flex" }}>
          <a
            className="CreateButton button reset-link cta cta--large hover-color--user hover-bg--trueblack"
            href="/new"
          >
            Create your budget
          </a>
          <VideoModal />
        </div>
      </animated.div>
    </animated.div>
  );
};
