import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ReactTooltip from "react-tooltip";

import HeroChart from "./HeroChart";
import QuickChanges from "./QuickChanges";

import { max } from "d3-array";
import { formatDollars, formatPercentageDelta } from "../formatDollars";

import difference, { isolateChanges } from "../difference";

const bottomCategories = (cats) => {
  return Object.values(cats).reduce((r,d) => {
    if (Array.isArray(d)) {
      return [...r,...d]
    }
    return [...r, ...bottomCategories(d)]
  }, [])
}
// const filterPolicies = (policies,genre,categories,budgetOptionsById) => {
//   if (!policies) { return null  }
//   const bottom = bottomCategories(categories[genre]);
//   const result = policies.filter((d) => {
//     const option = budgetOptionsById[d.id]
//     return bottom.includes(option.category)
//   })
//   return result.length ? result : null
// }

const filterChanges = (changes,parents) => {
  const result = {}
  Object.keys(changes).forEach(key => {
    if (parents.includes(key)) {
      result[key] = changes[key]
    }
  })
  return Object.values(result).length ? result : null
}

const getAccounts = (items) => (
  items.reduce((r,d) => {
    if (Array.isArray(d)) {
      return r.concat(d)
    } else if (typeof d==="object") {
      return r.concat(getAccounts(Object.values(d)))
    }
    return r
  }, [])
)

const getAccountYearValues = (accounts,data,year) => {
  return accounts.reduce((r,d) => {
    if (data[d]) {
      r[d] = Math.abs(data[d].values[year])
    } else {
      console.log('missing account: ' + d)
    }
    return r
  }, {})
}

const byGdp = (data,gdp) => {
  const keys = Object.keys(data);
  return Object.values(data).reduce((r,d,i) => {
    r[keys[i]] = d/gdp
    return r
  }, {})
}

const delta = (user,baseline) => {
  return 100*(user - baseline)/baseline
}

const maxForYear = (data,categories,year) => {
    let
      spendingAccounts = getAccounts(Object.values(categories.SPENDING)).concat(["Net Interest"]),
      revenueAccounts = getAccounts(Object.values(categories.REVENUE)),
      spendingData = getAccountYearValues(spendingAccounts, data.BUDGET, year),
      revenueData = getAccountYearValues(revenueAccounts, data.BUDGET, year)

    const gdp = data.ECON["Nominal GDP"].values[year];
    spendingData = byGdp(spendingData,gdp)
    revenueData = byGdp(revenueData,gdp)

    return max([
      Object.values(spendingData).reduce((r,d) => r + d, 0),
      Object.values(revenueData).reduce((r,d) => r + d, 0)
    ])
}

const maxForYearRange = (data,categories,start,end) => {
  const values = [];
  for (let i=start; i<=end; i++) {
    values.push(maxForYear(data,categories,i))
  }
  return max(values);
}

// const PoliciesEnacted = ({ genre, policies, className="" }) => {
//   return (
//     <div className={`PoliciesEnactedList ${className}`}>
//       {policies.map((d) => (
//         <PolicyRecap
//           genre={genre}
//           key={d.id}
//           id={d.id} />
//       ))}
//     </div>
//   )
// }

const SpendingVsRevenue = ({ categories, displayYear, user, baseline, userOptions, baselineOptions, definitions, startYear, endYear, policiesEnacted, budgetOptionsById, changes }) => {

  let
    spendingAccounts = getAccounts(Object.values(categories.SPENDING)).concat(["Net Interest"]),
    revenueAccounts = getAccounts(Object.values(categories.REVENUE)),
    yourSpendingData = getAccountYearValues(spendingAccounts, user.BUDGET, displayYear),
    yourRevenueData = getAccountYearValues(revenueAccounts, user.BUDGET, displayYear),
    yourDeficitData = getAccountYearValues(["Surplus: Total"], user.BUDGET, displayYear),
    baselineSpendingData = getAccountYearValues(spendingAccounts, baseline.BUDGET, displayYear),
    baselineRevenueData = getAccountYearValues(revenueAccounts, baseline.BUDGET, displayYear),
    baselineDeficitData = getAccountYearValues(["Surplus: Total"], baseline.BUDGET, displayYear),
    yourGdp = user.ECON["Nominal GDP"].values[displayYear],
    baselineGdp = baseline.ECON["Nominal GDP"].values[displayYear]

  yourSpendingData = byGdp(yourSpendingData,yourGdp)
  yourRevenueData = byGdp(yourRevenueData,yourGdp)
  yourDeficitData = byGdp(yourDeficitData,yourGdp)
  baselineSpendingData = byGdp(baselineSpendingData,baselineGdp)
  baselineRevenueData = byGdp(baselineRevenueData,baselineGdp)
  baselineDeficitData = byGdp(baselineDeficitData,baselineGdp)

  const { TOTAL_SPENDING, TOTAL_REVENUE } = definitions;

  //relative max
  //const maximum = max([Object.values(spendingData).reduce((r,d) => r + d, 0), Object.values(revenueData).reduce((r,d) => r + d, 0)])

  //allYearMax
  const maximum = maxForYearRange(user,categories,startYear,endYear)

  // const
  //   spendingPolicies = filterPolicies(policiesEnacted,'SPENDING', categories, budgetOptionsById),
  //   revenuePolicies = filterPolicies(policiesEnacted,'REVENUE',categories, budgetOptionsById);

  // const enactedChanges = Object.values(changes).reduce((r,d) => {
  //   if (d.additions && Object.values(d.additions).reduce((r,d) => r+d, 0) !== 0 ||
  //     d.growthRate !== "CBO_GROWTH" ||
  //     d.growthRateAddition !== 0 ||
  //     d.growthRateAdjustment !== 0) {
  //     r[d.key] = d
  //   }

  //   return r
  // }, {})

  const isolatedChanges = isolateChanges(difference(userOptions,baselineOptions));

  const
    spendingChanges = filterChanges(isolatedChanges, bottomCategories(categories.SPENDING)),
    revenueChanges = filterChanges(isolatedChanges, bottomCategories(categories.REVENUE))


  return (
    <div className="SpendingVsRevenue">
      <Section
        title={"Spending"}
        column={TOTAL_SPENDING}
        genre="spending"
        user={user}
        baseline={baseline}
        displayYear={displayYear}
        yourData={yourSpendingData}
        baselineData={baselineSpendingData}
        maximum={maximum}
        categories={categories}
        definitions={definitions}
        changes={spendingChanges}
        subcategories={[
          <div className="font--label" key="mand">
            <div className="uppercase bold">{definitions.MANDATORY}</div>
            {Object.keys(categories.SPENDING.MANDATORY).map((d) => <div key={d}>{definitions[d]}</div>)}
          </div>,
          <div className="font--label" key="disc">
            <div  className="uppercase bold">{definitions.DISCRETIONARY}</div>
            {Object.keys(categories.SPENDING.DISCRETIONARY).map((d) => <div key={d}>{definitions[d]}</div>)}
          </div>
        ]} />
      <Section
        title={"Revenue"}
        column={TOTAL_REVENUE}
        genre="revenue"
        user={user}
        baseline={baseline}
        displayYear={displayYear}
        yourData={yourRevenueData}
        baselineData={baselineRevenueData}
        maximum={maximum}
        categories={categories}
        definitions={definitions}
        changes={revenueChanges}
        subcategories={[
          <div className="font--label" key="allrev">
            {Object.keys(categories.REVENUE.ALL_REVENUE).map((d) => <div key={d}>{definitions[d]}</div>)}
          </div>
        ]}/>
      <Section
        title={"Deficit"}
        column={"Surplus: Total"}
        genre="debt"
        user={user}
        baseline={baseline}
        displayYear={displayYear}
        yourData={yourDeficitData}
        baselineData={baselineDeficitData}
        maximum={maximum}
        categories={categories}
        definitions={definitions} />
      <ReactTooltip effect={"solid"} />
    </div>
  )
}
SpendingVsRevenue.contextTypes = {
  store: PropTypes.object
}
const mapStateToProps = (state) => ({
  user: state.calculator.user,
  baseline: state.calculator.baseline,
  userOptions: state.calculator.userOptions,
  baselineOptions: state.calculator.baselineOptions,
  categories: {...state.calculator.categories, DEFICIT: "Surplus: Total"},
  definitions: state.calculator.definitions,
  startYear: state.calculator.user.startYear,
  endYear: state.calculator.user.endYear,
  displayYear: state.session.displayYear,
  budgetOptionsById: state.calculator.budgetOptionsById
})
export default connect(mapStateToProps)(SpendingVsRevenue);

const Section = ({ subcategories, changes, categories , title, genre, column, user, baseline, displayYear, yourData, baselineData, maximum, definitions }) => {
  const showDetails = (changes && changes !== null)
  return (
    <div className="RecapSection">
      <div className="RecapSection-chart">
        <HeroChart
          showSettings={false}
          showTitle={false}
          mainColumn={column}
          user={user}
          baseline={baseline}
          showLegend={false} />
      </div>
      <div className="RecapSection-header">
        <div className={`font--h1 uppercase bold color--${genre}`}>{title}</div>
        <div className={`figure font--number--large color--${genre}`}>
          {formatDollars(user.BUDGET[column].values[displayYear])}
        </div>
        <div className="delta font--number--large color--user">
          {formatPercentageDelta(
            delta(
              user.BUDGET[column].values[displayYear],
              baseline.BUDGET[column].values[displayYear]
            )
          )}
        </div>
      </div>
      {showDetails && (
      <div className="RecapSection-details">
        <div className={`RecapSection-details-content`}>
          {changes &&
            <QuickChanges
              categories={categories}
              changes={changes}
              definitions={definitions} />}
        </div>
      </div>)}

      {/*
      <div className="RecapSection-bar">
        <SegmentedBar
          userData={yourData}
          baselineData={baselineData}
          domain={[0,maximum]}
          className={`fill--${genre}`}
          definitions={definitions} />

        {
          //subcategories &&
          //<div className="RecapSection-children">
          //  {subcategories}
          //</div>
        }
      </div>
      */}
    </div>
  )
}