import React from "react";
import Choices from "./Choices.js";

const ChartDropdownControls = ({
  showTitle,
  showChoices,
  title,
  onChange,
  value,
  units,
  genre,
}) => {
  return showTitle || showChoices ? (
    <header className="chart-header">
      <div>
        {showTitle && !showChoices && (
          <h1 className={`heading font--h3 bold color--${genre}`}>{title}</h1>
        )}
        {showChoices && (
          <div>
            <aside className="HeaderChart-label font--label--small heading-label">
              Select 30-Year View:{" "}
            </aside>
            <Choices
              className={`heading font--label--small color--${genre}`}
              onChange={onChange}
              selectedValue={value}
            />
            <aside className="HeaderChart-unit font--label--small">
              {units}
            </aside>
          </div>
        )}
      </div>
    </header>
  ) : null;
};

export default ChartDropdownControls;
