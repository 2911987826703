import React from 'react';

export default () => {
  return (
    <div className="tab">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><path d="M33.9 4.8H8.5v44.3h37V16.4L33.9 4.8zm6.2 13.8v.3h-7.5v-7.8l7.5 7.5zM13.9 43.7V10.3h13.2v14.1H40v19.4H13.9v-.1z"/></svg>
      Save
    </div>
  )
}


