import React from "react";

export default ({ value, radius, textCentered, classAdd }) => {
	const centered = 25, 
		angle = Math.PI/4,
		edgeX = 25 + radius*Math.cos(angle),
		edgeY = 25 + radius*Math.sin(angle),
		positionX = textCentered ? centered : edgeX,
		positionY = textCentered ? centered : edgeY

	return (
		<g className={"category-group " + classAdd}>
			<circle cy="25" cx="25" className="category-circle" r={radius} />
			<text x={positionX} 
				y={positionY} 
				className={"category-value " + (textCentered ? 'centered' : 'edge')}
			>{value}</text>
		</g>
	)
}