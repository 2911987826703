export default function(datasets) {

	const normalize = require("../../../normalize.js"),
		operations = require("../../../operations.js")(datasets)

	return {
		"Public Debt": {
			"sheet": "cbo_ltbudgetdetail",
			"normalize": normalize.percent
		},
		"Total Spending": {
			"sheet": "cbo_ltbudget",
			"normalize": normalize.percent
		},
		"Total Noninterest Spending": {
			"sheet": "cbo_ltbudget",
			"column": "Total Noninterest Spending",
			"normalize": normalize.percent
		},
		"Total Revenues": {
			"sheet": "cbo_ltbudgetdetail",
			"normalize": normalize.percent
		},
		"Revenues Minus Total Non-Interest Spending": {
			"sheet": "cbo_ltbudget",
			"normalize": normalize.percent
		},
		"Revenues Minus Total Spending": {
			"sheet": "cbo_ltbudget",
			"normalize": normalize.percent
		},
		"Social Security": {
			"sheet": "cbo_ltbudgetdetail",
			"normalize": normalize.percent
		},
		"Medicare": {
			"sheet": "cbo_ltbudget",
			"normalize": normalize.percent
		},
		"Medicaid-CHIP-Market Place Subsidies": {
			"sheet": "cbo_ltbudget",
			"normalize": normalize.percent
		},
		"Mandatory: HC Outlays": {
			"formula": function() {
				return operations.sumCols([
					{
						dataset: "CBO_LT_BUDGET",
						column: "Medicare"
					},
					{
						dataset: "CBO_LT_BUDGET",
						column: "Medicaid-CHIP-Market Place Subsidies"
					}
				])
			}
		},
		"Other Mandatory Spending": {
			"sheet": "cbo_ltdiscretionary",
			"normalize": normalize.percent
		},
		"Mandatory: Total": {
			"formula": function() {
				return operations.sumCols([
					{
						dataset: "CBO_LT_BUDGET",
						column: "Other Mandatory Spending"
					},
					{
						dataset: "CBO_LT_BUDGET",
						column: "Mandatory: HC Outlays"
					},
					{
						dataset: "CBO_LT_BUDGET",
						column: "Social Security"
					}
				])
			}
		},
		"Discretionary Spending": {
			"sheet": "cbo_ltdiscretionary",
			"normalize": normalize.percent
		},
		"Net Interest": {
			"sheet": "cbo_ltbudgetdetail",
			"normalize": normalize.percent
		},
		"Individual Income Taxes": {
			"sheet": "cbo_ltbudgetdetail",
			"normalize": normalize.percent
		},
		"Payroll Taxes": {
			"sheet": "cbo_ltbudgetdetail",
			"normalize": normalize.percent
		},
		"Corporate Income Taxes": {
			"sheet": "cbo_ltbudgetdetail",
			"normalize": normalize.percent
		},
		"Other Revenue Sources": {
			"sheet": "cbo_ltbudgetdetail",
			"normalize": normalize.percent
		}
	}
}