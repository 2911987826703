import React from "react";
import Arrow from "components/icons/slide_toggle_arrow.js";

export default ({ buttonRef, onClick, className, label="More info" }) => {
  return <button
    ref={buttonRef}
    className={`slide-toggle-button ${className}`}
    onClick={onClick}>
    {label} <Arrow />
  </button>
}