import React, { Component } from "react";
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import HeroChart from './HeroChart';

class EconTrendChart extends Component {
  static contextTypes = {
    store: PropTypes.object
  }

  render() {
    const {
      user,
      baseline,
      column,
      displayYear,
      definitions
    } = this.props;

    return (
      <div className="EconTrendChart">
        <HeroChart
          user={user}
          baseline={baseline}
          mainColumn={column}
          showTitle={false}
          showLegend={false}
          displayYear={displayYear}
          includeZero={column === "Nominal GDP"}
          definitions={definitions} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.calculator.user,
  baseline:state.calculator.baseline,
  displayYear: state.session.displayYear,
  definitions: state.calculator.definitions
})

export default connect(mapStateToProps)(EconTrendChart);