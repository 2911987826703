import { format } from "d3-format";

const formatDollarsFull = (d) => format("$,.0f")(d * 1e3);

const formatRealDollarsFull = (d) => format("$,.0f")(d);

const formatDollars = (d) => format("$.3s")(d * 1e3).replace("G", "B");

const formatDollarsPercentageCost = (d) =>
  d >= 0 ? "costs " + formatDollars(d) : "saves " + formatDollars(d * -1);

const formatDollarsPercentageGain = (d) =>
  d >= 0 ? "saves " + formatDollars(d) : "costs " + formatDollars(d * -1);

const formatDollarsBillions = (d) => formatDollars(d);

const formatDollarsLong = (d) =>
  format("$.3s")(d * 1e3)
    .replace("G", " Billion")
    .replace("T", " Trillion");

const formatDollarsDelta = (d) =>
  d === 0 ? "" : deltaSign(d) + formatDollars(d);

const formatPercentageDelta = (d, places = 1) => {
  if (!d) return "";
  if (d === 0) return "0%";
  return (
    deltaSign(d) +
    (Math.abs(Math.round(d * 10) / 10) >= 0.1
      ? formatPercentage(d, places)
      : "≈0%")
  );
};

const deltaSign = (d) => (d >= 0 ? "+" : "");

//tenths
const formatPercentage = (d, places = 1) =>
  Math.round(d * Math.pow(10, places)) / Math.pow(10, places) + "%";

//4 places
const formatPercentageLong = (d) => Math.round(d * 1e2) / 1e2 + "%";

export {
  formatDollarsFull,
  formatDollars,
  formatRealDollarsFull,
  formatDollarsLong,
  formatDollarsDelta,
  formatDollarsBillions,
  formatDollarsPercentageCost,
  formatDollarsPercentageGain,
  formatPercentageDelta,
  formatPercentage,
  formatPercentageLong,
};
