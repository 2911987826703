import React, { Component } from "react";
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import Slider from 'rc-slider';
import { scaleLinear } from "d3-scale";
import { extent } from "d3-array";
import { BUDGET_KEY, ECON_KEY } from "../Constants";
import { forceSimulation, forceX, forceY, forceManyBody, forceCollide } from "d3-force";
import { formatDollarsFull, formatDollarsLong } from "../formatDollars";
import objectFind from 'lodash/find';
import EconTrend from "./EconTrend";
import SpendingVsRevenue from "./SpendingVsRevenue";

import { flattenTree } from '../categoryFunctions';

import { setDisplayYear } from "../Actions";

const econCats = {
	"Total Factor Productivity Growth Rate": "TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE",
	"Labor Force Growth Rate": "LABOR_FORCE_GROWTH_RATE",
	"Unemployment Rate": "UNEMPLOYMENT_RATE",
	"Inflation Rate": "INFLATION_RATE",
	"Real Interest Rate": "REAL_INTEREST_RATE"
}

const rPad = 1.1;

function createRadiusScale(values) {
	return scaleLinear()
		.domain(extent(values))
		.range([1,24])
}

const CategoryBubble = ({
	categories,
	baseline,
	type,
	scale,
	year
}) => {
	const w = 130,
		h = 80

	const keyValue = {}

	Object.keys(categories).forEach(key => {
		const value = baseline[BUDGET_KEY][categories[key]].values[year];
		keyValue[key] = value;
	})

	const bubbles = []

	Object.keys(keyValue).forEach(key => {
		const value = keyValue[key];
		bubbles.push({
			r: scale(value),
			x: w/2,
			y: h/2,
			className: type + " baseline",
			name: key
		})
	})

	return (
		<ForceGraph
			height={h}
			width={w}
			viewBox={"0 0 " + w + " " + h}
			nodes={bubbles} />
	)
}

class ForceGraph extends Component {
	constructor(props) {
		super(props);
		this.state = {nodes: props.nodes};
	}

	componentDidMount() {
		this.force = forceSimulation(this.state.nodes).alpha(0.5).alphaTarget(0.1)
		.force("charge", forceManyBody().strength(0.1))
		.force("collision", forceCollide().radius((d) => d.r*rPad).strength(0.75))
		.force("x", forceX(this.props.width / 2))
		.force("y", forceY(this.props.height / 2))



		this.force.on('tick', () => {
			const nodes = this.state.nodes.map((node) => {
				node.x = Math.max(node.r*rPad, Math.min(this.props.width - node.r, node.x))
				node.y = Math.max(node.r*rPad, Math.max(node.r, Math.min(this.props.height - node.r, node.y)))
				return node
			})
			this.setState({nodes: nodes})
		});
	}

	componentWillUnmount() {
		this.force.stop();
	}

	componentWillReceiveProps(nextProps) {
		const nextNodes = this.state.nodes.map((node) => {
			node.r = objectFind(nextProps.nodes, {'name': node.name}).r
			return node
		})

		this.setState({
			nodes: nextNodes
		})

		this.force.alpha(1).restart()
	}

	render() {
		return (
			<svg
				width={this.props.width}
				height={this.props.height}
				viewBox={this.props.viewBox}
				className={"category-circle svg-fullsize"} >
		  	{this.state.nodes.map((node, index) => {
		  		if (isNaN(node.x)) return null

		  		return (<g transform={"translate(" + node.x + " " + node.y + ")"} className={node.className} key={index}>
		    		<circle r={node.r} cx={0} cy={0} />
		    		{
		    		//<text className="category-value" textAnchor="middle" alignmentBaseline="middle">{node.name}</text>
		    		}
		    	</g>)
		    })}
		  </svg>
		)
	}
}

const nodes = [];
for (let i = 0; i < 100; i++) {
  nodes.push({
  	r: (Math.random() * 5 ) + 2,
    x: 0,
    y: 0
  });
}

const EconTrends = ({ baseline }) => {
	const trends = []

	Object.keys(econCats).forEach(key => {
		trends.push(<EconTrend key={key} title={key} values={baseline[econCats[key]].values} />)
	})
	return (
		<div className="EconTrends">{trends}</div>
	)
}

const childReducer = (r,d) => {
	if (d.bottom) {
		r.push(d.key)
	}
	return r
}

class DefaultEdit extends Component {

	constructor(props) {
		super(props);

		this.onSliderChange = this.onSliderChange.bind(this)
	}

	onSliderChange(value) {
		this.props.setDisplayYear(value)
	}

	render() {
		const { baseline, categories, startYear, endYear } = this.props;

		const budgetCats = {
			spending: flattenTree(categories.SPENDING).reduce(childReducer,[]),
			revenue: flattenTree(categories.REVENUE).reduce(childReducer,[])
		}

		const
			allCats = [...budgetCats.spending,...budgetCats.revenue],
			values = allCats.map((d) => baseline[BUDGET_KEY][d].values[this.props.displayYear]),
			radius = createRadiusScale(values)

		return (
			<div className="DefaultEdit outer-container detail-section">
				<div className="font--body text-align--center">
					<div>We're on pace to have a {formatDollarsLong(baseline[BUDGET_KEY]["Net Debt"].values[this.props.displayYear])} federal debt by {this.props.displayYear}. It doesn't have to be this way.</div>
					<div>Deficit: {formatDollarsLong(baseline[BUDGET_KEY]["Surplus: Total"].values[this.props.displayYear])}</div>
				</div>
				<Slider
					min={startYear}
					max={endYear}
					value={+this.props.displayYear}
					onChange={this.onSliderChange} />
				<SpendingVsRevenue
					data={baseline}
					year={this.props.displayYear} />

				<div className="GenreGrid">
					<div className="link half">
						<div className="font--label--small">Revenue</div>
						<div className="font--number">{formatDollarsFull(baseline[BUDGET_KEY]["Revenue: Total"].values[this.props.displayYear])}</div>
						<CategoryBubble
							categories={budgetCats.revenue}
							baseline={baseline}
							scale={radius}
							type={"revenue"}
							year={this.props.displayYear} />
					</div>
					<div className="link half">
						<div className="font--label--small">Spending</div>
						<div className="font--number">{formatDollarsFull(baseline[BUDGET_KEY]["Outlays: Total"].values[this.props.displayYear])}</div>
						<CategoryBubble
							categories={budgetCats.spending}
							baseline={baseline}
							scale={radius}
							type={"spending"}
							year={this.props.displayYear} />
					</div>
					<div className="link">
						<div className="font--label--small">Economy</div>
						<EconTrends baseline={baseline[ECON_KEY]} />
					</div>
				</div>

			</div>
		)
	}
}


DefaultEdit.contextTypes = {
	store: PropTypes.object
}
const mapStateToProps = (state) => ({
  baseline: state.calculator.baseline,
  categories: state.calculator.categories,
  displayYear: state.session.displayYear,
  startYear: state.calculator.baseline.startYear,
  endYear: state.calculator.baseline.endYear
})
const mapDispatchToProps = (dispatch) => ({
	setDisplayYear: (value) => {
    dispatch(setDisplayYear(value))
  }
})

export default connect(mapStateToProps,mapDispatchToProps)(DefaultEdit);
