import React, { Component } from "react";

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { resetAll } from '../Actions';


class ResetButton extends Component {
  render() {
    const {
      resetAll
    } = this.props

    return (
      <button
        onClick={resetAll}
        className="action-button">
        Reset All</button>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetAll: () => {
    dispatch(resetAll)
  }
})
ResetButton.contextTypes = {
  store: PropTypes.object
}

export default connect(null,mapDispatchToProps)(ResetButton);