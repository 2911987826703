import React, { Component } from "react"
import AddFeaturedCalc from "./AddFeaturedCalc.js";
import FeaturedCalcsAdmin from './FeaturedCalcsAdmin.js';

import firebase from "firebase/app";
import 'firebase/database';
const { database } = firebase;

class Admin extends Component {
  constructor() {
    super()
    this.state = {
      featuredCalcs: undefined
    }
  }

  componentWillMount() {
    this.unregisterFirebaseObserver = database().ref('/meta/featuredCalculators').on("value", (snapshot) => {
      this.setState({
        featuredCalcs: snapshot ? snapshot.val() : undefined
      })
    })
  }

  componentWillUnmount() {
    this.unregisterFirebaseObserver();
  }

  render() {
    return (
      <div className="nav-pad">
        <FeaturedCalcsAdmin calcs={this.state.featuredCalcs} />
        <AddFeaturedCalc />
      </div>
    )
  }
}

export default Admin;