export default function(datasets) {
	const
		operations = require("../../../operations.js")(datasets),
		formulas = require("../../../formulas.js")(datasets),
		normalize = require("../../../normalize.js")

	const data = {
		"TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE": {
			"sheet": "transformed cbo ltecon",
			"column": "fix_tfp_percentchange"
		},
		"Capital Growth": {
			"sheet": "transformed cbo ltecon",
			"column": "bm_capital_percentchange"
		},
		"LABOR_FORCE_GROWTH_RATE": {
			"sheet": "transformed cbo ltecon",
			"column": "bm_laborforce_percentchange"
		},
		"UNEMPLOYMENT_RATE": {
			"sheet": "transformed cbo ltecon",
			"column": "fix_unemprate_percent"
		},
		"Average Hours Worked Growth Rate": {
			"sheet": "transformed cbo ltecon",
			"column": "fix_avehoursworked_percentchange"
		},
		"INFLATION_RATE": {
			"sheet": "transformed cbo ltecon",
			"column": "fix_cpiu_percentchange"
		},
		"GDP Price Index Growth Rate": {
			"sheet": "transformed cbo ltecon",
			"column": "fix_gdppriceindex_percentchange"
		},
		"Total Hours Worked Growth Rate": {
			"formula": function() {
				return formulas['Total Hours Worked Growth Rate'](data)
			}
		},
		"Real GDP Growth Rate": {
			"formula": function() {
				return formulas['Real GDP Growth Rate'](data)
			}
		},
		"bm_gdp_real": {
			"sheet": "transformed cbo ltecon",
			"column": "bm_gdp_real",
			"normalize": normalize.billions
		},
		"Real GDP": {
			"formula": function() {
				return formulas['Real GDP'](data)
			}
		},
		"Labor Productivity Growth Rate": {
			"formula": function() {
				return formulas['Labor Productivity Growth Rate'](data)
			}
		},
		"Labor Productivity Growth Rate (Nominal)": {
			"formula": function() {
				return formulas['Labor Productivity Growth Rate (Nominal)'](data)
			}
		},
		"Labor Productivity Index (Nominal)": {
			"formula": function() {
				return formulas["Labor Productivity Index (Nominal)"](data)
			}
		},
		"CPI-U Price Index": {
			"formula": function() {
				return formulas["CPI-U Price Index"](data)
			}
		},
		"GDP Price Index": {
			"formula": function() {
				return formulas["GDP Price Index"](data)
			}
		},
		"Nominal GDP": {
			"formula": function() {
				return formulas["Nominal GDP"](data)
			}
		},
		"Population": {
			"sheet": "transformed cbo ltecon",
			"column": "fix_pop_count"
		},
		"Nominal Interest Rate on Public Debt": {
			"sheet": "transformed cbo ltecon",
			"column": "fix_netdebtinterest_nom_percent"
		},
		"Nominal Interest Rate on OASDI Trust Fund": {
			"sheet": "transformed cbo ltecon",
			"column": "fix_oasdiinterest_nom_percent"
		},
		"REAL_INTEREST_RATE": {
			"formula": function() {
				return operations.sumValues([data["Nominal Interest Rate on Public Debt"].values, operations.negate(data["INFLATION_RATE"].values)])
			}
		},
		"Real Interest Rate on OASDI Trust Fund": {
			"formula": function() {
				return operations.sumValues([data["Nominal Interest Rate on OASDI Trust Fund"].values, operations.negate(data["INFLATION_RATE"].values)])
			}
		}
	}

	return data
}