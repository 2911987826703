import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { categoryUrl, getGenreChanges, determineGenre } from '../categoryFunctions';

import QuickChange from './QuickChange';

class QuickChanges extends Component {
  static contextTypes = {
    store: PropTypes.object
  }

  render() {
    const {
      definitions,
      categories,
      calcKey,
      includeSnapshot=false,
      includeLinks=true,
      user,
      baseline,
      changes,
      displayYear
    } = this.props

    const changesWithKeys = Object.keys(changes).reduce((r,d) => {
      r.push({
        key: d,
        ...changes[d]
      })
      return r
    }, [])

    const revenueChanges = getGenreChanges(changesWithKeys, 'revenue', categories)
    const spendingChanges = getGenreChanges(changesWithKeys, 'spending', categories)
    const economyChanges = getGenreChanges(changesWithKeys, 'economy', categories)

    const sortedChangesWithKeys = [
      ...revenueChanges,
      ...spendingChanges,
      ...economyChanges
    ]

    return (
      <div className="Sidebar-block">
        {sortedChangesWithKeys.map((item) => {
          const genre = determineGenre(item.key,categories)
          const changesWithoutKey = changes[item.key]
          return (
            <QuickChange
              key={item.key}
              catKey={item.key}
              categoryLink={categoryUrl(calcKey,genre.toLowerCase(),definitions[item.key])}
              genre={genre}
              changes={changesWithoutKey}
              definitions={definitions}
              includeSnapshot={includeSnapshot}
              includeLink={includeLinks}
              user={user}
              baseline={baseline}
              displayYear={displayYear} />
        )})}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  definitions: state.calculator.definitions,
  categories: state.calculator.categories,
  calcKey: state.calculator.calcKey,
  user: state.calculator.user,
  baseline: state.calculator.baseline,
  userOptions: state.calculator.userOptions,
  baselineOptions: state.calculator.baselineOptions,
  displayYear: state.session.displayYear
})
export default connect(mapStateToProps)(QuickChanges);