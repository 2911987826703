import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import NextArrowIcon from "components/next-arrow-icon";
import { isAuthoredCalc } from "authoredCalcs";
import Unsaved from "./unsaved";
import { authenticator } from "fb";

const Footer = ({ calcKey, authorName, toggleAuthenticateSkip }) => {
  return (
    <footer className="Report-footer">
      {!authenticator.currentUser && !authorName && isAuthoredCalc(calcKey) && (
        <div className="Report-footer-unsaved">
          <Unsaved toggleAuthenticateSkip={toggleAuthenticateSkip} />
        </div>
      )}

      <div className="Report-footer-create bg--user">
        <div className="Report-footer-create-heading font--h1">
          Create A Budget
        </div>
        <Link className="Report-footer-create-link button" to="/new">
          Start a new one
        </Link>
        <Link
          className="Report-footer-create-link button"
          to={`/newfrom/${calcKey}`}
        >
          Based on this one
        </Link>
      </div>
      <Module
        heading={"Tour the Budget"}
        description={
          "The challenge is more daunting than you can imagine.  Is there a way to fix this?  Explore some popular options for balancing the budget."
        }
        link={{
          text: "The Balancing Act",
          href: "https://americaoffbalance.org/tour",
        }}
        className="bg--tour"
      />
      <Module
        heading={"Budget Matters"}
        description={
          "Analysis and Commentary on the Federal Budget in the form of a blog."
        }
        link={{
          text: "Budget Matters Blog",
          href: "https://budgetmatters.americaoffbalance.org",
        }}
        className="bg--blog color--white"
      />
    </footer>
  );
};

export default Footer;

const Module = ({ heading, description, link, className }) => {
  return (
    <div className={`Report-footer-module ${className}`}>
      <div className="Report-footer-module-heading font--h1">{heading}</div>
      <div className="Report-footer-module-body font--body">{description}</div>
      <a
        className="Report-footer-module-link"
        href={link.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {link.text} <NextArrowIcon />
      </a>
    </div>
  );
};
