import React from "react";
import EditIcon from "./icons/EditIcon";

export default ({ onClick, cta, className="" }) => (
  <button
  className={`EditMetaButton icon-button ${className}`}
  onClick={onClick}
  aria-label="Edit budget title and description">
    {cta && <span className="cta">{cta}</span>}
    <EditIcon />
  </button>
)