import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import Calculators from "./Calculators";
import Profile from "./Profile";

import { confirmAlert } from "react-confirm-alert";

import firebase from "firebase/app";
import "firebase/database";
const { database } = firebase;

export default class Account extends Component {
  constructor() {
    super();
    this.deleteEverything = this.deleteEverything.bind(this);
  }

  deleteCalc(authorUid, calcKey) {
    return () => {
      database().ref(`users/${authorUid}/calculators/${calcKey}`).remove();
      database().ref(`calculators/${calcKey}`).remove();
      database().ref(`meta/featuredCalculators/${calcKey}`).remove();
    };
  }

  deleteEverything() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="AlertModal text-align--center">
            <h1 className="AlertModal-heading font--h1">Are you sure?</h1>
            <ul className="AlertModal-list font--label">
              Click delete my account permanantly erase all your data
            </ul>
            <div className="AlertModal-buttons">
              <button className="button button--default" onClick={onClose}>
                Cancel
              </button>
              <button
                className="button button--user border-color--user bg--user color--white"
                onClick={() => {
                  const authorUid = this.props.loggedInUser.uid;
                  database()
                    .ref(`users/${authorUid}/calculators/`)
                    .once("value")
                    .then((snapshot) => {
                      Object.keys(snapshot.val()).forEach((calcKey) => {
                        this.deleteCalc(authorUid, calcKey);
                      });
                      database().ref(`users/${authorUid}`).remove();

                      this.props.authenticator.signOut();
                    });
                  onClose();
                }}
              >
                Delete my account
              </button>
            </div>
          </div>
        );
      },
    });
  }

  render() {
    return (
      <div className="Account">
        {this.props.firstAuthComplete ? (
          this.props.loggedInUser ? (
            <AccountDetails
              deleteCalc={this.deleteCalc}
              deleteEverything={this.deleteEverything}
              authenticator={this.props.authenticator}
              loggedInUser={this.props.loggedInUser}
            />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }
}

const AccountDetails = ({
  loggedInUser,
  authenticator,
  deleteCalc,
  deleteEverything,
}) => (
  <div className="AccountDetails">
    <Profile
      loggedInUser={loggedInUser}
      authenticator={authenticator}
      deleteEverything={deleteEverything}
    />
    <Calculators loggedInUser={loggedInUser} deleteCalc={deleteCalc} />
  </div>
);
