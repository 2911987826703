import React from 'react';

export default () => {
  return (
    <div className="tab">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><path d="M7.6 45.3V3.1H2.2v47.6h48.5v-5.4H7.6z"/><path d="M25.9 29l6.3 6.3 18-18.1-3.8-3.8-14.2 14.2-6.3-6.3-14.3 14.4 3.8 3.9L25.9 29z"/></svg>
      Projections
    </div>
  )
}


