import React, { Component } from "react";

import GrowthRatePercentageInput from './GrowthRatePercentageInput';
import { roundPercentageChange } from '../rounding';

export default class GrowthSlider extends Component
{
  constructor(props) {
    super(props)
    this.state = {
      inputValue: this.props.value || 0,
      value: this.props.value || 0
    }
    this.onInputChange = this.onInputChange.bind(this)
    this.onInputBlur = this.onInputBlur.bind(this)
    this.onInputKeyPress = this.onInputKeyPress.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.increment = this.increment.bind(this)
    this.decrement = this.decrement.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      inputValue: nextProps.value,
      value: nextProps.value
    })
  }

  onInputChange(event) {
    let
      value = event.target.value,
      valueString = value.toString(),
      decimalIndex = valueString.indexOf('.')

    if (decimalIndex !== -1 && valueString.length > decimalIndex + 5) {
      valueString = valueString.substring(0,decimalIndex + 5)
    }
    this.setState({
      inputValue: valueString
    })
  }
  onInputBlur() {
    this.handleChange(this.state.inputValue)
  }
  onInputKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleChange(this.state.inputValue)
    }
  }

  handleChange(newValue) {
    if (isNaN(newValue) || newValue === "") {
      newValue = 0
    }
    newValue = roundPercentageChange(parseFloat(newValue))

    const oldValue = this.state.value;
    this.setState({
      value: newValue,
      inputValue: newValue
    })
    this.props.onChange(newValue,oldValue)
  }
  increment() {
    this.setState({
      inputValue: this.state.inputValue + this.props.incrementAmount
    }, () => {
      this.handleChange(this.state.inputValue)
    })
  }
  decrement() {
    this.setState({
      inputValue: this.state.inputValue - this.props.incrementAmount
    }, () => {
      this.handleChange(this.state.inputValue)
    })
  }

  render() {
    const { className="", label, parentValue } = this.props
  	return (
      <div className={`GrowthSlider font--label--small color--label ${className}`}>
        <div className="GrowthRateSetting-label">
          {label} <div className="GrowthRateSetting-tooltip faded" data-tip data-for={`AdjustTooltip`} data-position="left"></div>
        </div>
        <GrowthRatePercentageInput
          onChange={this.onInputChange}
          onBlur={this.onInputBlur}
          onKeyPress={this.onInputKeyPress}
          showReset={this.state.inputValue !== parentValue}
          inputRef={null}
          currentValue={this.state.inputValue}
          increment={this.increment}
          decrement={this.decrement}
          />
      </div>
  	)
  }
}

GrowthSlider.defaultProps = {
  label: "Adjust all values by",
  tooltipClass: "color--white",
  parentValue: 0,
  incrementAmount: 1
}