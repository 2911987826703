import React from "react";
import ReactDOM from "react-dom";
import cloneDeep from "lodash/cloneDeep";
import { flattenTree } from "../categoryFunctions";
import setCategoriesToDefault from "./setCategoriesToDefault";
import versions from "../versions.js";
import renderCalcFactory from "../renderCalc.js";
import { getVersionPath } from "./helpers.js";

import { UnconnectedHeroChart as HeroChart } from "../components/HeroChart";

import { roundPercentageChange } from "../rounding";

import { databasor, authenticator } from "fb";
import { maritalStatuses } from "Constants";

const defaultTaxCalcOptions = {
  maritalStatus: maritalStatuses.SINGLE,
  income: 50000,
};

const migrateGrowthOptions = (calc) => {
  return {
    ...calc,
    userOptions: updateGrowthRateOptions(calc.userOptions),
    baselineOptions: updateGrowthRateOptions(calc.baselineOptions),
  };
};

const updateGrowthRateOptions = (options) => {
  Object.keys(options).forEach((key) => {
    switch (options[key].growthRate) {
      case "CBO Baseline":
        options[key].growthRate = "CBO_GROWTH";
        break;

      case "Zero Growth":
        options[key].growthRate = "ZERO_GROWTH";
        break;

      case "Historical Rate":
        options[key].growthRate = "HISTORICAL_GROWTH";
        break;

      case "Grow at Inflation":
        options[key].growthRate = "INFLATION_GROWTH";
        break;

      case "Grow at Nominal GDP":
        options[key].growthRate = "GDP_GROWTH";
        break;

      case "CBO_GROWTH":
      case "ZERO_GROWTH":
      case "HISTORICAL_GROWTH":
      case "INFLATION_GROWTH":
      case "GDP_GROWTH":
        break;

      default:
        console.warn(
          options[key].growthRate,
          "missing migration for this growth rate"
        );
    }
  });
  return options;
};

const getDefaultOptions = (versionPath) => {
  const calcCategories = require("../" + versionPath + "categories.js"),
    flatCats = flattenTree(calcCategories),
    START_YEAR = 2018,
    CALCULATOR_YEARS = 30,
    END_YEAR = START_YEAR + CALCULATOR_YEARS,
    options = setCategoriesToDefault(flatCats, START_YEAR, END_YEAR);

  return options;
};

const policiesByCategory = (options) => {
  return options.reduce(function (result, option) {
    (result[option.category] || (result[option.category] = [])).push(option);
    return result;
  }, {});
};

const policiesById = (options) => {
  return options.reduce(function (result, option) {
    if (option.id) {
      result[option.id] = option;
    }
    return result;
  }, {});
};

const newCalc = (version = versions.default) => {
  let versionPath = getVersionPath(version);
  const renderCalc = renderCalcFactory("./" + versionPath);

  let policies = require("../" + versionPath + "processed/policyOptions.json");
  const budgetOptionsById = policiesById(policies);

  const calc = {
    title: "",
    description: "",
    authorName: "",
    authorUid: "",
    calcKey: databasor.ref(`calculators`).push().key,
    baselineOptions: getDefaultOptions(versionPath),
    userOptions: getDefaultOptions(versionPath),
    taxCalcOptions: defaultTaxCalcOptions,
    baseline: renderCalc(
      getDefaultOptions(versionPath),
      budgetOptionsById,
      defaultTaxCalcOptions
    ),
    user: renderCalc(
      getDefaultOptions(versionPath),
      budgetOptionsById,
      defaultTaxCalcOptions
    ),
    growthOptions: require("../" + versionPath + "growthOptions.js").default,
    budgetOptionsByCategory: policiesByCategory(policies),
    budgetOptionsById: budgetOptionsById,
    categories: require("../" + versionPath + "categories.js"),
    definitions: require("../definitions.js"),
    version,
  };

  return calc;
};

const getChart = (calc) => {
  return new Promise((resolve, reject) => {
    const chartContainer = document.createElement("div");
    ReactDOM.render(
      <HeroChart
        showSettings={false}
        showTitle={false}
        showLegend={false}
        showAxis={false}
        margin={{ left: 0, top: 0, bottom: 0, right: 0 }}
        width={200}
        height={100}
        user={calc.user}
        baseline={calc.baseline}
        compareColumn={null}
        categories={calc.categories}
        definitions={calc.definitions}
        includeZero={false}
      />,
      chartContainer,
      () => {
        const chart = chartContainer.querySelector(".HeroChart .Chart");
        const remove = chart.querySelectorAll(".hover-target,.chart-header");
        for (let i = 0; i < remove.length; i++) {
          remove[i].remove();
        }
        resolve(chart.innerHTML);
      }
    );
  });
};

const updateCalcInDatabase = (currentState) => {
  const currentUser = authenticator.currentUser,
    authorUid = currentUser.uid,
    authorName = currentUser.displayName;

  const updatedState = {
    ...currentState,
    authorName,
    authorUid,
  };

  const saveState = cloneDeep(updatedState);

  if (!saveState.dateCreated) {
    saveState.dateCreated = new Date();
  }
  saveState.dateUpdated = new Date();
  saveState.startYear = saveState.user.startYear;
  saveState.endYear = saveState.user.endYear;
  saveState.userDebtGdp =
    (100 * saveState.user.BUDGET.NET_DEBT.values[saveState.user.endYear]) /
    saveState.user.ECON["Nominal GDP"].values[saveState.user.endYear];
  saveState.baselineDebtGdp =
    (100 *
      saveState.baseline.BUDGET.NET_DEBT.values[saveState.baseline.endYear]) /
    saveState.baseline.ECON["Nominal GDP"].values[saveState.baseline.endYear];

  //delete saveState.user
  delete saveState.baseline;
  delete saveState.user;
  delete saveState.budgetOptionsById;
  delete saveState.budgetOptionsByCategory;
  delete saveState.categories;
  delete saveState.definitions;
  delete saveState.growthOptions;

  getChart(updatedState).then((chartImage) => {
    const calcData = {
      title: saveState.title,
      authorUid,
      authorName,
      calc: {
        ...saveState,
        tileImage: chartImage,
      },
    };
    const updates = {};
    updates[`calculators/${updatedState.calcKey}`] = calcData;
    updates[
      `users/${authorUid}/calculators/${updatedState.calcKey}`
    ] = calcData;

    databasor.ref().update(updates);
  });

  return updatedState;
};

export default (currentState = newCalc(), action) => {
  const renderCalc =
    action.type === "LOAD_CALC"
      ? renderCalcFactory("./" + getVersionPath(action.calc.version))
      : renderCalcFactory("./" + getVersionPath(currentState.version));

  let userOptions,
    user,
    baseline,
    updatedState,
    newAdjustments,
    newTaxCalcOptions;

  const { budgetOptionsById, taxCalcOptions } = currentState;

  switch (action.type) {
    case "LOAD_CALC":
      action.calc = migrateGrowthOptions(action.calc);
      const versionPath = getVersionPath(action.calc.version);

      let policies = require("../" +
        versionPath +
        "processed/policyOptions.json");

      return {
        ...action.calc,
        budgetOptionsByCategory: policiesByCategory(policies),
        budgetOptionsById: policiesById(policies),
        user:
          action.calc.user ||
          renderCalc(
            action.calc.userOptions,
            budgetOptionsById,
            action.calc.taxCalcOptions || defaultTaxCalcOptions
          ), //transition calcs saved without rendered user.  GDP bug.
        baseline: renderCalc(
          action.calc.baselineOptions,
          budgetOptionsById,
          action.calc.taxCalcOptions || defaultTaxCalcOptions
        ),
        categories: require("../" + versionPath + "categories.js"),
        definitions: require("../definitions.js"),
        growthOptions: require("../" + versionPath + "growthOptions.js")
          .default,
      };

    case "GET_CALC_KEY":
      let calcKey = currentState.calcKey;

      if (calcKey === undefined || calcKey === null) {
        const pushCalc = databasor.ref(`/calculators`).push().key;
        calcKey = pushCalc.key;
      }

      return {
        ...currentState,
        calcKey,
      };

    case "SAVE_CALC":
      return updateCalcInDatabase(currentState);

    case "DELETE_CALC":
      databasor
        .ref(
          `users/${currentState.authorUid}/calculators/${currentState.calcKey}`
        )
        .remove();
      databasor.ref(`calculators/${currentState.calcKey}`).remove();

      return newCalc();

    case "NEW_CALC":
      return newCalc(action.version);

    case "NEW_CALC_FROM":
      const pushCalcKey = databasor.ref(`/calculators`).push().key;
      const now = new Date();

      return {
        ...currentState,
        ...action.calc,
        title: `Copy of ${currentState.title}`,
        description: "",
        authorName: authenticator.currentUser
          ? authenticator.currentUser.displayName
          : "",
        authorUid: authenticator.currentUser
          ? authenticator.currentUser.uid
          : "",
        modifiedFrom: {
          title: currentState.title,
          calcKey: currentState.calcKey,
        },
        dateCreated: now,
        dateUpdated: now,
        calcKey: pushCalcKey,
      };

    case "UPDATE_TITLE":
      updatedState = {
        ...currentState,
        title: action.title,
      };

      return updatedState;

    case "UPDATE_DESCRIPTION":
      updatedState = {
        ...currentState,
        description: action.description,
      };

      return updatedState;

    case "RESET_USER_OPTIONS":
      userOptions = cloneDeep(currentState.baselineOptions);
      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "SET_GROWTH_RATE":
      userOptions = {
        ...currentState.userOptions,
        [action.category]: {
          ...currentState.userOptions[action.category],
          growthRate: action.value,
        },
      };
      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "SET_GROWTH_RATES":
      newAdjustments = {};
      action.values.forEach((thisAction) => {
        newAdjustments[thisAction.category] = {
          ...currentState.userOptions[thisAction.category],
          growthRate: thisAction.value,
        };
      });
      userOptions = {
        ...currentState.userOptions,
        ...newAdjustments,
      };

      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "SET_GROWTH_RATE_ADJUSTMENT":
      userOptions = {
        ...currentState.userOptions,
        [action.category]: {
          ...currentState.userOptions[action.category],
          growthRateAdjustment: action.value,
        },
      };

      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "UPDATE_GROWTH_RATE_ADJUSTMENT":
      const updatedGrowthRateAdjustmentValue =
        currentState.userOptions[action.category].growthRateAdjustment +
        action.value;
      userOptions = {
        ...currentState.userOptions,
        [action.category]: {
          ...currentState.userOptions[action.category],
          growthRateAdjustment: roundPercentageChange(
            updatedGrowthRateAdjustmentValue
          ),
        },
      };

      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "UPDATE_GROWTH_RATE_ADJUSTMENTS":
      newAdjustments = {};
      action.adjustments.forEach((thisAction) => {
        const updatedGrowthRateAdjustmentValue =
          currentState.userOptions[thisAction.category].growthRateAdjustment +
          thisAction.value;
        newAdjustments[thisAction.category] = {
          ...currentState.userOptions[thisAction.category],
          growthRateAdjustment: roundPercentageChange(
            updatedGrowthRateAdjustmentValue
          ),
        };
      });

      userOptions = {
        ...currentState.userOptions,
        ...newAdjustments,
      };
      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "SET_GROWTH_RATE_ADDITION":
      userOptions = {
        ...currentState.userOptions,
        [action.category]: {
          ...currentState.userOptions[action.category],
          growthRateAddition: action.value,
        },
      };

      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "SET_GROWTH_RATE_ADDITIONS":
      newAdjustments = {};
      action.values.forEach((thisAction) => {
        newAdjustments[thisAction.category] = {
          ...currentState.userOptions[thisAction.category],
          growthRateAddition: thisAction.value,
        };
      });

      userOptions = {
        ...currentState.userOptions,
        ...newAdjustments,
      };
      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "UPDATE_GROWTH_RATE_ADDITION":
      const updatedGrowthRatedAdditionValue =
        currentState.userOptions[action.category].growthRateAddition +
        action.value;
      userOptions = {
        ...currentState.userOptions,
        [action.category]: {
          ...currentState.userOptions[action.category],
          growthRateAddition: roundPercentageChange(
            updatedGrowthRatedAdditionValue
          ),
        },
      };

      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "UPDATE_GROWTH_RATE_ADDITIONS":
      newAdjustments = {};
      action.values.forEach((thisAction) => {
        const updatedGrowthRatedAdditionValue =
          currentState.userOptions[thisAction.category].growthRateAddition +
          thisAction.value;
        newAdjustments[thisAction.category] = {
          ...currentState.userOptions[thisAction.category],
          growthRateAddition: roundPercentageChange(
            updatedGrowthRatedAdditionValue
          ),
        };
      });

      userOptions = {
        ...currentState.userOptions,
        ...newAdjustments,
      };
      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "SET_DOLLAR_ADDITION":
      userOptions = {
        ...currentState.userOptions,
        [action.category]: {
          ...currentState.userOptions[action.category],
          additions: {
            ...currentState.userOptions[action.category].additions,
            [action.year]: action.value,
          },
        },
      };

      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "ADD_POLICY":
      userOptions = {
        ...currentState.userOptions,
        policiesEnacted: [
          ...(currentState.userOptions.policiesEnacted || []),
          {
            id: action.id,
            disabled: action.disabled,
          },
        ],
      };

      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "REMOVE_POLICY":
      const newPoliciesEnacted = currentState.userOptions.policiesEnacted.filter(
        (d) => d.id !== action.id
      );

      userOptions = {
        ...currentState.userOptions,
        policiesEnacted: newPoliciesEnacted,
      };

      user = renderCalc(userOptions, budgetOptionsById, taxCalcOptions);

      return {
        ...currentState,
        user,
        userOptions,
      };

    case "SET_MARITAL_STATUS":
      newTaxCalcOptions = {
        ...currentState.taxCalcOptions,
        maritalStatus: action.value,
      };

      baseline = renderCalc(
        currentState.baselineOptions,
        budgetOptionsById,
        newTaxCalcOptions
      );

      user = renderCalc(
        currentState.userOptions,
        budgetOptionsById,
        newTaxCalcOptions
      );
      return {
        ...currentState,
        taxCalcOptions: newTaxCalcOptions,
        baseline,
        user,
      };

    case "SET_INCOME":
      newTaxCalcOptions = {
        ...currentState.taxCalcOptions,
        income: action.value,
      };

      baseline = renderCalc(
        currentState.baselineOptions,
        budgetOptionsById,
        newTaxCalcOptions
      );

      user = renderCalc(
        currentState.userOptions,
        budgetOptionsById,
        newTaxCalcOptions
      );
      return {
        ...currentState,
        taxCalcOptions: newTaxCalcOptions,
        baseline,
        user,
      };

    default:
      return currentState;
  }
};
