const {
  CBO_GROWTH,
  ZERO_GROWTH,
  INFLATION_GROWTH,
  GDP_GROWTH
} = require("../../definitions.js")

module.exports = {
	cbo: CBO_GROWTH,
	zero: ZERO_GROWTH,
	inflation: INFLATION_GROWTH,
	gdp: GDP_GROWTH
}