import React from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import CtaArrow from './icons/CtaArrow';
import ActionBar from './ActionBar';

const NewBudgetActionBar = ({ calc }) => (
  <ActionBar className="cta page">
    <Link to={`/new`} className="action-link">Create a new budget <CtaArrow /></Link>
  </ActionBar>
)

//{calc.calcKey && <Link to={`/${calc.calcKey}/edit`} className="action-link">Continue working on your budget</Link>}

const mapStateToProps = (state) => ({
  calc: state.calculator
})
export default connect(mapStateToProps)(NewBudgetActionBar)