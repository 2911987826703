import React, { Component } from "react";
import sortBy from 'lodash/sortBy';
import CalculatorTile from './CalculatorTile.js';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';

import firebase from "firebase/app";
import 'firebase/database';
const { database } = firebase;

class FeaturedCalculators extends Component {
  constructor() {
    super()
    this.state = {
      calcs: []
    }
  }

  componentWillMount() {
    database().ref(`meta/featuredCalculators`).once('value', (snapshot) => {
      if (snapshot.val()) {
        const sorted = sortBy(Object.values(snapshot.val()), ['order'])
        const ids = sorted.map((d) => d.calcKey);
        const promises = ids.map(id => {
          return database().ref(`calculators`).child(id).once('value', s => s)
        })
        Promise.all(promises)
          .then((data) => {
            this.setState({
              calcs: data.map((d) => d.val())
            })
          })
          .catch((e) => {
            console.warn(e)
          })
      } else {
        this.setState({
          calcs: []
        })
      }
    })
  }

  render() {
    return !this.state.calcs.length ? null : (
      <div className="FeaturedCalculators home-pad">
        <h2 className="FeaturedCalculators-heading font--h2">Featured Budgets</h2>
        <Flickity
          className={`FeaturedCalculators-carousel flickity count--${this.state.calcs.length}`}
          reloadOnUpdate={true}
          options={{
            contain: true,
            cellAlign: 'left',
            groupCells: "100%",
            draggable: '>=1',
            pageDots: false,
            //prevNextButtons: false
          }}>
        {this.state.calcs.map((d,i) => {
          return (
            <CalculatorTile
              key={d.calc.calcKey}
              title={d.title}
              author={d.calc.authorName}
              calc={d.calc} />
          )
        })}
        </Flickity>
      </div>
    )
  }
}

export default FeaturedCalculators