import React, { Component, createRef } from 'react';

export default class GrowthRatePercentageInput extends Component {
  constructor(props) {
    super(props)
    this.inputRef = createRef()
    this.onFocus = this.onFocus.bind(this)
    this.incrementRef = createRef()
    this.decrementRef = createRef()
    this.increment = this.blurOnClick(props.increment,this.incrementRef)
    this.decrement = this.blurOnClick(props.decrement,this.decrementRef)
  }

  blurOnClick(callback,ref) {
    return (event) => {
      callback()
      if (event.screenX !== 0 || event.screenY !== 0) {
        ref.current.blur()
      }
    }
  }

  onFocus() {
    this.inputRef.current.select()
  }

  render() {
    const {
      onChange,
      onBlur,
      onKeyPress,
      currentValue,
      unit="%"
    } = this.props

    return (
      <div className={`GrowthRatePercentageInput bg--white font--number border-color--user`}>
        <button
          ref={this.decrementRef}
          title={`Minus`}
          className="decrement-button reset-button color--user bg--userLight"
          onClick={this.decrement}>-</button>
        <div className="input-wrap color--label">
          <input
            className="reset-input "
            ref={this.inputRef}
            size={Math.max(currentValue.toString().length,3)}
            value={currentValue}
            onChange={onChange}
            onFocus={this.onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            type="text" />
          <span>{unit}</span>
        </div>
        <button
          ref={this.incrementRef}
          title={`Plus`}
          className="increment-button reset-button color--user bg--userLight"
          onClick={this.increment}>+</button>
      </div>
    );
  }
}

GrowthRatePercentageInput.defaultProps = {
  value: 0
}