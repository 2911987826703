import React, { Component } from "react";
import { animateScroll } from "react-scroll";
export default class ScrollDown extends Component {
  constructor() {
    super();
    this.state = {
      scrolled: false
    }
    this.onScroll = this.onScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll() {
    this.setState({
      scrolled: window.pageYOffset > 5
    })
  }

  onClick() {
    animateScroll.scrollTo(window.innerHeight);
  }

  render() {
    return this.state.scrolled ? null : (
      <div
        className="bg--white home-pad"
        style={{
          alignItems: "center",
          bottom: 0,
          display: "flex",
          position: 'fixed',
          height: "60px",
          width: "100%",
          left: 0
        }}>
        <div
          style={{
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            padding: "10px 0"
          }}
          onClick={this.onClick}>
          <svg viewBox="0 0 23 37" className="ScrollDown stroke--user" width={23} height={37}>
            <g fill="none" strokeWidth="2" transform="translate(2 1)">
              <path d="m2.82418338 31.0060016h13.63636362v-13.6363637" transform="matrix(.70710678 .70710678 -.70710678 .70710678 19.927555 .266267)"></path>
              <path d="m9.54545455.45454546v30.90909094"></path>
            </g>
          </svg>
          <span className="font--label--alt" style={{ paddingLeft: "1em" }}>Featured Budgets + Learn More About Budget Matters</span>
        </div>
      </div>
    )
  }
}
