import React from "react";

const RadioGroup = ({
  className="",
  options,
  onChange,
  selectedValue,
}) => {
  return (
    <div
      role="radiogroup"
      className={className}>
      <div>
        {options.map((d) => {
          const checked = d.value === selectedValue;
          return (
            <label key={d.text} className="RadioGroup-item" style={{ marginRight: "1em", cursor: "pointer" }}>
              <input
                type="radio"
                onChange={(event) => {
                  onChange(event.target.value)
                }}
                checked={checked}
                value={d.value} />
                <span className="RadioGroup-label">
                  <span className="text">{d.text}</span>
                </span>
            </label>
          )}
        )}
      </div>
    </div>
  )
}

export default RadioGroup
