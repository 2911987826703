module.exports = {
  "Start year of data (projections will begin in the following year)": 2018,
  "Years Projected": 30,
  "Share of output growth attributed to capital growth": 0.35,
  "Marginal Tax Rate on Labor Income in Start Year": .298,
  "Marginal Tax Rate on Labor Income in final year of 10-year outlook": .311,
  "Marginal Tax Rate on Labor Income in final year of long-term projection": .329,
  "Marginal Tax Rate on Capital Income in Start Year": .155,
  "Marginal Tax Rate on Capital Income in final year of 10-year outlook": .181,
  "Marginal Tax Rate on Capital Income in final year of long-term projection": .19,
  "Elasticity of Labor Supply to After-Tax Wage": 0.19,
  "Elasticity of Private Saving Rate to After-Tax Interest Rate": 0.20,
  "Elasticity of Income Tax Revenue with Respect to Real GDP Growth": 1.3,
  "Elasticity of Other Tax Revenue with Respect to Real GDP Growth": 1.7,
  "Assumed depreciation of capital rate": .1,
  "Elasticity of savings with respect to the deficit": -.126,
  "Social Security Replacement": .403,
  "Percentage point change in nominal interest rate from one percentage point change in deficit (as a percent of GDP)": .0002,
  "Full Retirement Age (FRA)": 67,
  "Average Retirement Age (ARA)": 64.5,
  "PIT_CAPSHARE": .25,
  "PAYROLL_CAPSHARE": 0,
  "CIT_CAPSHARE": 1,
  "OTHERTAXES_CAPSHARE": 0
}