import React, { Component } from 'react';

import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';

class FullCarousel extends Component {
  constructor() {
    super()
    this.state = {
      selectedIndex: 0
    }
  }

  componentDidMount() {
    this.flkty.on('change', () => {
      this.setState({
        selectedIndex: this.flkty.selectedIndex
      })
    })
  }
  render() {
    const { className="" } = this.props;

    return (
      <div className={`FullCarousel ${className} border-color--${this.props.pages[this.state.selectedIndex].title.props.className}`}>
        <div className="FullCarousel-inner">
          <div className="FullCarousel-nav" style={{
            flexShrink: 0
          }}>
            {this.props.pages.map((d,i) => {
              return React.cloneElement(d.title, {
                onClick: () => {
                  this.flkty.select(i)
                },
                key: i,
                className: 'FullCarousel-nav-item ' + d.title.props.className + (this.state.selectedIndex === i ? ' active' : '')
              });
            })}
          </div>
          <div style={{
            height: "100%",
            position: "relative"
          }}>
            <div style={{
              height: "100%",
              position: "absolute",
              width: "100%"
            }}>
              <Flickity
                flickityRef={c => this.flkty = c}
                className="FullCarousel-flickity"
                options={{
                  pageDots: false,
                  wrapAround: true,
                  prevNextButtons: false
                }}>
                {
                  this.props.pages.map((d,i) => (
                    <div key={i} className="FullCarousel-item">
                      {d.contents}
                    </div>
                  ))
                }
              </Flickity>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FullCarousel