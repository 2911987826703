import React from "react";
import ResetIcon from "./icons/ResetIcon.js";

import ReactTooltip from "react-tooltip";

export default ({ onClick, className }) => {
  return (
    <button
      className={`${className}`}
      onClick={onClick}
      data-tip="Reset">
      <ResetIcon />
      <ReactTooltip effect="solid" />
    </button>
  )
}
