import React, { Component } from "react";

import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import { Route } from "react-router-dom";
import { RoutedTabs, NavTab } from "react-router-tabs";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { Scrollbars } from "react-custom-scrollbars";

import { scaleLinear } from "d3-scale";
import { max } from "d3-array";

import Projections from "./Projections";
import Group from "./Group";
import Econ from "./Econ";
import CategoryDetail from "./CategoryDetail";
import UserChanges from "./UserChanges";
import SaveButton from "./SaveButton";
import ChangesIcon from "./icons/Changes";
import DashboardIcon from "./icons/Dashboard";
import CalculatorIcon from "./icons/CalculatorIcon";
import ScrollToTopOnMount from "./ScrollToTopOnMount";

import { formatDollars } from "../formatDollars.js";

import { setSidebarTabIndex } from "../Actions";

import difference, { isolateChanges } from "../difference";

const updateCategoryCharts = (user, baseline, categories, displayYear) => {
  const { scale, dataset } = makeCategoryScale(
    user,
    baseline,
    categories,
    displayYear
  );

  const groups = {
    mandatory: setupCategory(
      categories,
      "SPENDING",
      "MANDATORY",
      "spending",
      scale,
      dataset
    ),
    discretionary: setupCategory(
      categories,
      "SPENDING",
      "DISCRETIONARY",
      "spending",
      scale,
      dataset
    ),
    revenue: setupCategory(
      categories,
      "REVENUE",
      "ALL_REVENUE",
      "revenue",
      scale,
      dataset
    ),
  };

  return groups;
};

const makeCategoryScale = (user, baseline, categories, displayYear) => {
  const data = [],
    dataset = {},
    groupData = {},
    userValues = user.BUDGET,
    baselineValues = baseline.BUDGET;

  Object.keys(categories).forEach((genre) => {
    //TODO improve this?
    if (genre !== "ECONOMY") {
      const cat = categories[genre];
      Object.keys(cat).forEach((group) => {
        Object.keys(cat[group]).forEach((category) => {
          groupData[category] = {
            userValue: 0,
            baselineValue: 0,
          };
          cat[group][category].forEach(function (account) {
            const userValue =
              userValues[account] !== undefined
                ? userValues[account].values[displayYear]
                : null;
            const baselineValue =
              baselineValues[account] !== undefined
                ? baselineValues[account].values[displayYear]
                : null;
            const datum = {
              userValue: userValue,
              baselineValue: baselineValue,
              type: genre,
            };
            data.push(datum);
            dataset[account] = datum;

            groupData[category].userValue += userValue;
            groupData[category].baselineValue += baselineValue;
          });
        });
      });
    }
  });

  const maximum = max(Object.values(groupData), (d) =>
    Math.max(d.userValue, d.baselineValue)
  );

  const scale = scaleLinear().domain([0, maximum]).range([0, 100]);

  return { scale, dataset };
};

const setupCategory = (
  categories,
  genre,
  categoryHandle,
  type,
  scale,
  dataset
) => {
  const cats = [],
    category = categories[genre][categoryHandle];

  Object.keys(category).forEach((groupName) => {
    const subCats = [];

    category[groupName].forEach(function (d) {
      subCats.push({
        key: d,
        baselineRadius: scale(dataset[d].baselineValue),
        baselineValue: formatDollars(dataset[d].baselineValue),
        userRadius: scale(dataset[d].userValue),
        userValue: formatDollars(dataset[d].userValue),
        type: type,
      });
    });

    cats.push({
      categoryTitle: groupName,
      subCats: subCats,
      key: groupName,
    });
  });

  return {
    cats: cats,
    key: categoryHandle,
    handle: categoryHandle,
  };
};

const GenreEditor = ({ children }) => {
  return (
    <div className="genre-editor">
      <ScrollToTopOnMount />
      {children}
    </div>
  );
};

class Budget extends Component {
  render() {
    const {
      toggleAuthenticateSkip,
      toggleEditBudgetMeta,
      match,
      categories,
      user,
      baseline,
      userOptions,
      baselineOptions,
      definitions,
      budgetOptionsById,
      displayYear,
      setSidebarTabIndex,
      isMobile,
    } = this.props;

    const groups = updateCategoryCharts(
      user,
      baseline,
      categories,
      displayYear
    );

    const changes = difference(userOptions, baselineOptions);
    const isolatedChanges = isolateChanges(changes);
    if (changes.policiesEnacted) {
      changes.policiesEnacted.forEach((d) => {
        const policy = budgetOptionsById[d.id];
        if (!policy.hide) {
          //initialize if empty
          isolatedChanges[policy.category] =
            isolatedChanges[policy.category] || {};
          isolatedChanges[policy.category].policiesEnacted =
            isolatedChanges[policy.category].policiesEnacted || [];

          isolatedChanges[policy.category] = {
            ...isolatedChanges[policy.category],
            policiesEnacted: [
              policy,
              ...isolatedChanges[policy.category].policiesEnacted,
            ],
          };
        }
      });
    }

    const changesCount = Object.keys(isolatedChanges).reduce((r, d) => {
      r += Object.keys(isolatedChanges[d]).length;

      if (isolatedChanges[d].policiesEnacted) {
        //subtract 1 to make up for policiesEnacted already being counted as a key
        r += isolatedChanges[d].policiesEnacted.length - 1;
      }
      return r;
    }, 0);

    const EditorTabs = (
      <div className="genre-tabs text-align--center color--white">
        <RoutedTabs>
          <NavTab
            to={`/${match.params.calcKey}/edit/revenue`}
            replace={false}
            exact
            className="genre-tab font--label--alt revenue"
          >
            <span className="label">
              <span className="display-on-xxl">Adjust&nbsp;</span>Revenue
            </span>
          </NavTab>
          <NavTab
            to={`/${match.params.calcKey}/edit/spending`}
            replace={false}
            exact
            className="genre-tab font--label--alt spending"
          >
            <span className="label">
              <span className="display-on-xxl">Adjust&nbsp;</span>Spending
            </span>
          </NavTab>
          <NavTab
            to={`/${match.params.calcKey}/edit/economy`}
            replace={false}
            className="genre-tab font--label--alt economy"
          >
            <span className="label">
              <span className="display-on-xxl">Adjust&nbsp;</span>Economy
            </span>
          </NavTab>
        </RoutedTabs>
      </div>
    );

    const userChangeMade = changesCount > 0;

    const TheEditor = (
      <div className="Editor">
        <Route
          exact
          path={"/:calcKey/edit/spending"}
          render={() => (
            <GenreEditor>
              <Group
                {...groups.mandatory}
                userChangeMade={userChangeMade}
                genre="spending"
              />
              <Group
                {...groups.discretionary}
                userChangeMade={userChangeMade}
                genre="spending"
              />
            </GenreEditor>
          )}
        />
        <Route
          exact
          path={"/:calcKey/edit/revenue"}
          render={() => (
            <GenreEditor>
              <Group
                {...groups.revenue}
                userChangeMade={userChangeMade}
                genre="revenue"
              />
            </GenreEditor>
          )}
        />
        <Route
          exact
          path={"/:calcKey/edit/economy"}
          render={() => (
            <GenreEditor>
              <Econ />
            </GenreEditor>
          )}
        />
        <Route
          exact
          path={"/:calcKey/edit/:genre/:category"}
          render={({ match }) => <CategoryDetail match={match} />}
        />
      </div>
    );
    console.log(toggleAuthenticateSkip);
    const tabs = (
      <Tabs
        selectedIndex={this.props.sidebarTabIndex}
        onSelect={(tabIndex) => {
          setSidebarTabIndex(tabIndex);
        }}
      >
        <TabList className="Sidebar-tablist reset-list">
          {isMobile ? (
            <Tab>
              <CalculatorIcon />
            </Tab>
          ) : undefined}
          <Tab>
            <DashboardIcon />
          </Tab>
          <Tab>
            <ChangesIcon count={changesCount} />
          </Tab>
          <li className="react-tabs__tab">
            <SaveButton toggleAuthenticateSkip={toggleAuthenticateSkip} />
          </li>
        </TabList>

        {isMobile ? (
          <TabPanel>
            {EditorTabs}
            {TheEditor}
          </TabPanel>
        ) : undefined}

        <TabPanel>
          <Projections />
        </TabPanel>
        <TabPanel>
          <UserChanges
            toggleEditBudgetMeta={toggleEditBudgetMeta}
            changes={isolatedChanges}
            definitions={definitions}
            categories={categories}
          />
        </TabPanel>
      </Tabs>
    );
    return isMobile ? (
      <div className="Budget bg--userUltralight">
        <div className="Sidebar">{tabs}</div>
      </div>
    ) : (
      <div className="Budget bg--userUltralight">
        <Scrollbars className="Sidebar Sidebar--desktop" autoHide>
          {tabs}
        </Scrollbars>
        {EditorTabs}
        {TheEditor}
      </div>
    );
  }
}

Budget.contextTypes = {
  store: PropTypes.object,
};
const mapStateToProps = (state) => ({
  user: state.calculator.user,
  baseline: state.calculator.baseline,
  categories: state.calculator.categories,
  definitions: state.calculator.definitions,
  userOptions: state.calculator.userOptions,
  baselineOptions: state.calculator.baselineOptions,
  budgetOptionsById: state.calculator.budgetOptionsById,
  displayYear: state.session.displayYear,
  sidebarTabIndex: state.session.sidebarTabIndex,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarTabIndex: (value) => {
      dispatch(setSidebarTabIndex(value));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Budget);
