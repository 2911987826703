import React from "react";
import AnimatedPath from "components/AnimatedPath";

const Chart = ({
  width,
  height,
  margin,
  showAxis,
  axes,
  axisBottom,
  userPath,
  baselinePath,
  zero,
  hoverTargets,
  baselineClass,
}) => {
  return (
    <div className="Chart">
      <svg viewBox={`0 0 ${width} ${height}`}>
        <g transform={`translate(${margin.left} ${margin.top})`}>
          {showAxis && axes && <g className="axes">{axes}</g>}
          <AnimatedPath
            className={`user line-path stroke--user`}
            d={userPath}
          />
          <AnimatedPath
            className={`baseline line-path ${baselineClass}`}
            d={baselinePath}
          />
          {zero && (
            <g>
              <AnimatedPath className="comparison line-path" d={zero.path} />}
              <text
                className="font--number--small"
                x={zero.label.x}
                y={zero.label.y}
                textAnchor={"end"}
                dominantBaseline={"middle"}
              >
                {zero.label.text}
              </text>
            </g>
          )}
          {showAxis && hoverTargets}
        </g>
      </svg>
      {showAxis && axisBottom}
    </div>
  );
};

export default Chart;
