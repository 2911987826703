import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import QuickChanges from "./QuickChanges";
import { resetAll } from "../Actions";

import ResetIcon from "./icons/ResetCircleIcon";
import DescribeIcon from "./icons/DescribeIcon";

class UserChanges extends Component {
  static contextTypes = {
    store: PropTypes.object,
  };

  render() {
    const { changes, toggleEditBudgetMeta, resetAll } = this.props;

    return (
      <div className="UserChanges">
        <div className="Sidebar-block">
          {toggleEditBudgetMeta && (
            <button className="button-inline" onClick={toggleEditBudgetMeta}>
              <DescribeIcon />
              Describe your budget
            </button>
          )}
          {Object.keys(changes).length > 0 && (
            <button className="button-inline" onClick={resetAll}>
              <ResetIcon />
              Start Over
            </button>
          )}
        </div>
        <QuickChanges className="Sidebar-block" changes={changes} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetAll: () => {
      dispatch(resetAll);
    },
  };
};
export default connect(null, mapDispatchToProps)(UserChanges);
