module.exports = function(datasets) {

	const operations = require('./operations.js')(),
		startYear = datasets.startYear

	return {
		"kickoff": function(col) {
			const results = {}
			if (col[startYear - 1]) {
				results[startYear - 1] = col[startYear - 1]
			}
			results[startYear] = col[startYear]
			return results
		},
		"Total Hours Worked Growth Rate": function(dataset) {
			const labor = operations.sumValuesConst(dataset["LABOR_FORCE_GROWTH_RATE"].values, 1),
				thisYearUnemployment = operations.sumValuesConst(operations.negate(dataset["UNEMPLOYMENT_RATE"].values), 1),
				lastYear = operations.yearShift(dataset["UNEMPLOYMENT_RATE"].values, -1),
				lastYearUnemployment = operations.sumValuesConst(operations.negate(lastYear), 1),
				hoursGrowth = operations.sumValuesConst(dataset["Average Hours Worked Growth Rate"].values, 1)

			const unemployment = operations.quotientValues(thisYearUnemployment,lastYearUnemployment);

			const product = operations.productValues([labor,unemployment,hoursGrowth])

			const result = operations.sumValuesConst(product,-1);

			return result

		},
		"Real GDP Growth Rate": function(dataset) {
			const results = operations.sumValues([
				dataset["TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE"].values,
				operations.productValuesConst(dataset["Capital Growth"].values, datasets.TECHNICAL_ASSUMPTIONS["Share of output growth attributed to capital growth"]),
				operations.productValuesConst(dataset["Total Hours Worked Growth Rate"].values, (1 - datasets.TECHNICAL_ASSUMPTIONS["Share of output growth attributed to capital growth"]))
			])
			return results
		},
		"Real GDP": function(dataset) {
			const results = {}

			const kickoffYear = dataset["bm_gdp_real"].values[startYear]

			results[startYear] = kickoffYear;

			const rate = operations.sumValuesConst(dataset["Real GDP Growth Rate"].values, 1)

			for (var i=startYear+1; i<=datasets.endYear; i++) {

				results[i] = rate[i]*results[i - 1]
			}

			return results;
		},
		"Labor Productivity Growth Rate": function(dataset) {
			const growth = dataset["Real GDP Growth Rate"].values,
				hours = operations.negate(dataset["Total Hours Worked Growth Rate"].values)

			return operations.sumValues([growth,hours])
		},
		"Labor Productivity Growth Rate (Nominal)": function(dataset) {
			return operations.sumValues([
				dataset["GDP Price Index Growth Rate"].values,
				dataset["Labor Productivity Growth Rate"].values
			])
		},
		"Labor Productivity Index (Nominal)": function(dataset) {
			const results = {}

			const rate = operations.sumValuesConst(dataset["Labor Productivity Growth Rate (Nominal)"].values, 1)

			results[startYear] = 1;

			Object.keys(rate).forEach(key => {
				if (key > startYear && rate[key]) {
					results[key] = rate[key]*results[key - 1]
				}
			})

			return results

		},
		"CPI-U Price Index": function(dataset) {
			const results = {}

			const rate = operations.sumValuesConst(dataset["INFLATION_RATE"].values, 1)

			results[startYear] = 1;

			Object.keys(rate).forEach(key => {
				if (rate[key] && results[key - 1]) {
					results[key] = rate[key]*results[key - 1]
				}
			})

			return results
		},
		"GDP Price Index": function(dataset) {
			const results = {}
			const rate = operations.sumValuesConst(dataset["GDP Price Index Growth Rate"].values, 1)

			results[startYear] = 1;

			Object.keys(rate).forEach(key => {

				if (rate[key] && results[key - 1]) {
					results[key] = rate[key]*results[key - 1]
				}

			})

			return results
		},
		"Nominal GDP": function(dataset) {
			return operations.productValues([dataset["GDP Price Index"].values, dataset["Real GDP"].values])
		}
	}
}