import React from "react";
import { a, useSpring, config } from "react-spring";

const AnimatedRect = ({ x, y, height, width, forwardRef, ...rest }) => {
  x = Math.max(x, 0);
  width = Math.max(width, 0);
  const aprops = useSpring({ width, config: config.molasses });

  //test width to avoid render error loop
  return !isNaN(width) ? (
    <a.rect
      {...rest}
      ref={forwardRef}
      width={aprops.width}
      height={height}
      x={x}
      y={y}
    />
  ) : null;
};

export default AnimatedRect;
