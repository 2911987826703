module.exports = ({
	"REVENUE": {
		"ALL_REVENUE": {
			"INDIVIDUAL_TAXES": [
				"PERSONAL_INCOME_TAXES"
			],
			"PAYROLL_TAXES": [
				"SOCIAL_SECURITY_TAXES",
				"MEDICARE_TAXES",
				"OTHER_PAYROLL_TAXES"
			],
			"CORPORATE_TAXES": [
				"CORPORATE_INCOME_TAXES"
			],
			"OTHER_REVENUE": [
				"OTHER_TAXES"
			]
		}
	},
	"SPENDING": {
		"MANDATORY": {
			"HEALTH_CARE": [
				"MEDICARE",
		    "MEDICAID",
		    "CHIP",
		    "ACA"
			],
			"SOCIAL_SECURITY": [
				"SOCIAL_SECURITY_ALL"
			],
			"INCOME_SECURITY": [
	      "REFUNDABLE_TAX_CREDITS",
	      "VA_MANDATORY",
	      "UNEMPLOYMENT",
	      "SUPPLEMENTAL_SECURITY",
	      "NUTRITIONAL_ASSISTANCE",
			],
		  "OTHER_MANDATORY_SPENDING": [
		    "AGRICULTURE",
		    "OTHER_MANDATORY"
			]
		},
		"DISCRETIONARY": {
		  "NONDEFENSE": [
				"EDUCATION",
				"ENERGY",
				"ENVIRONMENT",
				"TRANSPORTATION",
				"INTERNATIONAL",
				"JUSTICE",
				"SCIENCE",
				"VA_DISCRETIONARY",
				"OTHER_DISCRETIONARY"
			],
			"DEFENSE": [
				"DEFENSE_OPERATIONS",
				"DEFENSE_OTHER",
				"DEFENSE_PERSONNEL",
				"DEFENSE_PROCUREMENT",
				"DEFENSE_RTDE"
			]
		}
	},
	"ECONOMY": [
		"TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE",
		"LABOR_FORCE_GROWTH_RATE",
		"UNEMPLOYMENT_RATE",
		"INFLATION_RATE",
		"REAL_INTEREST_RATE"
	]
})