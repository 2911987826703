import React, { useMemo, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setGrowthRateAdditions, addPolicy, removePolicy } from "Actions";
import changeTypes from "components/embed/changeTypes";
import { flattenTree, grandchildCategories } from "categoryFunctions";
import NextArrowIcon from "components/next-arrow-icon";
import styles from "./question.module.scss";

const Question = ({
  question,
  choices,
  dispatch,
  changeId,
  changeType,
  categories,
  nextIndex,
}) => {
  const [localValue, setLocalValue] = useState();
  const flatCats = useMemo(() => flattenTree(categories), [categories]);
  const onChange = (value) => {
    setLocalValue(value);
    if (changeType === changeTypes.LEVEL) {
      const children = grandchildCategories(changeId, flatCats);
      console.log(children);
      dispatch(
        setGrowthRateAdditions(
          children.length
            ? children.map(({ key }) => ({
                category: key,
                value,
              }))
            : [{ category: changeId, value }]
        )
      );
    } else if (changeType === changeTypes.POLICY) {
      if (value) {
        dispatch(addPolicy(changeId, false));
      } else {
        dispatch(removePolicy(changeId));
      }
    }
  };
  const inputRef = useRef();
  useEffect(() => {
    setLocalValue(null);
    inputRef.current.focus();
  }, [choices]);

  return (
    <div className={styles.question}>
      <div className={styles.text}>{question}</div>
      <div className={styles.choices}>
        {choices.map(({ value, label }, i) => {
          return (
            <label className={styles.choicesItem} key={changeId + value}>
              <input
                ref={i === 0 ? inputRef : null}
                type="radio"
                value={value}
                name={changeId}
                onClick={() => onChange(value)}
              />
              <div className={styles.choicesItemLabel}>{label}</div>
            </label>
          );
        })}
      </div>
      <button
        className={`${styles.next} ${localValue ? "active" : ""}`}
        onClick={nextIndex}
      >
        Next Question
        <NextArrowIcon />
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.calculator.categories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Question);
