import changeTypes from "components/embed/changeTypes";

export default {
  heading: "Tradeoffs",
  description:
    "Federal debt will exceed 100% of GDP in 2034. Can you save us from this crisis? The challenge is more daunting than you can imagine. Can anthing solve this problem",
  questions: [
    {
      heading: "All Spending",
      text: "How much should we change all spending?",
      changeType: changeTypes.LEVEL,
      changeId: "SPENDING",
      choices: [
        { value: -8, label: "-8%" },
        { value: -4, label: "-4%" },
        { value: 4, label: "4%" },
        { value: 8, label: "8%" },
      ],
    },
    {
      heading: "Repeal the Tax Cuts and Jobs Act",
      changeType: changeTypes.POLICY,
      changeId: "r189-0",
      choices: [
        {
          value: true,
          label: "yes",
        },
        {
          value: false,
          label: "no",
        },
      ],
    },
    {
      heading: "Personal Income Taxes",
      text: "How much should we change income taxes?",
      changeType: changeTypes.LEVEL,
      changeId: "PERSONAL_INCOME_TAXES",
      choices: [
        { value: -8, label: "-8%" },
        { value: -4, label: "-4%" },
        { value: 4, label: "4%" },
        { value: 8, label: "8%" },
      ],
    },
    {
      heading: "Social Security Spending",
      text: "How much should we change Social Security?",
      changeType: changeTypes.LEVEL,
      changeId: "SOCIAL_SECURITY_ALL",
      choices: [
        { value: -8, label: "-8%" },
        { value: -4, label: "-4%" },
        { value: 4, label: "4%" },
        { value: 8, label: "8%" },
      ],
    },
  ],
};
