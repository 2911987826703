import React from 'react';
import { Link } from 'react-router-dom';

import AccountIcon from "./icons/AccountIcon";

export default ({ firstAuthComplete, loggedInUser, toggleAuthenticate, className }) => (
  <div className={`Login${!firstAuthComplete ? " loading" : ""} ${className}`}>
    {loggedInUser ?
      <Link to="/account" className="icon--link"><AccountIcon /> <span className="Nav-item-helper-text">Account</span></Link> :
      <button
        className="reset-button font--label"
        onClick={toggleAuthenticate}>Log In</button>}
  </div>
)
