import React from "react";
import { Link } from "react-router-dom";

export default ({ loggedInUser, authenticator, deleteEverything }) => {
  const { displayName, roles } = loggedInUser;

  return (
    <div className="Profile page-pad">
      <div className="Profile-item">
        <div className={"font--h2"}>{ displayName }</div>
      </div>

      <button
        className="Profile-item font--label reset-button color--user link-button"
        onClick={() => authenticator.signOut()}>
        Sign-out
      </button>

      <button
        className="Profile-item font--label reset-button color--debt link-button"
        onClick={() => deleteEverything()}>
        Delete Everything
      </button>

      <div
        className={"Profile-item font--label reset-button color--black link-button"}>
        { roles && roles.includes('admin') && <Link to="/admin">Admin</Link> }
        </div>
    </div>
  )
}