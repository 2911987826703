import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import GrowthRateSelect from "./GrowthRateSelect";

const EconGrowthRateSelect = (props) => {
  return (
    <GrowthRateSelect {...props} tooltipType="economy" label="Adjust rate" />
  )
}

EconGrowthRateSelect.contextTypes = {
  store: PropTypes.object
}
const mapStateToProps = (state) => ({ options: state.calculator.growthOptions.economic })
export default connect(mapStateToProps)(EconGrowthRateSelect);