import React from "react";
import BudgetGrowthRateSelect from './BudgetGrowthRateSelect';
import GrowthSlider from './GrowthSlider';

export default ({
	onGrowthRateChange,
	onGrowthRateAdjustmentChange,
	growthRateValue,
	growthRateAdjustmentValue,
	onGrowthSliderChange,
	growthRateAdditionValue,
	growthRateAdditionMarks,
	parentGrowthRateAdditionValue,
	category,
	tooltipClass
}) => (
	<div className="GrowthRateSettings GrowthRateSettings--two">
		<div className="GrowthRateSetting">
			<BudgetGrowthRateSelect
				onChange={onGrowthRateChange}
				onGrowthRateAdjustmentChange={onGrowthRateAdjustmentChange}
				growthRateAdjustmentValue={growthRateAdjustmentValue}
				category={category}
				value={growthRateValue} />
		</div>
		<div className="GrowthRateSetting">
			<GrowthSlider
				tooltipClass={tooltipClass}
				onChange={onGrowthSliderChange}
				value={growthRateAdditionValue}
				parentValue={parentGrowthRateAdditionValue}
				marks={growthRateAdditionMarks} />
		</div>
	</div>
)