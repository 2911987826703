import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
//import Slider from 'rc-slider';
import Slider from 'react-slider';
import { setDisplayYear } from "../Actions.js";

class DisplayYearSlider extends Component {
  static defaultProps = {
    orientation: 'horizontal'
  }
  render() {
    const { displayYear, startYear, endYear, orientation, setDisplayYear } = this.props
    return (
      <div className={`DisplayYearSlider ${orientation}`}>
        <div className="DisplayYearSlider-year-label font--label--small text-align--center">{startYear}</div>
        <Slider
          withBars
          orientation={orientation}
          className={`year-slider slider--black ${orientation}`}
          value={displayYear}
          min={startYear}
          max={endYear}
          onChange={setDisplayYear}>
          <div>{displayYear}</div>
        </Slider>
        <div className="DisplayYearSlider-year-label font--label--small text-align--center">{endYear}</div>
      </div>
    );
  }
}

DisplayYearSlider.contextTypes = {
  store: PropTypes.object
}

const mapStateToProps = (state) => ({
  displayYear: state.session.displayYear,
  startYear: state.calculator.baseline.startYear,
  endYear: state.calculator.baseline.endYear
})
const mapDispatchToProps = (dispatch) => ({
  setDisplayYear: (year) => {
    dispatch(setDisplayYear(year))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(DisplayYearSlider)