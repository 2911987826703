import React from "react";
import { Link } from 'react-router-dom';

import CloseIcon from "./icons/Close.js";

export default ({ to, style }) =>
<Link
  style={style}
	title="Go Back"
	to={to}
	className="DoneEditing reset-link font--label--small uppercase">
	<span className={"color--label"}>Close</span>
  <CloseIcon fill="transparent" stroke="black" />
</Link>