import { auth, database, initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/database";

// Configure Firebase.
const config = {
  apiKey: "AIzaSyBKt600T60YInPrRju5YXVEiVQrjsfBMTY",
  authDomain: "hoover-america-off-balance.firebaseapp.com",
  databaseURL: "https://hoover-america-off-balance.firebaseio.com",
  projectId: "hoover-america-off-balance",
  storageBucket: "",
  messagingSenderId: "804453209920",
};
initializeApp(config);
const authenticator = auth();
const databasor = database();

export { authenticator, databasor };
