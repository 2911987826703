import React from "react";

export default ({ authors }) => {
  if (authors.length) {
    return <div className="Authors ff--basis label" children={[
      'By ',
      ...authors.reduce((result,auth,i) => {
        result.push(<span key={auth}>{auth}</span>)
        if (i < authors.length - 2) {
          result.push(', ')
        } else if (i === authors.length - 2) {
          result.push(' and ')
        }
        return result
      }, [])
    ]} />
  }
  return null
}