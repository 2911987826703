import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { formatDollars, formatDollarsDelta, formatPercentage, formatPercentageDelta } from "../formatDollars";

import CloseIcon from './icons/Close';

import CategorySnapshot from './CategorySnapshot';

import {
  setGrowthRate,
  setGrowthRateAdjustment,
  setGrowthRateAddition,
  setDollarAddition,
  removePolicy
} from '../Actions.js';

class QuickChange extends Component {
  static contextTypes = {
    store: PropTypes.object
  }

  render() {
    const {
      categoryLink,
      catKey,
      changes,
      genre,
      definitions,
      includeSnapshot,
      includeLink,
      baseline,
      user,
      displayYear,
      resetGrowthRate,
      resetGrowthRateAddition,
      resetDollarAddition,
      removePolicy
    } = this.props

    const sections = []
    const category = definitions[catKey]

    if (!changes) return null

    const isEcon  = genre.toUpperCase() === 'ECONOMY'
    const rateSetting = changes.growthRate ? (isEcon ? changes.growthRate.replace('GROWTH','RATE') : changes.growthRate) : null
    const fallbackRate = isEcon ? 'CBO_RATE' : 'CBO_GROWTH'

    if (changes.growthRate || changes.growthRateAdjustment || changes.growthRateAddition || changes.additions) {
      if (changes.growthRate || changes.growthRateAdjustment) {
        sections.push(
          <div className="QuickChange-section" key="growthRate">
            <div>
              <span className="label bold">{!isEcon && 'Growth '}Rate: </span>
              <span className="value">
                <span>{definitions[rateSetting ? rateSetting : fallbackRate]}</span>
                <span className="font--number--small"> {formatPercentageDelta(changes.growthRateAdjustment)}</span>
              </span>
            </div>
            <button className="reset-button" onClick={() => {
              resetGrowthRate(catKey)
            }}><CloseIcon /></button>
          </div>
        )
      }
      if (changes.growthRateAddition) {
        sections.push(
           <div className="QuickChange-section" key="growthRateAddition">
            <div>
              <span className="label bold">{`${changes.growthRateAddition >= 0 ? "Increase" : "Decrease"} by: `}</span>
              <span className="value font--number--small">
                {`${formatPercentage(changes.growthRateAddition)}`}
              </span>
            </div>
            <button className="reset-button" onClick={() => {
              resetGrowthRateAddition(catKey)
            }}><CloseIcon /></button>
          </div>
        )
      }
      if (changes.additions) {
        sections.push(
           <div className="QuickChange-section QuickChange-section-column" key="additions">
            <div className="label bold">Additions: </div>
            <div className="value font--number--small">
              <ul className="reset-list">
                {Object.keys(changes.additions).reduce((r,d) => {
                  r.push(
                    <li className="QuickChange-item" key={d}>
                      <div>
                        <span>{d}</span>&nbsp;<span>{formatDollarsDelta(changes.additions[d])}</span>
                      </div>
                      <button className="reset-button" onClick={() => {
                        resetDollarAddition(catKey,d)
                      }}><CloseIcon /></button>
                    </li>
                  )
                  return r
                },[])}
              </ul>
            </div>
          </div>
        )
      }
    }

    if (changes.policiesEnacted && changes.policiesEnacted.length) {
      sections.push(
        <div className="QuickChange-section QuickChange-section-column" key="policies">
          <div className="font--label--small bold">Policies Enacted: </div>
          {changes.policiesEnacted.map((policy) => {
            return (
              <div className="QuickChange-item" key={policy.id}>
                <div>{policy.title}</div>
                <button className="reset-button" onClick={() => {
                  removePolicy(policy.id)
                  policy.turnOn.forEach(d => {
                     removePolicy(d)
                  })
                }}><CloseIcon /></button>
              </div>
            )
          })}
        </div>
      )
    }

    const section = Object.keys(user.ECON).includes(catKey) ? 'ECON' : 'BUDGET'

    return (
      <div className="QuickChange font--label--small" style={this.props.style}>
        {includeLink ?
          <Link
            to={categoryLink}
            className={`QuickChange-category QuickChange-category--link font--label bold color--${genre.toLowerCase()}`}>
            {category}
          </Link> :
          <div
            className={`QuickChange-category font--label bold color--${genre.toLowerCase()}`}>
            {category}
          </div>
        }
        {includeSnapshot &&
          <div className="QuickChange-snapshot">
            <CategorySnapshot
              cboValue={baseline[section][catKey].values[displayYear]}
              yourValue={user[section][catKey].values[displayYear]}
              genre={genre.toLowerCase()}
              format={section === 'ECON' ? (d) => formatPercentage(100*d) : formatDollars}
              deltaFormat={section === 'ECON' ? (d) => formatPercentageDelta(100*d) : formatDollarsDelta}
              year={displayYear} />
          </div>
        }
        <div className="QuickChange-changes">
        {sections}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetGrowthRate: (category) => {
    dispatch(setGrowthRate("CBO_GROWTH",category))
    dispatch(setGrowthRateAdjustment(0,category))
  },
  resetGrowthRateAddition: (category) => {
    dispatch(setGrowthRateAddition(0, category))
  },
  resetDollarAddition: (category,year) => {
    dispatch(setDollarAddition(0,category,year))
  },
  removePolicy: (id) => {
    dispatch(removePolicy(id))
  }
})
export default connect(null, mapDispatchToProps)(QuickChange)