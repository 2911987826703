export default (datasets) => ((settings) => {
	const
		operations = require("../../../operations.js")(datasets),
		formulas = require("../../../formulas.js")(datasets),
		reference = datasets.ECON_CBO_ASSUMED,
		startYear = datasets.startYear

	function baselineThenOption(column, settingHandle, historicalHandle)
	{
		const results = {}
		const setting = settingHandle || column
		historicalHandle = historicalHandle || column

		results[startYear - 1] = reference[column].values[startYear - 1]
		results[startYear] = reference[column].values[startYear]

		for (let i=datasets.startYear+1; i<=datasets.endYear; i++) {
			switch (settings[setting].growthRate) {
				case 'CBO_GROWTH':
					results[i] = reference[column].values[i]
					break;

				case 'HISTORICAL_GROWTH':
					results[i] = datasets.HISTORICAL_ECON_RATES[historicalHandle]
					break;

				case 'ZERO_GROWTH':
					results[i] = 0;
					break;

				default:
					console.warn("Setting option not set up!: " + settings[setting].growthRate)
			}

			if (typeof settings[setting] !== "undefined" && settings[setting].growthRateAdjustment) {
				results[i] += settings[setting].growthRateAdjustment/100
			}
		}

		return results
	}


	function baseline(column)
	{
		const results = {}

		results[startYear - 1] = reference[column].values[startYear - 1]
		results[startYear] = reference[column].values[startYear]

		for (let i=datasets.startYear+1; i<=datasets.endYear; i++) {
				results[i] = reference[column].values[i]
		}

		return results
	}

	function option(column, settingHandle, historicalHandle)
	{
		let results = {}
		const setting = settingHandle || column
		historicalHandle = historicalHandle || column

		results[startYear - 1] = reference[column].values[startYear - 1]
		for (var i=datasets.startYear; i<=datasets.endYear; i++) {
			switch (settings[setting].growthRate) {
				case 'CBO_GROWTH':
					results[i] = reference[column].values[i]
					break;

				case 'HISTORICAL_GROWTH':
					results[i] = datasets.HISTORICAL_ECON_RATES[historicalHandle]
					break;

				case 'ZERO_GROWTH':
					results[i] = 0;
					break;

				default:
					console.warn("Setting option not set up!: " + settings[setting].growthRate)
			}

			if (typeof settings[setting] !== "undefined" && settings[setting].growthRateAdjustment) {
				results[i] += settings[setting].growthRateAdjustment/100
			}
		}

		return results
	}

	const data = {
		"TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE": {
			"formula": function() {
				return baselineThenOption("TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE",null,"Total Factor Productivity")
			}
		},
		"Capital Growth": reference["Capital Growth"],
		"LABOR_FORCE_GROWTH_RATE": {
			"formula": function() {
				return option("LABOR_FORCE_GROWTH_RATE",null,"Labor Force Growth")
			}
		},
		"UNEMPLOYMENT_RATE": {
			"formula": function() {
				return baselineThenOption("UNEMPLOYMENT_RATE",null,"Unemployment Rate")
			}
		},
		"Average Hours Worked Growth Rate": reference["Average Hours Worked Growth Rate"],
		"INFLATION_RATE": {
			"formula": function() {
				return baselineThenOption("INFLATION_RATE",null,"CPI-U Growth Rate")
			}
		},
		"BASELINE_INFLATION_RATE": {
			"formula": function() {
				return baseline("INFLATION_RATE")
			}
		},
		"GDP Price Index Growth Rate": {
			"formula": function() {
				return baselineThenOption("GDP Price Index Growth Rate","INFLATION_RATE","GDP Price Index Growth Rate")
			}
		},
		"Total Hours Worked Growth Rate": {
			"formula": function() {
				return formulas['Total Hours Worked Growth Rate'](data)
			}
		},
		"Real GDP Growth Rate": {
			"formula": function() {
				return formulas['Real GDP Growth Rate'](data)
			}
		},
		"bm_gdp_real": reference["bm_gdp_real"],
		"Real GDP": {
			"formula": function() {
				return formulas['Real GDP'](data)
			}
		},
		"Labor Productivity Growth Rate": {
			"formula": function() {
				return formulas['Labor Productivity Growth Rate'](data)
			}
		},
		"Labor Productivity Growth Rate (Nominal)": {
			"formula": function() {
				return formulas['Labor Productivity Growth Rate (Nominal)'](data)
			}
		},
		"Labor Productivity Index (Nominal)": {
			"formula": function() {
				return formulas["Labor Productivity Index (Nominal)"](data)
			}
		},
		"CPI-U Price Index": {
			"formula": function() {
				return formulas["CPI-U Price Index"](data)
			}
		},
		"GDP Price Index": {
			"formula": function() {
				return formulas["GDP Price Index"](data)
			}
		},
		"Nominal GDP": {
			"formula": function() {
				return formulas["Nominal GDP"](data)
			}
		},
		"Nominal GDP Growth Rate": {
			"formula": function() {
				return operations.yearOverYear(data["Nominal GDP"].values)
			}
		},
		"Population": reference["Population"],
		"Real GDP Per Capita": {
			"formula": function() {
				return operations.quotientValues(data["Real GDP"].values, data["Population"].values)
			}
		},
		"Real GDP Per Capita Growth Rate": {
			"formula": function() {
				return operations.yearOverYear(data["Real GDP Per Capita"].values)
			}
		},
		"Nominal GDP Per Capita": {
			"formula": function() {
				return operations.quotientValues(data["Nominal GDP"].values, data["Population"].values)
			}
		},
		"Nominal GDP Per Capita Growth Rate": {
			"formula": function() {
				return operations.yearOverYear(data["Nominal GDP Per Capita"].values)
			}
		},
		"REAL_INTEREST_RATE": {
			"formula": function() {
				return option("REAL_INTEREST_RATE",null,"Net Debt Interest Rate")
			}
		},
		"BASELINE_REAL_INTEREST_RATE": {
			"formula": function() {
				return baseline("REAL_INTEREST_RATE")
			}
		},
		"Real Interest Rate on OASDI Trust Fund": {
			"formula": function() {
				return option("Real Interest Rate on OASDI Trust Fund","REAL_INTEREST_RATE","Net Debt Interest Rate")
			}
		},
		"Nominal Interest Rate on Public Debt": {
			"formula": function() {
				return operations.sumValues([data["INFLATION_RATE"].values,data["REAL_INTEREST_RATE"].values])
			}
		},
		"Baseline Nominal Interest Rate on Public Debt": {
			"formula": function() {
				return operations.sumValues([data["BASELINE_INFLATION_RATE"].values,data["BASELINE_REAL_INTEREST_RATE"].values])
			}
		},
		"Nominal Interest Rate on OASDI Trust Fund": {
			"formula": function() {
				return operations.sumValues([data["INFLATION_RATE"].values,data["Real Interest Rate on OASDI Trust Fund"].values])
			}
		}
	}
	//console.log(datasets)
	return data
})