//import React from "react";
//import { Trail, animated } from 'react-spring';

// export default ({ children }) => {
//   return (
//     <Trail
//       native
//       items={children}
//       keys={children.map((d) => d.key)}
//       from={{ opacity: 0}}
//       to={{ opacity: 1}}>
//       {d => styles => <animated.div className="transition-transform" style={styles}>{d}</animated.div>}
//     </Trail>
//   )
// }

export default ({ children }) => children;
