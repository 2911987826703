/* eslint-disable */
//eslint-disable-next-line
require = require("esm")(module);

const datasets = {},
  fs = require("fs"),
  d3 = require("d3");

const OPTION_LENGTH = 50;

const billions = (d) => d * 1e6;
//racheted down to millions

const RAW_DIR = "/raw",
  PROCESSED_DIR = "/processed/",
  SUFFIX = "_2019_08";

const rawSheets = {
  cbo_10yr_accountprojections: `/cbo_10yr_accountprojections${SUFFIX}.csv`,
  cbo_lt_econ: `/cbo_ltecon${SUFFIX}.csv`,
  maturedebt_share: `/maturedebt_share${SUFFIX}.csv`,
  "transformed cbo ltecon": `/cbo_ltecon_transformed${SUFFIX}.csv`,
  HISTORICAL_ECON_RATES: `/historical econ rates${SUFFIX}.csv`,
  ssa_6g6: `/ssa_6g6${SUFFIX}.csv`,
  ssa_6g2: `/ssa_6g2${SUFFIX}.csv`,
  ssa_4b3: `/ssa_4b3${SUFFIX}.csv`,
  ssa_5a1: `/ssa_5a1${SUFFIX}.csv`,
  ssa_5a3: `/ssa_5a3${SUFFIX}.csv`,
  cms_medicareenrollment: `/cms_medicareenrollment${SUFFIX}.csv`,
  cbo_lthcexcesscost: `/cbo_lthcexcesscost${SUFFIX}.csv`,
  "cbo budget options": `/cbo budget options${SUFFIX}.csv`,
  tc_assumptions: "/tc_assumptions.csv",
  tc_baseyeartaxcode: "/tc_baseyeartaxcode.csv",
  tc_policyoptions: "/tc_policyoptions.csv",
};

//Maybe user driven in the future
datasets.TECHNICAL_ASSUMPTIONS = require("./technicalAssumptions.js");

//pull these out for convenience
datasets["startYear"] = +datasets.TECHNICAL_ASSUMPTIONS[
  "Start year of data (projections will begin in the following year)"
];
datasets["endYear"] =
  datasets.startYear + +datasets.TECHNICAL_ASSUMPTIONS["Years Projected"];

//All raw
datasets.CBO_10 = require("./data-structures/cbo_10_year.js").default(datasets);
datasets.CBO_LT_ECON = require("./data-structures/cbo_lt_econ.js").default(
  datasets
);
//datasets.CBO_LT_BUDGET = require ('./data-structures/cbo_lt_budget.js')(datasets)
datasets.ECON_CBO_ASSUMED = require("./data-structures/Economic Model (orig) - CBO Assumed.js").default(
  datasets
);

init();

function init() {
  const results = [];

  Object.keys(rawSheets).forEach((sheet) => {
    const data = fs.readFileSync(
        __dirname + RAW_DIR + rawSheets[sheet],
        "utf8"
      ),
      parsed = {};

    parsed[sheet] = d3.csvParse(data);
    results.push(parsed);
  });
  processSheets(results);
}

function processSheets(results) {
  const processedData = {};

  results.forEach(function (result) {
    Object.keys(result).forEach((key) => {
      processedData[key] = result[key];
    });
  });

  processData(processedData);
}

function trimEmptyReducer(result, d) {
  d = d.trim();
  if (d !== "") {
    result.push(d.replace(".", "-"));
  }
  return result;
}

function pullOptions(parsedData) {
  const startYear = datasets["startYear"];

  const options = parsedData["cbo budget options"].reduce((r, d) => {
    const option = {};

    const id = d.number;
    option.id = id.replace(".", "-");
    option.isPercent = +d["percent"] === 1;
    option.hide = +d["hide"] === 1;

    option.category = d["annualbudget_variable"];
    if (option.category === undefined) {
      console.log("missing: " + d["annualbudget_variable"]);
      return r;
    }

    if (datasets.CBO_10[option.category] === undefined) {
      console.log("missing in projections: " + option.category);
      return r;
    }

    option.growsAfter10 = false;
    const additions = {};
    let i = startYear;

    while (i <= startYear + OPTION_LENGTH) {
      if (d[i]) {
        if (option.isPercent) {
          additions[i] = parseFloat(d[i]);
        } else {
          additions[i] =
            billions(parseFloat(d[i])) /
            datasets.CBO_10[option.category].values[i];
        }
      } else {
        if (i === startYear + 10) {
          option.growsAfter10 = true;
        }
        additions[i] = additions[i - 1];
      }
      i++;
    }

    option.values = additions;
    option.title = d["Title"];
    option.description = d["Description"];
    option.turnOn = d["secondaries_on"].split(",").reduce(trimEmptyReducer, []);
    option.turnOff = d["other_primaries_off"]
      .split(",")
      .reduce(trimEmptyReducer, []);
    option.url = d["url"];

    r.push(option);
    return r;
  }, []);

  return options;
}

function processData(parsedData) {
  const processors = require("../../processors.js")(datasets, parsedData),
    processFormulas = processors.processFormulas,
    processPulls = processors.processPulls;

  Object.keys(datasets).forEach((set) => {
    //first process direct value pulls
    processPulls(datasets[set]);

    //then functions that rely on direct value pulls
    processFormulas(datasets[set]);
  });

  datasets.HISTORICAL_ECON_RATES = parsedData.HISTORICAL_ECON_RATES[0];

  datasets["budget options"] = parsedData["budget_options"];

  const budgetOptions = pullOptions(parsedData);

  Object.keys(datasets.HISTORICAL_ECON_RATES).forEach((key) => {
    datasets.HISTORICAL_ECON_RATES[key] = parseFloat(
      datasets.HISTORICAL_ECON_RATES[key]
    );
  });

  fs.writeFile(
    __dirname + PROCESSED_DIR + "data.json",
    JSON.stringify(parsedData),
    (error) => {
      if (error) throw error;
      console.log("Parsed data has been saved!");
    }
  );

  fs.writeFile(
    __dirname + PROCESSED_DIR + "calc.json",
    JSON.stringify(datasets),
    (error) => {
      if (error) throw error;
      console.log("Calc has been saved!");
    }
  );

  fs.writeFile(
    __dirname + PROCESSED_DIR + "policyOptions.json",
    JSON.stringify(budgetOptions),
    (error) => {
      if (error) throw error;
      console.log("Budget Options by Category have been saved!");
    }
  );
}
