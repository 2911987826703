export default (datasets) => {
	return () => {
		const
      startYear = datasets.startYear + 4,
			endYear = datasets.startYear + 10,
      gdp = datasets.ECON_CBO_ASSUMED["Nominal GDP"].values,
      accounts = [
        "AGRICULTURE",
        "VA_MANDATORY",
        "UNEMPLOYMENT",
        "SUPPLEMENTAL_SECURITY",
        "OTHER_MANDATORY"
      ]

		const
      startValue = sumAccounts(datasets, accounts, startYear)/gdp[startYear],
			endValue = sumAccounts(datasets, accounts, endYear)/gdp[endYear],
      ratio = endValue/startValue,
			exp = 1/(endYear- startYear)

		return Math.pow(ratio,exp) - 1
	}
}

const sumAccounts = (datasets, accounts, year) => {
  return accounts.reduce((total,account) => total + datasets.CBO_10[account].values[year], 0)
}