import React from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  Crosshair,
} from "react-vis";
import useMeasure from "react-use-measure";
import "./chart.scss";

const colors = {
  debt: "#ff5a42",
  user: "#36c4ee",
};
const MeasuredChart = ({ className = "", data, currentYear, setYear }) => {
  const [ref, bounds] = useMeasure();

  return (
    <div className={className} style={{ width: "100%", height: "100%" }}>
      <div
        className="TaxChart"
        ref={ref}
        style={{ width: "100%", height: "400px", maxHeight: "70vh" }}
      >
        <div style={{ position: "absolute" }}>
          <Chart
            data={data}
            width={bounds.width}
            height={bounds.height}
            setYear={setYear}
            crosshairData={[currentYear.before, currentYear.after]}
          />
        </div>
      </div>
    </div>
  );
};
const Chart = ({ data, width, height, setYear, crosshairData }) => {
  const allY = [...data.before, ...data.after].map(({ y }) => y);
  const yMax = Math.max(...allY);
  const yMin = Math.min(...allY);
  return (
    <XYPlot
      height={height}
      width={width}
      yDomain={[Math.min(0.1, yMin), Math.max(0.3, yMax)]}
    >
      <HorizontalGridLines />
      <XAxis
        tickFormat={(v) => `${v}`}
        tickSize={0}
        tickTotal={Math.floor(width / 60)}
      />
      <YAxis tickFormat={(v) => `${Math.round(v * 100)}%`} />
      <LineSeries
        data={data.before}
        color={colors.debt}
        onNearestX={(value) => {
          setYear(value.x);
        }}
      />
      <LineSeries data={data.after} color={colors.user} />
      <Crosshair
        values={crosshairData}
        style={{ line: { backgroundColor: "black" } }}
      >
        <div></div>
      </Crosshair>
    </XYPlot>
  );
};

export default MeasuredChart;
