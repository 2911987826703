import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function difference(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = value;
    }
  });
}

export default difference;


export const isolateChanges = (changes) => {
  return Object.keys(changes).reduce((r,d) => {
    const isolated = {}
    const datum = changes[d];

    //get out if upper category
    if (!datum.bottom) return r

    if (datum.growthRate !== 'CBO_GROWTH') {
        isolated.growthRate = datum.growthRate
    }
    if (datum.growthRateAdjustment !== 0) {
      isolated.growthRateAdjustment = datum.growthRateAdjustment
    }
    if (datum.growthRateAddition !== 0) {
      isolated.growthRateAddition = datum.growthRateAddition
    }
    const isolatedDollars = {}

    Object.keys(datum.additions).forEach(year => {
      if (datum.additions[year] !== 0) {
        isolatedDollars[year] = datum.additions[year]
      }
    })
    if (Object.values(isolatedDollars).length) {
      isolated.additions = isolatedDollars
    }
    if (Object.values(isolated).length) {
      r[d] = isolated
    }
    return r
  }, {});
}