const isEmpty = require("lodash/isEmpty")

export default (datasets,settings,budgetOptionsById) => {
	const { categoryData, categoryValues, sumCategories } = require("../../../categoryFunctions.js")

	let
		operations = require("../../../operations.js")(datasets),
		formulas = require("../../../formulas.js")(datasets),
		categories = require("../categories.js");

	const
		startYear = datasets.startYear,
		kickoff = formulas.kickoff

	const
		initial = datasets.BUDGET_INITIAL,
		baseline = datasets.ECON_BASE

	const determinePolicyAdditions = (year,category,baseValue) => {
		if (settings.policiesEnacted === undefined) return 1

		const policies = settings.policiesEnacted.filter((d) => {
			if (budgetOptionsById[d.id] === undefined) {
				console.log("Missing budget option id: " + d.id)
				return false
			}
			return budgetOptionsById[d.id].category === category
		})

		const dollarPolicyEffect = policies.filter(({id}) => budgetOptionsById[id].isPercent === false).reduce((r, {id}) => (r - budgetOptionsById[id].values[year]), 0)
		const percentagePolicies = policies.filter(({id}) => {
			return budgetOptionsById[id].isPercent === true
		})

		//console.log(dollarPolicyEffect,percentagePolicies)

		return percentagePolicies.reduce((r, { id }) => {
			r *= (1 + budgetOptionsById[id].values[year])
			return r;
		}, 1 - dollarPolicyEffect)
	}

	const category = (key) => ({
		"key": key,
		"formula": function() {
			if (!initial[this.key]) {
				throw new Error("Missing: " + this.key)
			}
			const results = kickoff(initial[this.key].values)

			return results
		},
		"baseValues": {},
		"valuesWithoutPolicies": {},
		"updateLater": function(year) {
			if (!settings[this.key]) {
				throw new Error("Missing setting: " + this.key)
			}
			if (isEmpty(this.baseValues)) {
				this.baseValues = {...this.values}
			}

			if (isEmpty(this.valuesWithoutPolicies)) {
				this.valuesWithoutPolicies = {...this.values}
			}

			const growthSetting = settings[this.key].growthRate
			const growthRateAdjustment = settings[this.key].growthRateAdjustment/100
			const growthRateAddition = settings[this.key].growthRateAddition/100

			let addition = settings[this.key].additions === undefined ? undefined : settings[this.key].additions[year]
				addition = addition === undefined ? 0 : addition

			let
				growthRate,
				baseValue,
				cboGrowthRate

			switch (growthSetting) {
				case 'CBO_GROWTH':
					cboGrowthRate = initial[this.key].values[year]/initial[this.key].values[year-1] - 1
					growthRate = 1 + growthRateAdjustment + cboGrowthRate;
					baseValue = this.baseValues[year - 1]
					break;

				case 'ZERO_GROWTH':
					growthRate = 1 + growthRateAdjustment;
					baseValue = this.baseValues[year - 1]
					break;

				case 'INFLATION_GROWTH':
					growthRate = (1 + baseline["INFLATION_RATE"].values[year]) + growthRateAdjustment;
					baseValue = this.baseValues[year - 1]
					break;

				case 'GDP_GROWTH':
					growthRate = (1 + baseline["Nominal GDP Growth Rate"].values[year]) + growthRateAdjustment;
					baseValue = this.baseValues[year - 1]
					break;

				default:

			}

			baseValue *= growthRate

			const policyAddition = determinePolicyAdditions(year,this.key,baseValue)

			this.baseValues[year] = baseValue
			this.valuesWithoutPolicies[year] = (baseValue * (1 + growthRateAddition)) + addition
			this.values[year] = (baseValue * (policyAddition) * (1 + growthRateAddition)) + addition
		}
	})

	const
		defenseCategories = categories.SPENDING.DISCRETIONARY.DEFENSE,
		nondefenseCategories = categories.SPENDING.DISCRETIONARY.NONDEFENSE,
		discretionaryCategories = [...defenseCategories,...nondefenseCategories];

	const
		healthCategories = categories.SPENDING.MANDATORY.HEALTH_CARE,
		healthExcMedicareCategories = [
			"MEDICAID",
			"ACA",
			"CHIP"
		],
		socialSecurityCategories = categories.SPENDING.MANDATORY.SOCIAL_SECURITY,
		incomeSecurityCategories = categories.SPENDING.MANDATORY.INCOME_SECURITY,
		otherMandatoryCategories = categories.SPENDING.MANDATORY.OTHER_MANDATORY_SPENDING,
		mandatoryCategories = [
			...healthCategories,
			...socialSecurityCategories,
			...incomeSecurityCategories,
			...otherMandatoryCategories
		],
		revenueCategories = [
			...categories.REVENUE.ALL_REVENUE.INDIVIDUAL_TAXES,
			...categories.REVENUE.ALL_REVENUE.PAYROLL_TAXES,
			...categories.REVENUE.ALL_REVENUE.CORPORATE_TAXES,
			...categories.REVENUE.ALL_REVENUE.OTHER_REVENUE
		]

	const
		discretionaryData = categoryData(discretionaryCategories, category),
		healthData = categoryData(healthCategories, category),
		socialSecurityData = categoryData(socialSecurityCategories, category),
		incomeSecurityData = categoryData(incomeSecurityCategories, category),
		otherMandatoryData = categoryData(otherMandatoryCategories, category),
		revenueData = categoryData(revenueCategories, category)


	const data = {
		...discretionaryData,
		...healthData,
		...socialSecurityData,
		...incomeSecurityData,
		...otherMandatoryData,
		...revenueData,
		"CAPITAL_TAXES": {
			"values": {},
			"updateLater": function(year) {
				this.values[year] =
					data.PERSONAL_INCOME_TAXES.values[year] * datasets.TECHNICAL_ASSUMPTIONS.PIT_CAPSHARE +
					(data.SOCIAL_SECURITY_TAXES.values[year] + data.MEDICARE_TAXES.values[year] + data.OTHER_PAYROLL_TAXES.values[year]) * datasets.TECHNICAL_ASSUMPTIONS.PAYROLL_CAPSHARE +
					data.CORPORATE_INCOME_TAXES.values[year] * datasets.TECHNICAL_ASSUMPTIONS.CIT_CAPSHARE +
					data.OTHER_TAXES.values[year] * datasets.TECHNICAL_ASSUMPTIONS.OTHERTAXES_CAPSHARE
			}
		},
		"LABOR_TAXES": {
			"values": {},
			"updateLater": function(year) {
				this.values[year] =
					data.PERSONAL_INCOME_TAXES.values[year] * (1 - datasets.TECHNICAL_ASSUMPTIONS.PIT_CAPSHARE) +
					(data.SOCIAL_SECURITY_TAXES.values[year] + data.MEDICARE_TAXES.values[year] + data.OTHER_PAYROLL_TAXES.values[year]) * (1 - datasets.TECHNICAL_ASSUMPTIONS.PAYROLL_CAPSHARE) +
					data.CORPORATE_INCOME_TAXES.values[year] * (1 - datasets.TECHNICAL_ASSUMPTIONS.CIT_CAPSHARE) +
					data.OTHER_TAXES.values[year] * (1 - datasets.TECHNICAL_ASSUMPTIONS.OTHERTAXES_CAPSHARE)
			}
		},

		"Mandatory: Health Care (exc Medicare)": {
			"formula": function() {
				return operations.sumValues(
					categoryValues(healthExcMedicareCategories, data)
				)
			},
			"updateLater": function(year) {
				this.values[year] = sumCategories(healthExcMedicareCategories,data,year)
			}
		},
		"Mandatory: HC Outlays": {
			"formula": function() {
				return operations.sumValues(categoryValues(healthCategories, data))
			},
			"updateLater": function(year) {
				this.values[year] = sumCategories(healthCategories, data, year)
			}
		},

		"Mandatory: Total": {
			"formula": function() {
				return operations.sumValues(categoryValues(mandatoryCategories, data))
			},
			"updateLater": function(year) {
				this.values[year] = sumCategories(mandatoryCategories, data, year)
			}
		},

		"Discretionary: Total": {
			"formula": () => (
				operations.sumValues(categoryValues(discretionaryCategories, data))
			),
			"updateLater": function(year) {
				this.values[year] = sumCategories(discretionaryCategories,data,year)
			}
		},
		"Outlays: Non-Interest": {
			"formula": function() {
				return operations.sumValues([data["Mandatory: Total"].values, data["Discretionary: Total"].values])
			},
			"updateLater": function(year) {
				this.values[year] = data["Mandatory: Total"].values[year] +
					data["Discretionary: Total"].values[year]
			}
		},
		"Revenue: Payroll": {
			"formula": function() {
				return operations.sumValues([data["SOCIAL_SECURITY_TAXES"].values, data["MEDICARE_TAXES"].values, data["OTHER_PAYROLL_TAXES"].values])
			},
			"updateLater": function(year) {
				this.values[year] = data["SOCIAL_SECURITY_TAXES"].values[year] +
					data["MEDICARE_TAXES"].values[year] +
					data["OTHER_PAYROLL_TAXES"].values[year]
			}
		},
		"TOTAL_REVENUE": {
			"formula": function() {
				return operations.sumValues([data["Revenue: Payroll"].values, data["PERSONAL_INCOME_TAXES"].values, data["CORPORATE_INCOME_TAXES"].values, data["OTHER_TAXES"].values])
			},
			"updateLater": function(year) {
				this.values[year] = data["Revenue: Payroll"].values[year] +
					data["PERSONAL_INCOME_TAXES"].values[year] +
					data["CORPORATE_INCOME_TAXES"].values[year] +
					data["OTHER_TAXES"].values[year]
			}
		},
		"Surplus: Primary": {
			"formula": function() {
				return operations.sumValues([data.TOTAL_REVENUE.values, operations.negate(data["Outlays: Non-Interest"].values)])
			},
			"updateLater": function(year) {
				this.values[year] = data.TOTAL_REVENUE.values[year] - data["Outlays: Non-Interest"].values[year]
			}
		},
		"NET_DEBT": {
			"formula": function() {
				const result = {}
				result[startYear-1] = datasets.CBO_10["Net Debt"].values[startYear - 1]

				return result
			}
		},
		"Interest Weight": {
			"formula": function() {
				return datasets.WEIGHTED_INTEREST_ON_DEBT.R_WEIGHT.values
			},
			"updateLater": function(year) {
				this.values[year] = datasets.WEIGHTED_INTEREST_ON_DEBT.R_WEIGHT.values[year]
			}
		},
		"Interest Rate": {
			"formula": function () {
				return datasets.WEIGHTED_INTEREST_ON_DEBT.R_USER.values
			},
			"updateLater": function(year) {
				this.values[year] = datasets.WEIGHTED_INTEREST_ON_DEBT.R_USER.values[year]
			}
		},
		"Net Interest": {
			"formula": function () {
				const values = {};

				values[startYear] =
						data.NET_DEBT.values[startYear - 1] *
						data["Interest Weight"].values[startYear] -
						data["Surplus: Primary"].values[startYear] *
						data["Interest Rate"].values[startYear] / 2
				return values
			},
			"updateLater": function(year) {
				this.values[year] = (
					data.NET_DEBT.values[year - 1] *
					data["Interest Weight"].values[year] -
					data["Surplus: Primary"].values[year] *
					data["Interest Rate"].values[year] / 2
				)
			}
		},
		"Net Interest (old)": {
			"formula": function() {
				const result = {},
					interest = datasets.ECON_BENCH["Nominal Interest Rate on Public Debt"].values[startYear]

				result[startYear] = (data.NET_DEBT.values[startYear - 1] * interest) - (interest*data["Surplus: Primary"].values[startYear]/2)

				return result
			},
			"updateLater": function(year) {
				const interest = datasets.ECON_BENCH["Nominal Interest Rate on Public Debt"].values[year]
				this.values[year] = (data.NET_DEBT.values[year - 1] * interest) - (interest*data["Surplus: Primary"].values[year]/2)
			}
		},
		"TOTAL_SPENDING": {
			"formula": function() {
				return operations.sumValues([data["Net Interest"].values, data["Outlays: Non-Interest"].values])
			},
			"updateLater": function(year) {
				this.values[year] = data["Net Interest"].values[year] + data["Outlays: Non-Interest"].values[year]
			}
		},
		"SURPLUS": {
			"formula": function() {
				return operations.sumValues([data["Surplus: Primary"].values, operations.negate(data["Net Interest"].values)])
			},
			"updateLater": function(year) {
				this.values[year] = data["Surplus: Primary"].values[year] - data["Net Interest"].values[year]
			}
		},
		"Debt Additions": {
			"formula": function() {
				return datasets.CBO_10["Other Debt Additions"].values
			},
			"updateLater": function(year) {
				this.values[year] = datasets.CBO_10["Other Debt Additions"].values[year] || 0
			}
		},
		"Net Debt Again": {
			//TODO integrate better
			"formula": function() {
				data.NET_DEBT.values[startYear] = data.NET_DEBT.values[startYear - 1] - data.SURPLUS.values[startYear] + data["Debt Additions"].values[startYear]
			},
			"updateLater": function(year) {
				data.NET_DEBT.values[year] = data.NET_DEBT.values[year - 1] - data.SURPLUS.values[year] + data["Debt Additions"].values[year]
			}
		}
	}
	return data
}
