import React from "react";

export default ({ onChange, checked=false }) => {
	return (
		<label className="Lightswitch">
			<input type="checkbox" onClick={onChange} checked={checked} />
			<div className="background" />
			<div className="switch" />
		</label>
	)
}