export const loadState = () => {
	try {
		const serializedState = localStorage.getItem('calc')
		if (serializedState === null) {
			return undefined
		}
		return JSON.parse(serializedState)
	} catch(error) {
		console.warn(error);
		return undefined;
	}
}

export const saveState = (state) => {
	try {
		const serializedState = JSON.stringify(state)
		localStorage.setItem('calc', serializedState)
	} catch(error) {
		console.warn(error);
		return undefined;
	}
}