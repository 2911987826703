import React from 'react';
import { formatDollars, formatDollarsDelta } from '../formatDollars.js';
import Counter from "./Counter";
import { CBO_LABEL, YOU_LABEL } from "Constants";

export default ({ cboValue=null, yourValue=null, format=formatDollars, deltaFormat=formatDollarsDelta, genre, year, youLabel="You" }) => {
  const delta = (yourValue !== null && cboValue !== null) ? (yourValue - cboValue) : 0

  return (
    <div className="category-header-values">
      <div className="category-header-value-item">
        <span className="label font--label--small faded">Year</span>
        <div className="value color--black font--number"><Counter value={year} /></div>
      </div>
      <div className="category-header-value-item">
        <span className={`label font--label--small faded`}>{CBO_LABEL}</span>
        <span className={`value color--${genre} font--number`}><Counter format={format} value={cboValue} /></span>
      </div>
      {delta !== 0 ? (
        <div className="category-header-value-item">
          <span className="label font--label--small color--user">{YOU_LABEL}</span>
          <span className="value color--user font--number"><Counter format={format} value={yourValue} /></span>
        </div>
      ) : undefined}
      {delta !== 0 ? (
        <div className="category-header-value-item">
          <span className="label font--label--small faded">Change</span>
          <div className="value color--black font--number"><Counter format={deltaFormat} value={delta} /></div>
        </div>
      ) : undefined}
    </div>
  );
}