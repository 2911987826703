import React, { Component } from 'react';

import FeaturedCalculators from './FeaturedCalculators';
import DownIcon from './icons/Down.js';
import FeaturedArticles from './FeaturedArticles';
import illustration from '../assets/16.2.png';
import VideoModal from './VideoModal.js';


export default class Home extends Component {
  render() {
    return (
      <div
        className="Home bg--user"
        style={{ color: "#000" }}>
        <div className="Home-top container-wrapper home-pad">
          <div className="container">
            <h1 className="font--huge uppercase">The Budget Calculator</h1>
            <div className="body font--body">
              <p> By 2050, federal debt will reach 195 percent of GDP. Federal spending will be nearly a third of the economy and annual deficits will approach 13% of GDP.</p>
              <p>Can you balance the budget and save us from the debt crisis? Get ready to make some hard trade-offs.</p>
            </div>
            <div style={{ alignItems: "center", display: "flex" }}>
              <a className="CreateButton button reset-link cta cta--large hover-color--user hover-bg--trueblack" href="/new">Create your budget</a>
              <VideoModal />
            </div>
          </div>
          <div className="container container--illustration">
            <img src={illustration} alt="US Bald Eagle with a calculator in it's chest" />
          </div>
          <DownIcon />
        </div>
        <div className="Home-featured bg--white">
          <FeaturedCalculators />
          <FeaturedArticles />
        </div>
      </div>
    );
  }
}
