import React, { Component } from "react";

import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import { Redirect } from "react-router-dom";
import { saveCalc } from "../Actions";
import SaveIcon from "./icons/Save";
import { authenticator } from "fb";

class SaveButton extends Component {
  constructor() {
    super();

    this.saveOnClick = this.saveOnClick.bind(this);
    this.signupThenSave = this.signupThenSave.bind(this);
    this.saveAndPreview = this.saveAndPreview.bind(this);

    this.state = {
      toView: false,
    };
  }

  componentWillUnmount() {
    this.unregisterAuthObserver && this.unregisterAuthObserver();
  }

  saveOnClick() {
    this.props.saveCalc();
  }

  signupThenSave() {
    if (!authenticator.currentUser) {
      this.props.toggleAuthenticateSkip();

      if (!this.unregisterAuthObserver) {
        this.unregisterAuthObserver = authenticator.onAuthStateChanged(
          (user) => {
            if (user) {
              console.log("now user");
              this.saveAndPreview();
              this.unregisterAuthObserver();
              this.props.toggleAuthenticateSkip();
            } else {
              console.log("no user");
            }
          }
        );
      }
    } else {
      this.saveAndPreview();
    }
  }

  saveAndPreview() {
    this.props.saveCalc();
    if (this.props.viewAfterSave) {
      this.setState({
        toView: true,
      });
    }
  }

  render() {
    const { calcKey } = this.props;

    if (this.state.toView) {
      return <Redirect to={`/${calcKey}/view`} />;
    }

    return (
      <button
        className={`SaveButton ${this.props.className || ""}`}
        onClick={() => this.signupThenSave()}
      >
        <SaveIcon />
      </button>
    );
  }
}
SaveButton.defaultProps = {
  label: (
    <span>
      Save<span className="display-on-xxl"> your budget</span>
    </span>
  ),
  viewAfterSave: true,
};
const mapStateToProps = (state) => ({
  calcKey: state.calculator.calcKey,
});
const mapDispatchToProps = (dispatch) => ({
  saveCalc: () => {
    console.log("saving to firebase");
    dispatch(saveCalc);
  },
});
SaveButton.contextTypes = {
  store: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveButton);
