import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import { loadCalc, setDisplayYear } from "../Actions.js";

import { Route, Redirect } from "react-router-dom";

import Budget from "./Budget";
import Tutorial from "./Tutorial";
import Loading from "./Loading.js";
import Tooltip from "react-tooltip";

import firebase from "firebase/app";
import "firebase/database";
import { toggleTutorial } from "../Actions.js";
import { addAuthoredCalc } from "authoredCalcs";

const { database } = firebase;

//Todo. this is shared between view and table
const loadCalcFromDatabaseIfNeeded = (
  currentKey,
  newKey,
  databasor,
  loadCalc
) => {
  //if (currentKey !== newKey) {
  console.log("loading from database");

  databasor
    .ref(`/calculators/${newKey}`)
    .once("value")
    .then((snapshot) => {
      if (snapshot.val()) {
        const calc = snapshot.val().calc;
        loadCalc(calc);
      }
    });
  //}
};

const DEFAULT_EDIT_PATH = "/edit/revenue";

class Edit extends Component {
  constructor(props) {
    super(props);

    let showTutorial;
    try {
      showTutorial = localStorage.getItem("tutorialVisited") === null;
    } catch (error) {
      showTutorial = true;
    }
    this.props.toggleTutorial(showTutorial);
  }
  componentWillMount() {
    const databasor = database();

    addAuthoredCalc(this.props.calcKey);

    loadCalcFromDatabaseIfNeeded(
      this.props.calcKey,
      this.props.match.params.calcKey,
      databasor,
      this.props.loadCalc
    );

    if (this.props.showTutorial) {
      document.body.style.overflow = "hidden";
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "manual";
      }
    }
    this.props.setDisplayYear(this.props.endYear);
  }

  componentDidUpdate() {
    if (!this.props.showTutorial) {
      document.body.style.overflow = "";
    }
  }

  render() {
    const {
      firstAuthComplete,
      toggleAuthenticateSkip,
      authenticator,
      calcKey,
      toggleEditBudgetMeta,
      loggedInUser,
      authorUid,
      showTutorial,
      isMobile,
      toggleTutorial,
    } = this.props;

    return !firstAuthComplete ? (
      <Loading />
    ) : (authorUid && !loggedInUser) ||
      (authorUid && loggedInUser.uid !== authorUid) ? (
      <div>Not your calc!</div>
    ) : (
      <div className="Edit">
        <div className=" nav-pad">
          <Route
            exact
            path={"/:calcKey/edit"}
            render={({ match }) => (
              <Redirect to={`/${calcKey + DEFAULT_EDIT_PATH}`} />
            )}
          />
          <Route
            path={"/:calcKey/edit/:genre/:category?"}
            render={({ match }) => (
              <Budget
                isMobile={isMobile}
                authenticator={authenticator}
                toggleAuthenticateSkip={toggleAuthenticateSkip}
                toggleEditBudgetMeta={toggleEditBudgetMeta}
                match={match}
              />
            )}
          />
        </div>
        {showTutorial && (
          <Tutorial
            isMobile={isMobile}
            done={() => {
              try {
                localStorage.setItem("tutorialVisited", true);
              } catch (error) {
                console.warn(error);
              }
              toggleTutorial(false);
            }}
          />
        )}
        <Tooltip id="GrowthRateTooltip--budget" className="tooltip--info">
          <div>
            <p>
              The growth rate determines how fast this account grows each year.
            </p>
            <p>
              The default is the rate assumed by CBO (
              <span className="font--label--alt color--user">
                CBO Baseline Growth Rate
              </span>
              ). You may change the growth rate so the acount remains unchanged
              from year to year (
              <span className="font--label--alt color--user">Zero Growth</span>
              ), mirror the rate of inflation (
              <span className="font--label--alt color--user">
                Grow at Inflation
              </span>
              ), or make it constant as a percentage of Gross Domestic Product (
              <span className="font--label--alt color--user">
                Grow at Nominal GDP
              </span>
              ).
            </p>
            <p>
              Fine tune this assumption by increasing or decreasing the rate by
              a fixed amount (e.g. Inflation + 0.4%)
            </p>
          </div>
        </Tooltip>
        <Tooltip id="GrowthRateTooltip--economy" className="tooltip--info">
          <div>
            <p>
              You can choose the rate assumed by CBO (
              <span className="font--label--alt color--user">
                CBO Baseline Rate
              </span>
              ), set the rate to zero (
              <span className="font--label--alt color--user">Zero Rate</span>),
              or assume things will continue as they have in the past (
              <span className="font--label--alt color--user">
                Historical Rate
              </span>
              ).
            </p>
            <p>
              Fine tune this assumption by increasing or decreasing the rate by
              a fixed amount (e.g. Zero Rate + 2.4%).
            </p>
          </div>
        </Tooltip>
        <Tooltip id="AdjustTooltip" className="tooltip--info">
          <div>
            <p>Increase or decrease all years by the same amount.</p>
            <p>
              For example, selecting -50% cuts future levels in half while 100%
              doubles future levels.
            </p>
          </div>
        </Tooltip>
      </div>
    );
  }
}

Edit.contextTypes = {
  store: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    calcKey: state.calculator.calcKey,
    endYear: state.calculator.baseline.endYear,
    authorUid: state.calculator.authorUid,
    showTutorial: state.session.showTutorial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCalc: (calc) => {
      dispatch(loadCalc(calc));
      dispatch(setDisplayYear(calc.endYear));
    },
    toggleTutorial: (value) => {
      dispatch(toggleTutorial(value));
    },
    setDisplayYear: (value) => {
      dispatch(setDisplayYear(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
