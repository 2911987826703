import React from "react";

const NextArrowIcon = () => (
  <div className="next-arrow-icon">
    <svg height="25" viewBox="0 0 40 25" width="40">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
        strokeWidth="2"
        transform="matrix(0 -1 1 0 1 23)"
      >
        <path
          d="m3.10660172 34.1066017h14.99999998v-15"
          transform="matrix(.70710678 .70710678 -.70710678 .70710678 21.92031 .292893)"
        />
        <path d="m10.5.5v34" />
      </g>
    </svg>
  </div>
);

export default NextArrowIcon;
