import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import EditForm from "./EditForm";

import { updateTitle, updateDescription, saveCalc } from '../Actions';

class EditBudgetPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: props.title,
      description: props.description
    }

    this.handleChange = this.handleChange.bind(this)
    this.onSaveClick = this.onSaveClick.bind(this)
  }

  onSaveClick() {
    const { updateTitle, updateDescription } = this.props
    updateTitle(this.state.title)
    updateDescription(this.state.description)
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    }, this.onSaveClick)
  }

  render() {
    const { title, description} = this.state
    const { saveCalc, toggleModal } = this.props

    return (
      <EditForm
        title={`Describe your budget`}>
        <label className="label-with-placeholder">
          <div className="placeholder">Name Your Budget</div>
          <input
            value={title}
            name="title"
            onChange={this.handleChange}
            autoFocus />
        </label>
        <label className="label-with-placeholder">
          <div className="placeholder">What's your strategy?</div>
          <textarea
            value={description}
            name="description"
            onChange={this.handleChange} />
        </label>
        <button
          className="button bg--user color--white border-color--user"
          style={{ marginTop: "1em", marginLeft: "auto", display: "block" }}
          onClick={() => {
            saveCalc()
            toggleModal()
          }}>
          Save
        </button>
      </EditForm>
    )
  }
}

const mapStateToProps = (state) => ({
  title: state.calculator.title,
  description: state.calculator.description
})
const mapDispatchToProps = (dispatch) => ({
  saveCalc: () => {
    dispatch(saveCalc)
  },
  updateTitle: (title) => {
    dispatch(updateTitle(title))
  },
  updateDescription: (description) => {
    dispatch(updateDescription(description))
  }
})
EditBudgetPage.contextTypes = {
  store: PropTypes.object
}

export default connect(mapStateToProps,mapDispatchToProps)(EditBudgetPage);
