import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formatDollarsBillions, formatPercentage } from '../formatDollars';

const CategoryValueInYear = ({
	values,
	category,
	gdpValues,
	displayYear,
	className
}) => {

	const
		categoryValue = values[displayYear],
		gdpValue = gdpValues[displayYear],
		percentGDP = formatPercentage(100*categoryValue/gdpValue)

	return (
		<div className={`CategoryInYear font--callout ${className}`}>
			In {displayYear}, {category} is {formatDollarsBillions(categoryValue)} which is {percentGDP} of GDP
		</div>
	)
}

CategoryValueInYear.contextTypes = {
	store: PropTypes.object
}
const mapStateToProps = (state) => ({
	displayYear: state.session.displayYear
})
export default connect(mapStateToProps)(CategoryValueInYear)