export default [
  "DEFENSE_OPERATIONS",
  "DEFENSE_OTHER",
  "DEFENSE_PERSONNEL",
  "DEFENSE_PROCUREMENT",
  "DEFENSE_RTDE",
  "EDUCATION",
  "ENERGY",
  "ENVIRONMENT",
  "TRANSPORTATION",
  "INTERNATIONAL",
  "JUSTICE",
  "SCIENCE",
  "VA_DISCRETIONARY",
  "OTHER_DISCRETIONARY",
  "MEDICARE",
  "MEDICAID",
  "CHIP",
  "ACA",
  "SOCIAL_SECURITY_ALL",
  "REFUNDABLE_TAX_CREDITS",
  "VA_MANDATORY",
  "UNEMPLOYMENT",
  "SUPPLEMENTAL_SECURITY",
  "NUTRITIONAL_ASSISTANCE",
  "AGRICULTURE",
  "OTHER_MANDATORY",
  "PERSONAL_INCOME_TAXES",
  "SOCIAL_SECURITY_TAXES",
  "MEDICARE_TAXES",
  "OTHER_PAYROLL_TAXES",
  "Revenue: Payroll",
  "CORPORATE_INCOME_TAXES",
  "OTHER_TAXES",
  "Mandatory: Total",
  "Discretionary: Total",
  "Outlays: Non-Interest",
  "TOTAL_REVENUE",
  "Net Interest",
  "TOTAL_SPENDING",
  "SURPLUS",
  "Debt Additions",
  "NET_DEBT",
  "TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE",
  "Capital Growth",
  "LABOR_FORCE_GROWTH_RATE",
  "UNEMPLOYMENT_RATE",
  "Average Hours Worked Growth Rate",
  "Total Hours Worked Growth Rate",
  "INFLATION_RATE",
  "GDP Price Index Growth Rate",
  "Population",
  "Labor Productivity Growth Rate",
  "Real GDP",
  "Real GDP Growth Rate",
  "Nominal GDP",
  "Nominal GDP Growth Rate",
  "REAL_INTEREST_RATE"
]