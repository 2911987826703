export default function (datasets) {
  const operations = require("../../../operations.js")(datasets),
    cbo = datasets.ECON_CBO_ASSUMED,
    benchmark = datasets.ECON_BENCH,
    macro = datasets.ECON_MACRO,
    tech = datasets.TECHNICAL_ASSUMPTIONS,
    startYear = datasets.startYear;

  const data = {
    TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE:
      benchmark["TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE"],
    "Capital Growth": {
      values: {},
      update: function (year) {
        this.values[year] = macro["Baseline Capital Growth"].values[year];
      },
    },
    LABOR_FORCE_GROWTH_RATE: {
      formula: () => {
        const results = {};
        results[startYear] =
          benchmark["LABOR_FORCE_GROWTH_RATE"].values[startYear];
        return results;
      },
      update: function (year) {
        this.values[year] =
          macro["Annual Change in Labor Supply Growth Due to Higher Taxes"]
            .values[year] + benchmark["LABOR_FORCE_GROWTH_RATE"].values[year];
      },
    },
    UNEMPLOYMENT_RATE: benchmark["UNEMPLOYMENT_RATE"],
    "Average Hours Worked Growth Rate":
      benchmark["Average Hours Worked Growth Rate"],
    INFLATION_RATE: benchmark["INFLATION_RATE"],
    "GDP Price Index Growth Rate": benchmark["GDP Price Index Growth Rate"],
    "Total Hours Worked Growth Rate": {
      values: {},
      update: function (year) {
        const labor = data["LABOR_FORCE_GROWTH_RATE"].values[year] + 1,
          thisYearUnemployment = 1 - data["UNEMPLOYMENT_RATE"].values[year],
          lastYearUnemployment = 1 - data["UNEMPLOYMENT_RATE"].values[year - 1],
          hoursGrowth =
            data["Average Hours Worked Growth Rate"].values[year] + 1,
          unemployment = thisYearUnemployment / lastYearUnemployment,
          product = labor * unemployment * hoursGrowth,
          value = product - 1;

        this.values[year] = value;
      },
    },
    "Real GDP Growth Rate": {
      formula: () => {
        const results = {};
        results[startYear] =
          benchmark["Real GDP Growth Rate"].values[startYear];
        return results;
      },
      update: function (year) {
        this.values[year] =
          data["TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE"].values[year] +
          data["Capital Growth"].values[year] *
            tech["Share of output growth attributed to capital growth"] +
          data["Total Hours Worked Growth Rate"].values[year] *
            (1 - tech["Share of output growth attributed to capital growth"]);
      },
    },
    bm_gdp_real: cbo["bm_gdp_real"],
    "Real GDP": {
      formula: function () {
        const results = {};

        //return formulas['Real GDP'](data)
        results[startYear] = cbo["bm_gdp_real"].values[startYear];

        return results;
      },
      update: function (year) {
        this.values[year] =
          this.values[year - 1] *
          (1 + data["Real GDP Growth Rate"].values[year]);
      },
    },
    "Labor Productivity Growth Rate": {
      values: {},
      update: function (year) {
        this.values[year] =
          data["Real GDP Growth Rate"].values[year] -
          data["Total Hours Worked Growth Rate"].values[year];
      },
    },
    "Labor Productivity Growth Rate (Nominal)": {
      values: {},
      update: function (year) {
        this.values[year] =
          data["GDP Price Index Growth Rate"].values[year] +
          data["Labor Productivity Growth Rate"].values[year];
      },
    },
    "Labor Productivity Index (Nominal)": {
      formula: function () {
        return {
          [startYear]: 1,
          ...operations.sumValues([
            data["Labor Productivity Growth Rate"].values,
            data["GDP Price Index Growth Rate"].values,
          ]),
        };
      },
      update: function (year) {
        this.values[year] =
          data["Labor Productivity Growth Rate"].values[year] +
          data["GDP Price Index Growth Rate"].values[year];
      },
    },
    /*
		"CPI-U Price Index": {
			"formula": function() {
				return formulas["CPI-U Price Index"](data)
			}
		},
		*/
    "GDP Price Index": {
      formula: function () {
        const results = {};
        results[startYear] = 1;
        return results;
      },
      update: function (year) {
        this.values[year] =
          this.values[year - 1] *
          (1 + data["GDP Price Index Growth Rate"].values[year]);
      },
    },
    "Nominal GDP": {
      formula: function () {
        const values = {};
        values[startYear] =
          data["GDP Price Index"].values[startYear] *
          data["Real GDP"].values[startYear];
        return values;
      },
      update: function (year) {
        this.values[year] =
          data["GDP Price Index"].values[year] * data["Real GDP"].values[year];
      },
    },
    "Nominal GDP Growth Rate": {
      values: {},
      update: function (year) {
        this.values[year] =
          data["Nominal GDP"].values[year] /
            data["Nominal GDP"].values[year - 1] -
          1;
      },
    },
    Population: cbo["Population"],
    /*
		"Real GDP Per Capita": {
			"formula": function() {
				return operations.quotientValues(data["Real GDP"].values, data["Population"].values)
			}
		},
		"Real GDP Per Capita Growth Rate": {
			"formula": function() {
				return operations.yearOverYear(data["Real GDP Per Capita"].values)
			}
		},
		*/
    "Nominal GDP Per Capita": {
      values: {},
      update: function (year) {
        const value =
          data["Nominal GDP"].values[year] / data["Population"].values[year];
        if (!isNaN(value)) {
          this.values[year] = value;
        }
      },
    },
    "Nominal GDP Per Capita Growth Rate": {
      values: {},
      update: function (year) {
        const value =
          data["Nominal GDP Per Capita"].values[year] /
            data["Nominal GDP Per Capita"].values[year - 1] -
          1;
        if (!isNaN(value)) {
          this.values[year] = value;
        }
      },
    },
    REAL_INTEREST_RATE: benchmark["REAL_INTEREST_RATE"],
    /*
		"Real Interest Rate on OASDI Trust Fund": {
			"formula": function() {
				return option("Real Interest Rate on OASDI Trust Fund","Real Interest Rate","Net Debt Interest Rate")
			}
		},
		"Nominal Interest Rate on Public Debt": {
			"formula": function() {
				return operations.sumValues([data["CPI-U Growth Rate"].values,data["Real Interest Rate on Public Debt"].values])
			}
		},
		"Nominal Interest Rate on OASDI Trust Fund": {
			"formula": function() {
				return operations.sumValues([data["CPI-U Growth Rate"].values,data["Real Interest Rate on OASDI Trust Fund"].values])
			}
		}
*/
  };

  return data;
}
