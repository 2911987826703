import React from "react";
import ReactTooltip from "react-tooltip";
import ChartDatum from "./chart-datum";

class Tooltips extends React.Component {
  render() {
    const {
      idPrefix,
      xScale,
      userDataDollars,
      baselineDataDollars,
      userDataGdp,
      baselineDataGdp,
      formatValue,
      formatDelta,
      style,
      title,
      genre,
    } = this.props;

    let ticks = [];
    for (let i = xScale.domain()[0]; i <= xScale.domain()[1]; i++) {
      ticks.push(i);
    }

    return (
      <div style={style}>
        {ticks.map((d) => {
          return (
            <ReactTooltip
              className="chart-tooltip"
              id={idPrefix + d}
              key={idPrefix + d}
              place="right"
              effect="solid"
              type="info"
            >
              <ChartDatum
                value={d}
                genre={genre}
                title={title}
                formatValue={formatValue}
                formatDelta={formatDelta}
                userDataGdp={userDataGdp}
                userDataDollars={userDataDollars}
                baselineDataGdp={baselineDataGdp}
                baselineDataDollars={baselineDataDollars}
              />
            </ReactTooltip>
          );
        })}
      </div>
    );
  }
}

export default Tooltips;
