import React, { Component, createRef } from "react";
import { select } from "d3-selection";
// eslint-disable-next-line
import { transition } from "d3-transition";

export default class AnimatedPath extends Component {
  constructor(props) {
    super()

    const { d } = props
    this.state = { d }
    this.updatePath = this.updatePath.bind(this)
    this.pathRef = createRef()
  }

  updatePath(d) {
    select(this.pathRef.current)
      .transition()
      .attr("d", d)
      .on("end", () => {
        if (this.pathRef.current) {
          this.setState({d})
        }
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props.d !== prevProps.d) {
      this.updatePath(this.props.d)
    }
  }

  render() {
    const { className, fill } = this.props;
    return (
      <path fill={fill} d={this.state.d} className={className} ref={this.pathRef} />
    )
  }
}