import React from "react";

export default ({ color = "#B0FFCD", direction="RIGHT" }) => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    stroke={ color }
    transform={direction === "LEFT" ? "rotate(180)" : undefined }
    fill="none">
    <path d="M9.525 7.975l3.5-3.5-3.5-3.5M12.5 4.5H.5"/>
  </svg>
)