export default (datasets, settings) => {
  const formulas = require("../../../formulas.js")(datasets),
    categories = require("../categories.js"),
    startYear = datasets.startYear,
    kickoff = formulas.kickoff;

  const defenseCategories = categories.SPENDING.DISCRETIONARY.DEFENSE,
    nondefenseCategories = categories.SPENDING.DISCRETIONARY.NONDEFENSE,
    discretionaryCategories = [...defenseCategories, ...nondefenseCategories];

  const categoryGrowByGdp = (key) => {
    return {
      formula: function () {
        if (!datasets.CBO_10[key]) {
          throw new Error("Missing: " + key);
        }
        const results = kickoff(datasets.CBO_10[key].values);

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          this.values[year] =
            datasets.CBO_10[key].values[year] * data.NGDP_RATIO.values[year];
        } else {
          this.values[year] =
            this.values[year - 1] *
            (1 + datasets.ECON_BENCH["Nominal GDP Growth Rate"].values[year]);
        }
      },
    };
  };

  const categoryGrowByInflation = (key) => {
    return {
      formula: function () {
        if (!datasets.CBO_10[key]) {
          throw new Error("Missing: " + key);
        }
        const results = kickoff(datasets.CBO_10[key].values);

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          this.values[year] = datasets.CBO_10[key].values[year];
        } else {
          this.values[year] =
            this.values[year - 1] *
            (1 + datasets.ECON_BENCH["INFLATION_RATE"].values[year]);
        }
      },
    };
  };

  const categoryGrowByMandatoryOther = (key) => {
    return {
      formula: function () {
        return kickoff(datasets.CBO_10[key].values);
      },
      update: function (year) {
        if (year <= startYear + 10) {
          this.values[year] =
            datasets.CBO_10[key].values[year] *
            data.INFLATION_RATIO.values[year];
        }
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          this.values[year] =
            this.values[year - 1] *
            (1 + datasets.ECON_BENCH["Nominal GDP Growth Rate"].values[year]) *
            (1 + datasets.otherMandatoryChange(key));
        }
      },
    };
  };

  const discretionaryData = discretionaryCategories.reduce((r, d) => {
    r[d] = categoryGrowByGdp(d);
    return r;
  }, {});

  const data = {
    NGDP_RATIO: {
      values: {},
      update: function (year) {
        this.values[year] =
          datasets.ECON_BASE["Nominal GDP"].values[year] /
          datasets.ECON_CBO_ASSUMED["Nominal GDP"].values[year];
      },
    },
    RGDP_RATIO: {
      values: {},
      update: function (year) {
        this.values[year] =
          datasets.ECON_BASE["Real GDP"].values[year] /
          datasets.ECON_CBO_ASSUMED["Real GDP"].values[year];
      },
    },
    "Inflation Index": {
      formula: function () {
        return {
          [startYear]: 1,
        };
      },
      update: function (year) {
        this.values[year] =
          this.values[year - 1] *
          (1 + datasets.ECON_BASE["INFLATION_RATE"].values[year]);
      },
    },
    INFLATION_RATIO: {
      values: {},
      update: function (year) {
        this.values[year] =
          data["Inflation Index"].values[year] /
          datasets.ECON_CBO_ASSUMED["CPI-U Price Index"].values[year];
      },
    },
    GDPPRICEINDEX_RATIO: {
      values: {},
      update: function (year) {
        this.values[year] =
          datasets.ECON_BASE["GDP Price Index"].values[year] /
          datasets.ECON_CBO_ASSUMED["GDP Price Index"].values[year];
      },
    },
    ...discretionaryData,

    //TODO how to project these accounts forward?
    SOCIAL_SECURITY_ALL: {
      formula: function () {
        const results = kickoff(datasets.CBO_10["SOCIAL_SECURITY_ALL"].values);

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          this.values[year] =
            datasets.CBO_10["SOCIAL_SECURITY_ALL"].values[year] *
            data.INFLATION_RATIO.values[year];
        }
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          this.values[year] = datasets.SS_OUTLAY["Outlays: OASI"].values[year];
        }
      },
    },

    MEDICARE: {
      formula: function () {
        const results = kickoff(datasets.CBO_10["MEDICARE"].values);

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          this.values[year] =
            datasets.CBO_10["MEDICARE"].values[year] *
            data.NGDP_RATIO.values[year];
        }
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          this.values[year] =
            this.values[year - 1] *
            (1 +
              datasets.HC_COMPONENTS["Medicare: Enrollment Growth Rate"].values[
                year
              ]) *
            (1 +
              datasets.HC_COMPONENTS[
                "Medicare: Increase from Changes in Age/Gender Mix"
              ].values[year]) *
            (1 +
              datasets.ECON_BASE["Nominal GDP Per Capita Growth Rate"].values[
                year
              ] +
              datasets.HC_COMPONENTS["Medicare: Excess Cost Rate"].values[
                year
              ]);
        }
      },
    },
    MEDICAID: {
      formula: function () {
        const results = kickoff(datasets.CBO_10["MEDICAID"].values);

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          this.values[year] =
            datasets.CBO_10["MEDICAID"].values[year] *
            data.NGDP_RATIO.values[year];
        }
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          this.values[year] =
            this.values[year - 1] *
            (1 +
              datasets.HC_COMPONENTS["Medicaid: Enrollment Growth Rate"].values[
                year
              ]) *
            (1 +
              datasets.ECON_BASE["Nominal GDP Per Capita Growth Rate"].values[
                year
              ] +
              datasets.HC_COMPONENTS["Medicaid: Excess Cost Rate"].values[
                year
              ]);
        }
      },
    },
    ACA: {
      formula: function () {
        const results = kickoff(datasets.CBO_10["ACA"].values);

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          this.values[year] =
            datasets.CBO_10["ACA"].values[year] * data.NGDP_RATIO.values[year];
        }
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          if (
            this.values[year - 1] /
              datasets.ECON_BENCH["Nominal GDP"].values[year] >
            0.00504
          ) {
            this.values[year] =
              this.values[year - 1] *
              (1 + datasets.ECON_BASE["Nominal GDP Growth Rate (Baseline)"]);
          } else {
            this.values[year] =
              this.values[year - 1] *
              (1 +
                datasets.HC_COMPONENTS["Exchanges: Enrollment Growth Rate"]
                  .values[year]) *
              (1 +
                datasets.HC_COMPONENTS["Exchanges: Private Excess Cost Rate"]
                  .values[year] +
                datasets.ECON_BASE["Nominal GDP Per Capita Growth Rate"].values[
                  year
                ]);
          }
        }
      },
    },
    CHIP: {
      formula: function () {
        const results = kickoff(datasets.CBO_10["CHIP"].values);

        return results;
      },
      update: function (year) {
        this.values[year] = datasets.CBO_10["CHIP"].values[year];
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          this.values[year] =
            this.values[year - 1] *
            (1 + datasets.ECON_BASE["Nominal GDP Growth Rate"].values[year]);
        }
      },
    },

    REFUNDABLE_TAX_CREDITS: categoryGrowByInflation("REFUNDABLE_TAX_CREDITS"),
    VA_MANDATORY: categoryGrowByMandatoryOther("VA_MANDATORY"),
    UNEMPLOYMENT: categoryGrowByMandatoryOther("UNEMPLOYMENT"),
    SUPPLEMENTAL_SECURITY: categoryGrowByMandatoryOther(
      "SUPPLEMENTAL_SECURITY"
    ),
    NUTRITIONAL_ASSISTANCE: categoryGrowByMandatoryOther(
      "NUTRITIONAL_ASSISTANCE"
    ),
    AGRICULTURE: categoryGrowByMandatoryOther("AGRICULTURE"),
    OTHER_MANDATORY: categoryGrowByMandatoryOther("OTHER_MANDATORY"),

    PERSONAL_INCOME_TAXES: {
      formula: function () {
        const results = kickoff(
          datasets.CBO_10["PERSONAL_INCOME_TAXES"].values
        );

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          this.values[year] =
            datasets.CBO_10["PERSONAL_INCOME_TAXES"].values[year] *
            (1 +
              (data.RGDP_RATIO.values[year] - 1) *
                datasets.TECHNICAL_ASSUMPTIONS[
                  "Elasticity of Income Tax Revenue with Respect to Real GDP Growth"
                ]) *
            data.GDPPRICEINDEX_RATIO.values[year];
        }
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          this.values[year] =
            this.values[year - 1] *
            (1 +
              datasets.ECON_BASE["Real GDP Growth Rate"].values[year] *
                datasets.TECHNICAL_ASSUMPTIONS[
                  "Elasticity of Income Tax Revenue with Respect to Real GDP Growth"
                ]) *
            (1 +
              datasets.ECON_BENCH["GDP Price Index Growth Rate"].values[year]);
        }
      },
    },
    CORPORATE_INCOME_TAXES: {
      formula: function () {
        const results = kickoff(
          datasets.CBO_10["CORPORATE_INCOME_TAXES"].values
        );

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          this.values[year] =
            datasets.CBO_10["CORPORATE_INCOME_TAXES"].values[year] *
            data.NGDP_RATIO.values[year];
        }
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          this.values[year] =
            this.values[year - 1] *
            (1 + datasets.ECON_BASE["Nominal GDP Growth Rate"].values[year]);
        }
      },
    },
    OTHER_TAXES: {
      formula: function () {
        const results = kickoff(datasets.CBO_10["OTHER_TAXES"].values);

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          this.values[year] =
            datasets.CBO_10["OTHER_TAXES"].values[year] *
            data.NGDP_RATIO.values[year];
        }
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          this.values[year] =
            this.values[year - 1] *
            (1 +
              datasets.ECON_BASE["Real GDP Growth Rate"].values[year] *
                datasets.TECHNICAL_ASSUMPTIONS[
                  "Elasticity of Other Tax Revenue with Respect to Real GDP Growth"
                ]) *
            (1 +
              datasets.ECON_BENCH["GDP Price Index Growth Rate"].values[year]);
        }
      },
    },
    SOCIAL_SECURITY_TAXES: {
      formula: function () {
        const results = kickoff(
          datasets.CBO_10["SOCIAL_SECURITY_TAXES"].values
        );

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          //todo plus user
          this.values[year] =
            datasets.CBO_10["SOCIAL_SECURITY_TAXES"].values[year] *
            data.NGDP_RATIO.values[year];
        }
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          this.values[year] =
            datasets.OASDHI["OASDI Revenues: Total"].values[year];
        }
      },
    },
    MEDICARE_TAXES: {
      formula: function () {
        const results = kickoff(datasets.CBO_10["MEDICARE_TAXES"].values);

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          //todo plus user
          this.values[year] =
            datasets.CBO_10["MEDICARE_TAXES"].values[year] *
            data.NGDP_RATIO.values[year];
        }
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          this.values[year] = datasets.OASDHI["Total HI Revenue"].values[year];
        }
      },
    },
    OTHER_PAYROLL_TAXES: {
      formula: function () {
        const results = kickoff(datasets.CBO_10["OTHER_PAYROLL_TAXES"].values);

        return results;
      },
      update: function (year) {
        if (year <= startYear + 10) {
          this.values[year] =
            datasets.CBO_10["OTHER_PAYROLL_TAXES"].values[year] *
            data.NGDP_RATIO.values[year];
        }
      },
      updateLater: function (year) {
        if (year > startYear + 10) {
          this.values[year] =
            this.values[year - 1] *
            (1 + datasets.ECON_BASE["Nominal GDP Growth Rate"].values[year]);
        }
      },
    },
    CAPITAL_TAXES: {
      values: {},
      updateLater: function (year) {
        this.values[year] =
          data.PERSONAL_INCOME_TAXES.values[year] *
            datasets.TECHNICAL_ASSUMPTIONS.PIT_CAPSHARE +
          (data.SOCIAL_SECURITY_TAXES.values[year] +
            data.MEDICARE_TAXES.values[year] +
            data.OTHER_PAYROLL_TAXES.values[year]) *
            datasets.TECHNICAL_ASSUMPTIONS.PAYROLL_CAPSHARE +
          data.CORPORATE_INCOME_TAXES.values[year] *
            datasets.TECHNICAL_ASSUMPTIONS.CIT_CAPSHARE +
          data.OTHER_TAXES.values[year] *
            datasets.TECHNICAL_ASSUMPTIONS.OTHERTAXES_CAPSHARE;
      },
    },
    LABOR_TAXES: {
      values: {},
      updateLater: function (year) {
        this.values[year] =
          data.PERSONAL_INCOME_TAXES.values[year] *
            (1 - datasets.TECHNICAL_ASSUMPTIONS.PIT_CAPSHARE) +
          (data.SOCIAL_SECURITY_TAXES.values[year] +
            data.MEDICARE_TAXES.values[year] +
            data.OTHER_PAYROLL_TAXES.values[year]) *
            (1 - datasets.TECHNICAL_ASSUMPTIONS.PAYROLL_CAPSHARE) +
          data.CORPORATE_INCOME_TAXES.values[year] *
            (1 - datasets.TECHNICAL_ASSUMPTIONS.CIT_CAPSHARE) +
          data.OTHER_TAXES.values[year] *
            (1 - datasets.TECHNICAL_ASSUMPTIONS.OTHERTAXES_CAPSHARE);
      },
    },
  };
  return data;
};
