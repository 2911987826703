import React, { Component } from 'react';
import Modal from "react-modal";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CloseIcon from './icons/Close';
import Illustration from 'assets/head-in-the-sand.png';
import AOB from 'assets/AOB_large.svg';

import Facebook from './Facebook.js';
import Twitter from './Twitter.js';
import Email from './Email.js';
import LinkedIn from './LinkedIn.js';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

const customStyles = {
  content: {
    top                   : '0',
    left                  : '0',
    right                 : '0',
    bottom                : '0',
    padding: 0
  }
}
export default class ShareModal extends Component {

  constructor() {
    super()

    this.state = {
      copied: false
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.toggle}
        style={customStyles}>
        <div className="ShareDialog bg--user">
          <img src={Illustration} alt="" />
          <img src={AOB} alt="America Off Balance" />
          <h1 className="ShareDialog-heading" style={{ marginTop: "1em" }}>Share this budget</h1>

          <div className="ShareDialog-buttons">
            <FacebookShareButton url={window.location.href}>
              <Facebook />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href} via="AOBalance">
              <Twitter />
            </TwitterShareButton>
            <LinkedinShareButton url={window.location.href}>
              <LinkedIn />
            </LinkedinShareButton>
            <EmailShareButton url={window.location.href}>
              <Email />
            </EmailShareButton>
          </div>
          <div style={{ marginTop: "1em" }}>
            <CopyToClipboard
              text={window.location.href}
              onCopy={() => this.setState({copied: true})}>
              <button className="button ShareDialog-button" style={{ position: 'relative' }}>
                Copy link
                <span className="ShareDialog-button-message" style={{ visibility: !this.state.copied ? 'hidden' : 'visible' }}></span>
              </button>
            </CopyToClipboard>
          </div>
        </div>
        <button onClick={this.props.toggle} className="ShareDialog-close-button reset-buttton">
          Close <CloseIcon fill="transparent" stroke="black" />
        </button>
      </Modal>
    );
  }
}
