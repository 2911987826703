import React from "react";
import useFetch from "hooks/useFetch";
import styles from "./instance.module.scss";
const Directory = () => {
  const { error, data } = useFetch(
    `https://api.americaoffbalance.org/embed-directory.json`
  );

  return (
    <div className={styles.Embed}>
      <div
        className={`bg--revenue color--white`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {error && "Error"}
        {data && (
          <ul>
            {data.data.map(({ slug, heading }) => {
              return (
                <li key={slug}>
                  <a href={`/${slug}`}>{heading}</a>
                  <code>
                    {`<div id="aobcalcembed" data-slug="${slug}"></div>`}
                    <br />
                    {`<script src="https://calculator-embed.web.app/static/js/bundle.min.js"></script>`}
                  </code>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Directory;
