import { getVersionPath } from './helpers.js';
import versions from "../versions.js";

const defaultSession = (version=versions.default) => {
  const versionPath = getVersionPath(version);
  const tech = require('../' + versionPath + 'technicalAssumptions.js');

  const displayYear = +tech['Start year of data (projections will begin in the following year)'] + + tech['Years Projected'];
  return {
    displayYear: displayYear,
    sidebarTabIndex: 0,
    showTutorial: false
  }
}

export default (currentState = defaultSession(), action) => {

  switch (action.type) {

    case "SET_DISPLAY_YEAR":
      return {
        ...currentState,
        displayYear: action.value
      }

    case "SET_SIDEBAR_TAB_INDEX":
      return {
        ...currentState,
        sidebarTabIndex: action.value
      }

    case "RESET_SESSION":
      return defaultSession()

    case "TOGGLE_TUTORIAL":
      return {
        ...currentState,
        showTutorial: action.value
      }

    default:
      return currentState
  }
}