import React, { Component } from 'react';

export default class RoundButton extends Component {
  render() {
    const { icon, label, children } = this.props
    return (
      <div className="round-button">
        <div className="button-circle">
          {icon}
          {children}
        </div>
        <div className="button-label font--label--small">{label}</div>
      </div>
    );
  }
}
