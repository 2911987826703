import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import GrowthRateSelect from "./GrowthRateSelect";

const BudgetGrowthRateSelect = (props) => {
  return (
    <GrowthRateSelect {...props} tooltipType="budget" />
  )
}

BudgetGrowthRateSelect.contextTypes = {
  store: PropTypes.object
}
const mapStateToProps = (state) => ({ options: state.calculator.growthOptions.budgetary })

export default connect(mapStateToProps)(BudgetGrowthRateSelect);