import { useEffect, useState } from "react";

const useBreakpoint = (breakpoint) => {
  const [isBreakpoint, setBreakpoint] = useState();
  useEffect(() => {
    const onResize = () => {
      const nowBreakpoint = window.innerWidth < breakpoint;

      if (nowBreakpoint !== isBreakpoint) {
        setBreakpoint(nowBreakpoint);
      }
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  });
  return isBreakpoint;
};

export default useBreakpoint;
