import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class TreeMap extends Component {

  render() {
    const cats = this.props.categories;
    const { user } = this.props;

    const budget = user["Budget Model (orig) - Budget Projections"];

    const reduceCats = (cats) => {
      if (typeof cats === 'object' && !Array.isArray(cats)) {
        return Object.keys(cats).reduce((r,d) => {
          r.push({
            key: d,
            values: reduceCats(cats[d])
          })
          return r
        }, [])
      } else if (Array.isArray(cats)) {
        console.log(budget)
        return cats.reduce((r,d) => {
          r.push({
            key: d,
            value: budget[d] ? budget[d].values[user.endYear] : null
          })
          return r
        }, [])
      }
    }
    const data = reduceCats(cats)

    console.log(JSON.stringify(data))
    return <div></div>
  }
}
TreeMap.contextTypes = {
  store: PropTypes.object
}
const mapStateToProps = (state) => ({
  ...state.calculator
})

export default connect(mapStateToProps)(TreeMap);