import React from "react";
import Directory from "./directory";
import Instance from "./instance";
import { BrowserRouter, Route } from "react-router-dom";

const App = ({ slug }) => {
  if (slug) {
    return <Instance slug={slug} />;
  }
  return (
    <BrowserRouter>
      <Route
        path="/:slug"
        render={({ match }) => <Instance slug={match.params.slug} />}
      />
      <Route exact path="/" component={Directory} />
    </BrowserRouter>
  );
};

export default App;
