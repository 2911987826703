module.exports = {
	billions: {
		"operation": "multiply",
		"factor": 1e6 //1 billion
	},
	millions: {
		"operation": "multiply",
		"factor": 1e3 //1 million
	},
	thousands: {
		"operation": "multiply",
		"factor": 1e0
	},
	percent: {
		"operation": "multiply",
		"factor": .01
	},
	percent100k: {
		"operation": "multiply",
		"factor": .00001
	}
}