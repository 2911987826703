export default function (calc, raw, settings) {
  const enactedPolicies = settings.policiesEnacted
    ? settings.policiesEnacted.map(({ id }) => {
        return raw.tc_policyoptions.find(
          ({ Number }) => Number.replace(".", "-") === id
        );
      })
    : [];

  const values = enactedPolicies.reduce((r, d) => {
    Object.keys(d).forEach((key) => {
      if (key === "Number" || key === "Title") {
        return;
      }
      const val = +d[key];
      if (val !== 0) {
        if (r[key]) {
          r[key] += val;
        } else {
          r[key] = val;
        }
      }
    });
    return r;
  }, {});

  return values;
}
