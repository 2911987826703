import React from "react";

export default ({
  pieces
}) => {
  return (
    <div className="Legend font--label--small">
      <ul className="Legend-list">
      {pieces.map((d,i) => (
        <li
          className="Legend-item"
          key={d.label}>
          <LegendMark className={d.className} />
          &nbsp;{d.label}
        </li>
      ))}
      </ul>
    </div>
  )
}

const LegendMark = ({
  size=10,
  className
}) => (
  <svg width={size} height={size}>
    <rect
      className={className}
      width={size}
      height={size} />
  </svg>
)