export default function (calc, raw, settings) {
  const data = {
    ngdp_growth: {
      values: {},
      update: function (year) {
        this.values[year] =
          calc.ECON_BASE["Nominal GDP Growth Rate"].values[year];
      },
    },
    inflation_growth: {
      values: {},
      update: function (year) {
        this.values[year] = calc.ECON_BASE["INFLATION_RATE"].values[year];
      },
    },
    pit_baseline: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          calc.BUDGET_INITIAL["PERSONAL_INCOME_TAXES"].values[year];
      },
    },
    pit_growthrate: {
      values: {},
      update: function (year) {
        let rate;
        switch (settings.PERSONAL_INCOME_TAXES.growthRate) {
          case "CBO_GROWTH":
            rate =
              data.pit_baseline.values[year] /
                data.pit_baseline.values[year - 1] -
              1;
            break;

          case "GDP_GROWTH":
            rate = data.ngdp_growth.values[year];
            break;

          case "INFLATION_GROWTH":
            rate = data.inflation_growth.values[year];
            break;

          default:
            rate = 0;
          //Zero Growth??
        }
        this.values[year] =
          1 + rate + settings.PERSONAL_INCOME_TAXES.growthRateAdjustment;
      },
    },
    pit_user: {
      values: {},
      kickoff: true,
      update: function (year) {
        if (year === calc.startYear) {
          this.values[year] = data.pit_baseline.values[year];
        } else {
          this.values[year] =
            this.values[year - 1] * data.pit_growthrate.values[year];
        }
      },
    },
    pit_change: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.pit_user.values[year] / data.pit_baseline.values[year];
      },
    },
    oasdi_baseline: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          calc.BUDGET_INITIAL.SOCIAL_SECURITY_TAXES.values[year];
      },
    },
    oasdi_growth: {
      values: {},
      update: function (year) {
        let rate;
        switch (settings.SOCIAL_SECURITY_TAXES.growthRate) {
          case "CBO_GROWTH":
            rate =
              data.oasdi_baseline.values[year] /
                data.oasdi_baseline.values[year - 1] -
              1;
            break;

          case "GDP_GROWTH":
            rate = data.ngdp_growth.values[year];
            break;

          case "INFLATION_GROWTH":
            rate = data.inflation_growth.values[year];
            break;

          default:
            rate = 0;
          //Zero Growth??
        }
        this.values[year] =
          1 + rate + settings.SOCIAL_SECURITY_TAXES.growthRateAdjustment;
      },
    },
    oasdi_user: {
      values: {},
      kickoff: true,
      update: function (year) {
        if (year === calc.startYear) {
          this.values[year] = data.oasdi_baseline.values[year];
        } else {
          this.values[year] =
            this.values[year - 1] * data.oasdi_growth.values[year];
        }
      },
    },
    oasdi_change: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_user.values[year] / data.oasdi_baseline.values[year];
      },
    },
    hi_baseline: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] = calc.BUDGET_INITIAL.MEDICARE_TAXES.values[year];
      },
    },
    hi_growth: {
      values: {},
      update: function (year) {
        let rate;
        switch (settings.MEDICARE_TAXES.growthRate) {
          case "CBO_GROWTH":
            rate =
              data.hi_baseline.values[year] /
                data.hi_baseline.values[year - 1] -
              1;
            break;

          case "GDP_GROWTH":
            rate = data.ngdp_growth.values[year];
            break;

          case "INFLATION_GROWTH":
            rate = data.inflation_growth.values[year];
            break;

          default:
            rate = 0;
          //Zero Growth??
        }
        this.values[year] =
          1 + rate + settings.MEDICARE_TAXES.growthRateAdjustment;
      },
    },
    hi_user: {
      values: {},
      kickoff: true,
      update: function (year) {
        if (year === calc.startYear) {
          this.values[year] = data.hi_baseline.values[year];
        } else {
          this.values[year] =
            this.values[year - 1] * data.hi_growth.values[year];
        }
      },
    },
    hi_change: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_user.values[year] / data.hi_baseline.values[year];
      },
    },
  };

  return data;
}
