export default function(datasets) {
	const
		normalize = require("../../../normalize.js"),
		operations = require("../../../operations.js")(datasets),
		startYear = datasets.startYear

	const data = {
		"10-YR Revenue": {
			"values": {},
			"update": function(year) {
				this.values[year] =datasets.BUDGET_INITIAL["SOCIAL_SECURITY_TAXES"].values[year]
			}
		},
		"Taxable payroll": {
			"sheet": "ssa_6g6"
		},
		"Gross domestic product": {
			"sheet": "ssa_6g6"
		},
		"Taxable Payroll as a Percent of GDP": {
			"formula": function() {
				return operations.quotientValues(data["Taxable payroll"].values, data["Gross domestic product"].values)
			}
		},
		"Taxable Payroll": {
			"values": {},
			"update": function(year) {
				if (year === startYear + 1) {
					this.values[startYear] = datasets.ECON_BASE["Nominal GDP"].values[startYear] * data["Taxable Payroll as a Percent of GDP"].values[startYear]
				}
				this.values[year] = datasets.ECON_BASE["Nominal GDP"].values[year] * data["Taxable Payroll as a Percent of GDP"].values[year]
			}
		},
		"Growth in oasdi payroll taxes": {
			"values": {},
			"update": function(year) {
				this.values[year] = data["Taxable Payroll"].values[year] / data["Taxable Payroll"].values[year - 1] - 1
			}
		},
		"Long-Term Calculation": {
			"values": {},
			"update": function(year) {
				if (year >= startYear + 10) {
					if (year === startYear + 10) {
						this.values[year] = data["10-YR Revenue"].values[year]
					} else {
						this.values[year] = this.values[year - 1] * (1 + data["Growth in oasdi payroll taxes"].values[year])
					}
				}
			}
		},
		"OASDI Revenues: Total": {
			"values": {},
			"update": function(year) {
				if (year === startYear + 1) {
					this.values[startYear - 1] = data["10-YR Revenue"].values[startYear - 1]
					this.values[startYear] = data["10-YR Revenue"].values[startYear]
				}
				if (year <= startYear + 10) {
					this.values[year] = data["10-YR Revenue"].values[year]
					//TODO * (1+SUMIFS('bc_ss options calc (orig)'!$D$43:$D$121,'bc_ss options calc (orig)'!$B$43:$B$121,A9))
				} else {
					this.values[year] = data["Long-Term Calculation"].values[year]
					//TODO * (1+SUMIFS('bc_ss options calc (orig)'!$D$43:$D$121,'bc_ss options calc (orig)'!$B$43:$B$121,A9))
				}
			}
		},
		"Medicare Income rate": {
			"sheet": "ssa_6g2",
			"column": "Income rate (HI)",
			"normalize": normalize.percent
		},
		"SSA Trustee's estimate of HI payroll tax": {
			"values": {},
			"update": function(year) {
				if (year === startYear + 1) {
					this.values[startYear] = data["Medicare Income rate"].values[startYear] * data["Taxable Payroll"].values[startYear]
				}
				this.values[year] = data["Medicare Income rate"].values[year] * data["Taxable Payroll"].values[year]
			}
		},
		"Growth in Hi payroll taxes": {
			"values": {},
			"update": function(year) {
				this.values[year] = (data["SSA Trustee's estimate of HI payroll tax"].values[year] / data["SSA Trustee's estimate of HI payroll tax"].values[year - 1]) - 1
			}
		},
		"Total HI Revenue": {
			"values": {},
			"update": function(year) {
				if (year <= startYear + 10) {
					this.values[year] = datasets.BUDGET_INITIAL["MEDICARE_TAXES"].values[year]
				} else {
					this.values[year] = this.values[year - 1] * (1 + data["Growth in Hi payroll taxes"].values[year])
				}
			}
		}
	}

	return data
}