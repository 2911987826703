import React, { Component } from 'react';

import Flickity from "react-flickity-component";
import 'flickity/css/flickity.css';

import Logo from './icons/AmericaOffBalanceIcon.js';
import Calculator from '../assets/16.2.png'

import step1 from '../assets/adjust_categories.mp4';
import step1_mobile from '../assets/adjust_categories_mobile.mp4';
import step2 from '../assets/enact_policies.mp4';
import step2_mobile from '../assets/enact_policies_mobile.mp4';
import step3 from '../assets/save_share.mp4';
import step3_mobile from '../assets/save_share_mobile.mp4';

export default class Tutorial extends Component {
  constructor() {
    super()
    this.finishTutorial = this.finishTutorial.bind(this)
  }
  finishTutorial() {
    this.props.done()
  }
  render() {
    const { isMobile } = this.props;

    return (
      <div className="Tutorial page-pad--large" onClick={this.finishTutorial}>
        <div className="FullCarousel Tutorial-inner bg--user" onClick={(event) => {
          event.stopPropagation()
        }}>
          <Logo />
          <Flickity
            className="FullCarousel-flickity"
            options={{
              draggable: true,
              cellSelector: '.Tutorial-slide'
            }}>
            <Slide className="TutorialSlide--text" copy={<div>
              <img alt="" src={Calculator} style={{ width: "100px", display: "block", margin: "0 auto" }} />
              <p className="font--h3">Welcome to the America Off Balance Budget Calculator.</p>
              <p>The Calculator gives you control over the federal budget. You may adjust spending or tax levels, enact new budget policies, or alter economic assumptions. It is actually simpler than you might expect. Here are a few helpful hints to get you started.</p>
            </div>} />

            <Slide
              key={'1' + isMobile}
              copy="Adjust growth rate assumptions for budget categories or economic assumptions. Your adjustments will always be shown in light blue."
              imageSrc={isMobile ? step1_mobile : step1}
              />
            <Slide
              key={'2' + isMobile}
              copy="Explore individual accounts, make adjustments, and choose policy ideas you would enact."
              imageSrc={isMobile ? step2_mobile : step2}
              />
            <Slide
              key={'3' + isMobile}
              copy="Save your adjustments and share a detailed report of your proposal."
              imageSrc={isMobile ? step3_mobile : step3}
              />

            <Slide className="TutorialSlide--text" copy={<div>
              <p>The Calculator follows the Congressional Budget Office’s (CBOs) long-term budget projections. It relies on CBO’s assumptions about the future economy, how the economy affects the budget, and how Americans respond to tax changes or increased government borrowing. These assumptions are subject to considerable debate.</p>
              <p>Large changes to budget or economic assumptions will result in less accurate projections.</p>
            </div>}>
              <button
                style={{ marginTop: '1em', alignSelf: 'center' }}
                className="button bg--black color--white border-color--black"
                onClick={this.finishTutorial}>Get Started</button>
            </Slide>
          </Flickity>
          <button
            className="reset-button color--user"
            style={{
              position: 'absolute',
              bottom: '50px',
              right: '50px',
            }}
            onClick={this.finishTutorial}>Skip</button>
        </div>
      </div>
    );
  }
}

const Slide = ({
  className,
  copy,
  imageSrc,
  finish,
  children
}) => (
  <article className={`FullCarousel-item Tutorial-slide ${className}`}>
    <div className="Tutorial-slide-copy font--body">{copy}</div>
    {imageSrc && <div style={{ width: '100%', height: 'auto', maxWidth: '700px', marginTop: '1em' }}>
      <video autoPlay loop muted playsInline style={{ width: '100%', height: '100%', display: 'block' }}>
        <source src={imageSrc} />
      </video>
    </div>}
    {children}
  </article>
)