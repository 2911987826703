import React from "react";
import UserChanges from "components/UserChanges";
import { connect } from "react-redux";
import difference, { isolateChanges } from "difference";

const Changes = ({ userOptions, baselineOptions, budgetOptionsById }) => {
  const changes = difference(userOptions, baselineOptions);
  const isolatedChanges = isolateChanges(changes);
  if (changes.policiesEnacted) {
    changes.policiesEnacted.forEach((d) => {
      const policy = budgetOptionsById[d.id];
      if (!policy.hide) {
        //initialize if empty
        isolatedChanges[policy.category] =
          isolatedChanges[policy.category] || {};
        isolatedChanges[policy.category].policiesEnacted =
          isolatedChanges[policy.category].policiesEnacted || [];

        isolatedChanges[policy.category] = {
          ...isolatedChanges[policy.category],
          policiesEnacted: [
            policy,
            ...isolatedChanges[policy.category].policiesEnacted,
          ],
        };
      }
    });
  }

  return (
    <div>
      <div>Changes</div>
      <UserChanges changes={isolatedChanges} />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userOptions: state.calculator.userOptions,
    baselineOptions: state.calculator.baselineOptions,
    budgetOptionsById: state.calculator.budgetOptionsById,
  };
};
export default connect(mapStateToProps)(Changes);
