import React, { Component, createRef } from "react";
import ReactResponsiveSelect from 'react-responsive-select';
import 'react-responsive-select/dist/ReactResponsiveSelect.css';
import GrowthRatePercentageInput from './GrowthRatePercentageInput';
import { roundPercentageChange } from '../rounding';
import SelectCaret from './icons/SelectCaret';

class GrowthRateSelect extends Component {

	constructor(props) {
		super(props)

    this.state = {
      currentAdjustmentValue: props.growthRateAdjustmentValue || 0
    }
    this.onInputChange = this.onInputChange.bind(this)
    this.update = this.update.bind(this)
    this.onInputBlur = this.onInputBlur.bind(this)
    this.onInputKeyPress = this.onInputKeyPress.bind(this)
    this.increment = this.increment.bind(this)
    this.decrement = this.decrement.bind(this)
    this.selectRef = createRef()
  }

  update(force=false) {
    if (isNaN(this.state.currentAdjustmentValue) || this.state.currentAdjustmentValue === "") {
      this.setState({
        currentAdjustmentValue: 0
      })
    }
    const newValue = roundPercentageChange(parseFloat(this.state.currentAdjustmentValue))

    if (force || newValue !== this.state.currentAdjustmentValue) {
      this.setState({
        currentAdjustmentValue: newValue
      }, function() {
        const newValue = this.state.currentAdjustmentValue
        const oldValue = this.props.growthRateAdjustmentValue
        this.props.onGrowthRateAdjustmentChange(newValue,oldValue)
      })
    }
  }
  onInputChange(event) {
    let
      value = event.target.value,
      valueString = value.toString(),
      decimalIndex = valueString.indexOf('.')

    if (decimalIndex !== -1 && valueString.length > decimalIndex + 5) {
      valueString = valueString.substring(0,decimalIndex + 5)
    }
    this.setState({
      currentAdjustmentValue: valueString
    })
  }
  onInputBlur() {
    this.update()
  }
  onInputKeyPress(event) {
    if (event.key === 'Enter') {
      this.update()
    }
  }

  increment() {
    this.setState({
      currentAdjustmentValue: this.state.currentAdjustmentValue + this.props.incrementAmount
    }, () => {
      this.update(true)
    })
  }
  decrement() {
    this.setState({
      currentAdjustmentValue: this.state.currentAdjustmentValue - this.props.incrementAmount
    }, () => {
      this.update(true)
    })
  }

	render() {
		const {
			options,
			value,
			onChange,
      label="Adjust growth rate",
      tooltipType="budget"
		} = this.props

		const theOptions = Object.keys(options).reduce((r,option) => {
			r.push({
				text: options[option],
				value: option
			})
      return r;
		}, [])

		return (
			<div className="GrowthRateSelect font--label--small">
				<div className="GrowthRateSetting-label color--black">
          {label} <div className="GrowthRateSetting-tooltip faded" data-tip data-for={`GrowthRateTooltip--${tooltipType}`} data-position="left"></div>
        </div>

				<div className={"select border-color--user"}>
					<ReactResponsiveSelect
            ref={this.selectRef}
						options={theOptions}
						selectedValue={value}
						caretIcon={<SelectCaret />}
						onChange={(option) => {
              if (option.altered) {
                onChange(option.value)
                this.selectRef.current.selectBox.children[0].children[0].blur()
              }
            }} />
				</div>

        <div className="plus-minus color--black">±</div>

				<GrowthRatePercentageInput
					currentValue={this.state.currentAdjustmentValue}
					onChange={this.onInputChange}
					onKeyPress={this.onInputKeyPress}
					onBlur={this.onInputBlur}
          increment={this.increment}
          decrement={this.decrement} />
			</div>
		)
	}
}

GrowthRateSelect.defaultProps = {
  incrementAmount: .1
}

export default GrowthRateSelect;