import React from "react";
import { formatDollarsDelta, formatPercentageDelta } from "formatDollars";
import { PolicyInfo } from "components/report/policy";
import SuperHeroChart from "components/HeroChart/super-hero-chart";
import Accordion from "components/accordion";
import "./category-changes.scss";
import carrot from "./carrot.svg";

const CategoryChanges = ({
  catKey,
  changes,
  genre,
  definitions,
  baseline,
  user,
  displayYear,
}) => {
  const sections = [];
  const category = definitions[catKey];
  const section = Object.keys(user.ECON).includes(catKey) ? "ECON" : "BUDGET";
  const isEcon = genre.toUpperCase() === "ECONOMY";
  const rateSetting = changes.growthRate
    ? isEcon
      ? changes.growthRate.replace("GROWTH", "RATE")
      : changes.growthRate
    : null;
  const fallbackRate = isEcon ? "CBO_RATE" : "CBO_GROWTH";

  const changeCount = Object.keys(changes).length;
  const format = isEcon ? formatPercentageDelta : formatDollarsDelta;
  const diff = format(
    user[section][catKey].values[displayYear] -
      baseline[section][catKey].values[displayYear]
  );

  if (
    rateSetting ||
    changes.growthRateAdjustment ||
    changes.growthRateAddition ||
    changes.additions
  ) {
    if (rateSetting || changes.growthRateAdjustment) {
      sections.push(
        <div className="BudgetChange-section" key="growthRate">
          <div className="BudgetChange-section-heading">
            {isEcon ? "New Rate" : "New Growth Rate"}
          </div>
          <div>
            <span>{definitions[rateSetting ? rateSetting : fallbackRate]}</span>
            <span className="BudgetChange-section-value">
              {" "}
              {formatPercentageDelta(changes.growthRateAdjustment)}
            </span>
          </div>
        </div>
      );
    }
    if (changes.growthRateAddition) {
      sections.push(
        <div className="BudgetChange-section" key="growthRateAddition">
          <div className="BudgetChange-section-heading">Overall Change</div>
          <div className="BudgetChange-section-value">
            {`${formatPercentageDelta(changes.growthRateAddition)}`}
          </div>
        </div>
      );
    }
    if (changes.additions) {
      sections.push(
        <div className="BudgetChange-section" key="additions">
          <div className="BudgetChange-section-heading">
            Year by year changes
          </div>
          <div className="">
            <ul className="reset-list">
              {Object.keys(changes.additions).reduce((r, d) => {
                r.push(
                  <li
                    className="BudgetChange-item BudgetChange-section-value"
                    style={{ display: "flex", alignItems: "flex-end" }}
                    key={d}
                  >
                    <span>{d}</span>&nbsp;
                    <span>{formatDollarsDelta(changes.additions[d])}</span>
                  </li>
                );
                return r;
              }, [])}
            </ul>
          </div>
        </div>
      );
    }
  }

  if (changes.policiesEnacted && changes.policiesEnacted.length) {
    sections.push(
      <div className="BudgetChange-section" key="policies">
        <div className="BudgetChange-section-heading">Policies Enacted</div>
        {changes.policiesEnacted.map((policy) => {
          return (
            <div className="BudgetChange-item" key={policy.id}>
              <PolicyInfo policy={policy} definitions={definitions} />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="CategoryChanges">
      <Accordion
        button={
          <div className={`CategoryChanges-header border-color--${genre}`}>
            <div
              className={`CategoryChanges-header-heading font--h3 color--${genre}`}
            >
              {category}
            </div>
            <div
              className={`CategoryChanges-header-changecount color--${genre}`}
            >
              {changeCount} reform{changeCount > 1 && "s"}
            </div>
            <div className="CategoryChanges-header-effect">
              <span className="CategoryChanges-header-effect-label faded">
                Change
              </span>
              <span className="CategoryChanges-header-effect-value">
                {diff}
              </span>
            </div>
            <img src={carrot} alt="" />
          </div>
        }
        content={
          <div className="CategoryChanges-body">
            <div className="CategoryChanges-body-changes">{sections}</div>
            <div className="CategoryChanges-body-chart">
              <SuperHeroChart
                mainColumn={catKey}
                showLegend={false}
                showTitle={false}
                showChoices={false}
                user={user}
                baseline={baseline}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CategoryChanges;
