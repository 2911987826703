import React from "react";

export default ({ fill='#D6D6D6', stroke='#F7F9FB'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.2 15.2">
		<circle fill={ fill } cx="7.6" cy="7.6" r="7.6"/>
		<g fill="none" stroke={stroke}>
			<line x1="4.5" y1="4.5" x2="10.7" y2="10.7"/>
				<line x1="10.7" y1="4.5" x2="4.5" y2="10.7"/>
		</g>
	</svg>
)