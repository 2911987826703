import React from "react";
import { CBO_LABEL, YOU_LABEL } from "Constants";
import { formatPercentage, formatPercentageDelta } from "formatDollars.js";
import "./ChartDatum.scss";

const ChartDatum = ({
  genre,
  title,
  value,
  formatValue,
  formatDelta,
  baselineDataDollars,
  baselineDataGdp,
  userDataDollars,
  userDataGdp,
}) => {
  const mainDelta = formatDelta(
    userDataDollars[value] - baselineDataDollars[value]
  );
  const gdpDelta =
    userDataGdp && baselineDataGdp
      ? formatPercentageDelta(
          100 * (userDataGdp[value] - baselineDataGdp[value])
        )
      : undefined;
  return (
    <div className="ChartDatum">
      <h1 className={`font--h3 bold color--${genre}`}>{title}</h1>
      <div className="category-header-values">
        <div className="category-header-value-item">
          <span className="label font--label--small faded">Year</span>
          <div className="value color--black font--number">{value}</div>
        </div>
        <div className="category-header-value-item">
          <span className="label font--label--small faded">{CBO_LABEL}</span>
          <div className={`value font--number color--${genre}`}>
            <div>{formatValue(baselineDataDollars[value])}</div>
            {baselineDataGdp && (
              <div>{formatPercentage(100 * baselineDataGdp[value])} of gdp</div>
            )}
          </div>
        </div>
        <div className="category-header-value-item">
          <span className="label font--label--small faded">{YOU_LABEL}</span>
          <div className="value color--user font--number">
            <div>{formatValue(userDataDollars[value])}</div>
            {userDataGdp && (
              <div>{formatPercentage(100 * userDataGdp[value])} of gdp</div>
            )}
          </div>
        </div>
        {mainDelta && (
          <div className="category-header-value-item">
            <span className="label font--label--small faded">Change</span>
            <div className="value color--black font--number">
              <div>{mainDelta}</div>
              {gdpDelta ? <div>{gdpDelta} of gdp</div> : undefined}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChartDatum;
