import React from "react";
import NextArrowIcon from "components/next-arrow-icon";
//import videoSrc from "components/embed/hoover_coin.mp4";
import imgSrc from "components/embed/tour-thumb.png";
import { useSprings, a } from "react-spring";
import Logo from "components/icons/AmericaOffBalanceIcon";
import classes from "./entry-point.module.scss";

const EntryPoint = ({ className = "", heading, description, next }) => {
  const [props] = useSprings(4, (index) => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: Math.pow(1.7, index) * 500,
  }));

  return (
    <div
      className={`${classes.container} Report-footer-module bg--revenue color--white ${className}`}
    >
      <a.div style={props[1]} className={`${classes.heading}`}>
        {heading}
      </a.div>
      <a.div style={props[2]} className={`${classes.body}`}>
        {description}
      </a.div>
      <div className={classes.linkContainer}>
        <a.button
          style={props[3]}
          className={`${classes.link} hover-bg--white hover-color--revenue`}
          onClick={next}
        >
          Get Started <NextArrowIcon />
        </a.button>
      </div>
      {/* <a.video
        style={props[0]}
        className="Embed-landing-video"
        src={videoSrc}
        loop
        autoPlay
        muted
        playsInline
      ></a.video> */}
      <div className={classes.image}>
        <Logo />
        <a.img style={props[0]} src={imgSrc} />
      </div>
    </div>
  );
};

export default EntryPoint;
