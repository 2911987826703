import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Transition } from "react-transition-group";

import Login from "./Login";
import Logo from "./icons/AmericaOffBalanceIcon";
import HamburgerIcon from "./icons/HamburgerIcon";
import HamburgerMenu from "./HamburgerMenu";
import HelpIcon from './icons/InfoIcon';

import { toggleTutorial } from '../Actions';

class Nav extends React.Component {
  static propTypes = {
    loggedInUser: PropTypes.object,
    firstAuthComplete: PropTypes.bool,
    toggleAuthenticate: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      showHamburgerMenu: false,
    }
  }

  render() {
    const transitionStyles = {
      entered:  { transform: "translateX(0)" },
      exiting: { transform: "translateX(-100%)" },
    };

    const { toggleTutorial, showHelp } = this.props

    return (
      <nav className="Nav">
        <button className="reset-button hamburger-btn" onClick={this.handleHamburgerClick}>
          <HamburgerIcon />
        </button>
        <a href="/"><Logo /></a>

        <div className="nav-right font--body--alt--small">
          <div className="Nav-links">
            <a className="Nav-link" href="https://americaoffbalance.org/tour">Tour the Budget</a>
            <a className="Nav-link" href="https://budgetmatters.americaoffbalance.org">Budget Matters</a>
            <a className="Nav-link" href="https://calculator.americaoffbalance.org">Fix the Budget</a>
          </div>
          {showHelp && (
          <button
            className="nav-right-item reset-button icon--link"
            onClick={() => {
              toggleTutorial(true)
            }}>
            <HelpIcon /> <span className="Nav-item-helper-text">Help</span>
          </button>)}
          <Login
            className="nav-right-item"
            loggedInUser={this.props.loggedInUser}
            firstAuthComplete={this.props.firstAuthComplete}
            toggleAuthenticate={this.props.toggleAuthenticate} />
        </div>

        <Transition
          in={this.state.showHamburgerMenu}
          timeout={200}
          unmountOnExit
        >
          { state => {
            return (
              <HamburgerMenu
                style={{
                  ...transitionStyles[state]
                }}
                isMobile={this.props.isMobile}
                onDismissClick={this.handleHamburgerClick}
              />
            )
          }}
        </Transition>
      </nav>
    )
  }

  handleHamburgerClick = (e) => {
    e.preventDefault();
    this.setState({
      showHamburgerMenu: !this.state.showHamburgerMenu,
    })
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleTutorial: (value) => {
    dispatch(toggleTutorial(value))
  }
})

export default connect(null, mapDispatchToProps)(Nav)
