const _ = require("lodash");

module.exports = function (datasets, parsedData) {
  const operations = require("./operations.js")(datasets);

  function extractColumn(sheet, column) {
    var data = {};
    sheet.forEach((datum) => {
      let year =
        datum.year ||
        datum.Year ||
        datum["Calendar Year"] ||
        datum["Fiscal Year"];
      year = parseInt(year, 10);
      if (year) {
        data[year] = parseFloat(datum[column]);
      }
    });
    return data;
  }

  function normalizeValues(data, operator) {
    return Object.keys(data).reduce((r, key) => {
      r[key] = operations[operator.operation](data[key], operator.factor);
      return r;
    }, {});
  }

  return {
    processFormulas: function (dataset, settings) {
      settings = settings || null;

      const formulas = _.pickBy(dataset, function (d, key) {
        return d && typeof d.formula !== "undefined";
      });
      Object.keys(formulas).forEach((key) => {
        const column = dataset[key];
        column.values = column.formula();
      });
    },
    processPulls: function (dataset) {
      //first process direct value pulls
      const directPulls = _.pickBy(dataset, function (d, key) {
        return typeof d.sheet !== "undefined";
      });

      Object.keys(directPulls).forEach((key) => {
        let column = dataset[key];
        const columnKey = column.column || key;

        column.values = extractColumn(parsedData[column.sheet], columnKey);
        if (column.normalize) {
          column.values = normalizeValues(column.values, column.normalize);
        }
      });
    },
    processUpdates: function (dataset, year) {
      const updates = _.pickBy(dataset, function (d, key) {
        return typeof d.update !== "undefined";
      });
      Object.keys(updates).forEach((key) => {
        updates[key].update(year);
      });
    },
    kickoffUpdates: function (dataset, year) {
      const updates = _.pickBy(dataset, function (d, key) {
        return typeof d.update !== "undefined" && d.kickoff;
      });
      Object.keys(updates).forEach((key) => {
        updates[key].update(year);
      });
    },
    processLaterUpdates: function (dataset, year) {
      const updates = _.pickBy(dataset, function (d, key) {
        return typeof d.updateLater !== "undefined";
      });

      Object.keys(updates).forEach((key) => {
        updates[key].updateLater(year);
      });
    },
  };
};
