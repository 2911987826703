import React from "react";
import Image from "./Image.js";

export default ({ title, date, url, series, image, style, authors, description, index, variant }) => {
  const variantClass = variant ? variant : index === 0 ? 'large' : index < 4 ? 'medium' : 'small'
  return (
    <li className={`ArticleList-item ArticleList-item--${variantClass}`} style={style}>
      <a href={`https://budgetmatters.americaoffbalance.org${url}`} target="blank">
        <div className="ArticleList-item-content">
          <div className="ArticleList-item-meta  ff--basis label">{series}</div>
          <div className="ArticleList-item-label">{title}</div>
          {variantClass !== 'small' && <div className="ArticleList-item-description body">{description}</div>}
        </div>
        {variantClass === "large" && <Image image={image} />}
      </a>
    </li>
  )
}