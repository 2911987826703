import React from 'react';

export default ({ count }) => {
  return (
    <div className="tab">
      <div className="change-count bg--white color--black font--number--small">{count}</div>
      <span>Changes</span>
    </div>
  )
}

//<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><title>Changes</title><path d="M3.1 7.9h6.1v5.8H3.1V7.9zm0 16.2h6.1v5.8H3.1v-5.8zm0 16.2h6.1v5.8H3.1v-5.8zM15.9 7.9h35v5.8h-35V7.9zm0 16.2h35v5.8h-35v-5.8zm0 16.2h35v5.8h-35v-5.8z"/></svg>
