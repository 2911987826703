export default function (calc, raw, settings, taxCalcSettings) {
  const baseByTaxCode = raw.tc_baseyeartaxcode.reduce((r, d) => {
    r[d.tax_code] = d;
    return r;
  }, {});

  const categories = [
    "pit",
    "oasdi_employee",
    "oasdi_employer",
    "oasdi_total",
    "hi_employee",
    "hi_employer",
    "hi_total",
    "total",
  ];
  function policyChange(key) {
    return calc.TC_CHANGE_OPTIONS[key] ? calc.TC_CHANGE_OPTIONS[key] : 0;
  }
  function rateChange(key, year) {
    return calc.TC_CHANGE_GROWTHRATES[key].values[year];
  }
  function levelChange(key) {
    return (100 + settings[key].growthRateAddition) / 100;
  }

  let data = {
    tax_law: {
      values: {},
      kickoff: true,
      update: function (year) {
        const key = `code_yr${year - calc.startYear}`;
        this.values[year] = policyChange(key) + +raw.tc_assumptions[0][key];
      },
    },
    mstat_code: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] = taxCalcSettings.maritalStatus === "Single" ? 1 : 2; //2 for "Married"
      },
    },
    tax_code: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] = (
          data.tax_law.values[year] +
          data.mstat_code.values[year] / 10
        ).toString();
      },
    },
    chained_cpi: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          calc.ECON_CBO_ASSUMED.INFLATION_RATE.values[year] +
          +raw.tc_assumptions[0]["chained cpi - cpi"];
      },
    },
    chained_cpi_index: {
      values: {
        [calc.startYear]: 1,
      },
      update: function (year) {
        this.values[year] =
          data.chained_cpi_index.values[year - 1] *
          (1 + data.chained_cpi.values[year]);
      },
    },
    labor_productivity_index:
      calc.ECON_CBO_ASSUMED["Labor Productivity Index (Nominal)"],
    //nominal_labor_productivity   //Labor Productivity Index (Nominal) from BASELINE
    pit_stded: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          round50(
            baseByTaxCode[data.tax_code.values[year]].pit_stded *
              data.chained_cpi_index.values[year]
          ) + policyChange(`pit_stded`);
      },
    },
    pit_exemption: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] = round50(
          baseByTaxCode[data.tax_code.values[year]].pit_exemption *
            data.chained_cpi_index.values[year] +
            policyChange(`pit_exemption`)
        );
      },
    },
    ...iterate(
      (i) => `pit_rate${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] =
            (+baseByTaxCode[data.tax_code.values[year]][`pit_rate${i}`] +
              policyChange(`pit_rate${i}`)) *
            rateChange(`pit_change`, year) *
            levelChange("PERSONAL_INCOME_TAXES");
        },
      }),
      1,
      8
    ),
    ...iterate(
      (i) => `pit_bracket${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] = Math.min(
            999999999,
            round5(
              +baseByTaxCode[data.tax_code.values[year]][`pit_bracket${i}`] *
                data.chained_cpi_index.values[year]
            ) + policyChange(`pit_bracket${i}`)
          );
        },
      }),
      1,
      9
    ),
    ...iterate(
      (i) => `oasdi_employee_rate${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] =
            (+baseByTaxCode[data.tax_code.values[year]][
              `oasdi_employee_rate${i}`
            ] +
              policyChange(`oasdi_employee_rate${i}`)) *
            rateChange(`oasdi_change`, year) *
            levelChange("SOCIAL_SECURITY_TAXES");
        },
      }),
      1,
      3
    ),
    ...iterate(
      (i) => `oasdi_employee_bracket${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] = Math.min(
            999999999,
            +baseByTaxCode[data.tax_code.values[year]][
              `oasdi_employee_bracket${i}`
            ] *
              data.labor_productivity_index.values[year] +
              policyChange(`oasdi_employee_bracket${i}`)
          );
        },
      }),
      1,
      4
    ),
    ...iterate(
      (i) => `oasdi_employer_rate${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] =
            (+baseByTaxCode[data.tax_code.values[year]][
              `oasdi_employer_rate${i}`
            ] +
              policyChange(`oasdi_employer_rate${i}`)) *
            rateChange(`oasdi_change`, year) *
            levelChange("SOCIAL_SECURITY_TAXES");
        },
      }),
      1,
      3
    ),
    ...iterate(
      (i) => `oasdi_employer_bracket${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] = round100(
            Math.min(
              999999999,
              +baseByTaxCode[data.tax_code.values[year]][
                `oasdi_employer_bracket${i}`
              ] *
                data.labor_productivity_index.values[year] +
                policyChange(`oasdi_employer_bracket${i}`)
            )
          );
        },
      }),
      1,
      4
    ),
    ...iterate(
      (i) => `hi_employee_rate${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] =
            (+baseByTaxCode[data.tax_code.values[year]][
              `hi_employee_rate${i}`
            ] +
              policyChange(`hi_employee_rate${i}`)) *
            rateChange(`hi_change`, year) *
            levelChange("MEDICARE_TAXES");
        },
      }),
      1,
      2
    ),
    ...iterate(
      (i) => `hi_employee_bracket${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] = Math.min(
            999999999,
            +baseByTaxCode[data.tax_code.values[year]][
              `hi_employee_bracket${i}`
            ] + policyChange(`hi_employee_bracket${i}`)
          );
        },
      }),
      1,
      3
    ),
    ...iterate(
      (i) => `hi_employer_rate${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] =
            (+baseByTaxCode[data.tax_code.values[year]][
              `hi_employer_rate${i}`
            ] +
              policyChange(`hi_employer_rate${i}`)) *
            rateChange(`hi_change`, year) *
            levelChange("MEDICARE_TAXES");
        },
      }),
      1,
      2
    ),
    ...iterate(
      (i) => `hi_employer_bracket${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] = Math.min(
            999999999,
            +baseByTaxCode[data.tax_code.values[year]][
              `hi_employer_bracket${i}`
            ] + policyChange(`hi_employer_bracket${i}`)
          );
        },
      }),
      1,
      3
    ),
    agi: {
      values: {
        [calc.startYear]: taxCalcSettings.income,
      },
      update: function (year) {
        this.values[year] =
          taxCalcSettings.income * data.labor_productivity_index.values[year];
      },
    },
    taxable_income: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.agi.values[year] -
          data.pit_stded.values[year] -
          data.pit_exemption.values[year] * data.mstat_code.values[year];
      },
    },
    ...iterate(
      (i) => `pit_taxespaid${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] =
            Math.max(
              0,
              Math.min(
                data.taxable_income.values[year],
                data[`pit_bracket${i + 1}`].values[year]
              ) - data[`pit_bracket${i}`].values[year]
            ) * data[`pit_rate${i}`].values[year];
        },
      }),
      1,
      8
    ),
    pit_liability: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] = [1, 2, 3, 4, 5, 6, 7, 8].reduce(
          (r, i) => r + data[`pit_taxespaid${i}`].values[year],
          0
        );
      },
    },
    ...iterate(
      (i) => `oasdi_employee_taxespaid${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] =
            Math.max(
              0,
              Math.min(
                data.agi.values[year],
                data[`oasdi_employee_bracket${i + 1}`].values[year]
              ) - data[`oasdi_employee_bracket${i}`].values[year]
            ) * data[`oasdi_employee_rate${i}`].values[year];
        },
      }),
      1,
      3
    ),
    ...iterate(
      (i) => `oasdi_employer_taxespaid${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] =
            Math.max(
              0,
              Math.min(
                data.agi.values[year],
                data[`oasdi_employer_bracket${i + 1}`].values[year]
              ) - data[`oasdi_employer_bracket${i}`].values[year]
            ) * data[`oasdi_employer_rate${i}`].values[year];
        },
      }),
      1,
      3
    ),
    oasdi_employer_liability: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_employer_taxespaid1.values[year] +
          data.oasdi_employer_taxespaid2.values[year] +
          data.oasdi_employer_taxespaid3.values[year];
      },
    },
    oasdi_total_liability: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] = [1, 2, 3].reduce(
          (r, i) =>
            r +
            data[`oasdi_employee_taxespaid${i}`].values[year] +
            data[`oasdi_employer_taxespaid${i}`].values[year],
          0
        );
      },
    },
    oasdi_employee_liability: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_employee_taxespaid1.values[year] +
          data.oasdi_employee_taxespaid2.values[year] +
          data.oasdi_employee_taxespaid3.values[year];
      },
    },
    ...iterate(
      (i) => `hi_employee_taxespaid${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] =
            Math.max(
              0,
              Math.min(
                data.agi.values[year],
                data[`hi_employee_bracket${i + 1}`].values[year]
              ) - data[`hi_employee_bracket${i}`].values[year]
            ) * data[`hi_employee_rate${i}`].values[year];
        },
      }),
      1,
      2
    ),
    hi_employee_liability: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] = [1, 2].reduce(
          (r, i) => r + data[`hi_employee_taxespaid${i}`].values[year],
          0
        );
      },
    },
    ...iterate(
      (i) => `hi_employer_taxespaid${i}`,
      (i) => ({
        values: {},
        kickoff: true,
        update: function (year) {
          this.values[year] =
            Math.max(
              0,
              Math.min(
                data.agi.values[year],
                data[`hi_employer_bracket${i + 1}`].values[year]
              ) - data[`hi_employer_bracket${i}`].values[year]
            ) * data[`hi_employer_rate${i}`].values[year];
        },
      }),
      1,
      2
    ),
    hi_employer_liability: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] = [1, 2].reduce(
          (r, i) => r + data[`hi_employer_taxespaid${i}`].values[year],
          0
        );
      },
    },
    hi_total_liability: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_employee_liability.values[year] +
          data.hi_employer_liability.values[year];
      },
    },
    total_liability: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.pit_liability.values[year] +
          data.oasdi_total_liability.values[year] +
          data.hi_total_liability.values[year];
      },
    },
    pit_mtr_unadj: {
      values: {},
      kickoff: true,
      update: function (year) {
        const pit_brackets = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(
          (i) => data[`pit_bracket${i}`].values[year]
        );
        const pit_bracket =
          pit_brackets.reduce((r, d, i) => {
            if (d > r && d <= data.taxable_income.values[year]) {
              r = i;
            }
            return r;
          }, null) + 1;

        this.values[year] = data[`pit_rate${pit_bracket}`].values[year];
      },
    },
    oasdi_employee_mtr_unadj: {
      values: {},
      kickoff: true,
      update: function (year) {
        const brackets = [1, 2, 3, 4].map(
          (i) => data[`oasdi_employee_bracket${i}`].values[year]
        );
        const bracket =
          brackets.reduce((r, d, i) => {
            if (d > r && d <= data.taxable_income.values[year]) {
              r = i;
            }
            return r;
          }, null) + 1;

        this.values[year] = data[`oasdi_employee_rate${bracket}`].values[year];
      },
    },
    oasdi_employer_mtr_unadj: {
      values: {},
      kickoff: true,
      update: function (year) {
        const brackets = [1, 2, 3, 4].map(
          (i) => data[`oasdi_employer_bracket${i}`].values[year]
        );
        const bracket =
          brackets.reduce((r, d, i) => {
            if (d > r && d <= data.taxable_income.values[year]) {
              r = i;
            }
            return r;
          }, null) + 1;

        this.values[year] = data[`oasdi_employer_rate${bracket}`].values[year];
      },
    },
    oasdi_total_mtr_unadj: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_employee_mtr_unadj.values[year] +
          data.oasdi_employer_mtr_unadj.values[year];
      },
    },
    hi_employee_mtr_unadj: {
      values: {},
      kickoff: true,
      update: function (year) {
        const brackets = [1, 2, 3].map(
          (i) => data[`hi_employee_bracket${i}`].values[year]
        );
        const bracket =
          brackets.reduce((r, d, i) => {
            if (d > r && d <= data.taxable_income.values[year]) {
              r = i;
            }
            return r;
          }, null) + 1;

        this.values[year] = data[`hi_employee_rate${bracket}`].values[year];
      },
    },
    hi_employer_mtr_unadj: {
      values: {},
      kickoff: true,
      update: function (year) {
        const brackets = [1, 2, 3].map(
          (i) => data[`hi_employer_bracket${i}`].values[year]
        );
        const bracket =
          brackets.reduce((r, d, i) => {
            if (d > r && d <= data.taxable_income.values[year]) {
              r = i;
            }
            return r;
          }, null) + 1;

        this.values[year] = data[`hi_employer_rate${bracket}`].values[year];
      },
    },
    hi_total_mtr_unadj: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_employee_mtr_unadj.values[year] +
          data.hi_employer_mtr_unadj.values[year];
      },
    },
    total_mtr_unadj: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.pit_mtr_unadj.values[year] +
          data.oasdi_total_mtr_unadj.values[year] +
          data.hi_total_mtr_unadj.values[year];
      },
    },
    pit_mtr: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.pit_mtr_unadj.values[year] /
          (1 +
            data.oasdi_employer_mtr_unadj.values[year] +
            data.hi_employer_mtr_unadj.values[year]);
      },
    },
    oasdi_employee_mtr: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_employee_mtr_unadj.values[year] /
          (1 +
            data.oasdi_employer_mtr_unadj.values[year] +
            data.hi_employer_mtr_unadj.values[year]);
      },
    },
    oasdi_employer_mtr: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_employer_mtr_unadj.values[year] /
          (1 +
            data.oasdi_employer_mtr_unadj.values[year] +
            data.hi_employer_mtr_unadj.values[year]);
      },
    },
    oasdi_total_mtr: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_total_mtr_unadj.values[year] /
          (1 +
            data.oasdi_employer_mtr_unadj.values[year] +
            data.hi_employer_mtr_unadj.values[year]);
      },
    },
    hi_employee_mtr: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_employee_mtr_unadj.values[year] /
          (1 +
            data.oasdi_employer_mtr_unadj.values[year] +
            data.hi_employer_mtr_unadj.values[year]);
      },
    },
    hi_employer_mtr: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_employer_mtr_unadj.values[year] /
          (1 +
            data.oasdi_employer_mtr_unadj.values[year] +
            data.hi_employer_mtr_unadj.values[year]);
      },
    },
    hi_total_mtr: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_total_mtr_unadj.values[year] /
          (1 +
            data.oasdi_employer_mtr_unadj.values[year] +
            data.hi_employer_mtr_unadj.values[year]);
      },
    },
    total_mtr: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.total_mtr_unadj.values[year] /
          (1 +
            data.oasdi_employer_mtr_unadj.values[year] +
            data.hi_employer_mtr_unadj.values[year]);
      },
    },
    agi_real: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.agi.values[year] / data.chained_cpi_index.values[year];
      },
    },
    pit_liability_real: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.pit_liability.values[year] / data.chained_cpi_index.values[year];
      },
    },
    oasdi_employee_liability_real: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_employee_liability.values[year] /
          data.chained_cpi_index.values[year];
      },
    },
    oasdi_employer_liability_real: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_employer_liability.values[year] /
          data.chained_cpi_index.values[year];
      },
    },
    oasdi_total_liability_real: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_total_liability.values[year] /
          data.chained_cpi_index.values[year];
      },
    },
    hi_employee_liability_real: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_employee_liability.values[year] /
          data.chained_cpi_index.values[year];
      },
    },
    hi_employer_liability_real: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_employer_liability.values[year] /
          data.chained_cpi_index.values[year];
      },
    },
    hi_total_liability_real: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_total_liability.values[year] /
          data.chained_cpi_index.values[year];
      },
    },
    total_liability_real: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.total_liability.values[year] /
          data.chained_cpi_index.values[year];
      },
    },
    agi_pv: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.agi_real.values[year] /
          Math.pow(
            1 + calc.TECHNICAL_ASSUMPTIONS["Discount Rate"],
            year - calc.startYear
          );
      },
    },
    pit_liability_pv: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.pit_liability_real.values[year] /
          Math.pow(
            1 + calc.TECHNICAL_ASSUMPTIONS["Discount Rate"],
            year - calc.startYear
          );
      },
    },
    oasdi_employee_liability_pv: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_employee_liability_real.values[year] /
          Math.pow(
            1 + calc.TECHNICAL_ASSUMPTIONS["Discount Rate"],
            year - calc.startYear
          );
      },
    },
    oasdi_employer_liability_pv: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_employer_liability_real.values[year] /
          Math.pow(
            1 + calc.TECHNICAL_ASSUMPTIONS["Discount Rate"],
            year - calc.startYear
          );
      },
    },
    oasdi_total_liability_pv: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.oasdi_total_liability_real.values[year] /
          Math.pow(
            1 + calc.TECHNICAL_ASSUMPTIONS["Discount Rate"],
            year - calc.startYear
          );
      },
    },
    hi_employee_liability_pv: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_employee_liability_real.values[year] /
          Math.pow(
            1 + calc.TECHNICAL_ASSUMPTIONS["Discount Rate"],
            year - calc.startYear
          );
      },
    },
    hi_employer_liability_pv: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_employer_liability_real.values[year] /
          Math.pow(
            1 + calc.TECHNICAL_ASSUMPTIONS["Discount Rate"],
            year - calc.startYear
          );
      },
    },
    hi_total_liability_pv: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.hi_total_liability_real.values[year] /
          Math.pow(
            1 + calc.TECHNICAL_ASSUMPTIONS["Discount Rate"],
            year - calc.startYear
          );
      },
    },
    total_liability_pv: {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data.total_liability_real.values[year] /
          Math.pow(
            1 + calc.TECHNICAL_ASSUMPTIONS["Discount Rate"],
            year - calc.startYear
          );
      },
    },
    agi_npv: npv("agi"),
    ...categories.reduce(
      (r, key) => ({ ...r, [`${key}_liability_npv`]: npv(`${key}_liability`) }),
      {}
    ),
    ...categories.reduce(
      (r, key) => ({
        ...r,
        [`${key}_atr_unadj`]: atrunadj(key),
      }),
      {}
    ),
    ...categories.reduce((r, key) => ({
      [`${key}_atr`]: atr(key),
    })),
  };

  function npv(key) {
    return {
      values: {},
      kickoff: true,
      update: function (year) {
        let result = 0;
        for (let i = year; i > calc.startYear; i--) {
          result += data[`${key}_pv`].values[i];
        }
        this.values[year] = result;
      },
    };
  }
  function atrunadj(key) {
    return {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data[`${key}_liability`].values[year] / data.agi.values[year];
      },
    };
  }
  function atr(key) {
    return {
      values: {},
      kickoff: true,
      update: function (year) {
        this.values[year] =
          data[`${key}_liability`].values[year] /
          (data.agi.values[year] +
            data.hi_employer_liability.values[year] +
            data.oasdi_employer_liability.values[year]);
      },
    };
  }
  return data;
}

function iterate(key, obj, start, end) {
  const result = {};
  for (var i = start; i <= end; i++) {
    result[key(i)] = obj(i);
  }
  return result;
}
function round5(value) {
  return Math.round(value / 5) * 5;
}
function round50(value) {
  return Math.round(value / 50) * 50;
}
function round100(value) {
  return Math.round(value / 100) * 100;
}
