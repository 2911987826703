import React, { Component } from "react";
import firebase from "firebase/app";
import 'firebase/database';
const { database } = firebase;

export default class AddFeaturedCalc extends Component {
  constructor() {
    super()

    this.state = {
      calcKey: "",
      error: null
    }
    this.addFeaturedCalc = this.addFeaturedCalc.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  addFeaturedCalc() {
    try {
      database().ref(`/calculators/${this.state.calcKey}`).once('value', (snapshot) => {
        if (snapshot.exists()) {
          const calc = snapshot.val().calc;

          const featuredRefPath = `/meta/featuredCalculators/${this.state.calcKey}`;

          const ref = database().ref(featuredRefPath)

          ref.once('value', (snapshot) => {
            if (snapshot.exists()) {
              this.setState({
                calcKey: "",
                error: 'Calc key ' + this.state.calcKey + ' is already featured'
              })
            } else {
              ref.set({
                calcKey: calc.calcKey,
                title: calc.title,
                order: null
              })
              this.setState({
                calcKey: ""
              })
            }
          })

        } else {
          this.setState((prevState, props) => {
            return {
              calcKey: "",
              error: 'Calc key ' + prevState.calcKey + ' does not exist'
            }
          })
        }
      })
    } catch(e) {
      this.setState({
        calcKey: "",
        error: e.message
      })
    }

  }

  handleInputChange(event) {
    this.setState({
      calcKey: event.target.value,
      error: null
    })
  }

  render() {
    return (
      <div className="AddCalc">
        <input
          type="text"
          placeholder="Calculator Id"
          value={this.state.calcKey}
          onChange={this.handleInputChange} />
        <button onClick={this.addFeaturedCalc}>Add</button>
        {this.state.error && <div className="error">{this.state.error}</div>}
      </div>
    )
  }
}