import React, { Suspense } from "react";
import "./embed.scss";

const App = React.lazy(() => import("components/embed/app"));
const Embed = ({ slug }) => {
  return (
    <div
      style={{
        maxWidth: "750px",
        minHeight: "400px",
        display: "flex",
        margin: "0 auto",
        padding: "1em",
      }}
    >
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "2em",
              fontFamily: "sans-serif",
              width: "100%",
              border: "1px solid",
              borderRadius: "3px",
              backgroundColor: "#1a8446",
              color: "#fff",
            }}
          >
            {/* Loading... */}
            {/*TODO add delayed loader*/}
          </div>
        }
      >
        <App slug={slug} />
      </Suspense>
    </div>
  );
};

export default Embed;
