import React, { useState } from "react";
import "./accordion.scss";

const Accordion = ({ button, content }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  return (
    <div className={`Accordion Accordion--${open ? "open" : "closed"}`}>
      <button className="Accordion-button" onClick={toggle}>
        {button}
      </button>
      {open && content}
    </div>
  );
};

export default Accordion;
