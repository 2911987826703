import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import Modal from "react-modal";
import CloseIcon from "./icons/Close";

import firebase from "firebase/app";
import "firebase/auth";
import { authenticator } from "fb";
const { auth } = firebase;

export default ({ isOpen, toggle }) => (
  <Modal isOpen={isOpen} onClose={toggle} onRequestClose={toggle}>
    <div className="LoginForm">
      <div
        className="font--h1 text-align--center"
        style={{ marginBottom: "1em" }}
      >
        Sign in to save, download, and share your budget.
      </div>
      <StyledFirebaseAuth
        uiConfig={{
          // Popup signin flow rather than redirect flow.
          signInFlow: "popup",
          // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
          callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false,
          },
          signInOptions: [
            auth.GoogleAuthProvider.PROVIDER_ID,
            //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            auth.EmailAuthProvider.PROVIDER_ID,
            //firebase.auth.PhoneAuthProvider.PROVIDER_ID
          ],
        }}
        firebaseAuth={authenticator}
      />
      <button
        onClick={toggle}
        className="ShareDialog-close-button reset-buttton"
      >
        Close <CloseIcon fill="transparent" stroke="black" />
      </button>
    </div>
  </Modal>
);
