import React, { Component } from 'react';
import YouTube from 'react-youtube';

export default class VideoModal extends Component {
  constructor() {
    super();
    this.state = {
      showVideo: false
    }
    this.toggleVideo = this.toggleVideo.bind(this)
  }
  toggleVideo() {
    this.setState({
      showVideo: !this.state.showVideo
    })
  }
  render() {
    return (
      <div className="VideoModal-wrap">
        <button className="PlayVideo" onClick={this.toggleVideo}>
          <div className="PlayIcon" /> Watch the video
        </button>
        {this.state.showVideo && <div className="VideoModal" onClick={this.toggleVideo}>
          <YouTube videoId={"G5yhfU4R8NU"} opts={{ playerVars: { autoplay: 1 }}} />
        </div>}
        {this.state.showVideo && <div className="VideoModal-close"></div>}
      </div>
    );
  }
}