import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import { loadCalc, setDisplayYear } from "Actions.js";

import Projections from "./projections";
import BudgetChanges from "./budget-changes";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";

//import { DiscussionEmbed } from "disqus-react";

import ShareModal from "components/ShareModal";
import Loading from "components/Loading.js";

import difference, { isolateChanges } from "difference";

import { selectVersionLabel } from "versions.js";
import { databasor } from "fb";
import ReportHeader from "./report-header";
import CalcHeader from "./calc-header";
import Controls from "./controls";
import Footer from "./footer";

import "./report.scss";
import TaxCalc from "components/tax-calc";

class Report extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  componentWillMount() {
    this.loadCalcFromDatabaseIfNeeded(
      this.props.calcKey,
      this.props.match.params.calcKey,
      this.props.loadCalc
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.calcKey !== this.props.match.params.calcKey) {
      this.loadCalcFromDatabaseIfNeeded(
        this.props.calcKey,
        this.props.match.params.calcKey,
        this.props.loadCalc
      );
    }
  }

  loadCalcFromDatabaseIfNeeded(currentKey, newKey, loadCalc) {
    if (currentKey !== newKey) {
      console.log("loading from database");

      databasor
        .ref(`/calculators/${newKey}`)
        .once("value")
        .then((snapshot) => {
          if (snapshot.val()) {
            const calc = snapshot.val().calc;
            loadCalc(calc);
            this.setState({
              loading: false,
            });
          }
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const {
      title,
      description,
      startYear,
      endYear,
      user,
      baseline,
      userOptions,
      baselineOptions,
      authorUid,
      authorName,
      calcKey,
      loggedInUser,
      displayYear,
      budgetOptionsById,
      definitions,
      toggleEditBudgetMeta,
      toggleAuthenticateSkip,
      isMobile,
      version,
      dateCreated,
      dateUpdated,
      modifiedFrom,
    } = this.props;

    const loggedInUserIsOwner = loggedInUser && authorUid === loggedInUser.uid;
    return this.state.loading ? (
      <Loading />
    ) : (
      <Overview
        loggedInUserIsOwner={loggedInUserIsOwner}
        calcKey={calcKey}
        isMobile={isMobile}
        title={title}
        description={description}
        toggleEditBudgetMeta={toggleEditBudgetMeta}
        toggleAuthenticateSkip={toggleAuthenticateSkip}
        startYear={startYear}
        endYear={endYear}
        user={user}
        baseline={baseline}
        userOptions={userOptions}
        baselineOptions={baselineOptions}
        budgetOptionsById={budgetOptionsById}
        authorName={authorName}
        displayYear={displayYear}
        definitions={definitions}
        version={version}
        dateCreated={dateCreated}
        dateUpdated={dateUpdated}
        modifiedFrom={modifiedFrom}
      />
    );
  }
}
Report.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    baseline: state.calculator.baseline,
    user: state.calculator.user,
    baselineOptions: state.calculator.baselineOptions,
    userOptions: state.calculator.userOptions,
    startYear: state.calculator.baseline.startYear,
    endYear: state.calculator.baseline.endYear,
    title: state.calculator.title,
    version: state.calculator.version,
    description: state.calculator.description,
    calcKey: state.calculator.calcKey,
    authorUid: state.calculator.authorUid,
    authorName: state.calculator.authorName,
    budgetOptionsById: state.calculator.budgetOptionsById,
    definitions: state.calculator.definitions,
    displayYear: state.session.displayYear,
    dateCreated: state.calculator.dateCreated,
    dateUpdated: state.calculator.dateUpdated,
    modifiedFrom: state.calculator.modifiedFrom,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadCalc: (calc) => {
      dispatch(loadCalc(calc));
      dispatch(setDisplayYear(calc.endYear));
    },
    setDisplayYear: (year) => {
      dispatch(setDisplayYear(year));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);

class Overview extends Component {
  constructor() {
    super();
    this.state = {
      shareIsOpen: false,
    };

    this.toggleShare = this.toggleShare.bind(this);
  }

  toggleShare() {
    this.setState({
      shareIsOpen: !this.state.shareIsOpen,
    });
  }

  render() {
    const {
      title,
      description,
      startYear,
      endYear,
      user,
      baseline,
      userOptions,
      baselineOptions,
      budgetOptionsById,
      authorName,
      displayYear,
      definitions,
      loggedInUserIsOwner,
      toggleEditBudgetMeta,
      toggleAuthenticateSkip,
      calcKey,
      isMobile,
      version,
      dateCreated,
      dateUpdated,
      modifiedFrom,
    } = this.props;

    const {
      debtGdp,
      debtDelta,
      revenueGdp,
      spendingGdp,
      deficitGdp,
      deficitSurplus,
    } = getTopLevelNumbersByGdp({ user, baseline, endYear });

    const changes = difference(userOptions, baselineOptions);
    let isolatedChanges = isolateChanges(changes);
    if (changes.policiesEnacted) {
      changes.policiesEnacted.forEach((d) => {
        const policy = budgetOptionsById[d.id];
        if (!policy.hide) {
          //initialize if empty
          isolatedChanges[policy.category] =
            isolatedChanges[policy.category] || {};
          isolatedChanges[policy.category].policiesEnacted =
            isolatedChanges[policy.category].policiesEnacted || [];

          isolatedChanges[policy.category] = {
            ...isolatedChanges[policy.category],
            policiesEnacted: [
              policy,
              ...isolatedChanges[policy.category].policiesEnacted,
            ],
          };
        }
      });
    }

    const displayTitle = title
      ? title
      : authorName
      ? authorName + "'s budget"
      : "Untitled Budget";
    const versionLabel = selectVersionLabel(version);

    return (
      <article className="Report">
        <ScrollToTopOnMount />
        <Controls
          loggedInUserIsOwner={loggedInUserIsOwner}
          calcKey={calcKey}
          startYear={startYear}
          endYear={endYear}
          displayTitle={displayTitle}
          user={user}
          baseline={baseline}
          definitions={definitions}
          isMobile={isMobile}
          toggleShare={this.toggleShare}
        />
        <CalcHeader />
        <ReportHeader
          displayTitle={displayTitle}
          authorName={authorName}
          description={description}
          loggedInUserIsOwner={loggedInUserIsOwner}
          toggleEditBudgetMeta={toggleEditBudgetMeta}
          versionLabel={versionLabel}
          endYear={endYear}
          debtGdp={debtGdp}
          debtDelta={debtDelta}
          deficitSurplus={deficitSurplus}
          revenueGdp={revenueGdp}
          spendingGdp={spendingGdp}
          deficitGdp={deficitGdp}
          dateCreated={dateCreated}
          dateUpdated={dateUpdated}
          modifiedFrom={modifiedFrom}
        />
        <BudgetChanges
          className="Report-changes"
          changes={isolatedChanges}
          isMobile={isMobile}
        />
        {baseline.TAX_CALC && (
          <div className="Report-taxcalc report-module">
            <TaxCalc />
          </div>
        )}
        <Projections
          user={user}
          baseline={baseline}
          displayYear={displayYear}
          isMobile={isMobile}
        />
        <Footer
          calcKey={calcKey}
          toggleAuthenticateSkip={toggleAuthenticateSkip}
          authorName={authorName}
        />
        <ShareModal isOpen={this.state.shareIsOpen} toggle={this.toggleShare} />
      </article>
    );
  }
}

function getTopLevelNumbersByGdp({ user, baseline, endYear }) {
  const userGdp = user.ECON["Nominal GDP"].values[endYear],
    baselineGdp = baseline.ECON["Nominal GDP"].values[endYear];

  const debtGdp =
    Math.round(100 * (user.BUDGET.NET_DEBT.values[endYear] / userGdp)) + "%";

  const userDebt = user.BUDGET.NET_DEBT.values,
    baselineDebt = baseline.BUDGET.NET_DEBT.values;

  const debtDelta =
    100 * (userDebt[endYear] / userGdp - baselineDebt[endYear] / baselineGdp);

  const revenueGdp =
    Math.round(100 * (user.BUDGET.TOTAL_REVENUE.values[endYear] / userGdp)) +
    "%";

  const spendingGdp =
    Math.round(100 * (user.BUDGET.TOTAL_SPENDING.values[endYear] / userGdp)) +
    "%";

  const deficitSurplus =
    user.BUDGET.SURPLUS.values[endYear] / userGdp > 0 ? "Surplus" : "Deficit";
  const deficitGdp =
    Math.round(
      Math.abs(100 * (user.BUDGET.SURPLUS.values[endYear] / userGdp))
    ) + "%";

  return {
    debtGdp,
    debtDelta,
    revenueGdp,
    spendingGdp,
    deficitGdp,
    deficitSurplus,
  };
}

/* <div className="page-pad--large">
                <DiscussionEmbed
                  shortname="america-off-balance"
                  config={{
                    url: window.location.href,
                    identifier: calcKey,
                  }}
                />
              </div> */
