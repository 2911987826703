import React from "react";
import EconTrendChart from "./EconTrendChart";

export default ({ values, title }) => {
	return (
		<div className="EconTrend">
			<EconTrendChart />
      <div className="font--label--xsmall">{title}</div>
		</div>
	)
}