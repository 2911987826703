import { Component } from 'react';

export default class ScrollToTopOnMount extends Component {
	componentDidMount() {
		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual";
		}
		window.pageYOffset > 60 && window.scrollTo(0, 60)
	}

	render() {
		return null
	}
}
