export default function(datasets) {
	const
		operations = require("../../../operations.js")(datasets),
		formulas = require("../../../formulas.js")(datasets),
		startYear = datasets.startYear

	const data = {
		"Baseline Marginal Tax Rate On Capital (CBO)": {
			"sheet": "transformed cbo ltecon",
			"column": "bl_tax_capital_percent"
		},
		"CAPTAX_RATIO": {
			"values": {},
			"update": function(year) {
				this.values[year] = datasets.BUDGET.CAPITAL_TAXES.values[year]/datasets.BUDGET_INITIAL.CAPITAL_TAXES.values[year]
			}
		},
		"Baseline Marginal Tax Rate On Capital": {
			"formula": function() {
				return {
					[startYear]: data["Baseline Marginal Tax Rate On Capital (CBO)"].values[startYear]
				}
			},
			"update": function(year) {
				this.values[year] = data["Baseline Marginal Tax Rate On Capital (CBO)"].values[year] * data.CAPTAX_RATIO.values[year]
			}
		},
		"Benchmark Marginal Tax Rate on Capital": {
			"sheet": "transformed cbo ltecon",
			"column": "bm_tax_capital_percent"
		},
		"Percent change in after tax interest rates from increase in taxes on capital from the final year of the 10-year budget window": {
			"formula": function() {
				const divisor = operations.sumValuesConst( operations.negate(operations.yearShift(data["Baseline Marginal Tax Rate On Capital"].values, -1)), 1),
					dividend = operations.sumValuesConst( operations.negate(operations.yearShift(data["Benchmark Marginal Tax Rate on Capital"].values, -1)), 1)

				return operations.growth(divisor,dividend)
			},
			"update": function(year) {
				//TODO - make this yearly instead of recomputing all years every year
				const divisor = operations.sumValuesConst( operations.negate(operations.yearShift(data["Baseline Marginal Tax Rate On Capital"].values, -1)), 1),
					dividend = operations.sumValuesConst( operations.negate(operations.yearShift(data["Benchmark Marginal Tax Rate on Capital"].values, -1)), 1)

				this.values = operations.growth(divisor,dividend)
			}
		},
		"Percent Impact on Savings from Higher Tax Rates": {
			"formula": function() {
				const results = operations.productValuesConst(
					data["Percent change in after tax interest rates from increase in taxes on capital from the final year of the 10-year budget window"].values,
					datasets.TECHNICAL_ASSUMPTIONS["Elasticity of Private Saving Rate to After-Tax Interest Rate"]
				)

				results[startYear] = 0;

				return results
			},
			"update": function(year) {
				//TODO - make this yearly instead of recomputing all years every year
				this.values = operations.productValuesConst(
					data["Percent change in after tax interest rates from increase in taxes on capital from the final year of the 10-year budget window"].values,
					datasets.TECHNICAL_ASSUMPTIONS["Elasticity of Private Saving Rate to After-Tax Interest Rate"]
				)
			}
		},
		"Budget Calculator Surplus Projection (Nominal)": {
			"formula": function() {
				return formulas.kickoff(datasets.BUDGET.SURPLUS.values)
			},
			"update": function(year) {
				this.values[year] = datasets.BUDGET.SURPLUS.values[year]
			}
		},
		"Budget Calculator Surplus Projection (Percent of Benchmark GDP)": {
			"formula": function() {
				return operations.quotientValues(data["Budget Calculator Surplus Projection (Nominal)"].values, datasets.ECON_BENCH["Nominal GDP"].values)
			},
			"update": function(year) {
				this.values[year] = data["Budget Calculator Surplus Projection (Nominal)"].values[year]/datasets.ECON_BENCH["Nominal GDP"].values[year]
			}
		},
		"Benchmark Surplus (Nominal)": {
			"formula": function() {
				return { ...data["Budget Calculator Surplus Projection (Nominal)"].values }
			},
			"update": function(year) {
				if (year <= startYear + 10) {
					this.values[year] = datasets.CBO_10["Surplus: Total"].values[year] * (datasets.ECON_BENCH["GDP Price Index"].values[year]/datasets.ECON_CBO_ASSUMED["GDP Price Index"].values[year])
				} else {
					const y10ofgdp = this.values[startYear + 10]/datasets.ECON_BENCH["Nominal GDP"].values[startYear + 10]
					this.values[year] = y10ofgdp * datasets.ECON_BENCH["Nominal GDP"].values[year]
				}
			}
		},
		"Percent Change in Surplus from Budget Calculator Projection to Benchmark Surplus": {
			"formula": function() {

				var results = operations.quotientValues(data["Budget Calculator Surplus Projection (Nominal)"].values, data["Benchmark Surplus (Nominal)"].values)
					results = operations.sumValuesConst(results, -1)
					return results;
			},
			"update": function(year) {
				this.values[year] = data["Budget Calculator Surplus Projection (Nominal)"].values[year]/data["Benchmark Surplus (Nominal)"].values[year] - 1
			}
		},
		"Percent impact on Savings from Higher Deficits": {
			"values": {},
			"update": function(year) {
				this.values[year] = data["Percent Change in Surplus from Budget Calculator Projection to Benchmark Surplus"].values[year - 1] * datasets.TECHNICAL_ASSUMPTIONS["Elasticity of savings with respect to the deficit"]
			}
		},
		"Combined impact on savings rate from reduced deficits and lower capital tax rates": {
			"values": {},
			"update": function(year) {
				this.values[year] = (data["Percent impact on Savings from Higher Deficits"].values[year] + 1)*(data["Percent Impact on Savings from Higher Tax Rates"].values[year] + 1) - 1
			}
		},
		"Benchmark Capital Stock Index": {
			"formula": function() {
				const results = {}
				results[datasets.startYear - 1] = 100

				for (var i=(datasets.startYear); i<=datasets.endYear; i++) {
					results[i] = results[i-1] * (1 + datasets.ECON_BENCH["Capital Growth"].values[i])
				}

				return results;
			}
		},
		"Benchmark Savings Index": {
			"values": {},
			"update": function(year) {
				this.values[year] = data["Benchmark Capital Stock Index"].values[year] - data["Benchmark Capital Stock Index"].values[year-1] * (1 - datasets.TECHNICAL_ASSUMPTIONS["Assumed depreciation of capital rate"])
			}
		},
		"Baseline Savings Level": {
			"values": {},
			"update": function(year) {
				this.values[year] = data["Benchmark Savings Index"].values[year] * (1 + data["Combined impact on savings rate from reduced deficits and lower capital tax rates"].values[year])
			},
		},
		"Baseline Capital Stock Index": {
			"formula": function() {
				const results = {}
				results[datasets.startYear] = 100
				return results
			},
			"update": function(year) {
				this.values[year] = this.values[year-1] * (1 - datasets.TECHNICAL_ASSUMPTIONS["Assumed depreciation of capital rate"]) + data["Baseline Savings Level"].values[year]
			}
		},
		"Baseline Capital Growth": {
			"values": {},
			"update": function(year) {
				this.values[year] = (data["Baseline Capital Stock Index"].values[year]/data["Baseline Capital Stock Index"].values[year - 1]) - 1
			}
		},
		"Baseline Marginal Tax Rate On Labor (CBO)": {
			"sheet": "transformed cbo ltecon",
			"column": "bl_tax_labor_percent"
		},
		"LABORTAX_RATIO": {
			"values": {},
			"update": function(year) {
				this.values[year] = datasets.BUDGET.LABOR_TAXES.values[year]/datasets.BUDGET_INITIAL.LABOR_TAXES.values[year]
			}
		},
		"Baseline Marginal Tax Rate On Labor": {
			"formula": function() {
				return {
					[startYear]: data["Baseline Marginal Tax Rate On Labor (CBO)"].values[startYear]
				}
			},
			"update": function(year) {
				this.values[year] = data["Baseline Marginal Tax Rate On Labor (CBO)"].values[year] * data.LABORTAX_RATIO.values[year]
			}
		},
		"Benchmark Marginal Tax Rate on Labor": {
			"sheet": "transformed cbo ltecon",
			"column": "bm_tax_labor_percent"
		},
		"Percent Change in After-Tax Wages from Higher Taxes on Labor": {
			"formula": function() {
				const lastYearBase = operations.yearShift(data["Baseline Marginal Tax Rate On Labor"].values, -1),
					lastYearBench = operations.yearShift(data["Benchmark Marginal Tax Rate on Labor"].values, -1),
					divisor = operations.sumValuesConst( operations.negate(lastYearBase), 1),
					dividend = operations.sumValuesConst( operations.negate(lastYearBench), 1)

				const results = operations.growth(divisor,dividend)
				results[startYear] = 0
				return results
			},
			"update": function(year) {
				//TODO stop recomputing every year
				const lastYearBase = operations.yearShift(data["Baseline Marginal Tax Rate On Labor"].values, -1),
					lastYearBench = operations.yearShift(data["Benchmark Marginal Tax Rate on Labor"].values, -1),
					divisor = operations.sumValuesConst( operations.negate(lastYearBase), 1),
					dividend = operations.sumValuesConst( operations.negate(lastYearBench), 1)

				this.values = {
					[startYear]: 0,
					...operations.growth(divisor,dividend)
				}
			}
		},
		"Annual Change in Labor Supply Growth Due to Higher Taxes": {
			"values": {},
			"update": function(year) {
				const thisYear = 1 + data["Percent Change in After-Tax Wages from Higher Taxes on Labor"].values[year],
					lastYear = 1 + data["Percent Change in After-Tax Wages from Higher Taxes on Labor"].values[year - 1],
					ratio = thisYear/lastYear - 1

				this.values[year] = ratio * datasets.TECHNICAL_ASSUMPTIONS["Elasticity of Labor Supply to After-Tax Wage"]
			}
		}
	}

	return data
}