export default function(datasets) {
	const
		normalize = require("../../../normalize.js"),
		operations = require("../../../operations.js")(datasets),
		startYear = datasets.startYear

	const data = {
		"Medicare: Enrollment": {
			"sheet": "cms_medicareenrollment",
			"column": "Total",
			"normalize": normalize.millions
		},
		"Medicare: Enrollment Growth Rate": {
			"formula": function() {
				const thisYear = data["Medicare: Enrollment"].values,
					lastYear = operations.yearShift(thisYear, -1)

				return operations.sumValuesConst(operations.quotientValues(thisYear,lastYear), -1)
			}
		},
		"Medicare: Increase from Changes in Age/Gender Mix": {
			"formula": function() {
				//TODO connect to table
				//SOURCE: Changed to residual calculation from CBO Long-Term Outlook (see DLH notes)
				const results = {};
				for (var i=startYear+10; i<=datasets.endYear; i++) {
					results[i] = i < 2043 ? .006 : .006
				}
				return results
			}
		},
		"Medicare: Excess Cost Rate": {
			"sheet": "cbo_lthcexcesscost",
			"column": "Medicare",
			"normalize": normalize.percent
		},
		"Medicaid: Enrollment Growth Rate": {
			"formula": function() {
				//Assumption is Enrollment is consistant with general pop growth
				const results = {};
				for (var i=startYear+10; i<=datasets.endYear; i++) {
					const
						thisYearPop = data["Population: Total (1000s)"].values[i],
						lastYearPop = data["Population: Total (1000s)"].values[i - 1]

					results[i] = (thisYearPop - lastYearPop)/lastYearPop
				}
				return results
			}
		},
		"Medicaid: Excess Cost Rate": {
			"sheet": "cbo_lthcexcesscost",
			"column": "Medicaid",
			"normalize": normalize.percent
		},
		//dependencies for Exchanges: Under 65 Population
		"Population: Total (1000s)": {
			"sheet": "ssa_5a3",
			"column": "Population: Total (1000s)",
			"normalize": normalize.thousands
		},
		"Population: 65 and over (1000s)": {
			"sheet": "ssa_5a3",
			"column": "Population: 65 and over (1000s)",
			"normalize": normalize.thousands
		},
		"Exchanges: Under 65 Population": {
			"formula": () => (operations.differenceValues(data["Population: Total (1000s)"].values, [data["Population: 65 and over (1000s)"].values]))
		},
		"Exchanges: Enrollment Growth Rate": {
			"formula": () => (operations.yearOverYear(data["Exchanges: Under 65 Population"].values))
		},
		"Exchanges: Private Excess Cost Rate": {
			"sheet": "cbo_lthcexcesscost",
			"column": "Private",
			"normalize": normalize.percent
		}
	}

	return data
}