import React from "react";
import { axisPropsFromTickScale } from 'react-d3-axis';

export default ({
  xScale,
  yScale,
  frequency,
  highlight,
  boxScale,
  yFormat
}) => {
  const
    yRange = yScale.range(),
    xRange = xScale.range(),
    lines = [], text= []

  axisPropsFromTickScale(yScale,4).values.forEach((i) => {
    const
      y = yScale(i),
      className = ""
    lines.push(
      <line
        className={className}
        key={'line' + i}
        x1={xRange[0] - 5/boxScale}
        x2={xRange[1]}
        y1={y}
        y2={y} />)
    text.push(
      <text
        className={className}
        fontSize={14/boxScale}
        key={'text' + i}
        x={xRange[0] - 8/boxScale}
        y={y}
        textAnchor="end"
        dominantBaseline="middle">
        {yFormat(i)}
      </text>
    )
  })

  return (
    <g className="font--label--small">
      <line x1={xRange[0]} x2={xRange[0]} y1={yRange[0]} y2={yRange[1]} />
      {lines}
      {text}
    </g>
  )
}