import React from "react";
import ReactResponsiveSelect from 'react-responsive-select';
import 'react-responsive-select/dist/ReactResponsiveSelect.css';
import SelectCaret from 'components/icons/SelectCaret';

const Choice = ({ label, genre }) => (
  <div className="option-inner">
    <div className={`option-inner-marker bg--${genre}`}></div>
    {label}
  </div>
)
const choices = [
  {
    text: 'Federal Debt',
    value: "NET_DEBT",
    markup: (
      <Choice label="Federal Debt" genre="debt" />
    ),
  },
  {
    text: 'Total Spending',
    value: "TOTAL_SPENDING",
    markup: (
      <Choice label="Total Spending" genre="spending" />
    ),
  },
  {
    text: 'Total Revenue',
    value: "TOTAL_REVENUE",
    markup: (
      <Choice label="Total Revenue" genre="revenue" />
    ),
  },
  {
    text: 'Surplus/Deficit',
    value: "SURPLUS",
    markup: (
      <Choice label="Surplus/Deficit" genre="debt" />
    ),
  },
  {
    text: 'Nominal GDP',
    value: "Nominal GDP",
    markup: (
      <Choice label="Nominal GDP" genre="economy" />
    ),
  },
  {
    text: 'Net Interest',
    value: 'Net Interest',
    markup: (
      <Choice label="Net Interest" genre="spending" />
    ),
  }
]

export default ({
  selectedValue,
  className,
  onChange,
  style
}) => (
  <div className="chart-data-select">
    <ReactResponsiveSelect
      customLabelRenderer={selectedOption => (
        <div
          className={className}
          style={style}>
          {selectedOption.text}
        </div>
      )}
      options={choices}
      selectedValue={selectedValue}
      caretIcon={<SelectCaret />}
      onChange={(option) => {
        onChange(option.value)
      }} />
  </div>
)