import React from "react";
import SuperHeroChart from "components/HeroChart/super-hero-chart";

const Projections = ({ user, baseline, displayYear, isMobile }) => {
  return (
    <div className="Report-projections report-module">
      <h2 className="font--h1">30 Year Projections</h2>
      <SuperHeroChart
        mainColumn={"NET_DEBT"}
        showLegend={false}
        showChoices
        user={user}
        baseline={baseline}
        isMobile={isMobile}
        displayYear={displayYear}
      />
    </div>
  );
};

export default Projections;
