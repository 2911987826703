import React from "react";
import "./chart-button-controls.scss";

const choices = [
  {
    text: "Federal Debt",
    value: "NET_DEBT",
  },
  {
    text: "Total Spending",
    value: "TOTAL_SPENDING",
  },
  {
    text: "Total Revenue",
    value: "TOTAL_REVENUE",
  },
  {
    text: "Surplus/Deficit",
    value: "SURPLUS",
  },
  {
    text: "Nominal GDP",
    value: "Nominal GDP",
  },
  {
    text: "Net Interest",
    value: "Net Interest",
  },
];

const ChartButtonControls = ({ onChange, currentValue, getGenre }) => {
  return (
    <header className="chart-header">
      <ul className="ChartButtonControls">
        {choices.map(({ text, value }) => {
          const isCurrent = currentValue === value;
          const genre = getGenre(value);
          return (
            <li className="ChartButtonControls-item" key={value}>
              <button
                className={`ChartButtonControls-item-button ${
                  isCurrent
                    ? `ChartButtonControls-item-button--active border-color--${genre}`
                    : ""
                }`}
                onClick={() => onChange(value)}
              >
                <div
                  className={`ChartButtonControls-item-button-indicator bg--${genre}`}
                />
                {text}
              </button>
            </li>
          );
        })}
      </ul>
    </header>
  );
};

export default ChartButtonControls;
