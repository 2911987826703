import React, { Component } from 'react';
import Logo from "./icons/AmericaOffBalanceIcon"

export default class Loading extends Component {
  render() {
    return (
      <div style={{ alignItems: "center", flexDirection: "column", justifyContent: "center", display: "flex", padding: "25px" }}>
        <div style={{ minHeight: "50px"}}><Logo animate={500} /></div>
        <div style={{ marginTop: "1em" }}>Loading</div>
      </div>
    );
  }
}
