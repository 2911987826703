import React, { useState } from "react";
import { maritalStatuses } from "Constants";
import { connect } from "react-redux";
import { setMaritalStatus, setIncome } from "Actions";
import "./tax-calc.scss";
import Chart from "./chart";
import { CBO_LABEL, YOU_LABEL } from "Constants";
import InputNumber from "react-input-number";

const formatPercentage = (value) => {
  return `${(Math.round(1000 * value) / 10).toFixed(1)}%`;
};

const TaxCalc = ({
  maritalStatus,
  income,
  setMaritalStatus,
  setIncome,
  before,
  startYear,
  chartData,
}) => {
  const [year, setYear] = useState(startYear);
  if (!before) {
    return null;
  }

  const thisYear = {
    before: chartData.before.find(({ x }) => x === year),
    after: chartData.after.find(({ x }) => x === year),
  };

  return (
    <div className="TaxCalc">
      <h2 className="font--h1">Federal Tax calculator</h2>
      <div className="TaxCalc-description">
        See how your taxes may change in the future with and without these
        proposed changes. Your federal taxes include payroll taxes paid by you
        and your employer and personal income taxes.
      </div>
      <div className="TaxCalc-inner">
        <div className="TaxCalc-controls">
          <div className="TaxCalc-controls-heading">Settings</div>
          <div className="TaxCalc-controls-item">
            <div className="TaxCalc-controls-item-label font--label--small faded">
              Filing Status{" "}
            </div>
            <div className="TaxCalc-radio">
              {Object.values(maritalStatuses).map((value) => (
                <label key={value}>
                  <input
                    type="radio"
                    name="maritalstatus"
                    value={value}
                    checked={maritalStatus === value}
                    onChange={() => setMaritalStatus(value)}
                  />
                  <div className="TaxCalc-radio-label">{value}</div>
                </label>
              ))}
            </div>
          </div>
          <label className="TaxCalc-controls-item">
            <div className="TaxCalc-controls-item-label font--label--small faded">
              Income
            </div>
            <div className="TaxCalc-numberinput">
              $
              <InputNumber
                min={0}
                step={1}
                value={income}
                onChange={(value) => setIncome(value)}
                enableMobileNumericKeyboard
              />
            </div>
          </label>
          <div className="TaxCalc-controls-result">
            <div className="TaxCalc-controls-item-label font--label--small faded">
              {startYear} tax rate
            </div>
            <div className="TaxCalc-value">
              {formatPercentage(
                chartData.before.find(({ x }) => x === startYear).y
              )}
            </div>
          </div>
        </div>

        <div className={"TaxCalc-chart"}>
          <div className="TaxCalc-chart-data">
            <Datum
              label="Taxes paid as a share of your income"
              year={thisYear.before.x}
              before={formatPercentage(thisYear.before.y)}
              after={formatPercentage(thisYear.after.y)}
            />
          </div>
          <Chart
            className="TaxCalc-chart-visual"
            data={chartData}
            currentYear={thisYear}
            setYear={setYear}
          />
          <div className="TaxCalc-chart-key font--label--small">
            <span className="color--debt">Before</span>
            <span className="faded">
              {" "}
              is calculated based on CBO projections.{" "}
            </span>
            <span className="color--user">After</span>
            <span className="faded">
              {" "}
              is calculated with proposed changes to budget.
            </span>
          </div>
        </div>
        {/* <div className={"TaxCalc-results"}>
          <Datum
            year={startYear}
            before={formatPercentage(before.startLiability / before.startAgi)}
            after={formatPercentage(after.startLiability / after.startAgi)}
          />
          <Datum
            year={endYear}
            before={formatPercentage(before.endLiability / before.endAgi)}
            after={formatPercentage(after.endLiability / after.endAgi)}
          />
        </div> */}
      </div>
    </div>
  );
};

const Datum = ({ year, before, after, label }) => {
  return (
    <div className="category-header-values">
      {label && <div className="category-header-values-label">{label}</div>}
      <div className="category-header-value-item">
        <span className="label font--label--small faded">Year</span>
        <div className="value color--black font--number">{year}</div>
      </div>
      <div className="category-header-value-item">
        <span className="label font--label--small faded">{CBO_LABEL}</span>
        <div className={`value font--number color--debt`}>
          <div>{before}</div>
        </div>
      </div>
      <div className="category-header-value-item">
        <span className="label font--label--small faded">{YOU_LABEL}</span>
        <div className="value color--user font--number">
          <div>{after}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user, baseline } = state.calculator;

  if (!baseline.TAX_CALC.baseline || !user.TAX_CALC.baseline) {
    return {};
  }

  const { startYear, endYear } = baseline;
  const baselineTC = baseline.TAX_CALC.baseline;
  const userTC = user.TAX_CALC.baseline;

  const chartData = { before: [], after: [] };
  for (let year = startYear; year <= endYear; year++) {
    chartData.before.push({
      x: year,
      y: baselineTC.total_atr.values[year],
    });
    chartData.after.push({
      x: year,
      y: userTC.total_atr.values[year],
    });
  }

  return {
    maritalStatus: state.calculator.taxCalcOptions.maritalStatus,
    income: state.calculator.taxCalcOptions.income,
    chartData,
    before: {
      startLiability: baselineTC.total_liability.values[startYear],
      endLiability: baselineTC.total_liability.values[endYear],
      cumulativeLiability: baselineTC.total_liability_npv.values[endYear],
      startAgi: baselineTC.agi.values[startYear],
      endAgi: baselineTC.agi.values[endYear],
    },
    after: {
      startLiability: userTC.total_liability.values[startYear],
      endLiability: userTC.total_liability.values[endYear],
      cumulativeLiability: userTC.total_liability_npv.values[endYear],
      startAgi: userTC.agi.values[startYear],
      endAgi: userTC.agi.values[endYear],
    },
    startYear,
    endYear,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setMaritalStatus: (value) => {
    dispatch(setMaritalStatus(value));
  },
  setIncome: (value) => {
    dispatch(setIncome(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaxCalc);
