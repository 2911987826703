import DEFS from "../../definitions.js";

export default {
	"economic": {
		CBO_GROWTH: DEFS.CBO_RATE,
		HISTORICAL_GROWTH: DEFS.HISTORICAL_RATE,
		ZERO_GROWTH: DEFS.ZERO_RATE
	},
	"budgetary": {
		CBO_GROWTH: DEFS.CBO_GROWTH,
		ZERO_GROWTH: DEFS.ZERO_GROWTH,
		INFLATION_GROWTH: DEFS.INFLATION_GROWTH,
		GDP_GROWTH: DEFS.GDP_GROWTH
	}
}