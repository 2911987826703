import React, { Component } from "react";
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import CategoryTrail from './CategoryTrail';

import Category from './Category';
import CategoryHeader from './CategoryHeader';

import Tooltip from "react-tooltip";

import { flattenTree, childCategories } from "../categoryFunctions";

import {
  setGrowthRate,
  setGrowthRates,
  setGrowthRateAdjustment,
  updateGrowthRateAdjustment,
  updateGrowthRateAdjustments,
  setGrowthRateAddition,
  updateGrowthRateAddition,
  updateGrowthRateAdditions
} from '../Actions';

class Group extends Component {
  constructor(props) {
    super(props)
    this.flatCats = flattenTree(this.props.categories);
    this.onGrowthRateChange = this.onGrowthRateChange.bind(this)
  }

  componentDidMount() {
    Tooltip.rebuild()
  }

  onGrowthRateChange(handle) {
    const
      children = childCategories(handle,this.flatCats),
      { setGrowthRate, setGrowthRates } = this.props
    return (value) => {
      setGrowthRate(value,handle)

      const updates = children.map((d) => {
        return {
          value: value,
          category: d.key
        }
      })
      setGrowthRates(updates)
    }
  }

  onGrowthRateAdjustmentChange(handle) {
    const children = childCategories(handle,this.flatCats)
    return (newValue,oldValue) => {
      this.props.setGrowthRateAdjustment(newValue,handle)

      const differenceValue = newValue - oldValue

      const updates = children.map((d) => {
        return {
          value: differenceValue,
          category: d.key
        }
      })
      this.props.updateGrowthRateAdjustments(updates)
    }
  }

  onGrowthSliderChange(handle) {
    const children = childCategories(handle,this.flatCats)
    return (newValue,oldValue) => {
      this.props.setGrowthRateAddition(newValue, handle)

      const differenceValue = newValue - oldValue

      const updates = children.map((d) => {
        return {
          value: differenceValue,
          category: d.key
        }
      })
      this.props.updateGrowthRateAdditions(updates)
    }
  }

  render() {
    const {
      handle,
      genre,
      cats,
      userOptions,
      definitions,
      userChangeMade
    } = this.props

    const mappedCats = cats.map((d) => {
      const growthKey = d.subCats.length > 1 ? d.key : d.subCats[0].key;
      return (
        <Category
          userChangeMade={userChangeMade}
          key={d.categoryTitle}
          {...d}
          genre={genre}
          growthKey={growthKey}
          onGrowthRateAdjustmentChange={this.onGrowthRateAdjustmentChange(growthKey)}
          onGrowthRateChange={this.onGrowthRateChange(growthKey)}
          onGrowthSliderChange={this.onGrowthSliderChange(growthKey)} />
      )
    })

    const
      growthRateValue = userOptions[handle].growthRate,
      growthRateAdjustmentValue = userOptions[handle].growthRateAdjustment,
      growthRateAdditionValue = userOptions[handle].growthRateAddition

    return (
      <div className={`Group ${genre} shadow bg--white`}>
        <CategoryHeader
          className={`Group-header outer-container`}
          title={<h2 className={`genre-heading font--h2 bold color--${genre}`}>{definitions[handle]}</h2>}
          onGrowthRateChange={this.onGrowthRateChange(handle)}
          growthRateValue={growthRateValue}
          onGrowthRateAdjustmentChange={this.onGrowthRateAdjustmentChange(handle)}
          growthRateAdjustmentValue={growthRateAdjustmentValue}
          onGrowthSliderChange={this.onGrowthSliderChange(handle)}
          growthRateAdditionValue={growthRateAdditionValue}
          toggleSettings />
        <CategoryTrail>{mappedCats}</CategoryTrail>
      </div>
    )
  }
}

Group.contextTypes = {
  store: PropTypes.object
}
const mapStateToProps = (state) => ({
  categories: state.calculator.categories,
  definitions: state.calculator.definitions,
  userOptions: state.calculator.userOptions
})
const mapDispatchToProps = (dispatch) => {
  return {
    setGrowthRate: (value, category) => {
      dispatch(
        setGrowthRate(value, category)
      )
    },
    setGrowthRates: (values) => {
      dispatch(
        setGrowthRates(values)
      )
    },
    setGrowthRateAdjustment: (value, category) => {
      dispatch(
        setGrowthRateAdjustment(value, category)
      )
    },
    updateGrowthRateAdjustment: (value, category) => {
      dispatch(
        updateGrowthRateAdjustment(value, category)
      )
    },
    updateGrowthRateAdjustments: (adjustments) => {
      dispatch(
        updateGrowthRateAdjustments(adjustments)
      )
    },
    setGrowthRateAddition: (value, category) => {
      dispatch(
        setGrowthRateAddition(value, category)
      )
    },
    updateGrowthRateAddition: (value, category) => {
      dispatch(
        updateGrowthRateAddition(value, category)
      )
    },
    updateGrowthRateAdditions: (values) => {
      dispatch(
        updateGrowthRateAdditions(values)
      )
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Group);
