import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import { Link } from "react-router-dom";

import { loadCalc, setDisplayYear } from "../Actions.js";

import { confirmAlert } from "react-confirm-alert";

import HeroChart from "./HeroChart";
import DisplayYearSlider from "./DisplayYearSlider";
import BudgetChanges from "./BudgetChanges";
import BudgetSnapshot from "./BudgetSnapshot";
import ScrollToTopOnMount from "./ScrollToTopOnMount";

import { DiscussionEmbed } from "disqus-react";

import ShareModal from "./ShareModal";
import Loading from "./Loading.js";
import EditIcon from "./icons/DescribeIcon";
import CalculatorIcon from "./icons/CalculatorIcon";
import DownloadIcon from "./icons/DownloadIcon";
import ShareIcon from "./icons/ShareIcon";

import difference, { isolateChanges } from "../difference";

import Eagle from "../assets/16.2.png";

import { formatPercentageDelta } from "../formatDollars";

import { selectVersionLabel } from "versions.js";

import firebase from "firebase/app";
import "firebase/database";
const { database } = firebase;

const ConvertToCSV = (array) => {
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line !== "") line += ",";

      line += array[i][index] !== undefined ? array[i][index] : "";
    }

    str += line + "\r\n";
  }

  return str;
};

const convertCalcToCSV = (calc, definitions, varsToInclude) => {
  const flat = { ...flattenValues(calc.ECON), ...flattenValues(calc.BUDGET) };
  Object.keys(flat).forEach((cat) => {
    if (!varsToInclude.includes(cat)) {
      delete flat[cat];
    }
  });
  const valuesByYear = getValuesByYear(
    Object.values(flat),
    calc.startYear,
    calc.endYear
  );
  const result = [
    [
      "year",
      ...Object.keys(flat).map((cat) =>
        definitions[cat] ? definitions[cat] : cat
      ),
    ],
    ...valuesByYear,
  ];
  const string = ConvertToCSV(result);
  return string;
};

const getValuesByYear = (values, startYear, endYear) => {
  const years = Object.keys(values[0]).filter(
    (d) => d >= startYear && d <= endYear
  );

  const result = years.reduce((allYearValues, year) => {
    const yearValues = Object.values(values).reduce(
      (r, cat) => {
        r.push(cat[year]);
        return r;
      },
      [year]
    );

    allYearValues.push(yearValues);

    return allYearValues;
  }, []);

  return result;
};

const flattenValues = (obj) => {
  return Object.keys(obj).reduce((r, d) => {
    r[d] = obj[d].values;
    return r;
  }, {});
};

class ViewBudget extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  componentWillMount() {
    const databasor = database();

    this.loadCalcFromDatabaseIfNeeded(
      this.props.calcKey,
      this.props.match.params.calcKey,
      databasor,
      this.props.loadCalc
    );
  }

  loadCalcFromDatabaseIfNeeded(currentKey, newKey, databasor, loadCalc) {
    console.log("loading from database");

    databasor
      .ref(`/calculators/${newKey}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          const calc = snapshot.val().calc;
          loadCalc(calc);
          this.setState({
            loading: false,
          });
        }
      });
  }

  render() {
    const {
      title,
      description,
      startYear,
      endYear,
      user,
      baseline,
      userOptions,
      baselineOptions,
      authorUid,
      authorName,
      calcKey,
      loggedInUser,
      displayYear,
      budgetOptionsById,
      definitions,
      toggleEditBudgetMeta,
      isMobile,
      version,
    } = this.props;

    const loggedInUserIsOwner = loggedInUser && authorUid === loggedInUser.uid;

    return this.state.loading ? (
      <Loading />
    ) : (
      <Overview
        loggedInUserIsOwner={loggedInUserIsOwner}
        calcKey={calcKey}
        isMobile={isMobile}
        title={title}
        description={description}
        toggleEditBudgetMeta={toggleEditBudgetMeta}
        startYear={startYear}
        endYear={endYear}
        user={user}
        baseline={baseline}
        userOptions={userOptions}
        baselineOptions={baselineOptions}
        budgetOptionsById={budgetOptionsById}
        authorName={authorName}
        displayYear={displayYear}
        definitions={definitions}
        version={version}
      />
    );
  }
}
ViewBudget.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => ({
  baseline: state.calculator.baseline,
  user: state.calculator.user,
  baselineOptions: state.calculator.baselineOptions,
  userOptions: state.calculator.userOptions,
  startYear: state.calculator.startYear,
  endYear: state.calculator.endYear,
  title: state.calculator.title,
  version: state.calculator.version,
  description: state.calculator.description,
  calcKey: state.calculator.calcKey,
  authorUid: state.calculator.authorUid,
  authorName: state.calculator.authorName,
  budgetOptionsById: state.calculator.budgetOptionsById,
  definitions: state.calculator.definitions,
  displayYear: state.session.displayYear,
});
const mapDispatchToProps = (dispatch) => {
  return {
    loadCalc: (calc) => {
      dispatch(loadCalc(calc));
      dispatch(setDisplayYear(calc.endYear));
    },
    setDisplayYear: (year) => {
      dispatch(setDisplayYear(year));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewBudget);

class Overview extends Component {
  constructor() {
    super();
    this.state = {
      shareIsOpen: false,
    };

    this.toggleShare = this.toggleShare.bind(this);
  }

  toggleShare() {
    this.setState({
      shareIsOpen: !this.state.shareIsOpen,
    });
  }

  render() {
    const {
      title,
      description,
      startYear,
      endYear,
      user,
      baseline,
      userOptions,
      baselineOptions,
      budgetOptionsById,
      authorName,
      displayYear,
      definitions,
      loggedInUserIsOwner,
      toggleEditBudgetMeta,
      calcKey,
      isMobile,
      version,
    } = this.props;

    const userGdp = user.ECON["Nominal GDP"].values[endYear],
      baselineGdp = baseline.ECON["Nominal GDP"].values[endYear];

    const debtOfGdp =
      Math.round(100 * (user.BUDGET.NET_DEBT.values[endYear] / userGdp)) + "%";

    const userDebt = user.BUDGET.NET_DEBT.values,
      baselineDebt = baseline.BUDGET.NET_DEBT.values;

    const debtDelta =
      100 * (userDebt[endYear] / userGdp - baselineDebt[endYear] / baselineGdp);

    const changes = difference(userOptions, baselineOptions);
    let isolatedChanges = isolateChanges(changes);
    if (changes.policiesEnacted) {
      changes.policiesEnacted.forEach((d) => {
        const policy = budgetOptionsById[d.id];
        if (!policy.hide) {
          //initialize if empty
          isolatedChanges[policy.category] =
            isolatedChanges[policy.category] || {};
          isolatedChanges[policy.category].policiesEnacted =
            isolatedChanges[policy.category].policiesEnacted || [];

          isolatedChanges[policy.category] = {
            ...isolatedChanges[policy.category],
            policiesEnacted: [
              policy,
              ...isolatedChanges[policy.category].policiesEnacted,
            ],
          };
        }
      });
    }

    const displayTitle = title
      ? title
      : authorName
      ? authorName + "'s budget"
      : "Untitled Budget";

    const versionLabel = selectVersionLabel(version);

    return (
      <article className="ViewBudget">
        <ScrollToTopOnMount />
        <div className="ViewBudget-header page-pad--large bg--user">
          <div className="ViewBudget-icon">
            <img alt="" src={Eagle} />
          </div>
          <div className="ViewBudget-meta font--body">
            <h1 className="title font--h1--huge">{displayTitle}</h1>
            <aside className="author">by {authorName}</aside>
            {description && <p className="description bold">{description}</p>}
            {loggedInUserIsOwner && (
              <button className="button-inline" onClick={toggleEditBudgetMeta}>
                <EditIcon />
                Edit title and description
              </button>
            )}
            <p className="notes font--body--small faded">
              This budget was created on the America Off Balance Budget
              Calculator, produced by the Hoover Institution to put the
              challenge of solving the nation’s debt crisis in the hands of
              every American.
            </p>
            {versionLabel && (
              <p className="notes font--body--small faded">
                It is based on Congressional Budget Office (CBO) data from{" "}
                {versionLabel}
              </p>
            )}
          </div>
          <div className="ViewBudget-outlook-debt">
            <div className="ViewBudget-outlook-debt-value">
              <div className="font--body color--label">{`${endYear} Federal Debt`}</div>
              <div className="font--number--huge color--white">{debtOfGdp}</div>
              <div className="font--body color--label">of GDP</div>
            </div>
            {debtDelta !== 0 && (
              <div className="ViewBudget-outlook-debt-value">
                <div className="font--body color--label">{`${endYear} Debt Change`}</div>
                <div className="font--number--huge color--debt">
                  {formatPercentageDelta(debtDelta, 0)}
                </div>
                <div className="font--body color--label">of GDP</div>
              </div>
            )}
          </div>
        </div>
        <div className="ViewBudget-detail">
          <div className="ViewBudget-controls-spacer bg--widget"></div>
          <div className="ViewBudget-controls bg--widget">
            <div className="ViewBudget-control ViewBudget-control-buttons">
              {loggedInUserIsOwner ? (
                <Link className="button-inline" to={`/${calcKey}/edit`}>
                  <CalculatorIcon />
                </Link>
              ) : (
                <Link className="button-inline" to="/new">
                  <CalculatorIcon text="Create a budget" />
                </Link>
              )}
              <button
                className="button-inline download"
                onClick={() => {
                  confirmAlert({
                    customUI: ({ onClose }) => {
                      const baselineTitle = `CBO Projections (${startYear}-${endYear}).csv`;
                      const userTitle = `${displayTitle} (${startYear}-${endYear}).csv`;
                      return (
                        <div className="AlertModal text-align--center">
                          <h1 className="AlertModal-heading font--h1">
                            Download Data
                          </h1>
                          <DownloadList
                            user={user}
                            baseline={baseline}
                            definitions={definitions}
                            userTitle={userTitle}
                            baselineTitle={baselineTitle}
                          />
                          <div className="AlertModal-buttons">
                            <button
                              className="button button--default"
                              onClick={onClose}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      );
                    },
                  });
                }}
              >
                <DownloadIcon />
              </button>
              <button className="button-inline" onClick={this.toggleShare}>
                <ShareIcon />
              </button>
            </div>
            <div className="ViewBudget-control">
              <DisplayYearSlider
                orientation={isMobile ? "horizontal" : "vertical"}
              />
            </div>
          </div>
          <div className="ViewBudget-body">
            <div className="ViewBudget-charts page-pad--large">
              <div className="ViewBudget-snapshot">
                <h1 className={`heading font--h3 bold color--label`}>
                  {displayYear} Projections
                </h1>
                <BudgetSnapshot
                  user={user}
                  baseline={baseline}
                  year={displayYear}
                />
              </div>
              <div className="ViewBudget-debt">
                <HeroChart
                  mainColumn={"NET_DEBT"}
                  showLegend={false}
                  user={user}
                  baseline={baseline}
                />
              </div>
              <HeroChart
                mainColumn={"TOTAL_REVENUE"}
                showChoices={true}
                showLegend={false}
                user={user}
                baseline={baseline}
              />
              <HeroChart
                mainColumn={"TOTAL_SPENDING"}
                showChoices={true}
                showLegend={false}
                user={user}
                baseline={baseline}
              />
              <HeroChart
                mainColumn={"SURPLUS"}
                showChoices={true}
                showLegend={false}
                user={user}
                baseline={baseline}
              />
            </div>
            <BudgetChanges changes={isolatedChanges} isMobile={isMobile} />
            <div className="page-pad--large">
              <DiscussionEmbed
                shortname="america-off-balance"
                config={{
                  url: window.location.href,
                  identifier: calcKey,
                }}
              />
            </div>
          </div>
        </div>

        <ShareModal isOpen={this.state.shareIsOpen} toggle={this.toggleShare} />
      </article>
    );
  }
}

class DownloadList extends Component {
  constructor() {
    super();

    this.state = {
      links: null,
    };
  }
  componentWillMount() {
    const { user, baseline, definitions } = this.props;
    import("../downloadVariables").then((response) => {
      const varsToInclude = response.default;
      const links = {
        user: convertCalcToCSV(user, definitions, varsToInclude),
        baseline: convertCalcToCSV(baseline, definitions, varsToInclude),
      };

      this.setState({
        links,
      });
    });
  }
  render() {
    const { userTitle, baselineTitle } = this.props;

    return this.state.links ? (
      <ul className="AlertModal-list font--h3">
        <li>
          <a
            className="color--black"
            href={
              "data:text/csv;charset=utf-8," + encodeURI(this.state.links.user)
            }
            download={userTitle}
          >
            {userTitle}
          </a>
        </li>
        <li>
          <a
            className="color--black"
            href={
              "data:text/csv;charset=utf-8," +
              encodeURI(this.state.links.baseline)
            }
            download={baselineTitle}
          >
            {baselineTitle}
          </a>
        </li>
      </ul>
    ) : (
      "Loading"
    );
  }
}
