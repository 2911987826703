import throttle from "lodash/throttle";

import Constants from "./Constants";

const { DATA_STRUCTURES_PATH } = Constants;

const log = throttle((calc, data) => {
  console.log("Calc: ", calc);
  console.log("Raw Data: ", data);
}, 5000);

const render = (CALC_PATH) => (
  settings,
  budgetOptionsById,
  taxCalcSettings
) => {
  const calc = require(CALC_PATH + "processed/calc.json");
  const data = require(CALC_PATH + "processed/data.json");
  const {
    ECON_BENCH,
    ECON_BASE,
    ECON_MACRO,
    SS_OUTLAY,
    HC_COMPONENTS,
    OASDHI,
    BUDGET_INITIAL,
    BUDGET,
    OTHER_MANDATORY,
    WEIGHTED_INTEREST_ON_DEBT,
    TC_BASELINE,
    TC_CHANGE_OPTIONS,
    TC_CHANGE_GROWTHRATES,
  } = require(CALC_PATH + "sheets.js");
  const processors = require("./processors.js")(calc, data);
  const {
    processFormulas,
    processPulls,
    kickoffUpdates,
    processUpdates,
    processLaterUpdates,
  } = processors;

  calc.otherMandatoryChange = require(CALC_PATH +
    DATA_STRUCTURES_PATH +
    OTHER_MANDATORY +
    ".js").default(calc);

  calc.ECON_BENCH = require(CALC_PATH +
    DATA_STRUCTURES_PATH +
    ECON_BENCH +
    ".js").default(calc);
  calc.ECON_BENCH = calc.ECON_BENCH(settings);
  processFormulas(calc.ECON_BENCH);

  calc.SS_OUTLAY = require(CALC_PATH +
    DATA_STRUCTURES_PATH +
    SS_OUTLAY +
    ".js").default(calc);
  processPulls(calc.SS_OUTLAY);
  processFormulas(calc.SS_OUTLAY);

  calc.HC_COMPONENTS = require(CALC_PATH +
    DATA_STRUCTURES_PATH +
    HC_COMPONENTS +
    ".js").default(calc);
  processPulls(calc.HC_COMPONENTS);
  processFormulas(calc.HC_COMPONENTS);

  calc.OASDHI = require(CALC_PATH +
    DATA_STRUCTURES_PATH +
    OASDHI +
    ".js").default(calc);
  processPulls(calc.OASDHI);
  processFormulas(calc.OASDHI);

  calc.BUDGET_INITIAL = require(CALC_PATH +
    DATA_STRUCTURES_PATH +
    BUDGET_INITIAL +
    ".js").default(calc, settings);
  processFormulas(calc.BUDGET_INITIAL);

  calc.WEIGHTED_INTEREST_ON_DEBT = require(CALC_PATH +
    DATA_STRUCTURES_PATH +
    WEIGHTED_INTEREST_ON_DEBT +
    ".js").default(calc);
  processPulls(calc.WEIGHTED_INTEREST_ON_DEBT);
  processFormulas(calc.WEIGHTED_INTEREST_ON_DEBT);

  calc.BUDGET = require(CALC_PATH +
    DATA_STRUCTURES_PATH +
    BUDGET +
    ".js").default(calc, settings, budgetOptionsById);
  processFormulas(calc.BUDGET);

  calc.ECON_MACRO = require(CALC_PATH +
    DATA_STRUCTURES_PATH +
    ECON_MACRO +
    ".js").default(calc);
  processPulls(calc.ECON_MACRO);
  processFormulas(calc.ECON_MACRO);

  calc.ECON_BASE = require(CALC_PATH +
    DATA_STRUCTURES_PATH +
    ECON_BASE +
    ".js").default(calc);
  processFormulas(calc.ECON_BASE);

  if (TC_CHANGE_OPTIONS) {
    calc.TC_CHANGE_OPTIONS = require(CALC_PATH +
      DATA_STRUCTURES_PATH +
      TC_CHANGE_OPTIONS +
      ".js").default(calc, data, settings);
  }
  if (TC_CHANGE_GROWTHRATES) {
    calc.TC_CHANGE_GROWTHRATES = require(CALC_PATH +
      DATA_STRUCTURES_PATH +
      TC_CHANGE_GROWTHRATES +
      ".js").default(calc, data, settings);
  }
  if (TC_BASELINE) {
    calc.TC_BASELINE = require(CALC_PATH +
      DATA_STRUCTURES_PATH +
      TC_BASELINE +
      ".js").default(calc, data, settings, taxCalcSettings);
  }

  //kickoff with initial year
  kickoffUpdates(calc.TC_CHANGE_GROWTHRATES, calc.startYear);
  kickoffUpdates(calc.TC_BASELINE, calc.startYear);

  for (var i = calc.startYear + 1; i <= calc.endYear; i++) {
    processUpdates(calc.WEIGHTED_INTEREST_ON_DEBT, i);

    processUpdates(calc.ECON_MACRO, i);

    processUpdates(calc.ECON_BASE, i);
    processUpdates(calc.BUDGET_INITIAL, i);

    processUpdates(calc.SS_OUTLAY, i);
    processUpdates(calc.OASDHI, i);

    processLaterUpdates(calc.BUDGET_INITIAL, i);
    processLaterUpdates(calc.BUDGET, i);
    processUpdates(calc.ECON_MACRO, i);

    processUpdates(calc.TC_CHANGE_OPTIONS, i);
    processUpdates(calc.TC_CHANGE_GROWTHRATES, i);
    processUpdates(calc.TC_BASELINE, i);
  }

  log(calc, data);

  return {
    BUDGET: trimData(calc.BUDGET),
    ECON: trimData(calc.ECON_BASE),
    TAX_CALC: {
      baseline: calc.TC_BASELINE,
    },
    startYear: calc.startYear,
    endYear: calc.endYear,
  };
};

const trimData = (data) =>
  Object.keys(data).reduce((r, d) => {
    if (data[d].values) {
      r[d] = {
        values: data[d].values,
        valuesWithoutPolicies: data[d].valuesWithoutPolicies || null,
      };
    }
    return r;
  }, {});

export default render;
