import React, { Component } from "react";
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { setDisplayYear } from "../Actions.js";
import BudgetSnapshot from './BudgetSnapshot';
import DisplayYearSlider from "./DisplayYearSlider";

import HeroChart from './HeroChart';

class Projections extends Component {
  render() {
    const {
      user,
      baseline,
      displayYear,
      setDisplayYear,
      definitions
    } = this.props

    const main = (
      <div
        className="Projections">
        <div className="HeaderChart-block-wrap">
          <div className="Sidebar-block HeaderChart-block-year">
            <div className="HeaderChart-controls">
              <aside className="HeaderChart-label font--label--alt">Select Year</aside>
              <div className="display-year-wrap">
                <DisplayYearSlider />
              </div>
            </div>
          </div>
          <div className="Sidebar-block">
            <BudgetSnapshot
              user={user}
              baseline={baseline}
              year={displayYear} />
          </div>
          <div className="Sidebar-block HeaderChart-block-longterm">
            <HeroChart
              className={"in-header"}
              showChoices
              shrinkOnScroll={false}
              user={user}
              baseline={baseline}
              displayYear={displayYear}
              setDisplayYear={setDisplayYear}
              definitions={definitions}
              />
          </div>
        </div>
      </div>
    )

  	return (
      <div className={`HeaderChart-wrap`}>
        {main}
      </div>
  	)
  }
}

Projections.contextTypes = {
  store: PropTypes.object
}

const mapStateToProps = (state) => ({
  user: state.calculator.user,
  baseline:state.calculator.baseline,
  displayYear: state.session.displayYear,
  definitions: state.calculator.definitions
})
const mapDispatchToProps = (dispatch) => ({
  setDisplayYear: (year) => {
    dispatch(setDisplayYear(year))
  }
})

export default connect(mapStateToProps,mapDispatchToProps)(Projections)