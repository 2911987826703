import React from "react";
import { connect } from "react-redux";
import { scaleLinear } from "d3-scale";
import AnimatedRect from "components/AnimatedRect";
import { a, useSpring, config } from "react-spring";
import styles from "./status.module.scss";

const Status = ({ user, baseline, year }) => {
  const userGdp = user.ECON["Nominal GDP"].values[year],
    baselineGdp = baseline.ECON["Nominal GDP"].values[year];

  const yourSpending = user.BUDGET.TOTAL_SPENDING.values[year],
    baselineSpending = baseline.BUDGET.TOTAL_SPENDING.values[year],
    yourRevenue = user.BUDGET.TOTAL_REVENUE.values[year],
    baselineRevenue = baseline.BUDGET.TOTAL_REVENUE.values[year],
    yourDeficit = user.BUDGET.SURPLUS.values[year],
    baselineDeficit = baseline.BUDGET.SURPLUS.values[year];

  const budgetMax = Math.max(
    yourRevenue,
    baselineRevenue,
    yourSpending,
    baselineSpending,
    Math.abs(yourDeficit),
    Math.abs(baselineDeficit)
  );

  const budgetScale = scaleLinear()
    .domain([0, budgetMax])
    .range([0, BAR_WIDTH]);

  const yourDebt = Math.round(
      (100 * user.BUDGET.NET_DEBT.values[year]) / userGdp
    ),
    baselineDebt = Math.round(
      (100 * baseline.BUDGET.NET_DEBT.values[year]) / baselineGdp
    );

  const debtMax = Math.max(yourDebt, baselineDebt);

  const debtScale = scaleLinear().domain([0, debtMax]).range([0, BAR_WIDTH]);

  return (
    <div className={styles.element}>
      <div className={styles.heading}>2050 projection</div>
      <div className={styles.debt}>
        <Debt
          label="Net Debt"
          baselineValue={baselineDebt}
          value={yourDebt}
          scale={debtScale}
          valueClass="debt"
        />
      </div>
      <div className={styles.detail}>
        <div>
          <Category
            label="Revenue"
            baselineValue={baselineRevenue}
            value={yourRevenue}
            scale={budgetScale}
            valueClass="revenue"
          />
        </div>
        <div>
          <Category
            label="Spending"
            baselineValue={baselineSpending}
            value={yourSpending}
            scale={budgetScale}
            valueClass="spending"
          />
        </div>
        <div>
          <Category
            label="Deficit"
            baselineValue={baselineDeficit}
            value={yourDeficit}
            scale={budgetScale}
            valueClass="debt"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.calculator.user,
    baseline: state.calculator.baseline,
    year: state.calculator.baseline.endYear,
  };
};
export default connect(mapStateToProps)(Status);

const BAR_HEIGHT = 12;
const BAR_WIDTH = 204;

const Category = ({ label, value, baselineValue, scale, valueClass }) => {
  const baselineX = scale(Math.abs(baselineValue));
  const fontSize = 12;
  const width = BAR_WIDTH + fontSize * 2;
  const height = BAR_HEIGHT * 1.8;

  const abaseline = useSpring({
    x: baselineX,
    config: config.molasses,
  });
  const indicatorLeft = abaseline.x.interpolate(
    (val) => `${(100 * val) / width}%`
  );

  return (
    <div className={styles.graph}>
      <div className={styles.graphLabel}>{label}: </div>
      <div className={styles.graphWrap}>
        <svg
          className={`baseline`}
          viewBox={`0 0 ${width} ${height}`}
          style={{ width: "100%" }}
        >
          <rect width={width} height={BAR_HEIGHT} fill={"#ebebeb"} />
          <AnimatedRect
            x={0}
            height={BAR_HEIGHT}
            width={scale(Math.abs(value))}
            className={`fill--${valueClass}`}
          />
          <a.line
            x1={abaseline.x}
            x2={abaseline.x}
            y1={0}
            y2={height}
            stroke={"#000"}
            strokeWidth={1}
          />
          {/* <a.text
          x={abaseline.x}
          y={height}
          textAnchor={"middle"}
          fontSize={fontSize}
        >
          Before
        </a.text> */}
        </svg>
        <a.div
          className={styles.graphIndicator}
          style={{
            left: indicatorLeft,
          }}
        >
          Before
        </a.div>
      </div>
    </div>
  );
};

const Debt = ({ label, value, baselineValue, scale, valueClass }) => {
  const baselineX = scale(Math.abs(baselineValue));
  const fontSize = 10;
  const width = BAR_WIDTH + fontSize * 2;
  const barHeight = BAR_HEIGHT * 2;
  const height = barHeight * 1.4;

  const abaseline = useSpring({
    x: baselineX,
    config: config.molasses,
  });

  const indicatorLeft = abaseline.x.interpolate(
    (val) => `${(100 * val) / width}%`
  );

  return (
    <div className={`Status-graph ${styles.debt}`}>
      <div className={styles.graphLabel}>{label}: </div>
      <div className={styles.graphWrap}>
        <svg
          className={`baseline`}
          viewBox={`0 0 ${width} ${height}`}
          style={{ width: "100%" }}
        >
          <defs>
            <linearGradient id="myGradient">
              <stop offset="5%" stopColor="#ff5a42" />
              <stop offset="95%" stopColor="#ffb34d" />
            </linearGradient>
          </defs>
          <rect width={width} height={barHeight} fill={"#000"} />
          <AnimatedRect
            x={0}
            height={barHeight}
            width={scale(Math.abs(value))}
            fill="url('#myGradient')"
          />
          <a.line
            x1={abaseline.x}
            x2={abaseline.x}
            y1={0}
            y2={height}
            stroke={"#000"}
            strokeWidth={1}
          />
          {/* <a.text
          x={abaseline.x}
          y={height}
          textAnchor={"middle"}
          fontSize={fontSize}
        >
          Before
        </a.text> */}
        </svg>
        <a.div
          className={styles.graphIndicator}
          style={{
            left: indicatorLeft,
          }}
        >
          Before
        </a.div>
      </div>
    </div>
  );
};
