import React from "react";

const V1 = () => {
  return (
    <svg height="33" viewBox="0 0 74 33" width="74">
      <g fillRule="evenodd" transform="translate(-1)">
        <path d="m4.59666667 2.87066667-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm8.24400003-2.892 2.256-5.508h2.232v8.4h-1.872v-6.732l-2.736 6.744h-.66l-2.688-6.744v6.732h-1.164v-8.4h2.448zm6.864 2.892v-8.4h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.984zm10.2-7.464v2.94h1.272c1.176 0 1.848-.456 1.848-1.488 0-.948-.648-1.452-1.848-1.452zm0 3.876v3.588h-1.92v-8.4h3.876c1.728 0 3.18.636 3.18 2.268 0 1.416-1.272 2.064-2.628 2.1v.024c1.044.084 1.692.528 2.04 1.5l.396 1.092c.216.564.384.888.78 1.416h-2.16c-.252-.42-.408-.816-.564-1.248l-.468-1.32c-.264-.756-.6-1.02-1.44-1.02zm9.264-4.812v8.4h-1.92v-8.4zm1.716 4.296c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.884-3.432 1.884-2.388 0-4.38-1.596-4.38-4.224zm12.24-1.692-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424z" />
        <path d="m12.4613333 32h-3.65999997v-8.4h3.46799997c1.716 0 3.108.6 3.108 2.076 0 1.224-1.032 1.896-2.184 1.98v.024c1.224.06 2.532.6 2.532 2.052 0 1.632-1.452 2.268-3.264 2.268zm-1.74-7.464v2.688h1.104c1.044 0 1.632-.36 1.632-1.356 0-.96-.564-1.332-1.62-1.332zm1.2 3.612h-1.2v2.916h1.236c1.176 0 1.848-.372 1.848-1.428s-.672-1.488-1.884-1.488zm8.424-1.944-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm3.612 0v-8.4h1.92v7.416h4.104v.984zm10.8-5.796-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm9.708-3.324v-5.076h1.152v8.412h-.756l-5.328-6.348v6.336h-1.164v-8.4h1.86zm2.88-.78c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.884-3.432 1.884-2.388 0-4.38-1.596-4.38-4.224zm9.36 4.104v-8.4h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.984z" />
        <path d="m49.772 15.8c0-2.496 1.944-4.32 4.38-4.32s4.38 1.824 4.38 4.32-1.944 4.32-4.38 4.32-4.38-1.824-4.38-4.32zm2.148 0c0 2.196.708 3.456 2.232 3.456s2.232-1.26 2.232-3.456-.708-3.456-2.232-3.456-2.232 1.26-2.232 3.456zm8.328 4.2v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78zm7.848 0v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78z" />
      </g>
    </svg>
  );
};

const V2 = () => {
  return (
    <svg height="38" viewBox="0 0 78 38" width="78">
      <g fillRule="evenodd" transform="translate(-2 -1)">
        <path
          d="m4.20484302 11.1630394-1.236 2.8552131h2.52zm2.628 5.8435082-.948-2.1051148h-3.288l-.912 2.1051148h-1.116l3.744-8.4930492h.72l3.792 8.4930492zm8.24399998-2.9157049 2.256-5.55314758h2.232v8.46885248h-1.872v-6.7871803l-2.736 6.7992786h-.66l-2.688-6.7992786v6.7871803h-1.164v-8.46885248h2.448zm6.864 2.9157049v-8.46885248h6.192v.99206558h-4.272v2.6011475h3.084v.9920656h-3.084v2.8915082h4.488v.9920656zm10.2-7.52518035v2.96409835h1.272c1.176 0 1.848-.4597377 1.848-1.5001967 0-.9557705-.648-1.46390165-1.848-1.46390165zm0 3.90777045v3.6174099h-1.92v-8.46885248h3.876c1.728 0 3.18.64121312 3.18 2.28659018 0 1.4276065-1.272 2.080918-2.628 2.1172131v.0241967c1.044.0846885 1.692.5323279 2.04 1.5122951l.396 1.1009508c.216.568623.384.8952787.78 1.4276066h-2.16c-.252-.4234426-.408-.8226885-.564-1.2582295l-.468-1.3308197c-.264-.7621967-.6-1.0283607-1.44-1.0283607zm9.264-4.85144258v8.46885248h-1.92v-8.46885248zm1.716 4.33121308c0-2.6616393 1.992-4.45219668 4.464-4.45219668 1.548 0 2.712.68960655 3.348 1.77845898l-.708.7863935c-.516-.8831804-1.248-1.54859019-2.4-1.54859019-1.572 0-2.568 1.18563939-2.568 3.30285249 0 2.1293114 1.14 3.3270492 2.568 3.3391475 1.152.0120984 1.884-.5202295 2.412-1.4276065l.696.5807213c-.636 1.1130491-1.8 1.8994426-3.432 1.8994426-2.388 0-4.38-1.609082-4.38-4.258623zm12.24-1.7058688-1.236 2.8552131h2.52zm2.628 5.8435082-.948-2.1051148h-3.288l-.912 2.1051148h-1.116l3.744-8.4930492h.72l3.792 8.4930492z"
          transform="matrix(.9612617 -.27563736 .27563736 .9612617 -2.347678 8.839648)"
        />
        <path
          d="m23.5673726 34.6778237h-3.66v-8.4688525h3.468c1.716 0 3.108.604918 3.108 2.0930164 0 1.2340328-1.032 1.911541-2.184 1.9962295v.0241967c1.224.0604918 2.532.6049181 2.532 2.0688197 0 1.645377-1.452 2.2865902-3.264 2.2865902zm-1.74-7.5251804v2.7100328h1.104c1.044 0 1.632-.3629508 1.632-1.3671147 0-.9678689-.564-1.3429181-1.62-1.3429181zm1.2 3.6416066h-1.2v2.9399016h1.236c1.176 0 1.848-.3750491 1.848-1.4397049 0-1.0646557-.672-1.5001967-1.884-1.5001967zm8.424-1.9599344-1.236 2.8552131h2.52zm2.628 5.8435082-.948-2.1051148h-3.288l-.912 2.1051148h-1.116l3.744-8.4930492h.72l3.792 8.4930492zm3.612 0v-8.4688525h1.92v7.4767869h4.104v.9920656zm10.8-5.8435082-1.236 2.8552131h2.52zm2.628 5.8435082-.948-2.1051148h-3.288l-.912 2.1051148h-1.116l3.744-8.4930492h.72l3.792 8.4930492zm9.708-3.3512459v-5.1176066h1.152v8.4809508h-.756l-5.328-6.4000328v6.3879345h-1.164v-8.4688525h1.86zm2.88-.7863935c0-2.6616393 1.992-4.4521967 4.464-4.4521967 1.548 0 2.712.6896066 3.348 1.778459l-.708.7863935c-.516-.8831804-1.248-1.5485902-2.4-1.5485902-1.572 0-2.568 1.1856393-2.568 3.3028525 0 2.1293114 1.14 3.3270491 2.568 3.3391475 1.152.0120984 1.884-.5202295 2.412-1.4276066l.696.5807213c-.636 1.1130492-1.8 1.8994427-3.432 1.8994427-2.388 0-4.38-1.609082-4.38-4.258623zm9.36 4.1376394v-8.4688525h6.192v.9920656h-4.272v2.6011475h3.084v.9920656h-3.084v2.8915082h4.488v.9920656z"
          transform="matrix(.99254615 .12186934 -.12186934 .99254615 4.080509 -5.828935)"
        />
        <path
          d="m49.6727823 15.2104479c0-2.516459 1.944-4.3554099 4.38-4.3554099s4.38 1.8389509 4.38 4.3554099-1.944 4.3554098-4.38 4.3554098-4.38-1.8389508-4.38-4.3554098zm2.148 0c0 2.214.708 3.4843278 2.232 3.4843278s2.232-1.2703278 2.232-3.4843278-.708-3.4843279-2.232-3.4843279-2.232 1.2703279-2.232 3.4843279zm8.328 4.2344262v-8.4688525h6.048v.9920656h-4.128v2.6737377h3.084v.9920656h-3.084v3.8109836zm7.848 0v-8.4688525h6.048v.9920656h-4.128v2.6737377h3.084v.9920656h-3.084v3.8109836z"
          transform="matrix(.75470958 -.65605903 .65605903 .75470958 5.194415 44.31399)"
        />
      </g>
    </svg>
  );
};

const V3 = () => {
  return (
    <svg height="39" viewBox="0 0 80 39" width="80">
      <g fillRule="evenodd" transform="translate(-2 -1)">
        <path
          d="m3.51102968 12.4272169-1.24098387 2.832h2.53016129zm2.63859678 5.796-.95182258-2.088h-3.30125807l-.91567742 2.088h-1.1205l3.75909678-8.42399998h.72290322l3.80729032 8.42399998zm8.27724194-2.892 2.2650968-5.50799998h2.241v8.39999998h-1.8795484v-6.732l-2.7470323 6.744h-.6626613l-2.6988387-6.744v6.732h-1.16869353v-8.39999998h2.45787093zm6.8916774 2.892v-8.39999998h6.2169678v.98399998h-4.2892259v2.58h3.0964355v.984h-3.0964355v2.868h4.5060968v.984zm10.241129-7.464v2.94h1.2771291c1.1807419 0 1.8554516-.456 1.8554516-1.488 0-.948-.6506129-1.452-1.8554516-1.452zm0 3.876v3.588h-1.9277419v-8.39999998h3.891629c1.7349678 0 3.1928226.63599998 3.1928226 2.26799998 0 1.416-1.277129 2.064-2.6385968 2.1v.024c1.0482097.084 1.6988226.528 2.0482259 1.5l.3975967 1.092c.216871.564.3855484.888.7831452 1.416h-2.1687097c-.2530161-.42-.4096451-.816-.5662742-1.248l-.4698871-1.32c-.2650645-.756-.6024193-1.02-1.4458064-1.02zm9.3013549-4.81199998v8.39999998h-1.927742v-8.39999998zm1.7229193 4.29599998c0-2.64 2.0000323-4.41599998 4.482-4.41599998 1.554242 0 2.7229355.68399998 3.3615 1.76399998l-.7108548.78c-.5180806-.876-1.2530323-1.536-2.4096774-1.536-1.5783387 0-2.5783549 1.176-2.5783549 3.276 0 2.112 1.1445968 3.3 2.5783549 3.312 1.1566451.012 1.8915968-.516 2.4217258-1.416l.6988064.576c-.6385645 1.104-1.807258 1.884-3.4458387 1.884-2.397629 0-4.3976613-1.596-4.3976613-4.224zm12.2893549-1.692-1.2409839 2.832h2.5301613zm2.6385968 5.796-.9518226-2.088h-3.3012581l-.9156774 2.088h-1.1205l3.7590968-8.42399998h.7229032l3.8072903 8.42399998z"
          transform="matrix(.94551858 -.32556815 .32556815 .94551858 -2.948169 10.42887)"
        />
        <path
          d="m25.001112 35.7292235h-3.674758v-8.4h3.4819839c1.7229193 0 3.1205322.6 3.1205322 2.076 0 1.224-1.0361613 1.896-2.1928064 1.98v.024c1.2289354.06 2.5422096.6 2.5422096 2.052 0 1.632-1.4578548 2.268-3.2771613 2.268zm-1.7470161-7.464v2.688h1.1084516c1.0482097 0 1.6385807-.36 1.6385807-1.356 0-.96-.5662742-1.332-1.6265323-1.332zm1.2048387 3.612h-1.2048387v2.916h1.2409839c1.1807419 0 1.8554516-.372 1.8554516-1.428s-.6747097-1.488-1.8915968-1.488zm8.4579678-1.944-1.2409839 2.832h2.5301613zm2.6385967 5.796-.9518225-2.088h-3.3012581l-.9156774 2.088h-1.1205l3.7590968-8.424h.7229032l3.8072903 8.424zm3.6265646 0v-8.4h1.9277419v7.416h4.1205484v.984zm10.8435483-5.796-1.2409838 2.832h2.5301613zm2.6385968 5.796-.9518226-2.088h-3.301258l-.9156774 2.088h-1.1205l3.7590967-8.424h.7229033l3.8072903 8.424zm9.7471452-3.324v-5.076h1.1566451v8.412h-.7590483l-5.3494839-6.348v6.336h-1.1686936v-8.4h1.8675zm2.8916129-.78c0-2.64 2.0000322-4.416 4.482-4.416 1.5542419 0 2.7229355.684 3.3615 1.764l-.7108549.78c-.5180806-.876-1.2530322-1.536-2.4096774-1.536-1.5783387 0-2.5783548 1.176-2.5783548 3.276 0 2.112 1.1445968 3.3 2.5783548 3.312 1.1566452.012 1.8915968-.516 2.4217258-1.416l.6988065.576c-.6385645 1.104-1.8072581 1.884-3.4458387 1.884-2.3976291 0-4.3976613-1.596-4.3976613-4.224zm9.3977419 4.104v-8.4h6.2169678v.984h-4.2892258v2.58h3.0964354v.984h-3.0964354v2.868h4.5060967v.984z"
          transform="matrix(.99254615 .12186934 -.12186934 .99254615 4.22431 -6.008407)"
        />
        <path
          d="m42.9729998 19.8136591c0-2.496 1.9518387-4.32 4.3976613-4.32 2.4458225 0 4.3976612 1.824 4.3976612 4.32s-1.9518387 4.32-4.3976612 4.32c-2.4458226 0-4.3976613-1.824-4.3976613-4.32zm2.1566613 0c0 2.196.7108548 3.456 2.241 3.456 1.5301451 0 2.241-1.26 2.241-3.456s-.7108549-3.456-2.241-3.456c-1.5301452 0-2.241 1.26-2.241 3.456zm8.3615806 4.2v-8.4h6.0723871v.984h-4.1446452v2.652h3.0964355v.984h-3.0964355v3.78zm7.8796452 0v-8.4h6.0723871v.984h-4.1446452v2.652h3.0964355v.984h-3.0964355v3.78z"
          transform="matrix(.9945219 -.10452846 .10452846 .9945219 -1.768655 5.879363)"
        />
      </g>
    </svg>
  );
};

const V4 = () => {
  return (
    <svg height="39" viewBox="0 0 75 39" width="75">
      <g fillRule="evenodd" transform="translate(-2 -1)">
        <path
          d="m4.37549425 10.4983783-1.24137391 2.832h2.53095652zm2.63942609 5.796-.95212174-2.088h-3.30229565l-.91596522 2.088h-1.12085217l3.76027826-8.42400001h.72313043l3.80848696 8.42400001zm8.27984346-2.892 2.2658087-5.50800001h2.2417044v8.40000001h-1.8801392v-6.73200001l-2.7478956 6.74400001h-.6628696l-2.6996869-6.74400001v6.73200001h-1.1690609v-8.40000001h2.4586435zm6.8938435 2.892v-8.40000001h6.2189217v.984h-4.2905739v2.58000001h3.0974087v.984h-3.0974087v2.868h4.5075131v.984zm10.2443478-7.46400001v2.94000001h1.2775305c1.181113 0 1.8560347-.456 1.8560347-1.488 0-.94800001-.6508173-1.45200001-1.8560347-1.45200001zm0 3.87600001v3.588h-1.9283478v-8.40000001h3.8928522c1.735513 0 3.1938261.636 3.1938261 2.26800001 0 1.416-1.2775305 2.064-2.6394261 2.1v.024c1.0485391.084 1.6993565.528 2.0488695 1.5l.3977218 1.092c.2169391.564.3856695.888.7833913 1.416h-2.1693913c-.2530957-.42-.4097739-.816-.5664522-1.248l-.4700348-1.32c-.2651478-.756-.6026087-1.02-1.4462608-1.02zm9.3042783-4.81200001v8.40000001h-1.9283478v-8.40000001zm1.7234609 4.29600001c0-2.64000001 2.0006608-4.41600001 4.4834087-4.41600001 1.5547304 0 2.7237913.684 3.3625565 1.764l-.7110783.78000001c-.5182435-.87600001-1.2534261-1.53600001-2.4104348-1.53600001-1.5788348 0-2.5791652 1.176-2.5791652 3.27600001 0 2.112 1.1449565 3.3 2.5791652 3.312 1.1570087.012 1.8921913-.516 2.422487-1.416l.6990261.576c-.6387652 1.104-1.8078261 1.884-3.4469218 1.884-2.3983826 0-4.3990434-1.596-4.3990434-4.224zm12.2932173-1.692-1.2413739 2.832h2.5309566zm2.6394261 5.796-.9521217-2.088h-3.3022957l-.9159652 2.088h-1.1208521l3.7602782-8.42400001h.7231304l3.808487 8.42400001z"
          transform="matrix(.96592583 -.25881905 .25881905 .96592583 -2.088988 8.321316)"
        />
        <path
          d="m20.1820115 35.8021555h-3.675913v-8.4h3.4830782c1.7234609 0 3.1215131.6 3.1215131 2.076 0 1.224-1.036487 1.896-2.1934957 1.98v.024c1.2293218.06 2.5430087.6 2.5430087 2.052 0 1.632-1.458313 2.268-3.2781913 2.268zm-1.7475652-7.464v2.688h1.1088c1.0485391 0 1.6390957-.36 1.6390957-1.356 0-.96-.5664522-1.332-1.6270435-1.332zm1.2052174 3.612h-1.2052174v2.916h1.2413739c1.1811131 0 1.8560348-.372 1.8560348-1.428s-.6749217-1.488-1.8921913-1.488zm8.4606261-1.944-1.2413739 2.832h2.5309565zm2.6394261 5.796-.9521218-2.088h-3.3022956l-.9159652 2.088h-1.1208522l3.7602783-8.424h.7231304l3.8084869 8.424zm3.6277043 0v-8.4h1.9283478v7.416h4.1218435v.984zm10.8469565-5.796-1.2413739 2.832h2.5309566zm2.6394261 5.796-.9521217-2.088h-3.3022957l-.9159652 2.088h-1.1208522l3.7602783-8.424h.7231304l3.808487 8.424zm9.7502087-3.324v-5.076h1.1570087v8.412h-.7592869l-5.3511653-6.348v6.336h-1.1690608v-8.4h1.8680869zm2.8925218-.78c0-2.64 2.0006608-4.416 4.4834087-4.416 1.5547304 0 2.7237913.684 3.3625565 1.764l-.7110783.78c-.5182435-.876-1.2534261-1.536-2.4104348-1.536-1.5788347 0-2.5791652 1.176-2.5791652 3.276 0 2.112 1.1449565 3.3 2.5791652 3.312 1.1570087.012 1.8921913-.516 2.422487-1.416l.6990261.576c-.6387652 1.104-1.8078261 1.884-3.4469218 1.884-2.3983826 0-4.3990434-1.596-4.3990434-4.224zm9.4006956 4.104v-8.4h6.2189218v.984h-4.290574v2.58h3.0974087v.984h-3.0974087v2.868h4.5075131v.984z"
          transform="matrix(.99254615 -.12186934 .12186934 .99254615 -3.505329 5.892683)"
        />
        <path
          d="m47.4127765 17.8071816c0-2.496 1.9524522-4.32 4.3990435-4.32s4.3990435 1.824 4.3990435 4.32-1.9524522 4.32-4.3990435 4.32-4.3990435-1.824-4.3990435-4.32zm2.1573392 0c0 2.196.7110782 3.456 2.2417043 3.456s2.2417044-1.26 2.2417044-3.456-.7110783-3.456-2.2417044-3.456-2.2417043 1.26-2.2417043 3.456zm8.3642087 4.2v-8.4h6.0742956v.984h-4.1459478v2.652h3.0974087v.984h-3.0974087v3.78zm7.8821217 0v-8.4h6.0742957v.984h-4.1459479v2.652h3.0974087v.984h-3.0974087v3.78z"
          transform="matrix(.99862953 .05233596 -.05233596 .99862953 1.013707 -3.097528)"
        />
      </g>
    </svg>
  );
};

const V5 = () => {
  return (
    <svg height="38" viewBox="0 0 79 38" width="79">
      <g fillRule="evenodd">
        <path
          d="m22.2500529 4.78067518-1.236 2.832h2.52zm2.628 5.79600002-.948-2.08800002h-3.288l-.912 2.08800002h-1.116l3.744-8.42400002h.72l3.792 8.42400002zm8.244-2.89200002 2.256-5.508h2.232v8.40000002h-1.872v-6.73200002l-2.736 6.74400002h-.66l-2.688-6.74400002v6.73200002h-1.164v-8.40000002h2.448zm6.864 2.89200002v-8.40000002h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.98400002zm10.2-7.46400002v2.94h1.272c1.176 0 1.848-.456 1.848-1.488 0-.948-.648-1.452-1.848-1.452zm0 3.876v3.58800002h-1.92v-8.40000002h3.876c1.728 0 3.18.636 3.18 2.268 0 1.416-1.272 2.064-2.628 2.1v.024c1.044.084 1.692.528 2.04 1.5l.396 1.092c.216.564.384.88800002.78 1.41600002h-2.16c-.252-.42-.408-.81600002-.564-1.24800002l-.468-1.32c-.264-.756-.6-1.02-1.44-1.02zm9.264-4.812v8.40000002h-1.92v-8.40000002zm1.716 4.296c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.88400002-3.432 1.88400002-2.388 0-4.38-1.59600002-4.38-4.22400002zm12.24-1.692-1.236 2.832h2.52zm2.628 5.79600002-.948-2.08800002h-3.288l-.912 2.08800002h-1.116l3.744-8.42400002h.72l3.792 8.42400002z"
          transform="matrix(.99862953 -.05233596 .05233596 .99862953 -.267508 2.537615)"
        />
        <path
          d="m3.21555249 26.9778656h-3.66v-8.4h3.468c1.716 0 3.108.6 3.108 2.076 0 1.224-1.032 1.896-2.184 1.98v.024c1.224.06 2.532.6 2.532 2.052 0 1.632-1.452 2.268-3.264 2.268zm-1.74-7.464v2.688h1.104c1.044 0 1.632-.36 1.632-1.356 0-.96-.564-1.332-1.62-1.332zm1.2 3.612h-1.2v2.916h1.236c1.176 0 1.848-.372 1.848-1.428s-.672-1.488-1.884-1.488zm8.42400001-1.944-1.23600001 2.832h2.52000001zm2.628 5.796-.948-2.088h-3.28800001l-.912 2.088h-1.116l3.74400001-8.424h.72l3.792 8.424zm3.612 0v-8.4h1.92v7.416h4.104v.984zm10.8-5.796-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm9.708-3.324v-5.076h1.152v8.412h-.756l-5.328-6.348v6.336h-1.164v-8.4h1.86zm2.88-.78c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.884-3.432 1.884-2.388 0-4.38-1.596-4.38-4.224zm9.36 4.104v-8.4h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.984z"
          transform="matrix(.92718385 .37460659 -.37460659 .92718385 10.669132 -9.332193)"
        />
        <path
          d="m51.3843143 18.6035258c0-2.496 1.944-4.32 4.38-4.32s4.38 1.824 4.38 4.32-1.944 4.32-4.38 4.32-4.38-1.824-4.38-4.32zm2.148 0c0 2.196.708 3.456 2.232 3.456s2.232-1.26 2.232-3.456-.708-3.456-2.232-3.456-2.232 1.26-2.232 3.456zm8.328 4.2v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78zm7.848 0v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78z"
          transform="matrix(.94551858 -.32556815 .32556815 .94551858 -2.593314 21.710016)"
        />
      </g>
    </svg>
  );
};

const V6 = () => {
  return (
    <svg height="37" viewBox="0 0 79 37" width="79">
      <g fillRule="evenodd">
        <path
          d="m22.2500529 4.44734184-1.236 2.832h2.52zm2.628 5.79599996-.948-2.08799996h-3.288l-.912 2.08799996h-1.116l3.744-8.42399996h.72l3.792 8.42399996zm8.244-2.89199996 2.256-5.508h2.232v8.39999996h-1.872v-6.73199996l-2.736 6.74399996h-.66l-2.688-6.74399996v6.73199996h-1.164v-8.39999996h2.448zm6.864 2.89199996v-8.39999996h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.98399996zm10.2-7.46399996v2.94h1.272c1.176 0 1.848-.456 1.848-1.488 0-.948-.648-1.452-1.848-1.452zm0 3.876v3.58799996h-1.92v-8.39999996h3.876c1.728 0 3.18.636 3.18 2.268 0 1.416-1.272 2.064-2.628 2.1v.024c1.044.084 1.692.528 2.04 1.5l.396 1.092c.216.564.384.888.78 1.41599996h-2.16c-.252-.41999996-.408-.81599996-.564-1.24799996l-.468-1.32c-.264-.756-.6-1.02-1.44-1.02zm9.264-4.812v8.39999996h-1.92v-8.39999996zm1.716 4.296c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.88399996-3.432 1.88399996-2.388 0-4.38-1.59599996-4.38-4.22399996zm12.24-1.692-1.236 2.832h2.52zm2.628 5.79599996-.948-2.08799996h-3.288l-.912 2.08799996h-1.116l3.744-8.42399996h.72l3.792 8.42399996z"
          transform="matrix(.99862953 -.05233596 .05233596 .99862953 -.250063 2.537158)"
        />
        <path
          d="m3.21555249 25.9778656h-3.66v-8.4h3.468c1.716 0 3.108.6 3.108 2.076 0 1.224-1.032 1.896-2.184 1.98v.024c1.224.06 2.532.6 2.532 2.052 0 1.632-1.452 2.268-3.264 2.268zm-1.74-7.464v2.688h1.104c1.044 0 1.632-.36 1.632-1.356 0-.96-.564-1.332-1.62-1.332zm1.2 3.612h-1.2v2.916h1.236c1.176 0 1.848-.372 1.848-1.428s-.672-1.488-1.884-1.488zm8.42400001-1.944-1.23600001 2.832h2.52000001zm2.628 5.796-.948-2.088h-3.28800001l-.912 2.088h-1.116l3.74400001-8.424h.72l3.792 8.424zm3.612 0v-8.4h1.92v7.416h4.104v.984zm10.8-5.796-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm9.708-3.324v-5.076h1.152v8.412h-.756l-5.328-6.348v6.336h-1.164v-8.4h1.86zm2.88-.78c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.884-3.432 1.884-2.388 0-4.38-1.596-4.38-4.224zm9.36 4.104v-8.4h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.984z"
          transform="matrix(.92718385 .37460659 -.37460659 .92718385 10.294525 -9.405009)"
        />
        <path
          d="m51.3843143 17.6035258c0-2.496 1.944-4.32 4.38-4.32s4.38 1.824 4.38 4.32-1.944 4.32-4.38 4.32-4.38-1.824-4.38-4.32zm2.148 0c0 2.196.708 3.456 2.232 3.456s2.232-1.26 2.232-3.456-.708-3.456-2.232-3.456-2.232 1.26-2.232 3.456zm8.328 4.2v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78zm7.848 0v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78z"
          transform="matrix(.94551858 -.32556815 .32556815 .94551858 -2.267746 21.655535)"
        />
      </g>
    </svg>
  );
};

const V7 = () => {
  return (
    <svg height="37" viewBox="0 0 79 37" width="79">
      <g fillRule="evenodd" transform="translate(0 -1)">
        <path
          d="m4.21682902 5.5389573-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm8.24399998-2.892 2.256-5.508h2.232v8.4h-1.872v-6.732l-2.736 6.744h-.66l-2.688-6.744v6.732h-1.164v-8.4h2.448zm6.864 2.892v-8.4h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.984zm10.2-7.464v2.94h1.272c1.176 0 1.848-.456 1.848-1.488 0-.948-.648-1.452-1.848-1.452zm0 3.876v3.588h-1.92v-8.4h3.876c1.728 0 3.18.636 3.18 2.268 0 1.416-1.272 2.064-2.628 2.1v.024c1.044.084 1.692.528 2.04 1.5l.396 1.092c.216.564.384.888.78 1.416h-2.16c-.252-.42-.408-.816-.564-1.248l-.468-1.32c-.264-.756-.6-1.02-1.44-1.02zm9.264-4.812v8.4h-1.92v-8.4zm1.716 4.296c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.884-3.432 1.884-2.388 0-4.38-1.596-4.38-4.224zm12.24-1.692-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424z"
          transform="matrix(.99756405 -.06975647 .06975647 .99756405 -.423932 2.130083)"
        />
        <path
          d="m22.4894719 29.1592568h-3.66v-8.4h3.468c1.716 0 3.108.6 3.108 2.076 0 1.224-1.032 1.896-2.184 1.98v.024c1.224.06 2.532.6 2.532 2.052 0 1.632-1.452 2.268-3.264 2.268zm-1.74-7.464v2.688h1.104c1.044 0 1.632-.36 1.632-1.356 0-.96-.564-1.332-1.62-1.332zm1.2 3.612h-1.2v2.916h1.236c1.176 0 1.848-.372 1.848-1.428s-.672-1.488-1.884-1.488zm8.424-1.944-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm3.612 0v-8.4h1.92v7.416h4.104v.984zm10.8-5.796-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm9.708-3.324v-5.076h1.152v8.412h-.756l-5.328-6.348v6.336h-1.164v-8.4h1.86zm2.88-.78c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.884-3.432 1.884-2.388 0-4.38-1.596-4.38-4.224zm9.36 4.104v-8.4h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.984z"
          transform="matrix(.9612617 .27563736 -.27563736 .9612617 8.762907 -12.43281)"
        />
        <path
          d="m54.2236727 15.3626817c0-2.496 1.944-4.32 4.38-4.32s4.38 1.824 4.38 4.32-1.944 4.32-4.38 4.32-4.38-1.824-4.38-4.32zm2.148 0c0 2.196.708 3.456 2.232 3.456s2.232-1.26 2.232-3.456-.708-3.456-2.232-3.456-2.232 1.26-2.232 3.456zm8.328 4.2v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78zm7.848 0v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78z"
          transform="matrix(.9998477 -.01745241 .01745241 .9998477 -.258001 1.161348)"
        />
      </g>
    </svg>
  );
};

const V8 = () => {
  return (
    <svg height="37" viewBox="0 0 68 37" width="68">
      <g fillRule="evenodd">
        <path
          d="m11.9958847 4.78343205-1.236 2.832h2.52zm2.628 5.79599995-.948-2.08799995h-3.288l-.91200004 2.08799995h-1.116l3.74400004-8.42399995h.72l3.792 8.42399995zm8.244-2.89199995 2.256-5.508h2.232v8.39999995h-1.872v-6.73199995l-2.736 6.74399995h-.66l-2.688-6.74399995v6.73199995h-1.164v-8.39999995h2.448zm6.864 2.89199995v-8.39999995h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.98399995zm10.2-7.46399995v2.94h1.272c1.176 0 1.848-.456 1.848-1.488 0-.948-.648-1.452-1.848-1.452zm0 3.876v3.58799995h-1.92v-8.39999995h3.876c1.728 0 3.18.636 3.18 2.268 0 1.416-1.272 2.064-2.628 2.1v.024c1.044.084 1.692.528 2.04 1.5l.396 1.092c.216.564.384.88799995.78 1.41599995h-2.16c-.252-.42-.408-.81599995-.564-1.24799995l-.468-1.32c-.264-.756-.6-1.02-1.44-1.02zm9.264-4.812v8.39999995h-1.92v-8.39999995zm1.716 4.296c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.88399995-3.432 1.88399995-2.388 0-4.38-1.59599995-4.38-4.22399995zm12.24-1.692-1.236 2.832h2.52zm2.628 5.79599995-.948-2.08799995h-3.288l-.912 2.08799995h-1.116l3.744-8.42399995h.72l3.792 8.42399995z"
          transform="matrix(.99862953 .05233596 -.05233596 .99862953 .386042 -1.983472)"
        />
        <path
          d="m3.85644368 35.2982912h-3.66v-8.4h3.468c1.716 0 3.108.6 3.108 2.076 0 1.224-1.032 1.896-2.184 1.98v.024c1.224.06 2.532.6 2.532 2.052 0 1.632-1.452 2.268-3.264 2.268zm-1.74-7.464v2.688h1.104c1.044 0 1.632-.36 1.632-1.356 0-.96-.564-1.332-1.62-1.332zm1.2 3.612h-1.2v2.916h1.236c1.176 0 1.848-.372 1.848-1.428s-.672-1.488-1.884-1.488zm8.42400002-1.944-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.91200002 2.088h-1.116l3.74400002-8.424h.72l3.792 8.424zm3.612 0v-8.4h1.92v7.416h4.104v.984zm10.8-5.796-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm9.708-3.324v-5.076h1.152v8.412h-.756l-5.328-6.348v6.336h-1.164v-8.4h1.86zm2.88-.78c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.884-3.432 1.884-2.388 0-4.38-1.596-4.38-4.224zm9.36 4.104v-8.4h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.984z"
          transform="matrix(.99862953 .05233596 -.05233596 .99862953 1.668646 -1.526436)"
        />
        <path
          d="m21.557006 19.0293484c0-2.496 1.944-4.32 4.38-4.32s4.38 1.824 4.38 4.32-1.944 4.32-4.38 4.32-4.38-1.824-4.38-4.32zm2.148 0c0 2.196.708 3.456 2.232 3.456s2.232-1.26 2.232-3.456-.708-3.456-2.232-3.456-2.232 1.26-2.232 3.456zm8.328 4.2v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78zm7.848 0v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78z"
          transform="matrix(.9998477 -.01745241 .01745241 .9998477 -.326969 .591795)"
        />
      </g>
    </svg>
  );
};

const V9 = () => {
  return (
    <svg height="34" viewBox="0 0 76 34" width="76">
      <g fillRule="evenodd" transform="translate(0 -1)">
        <path
          d="m22.8358427 12.4262883-1.2411074 2.8552131h2.5304132zm2.6388595 5.8435082-.9519173-2.1051148h-3.3015868l-.9157686 2.1051148h-1.1206116l3.7594711-8.4930492h.7229752l3.8076694 8.4930492zm8.2780661-2.9157049 2.2653224-5.55314758h2.2412231v8.46885248h-1.8797355v-6.7871803l-2.7473058 6.7992786h-.6627273l-2.6991074-6.7992786v6.7871803h-1.16881v-8.46885248h2.4581157zm6.8923637 2.9157049v-8.46885248h6.2175868v.99206558h-4.2896529v2.6011475h3.0967438v.9920656h-3.0967438v2.8915082h4.5065454v.9920656zm10.2421487-7.5251803v2.9640983h1.2772562c1.1808595 0 1.8556364-.4597377 1.8556364-1.5001967 0-.9557705-.6506777-1.4639016-1.8556364-1.4639016zm0 3.9077704v3.6174099h-1.9279338v-8.46885248h3.8920165c1.7351405 0 3.1931405.64121308 3.1931405 2.28659018 0 1.4276065-1.2772562 2.080918-2.6388595 2.1172131v.0241967c1.048314.0846885 1.6989917.5323279 2.0484297 1.5122951l.3976364 1.1009508c.2168925.568623.3855868.8952787.7832231 1.4276066h-2.1689256c-.2530413-.4234426-.4096859-.8226885-.5663306-1.2582295l-.4699339-1.3308197c-.2650909-.7621967-.6024793-1.0283607-1.4459504-1.0283607zm9.302281-4.85144258v8.46885248h-1.9279339v-8.46885248zm1.7230909 4.33121308c0-2.6616393 2.0002314-4.45219668 4.4824463-4.45219668 1.5543967 0 2.7232066.68960658 3.3618347 1.77845898l-.7109256.7863935c-.5181322-.8831804-1.253157-1.5485902-2.4099173-1.5485902-1.5784959 0-2.5786116 1.1856394-2.5786116 3.3028525 0 2.1293114 1.1447107 3.3270492 2.5786116 3.3391475 1.1567603.0120984 1.8917851-.5202295 2.4219669-1.4276065l.698876.5807213c-.6386281 1.1130491-1.807438 1.8994426-3.4461818 1.8994426-2.3978678 0-4.3980992-1.609082-4.3980992-4.258623zm12.2905785-1.7058688-1.2411074 2.8552131h2.5304132zm2.6388596 5.8435082-.9519174-2.1051148h-3.3015868l-.9157686 2.1051148h-1.1206115l3.759471-8.4930492h.7229752l3.8076695 8.4930492z"
          transform="matrix(.94551858 .32556815 -.32556815 .94551858 7.239799 -15.192591)"
        />
        <path
          d="m4.4760739 33.2325276h-3.67512397v-8.4688524h3.48233058c1.72309091 0 3.12084298.604918 3.12084298 2.0930164 0 1.2340327-1.03626447 1.9115409-2.1930248 1.9962295v.0241967c1.22905785.0604918 2.54246281.604918 2.54246281 2.0688197 0 1.645377-1.458 2.2865901-3.2774876 2.2865901zm-1.74719008-7.5251803v2.7100328h1.10856198c1.04831405 0 1.6387438-.3629508 1.6387438-1.3671148 0-.9678688-.56633058-1.342918-1.62669421-1.342918zm1.20495867 3.6416065h-1.20495867v2.9399017h1.24110743c1.18085951 0 1.85563637-.3750492 1.85563637-1.4397049 0-1.0646558-.67477686-1.5001968-1.89178513-1.5001968zm8.45880991-1.9599344-1.2411074 2.8552131h2.5304132zm2.6388595 5.8435082-.9519173-2.1051147h-3.3015868l-.91576861 2.1051147h-1.12061157l3.75947108-8.4930492h.7229752l3.8076694 8.4930492zm3.6269256 0v-8.4688524h1.9279339v7.4767868h4.1209587v.9920656zm10.8446281-5.8435082-1.2411074 2.8552131h2.5304132zm2.6388595 5.8435082-.9519173-2.1051147h-3.3015868l-.9157686 2.1051147h-1.1206116l3.7594711-8.4930492h.7229752l3.8076694 8.4930492zm9.7481157-3.3512459v-5.1176065h1.1567604v8.4809508h-.759124l-5.3500165-6.4000328v6.3879344h-1.1688099v-8.4688524h1.8676859zm2.8919009-.7863934c0-2.6616394 2.0002314-4.4521967 4.4824462-4.4521967 1.5543967 0 2.7232067.6896065 3.3618348 1.778459l-.7109257.7863934c-.5181322-.8831803-1.253157-1.5485902-2.4099173-1.5485902-1.5784959 0-2.5786116 1.1856394-2.5786116 3.3028525 0 2.1293115 1.1447108 3.3270492 2.5786116 3.3391475 1.1567603.0120984 1.8917851-.5202295 2.4219669-1.4276065l.6988761.5807213c-.6386281 1.1130492-1.8074381 1.8994426-3.4461819 1.8994426-2.3978677 0-4.3980991-1.6090819-4.3980991-4.2586229zm9.3986777 4.1376393v-8.4688524h6.2175867v.9920655h-4.2896529v2.6011476h3.0967438v.9920655h-3.0967438v2.8915082h4.5065455v.9920656z"
          transform="matrix(.99862953 -.05233596 .05233596 .99862953 -1.475559 1.646875)"
        />
        <path
          d="m3.89670889 17.2236178c0-2.5164591 1.95203305-4.3554099 4.39809917-4.3554099 2.44606614 0 4.39809914 1.8389508 4.39809914 4.3554099 0 2.516459-1.952033 4.3554098-4.39809914 4.3554098-2.44606612 0-4.39809917-1.8389508-4.39809917-4.3554098zm2.15687603 0c0 2.214.71092562 3.4843278 2.24122314 3.4843278s2.24122314-1.2703278 2.24122314-3.4843278-.71092562-3.4843279-2.24122314-3.4843279-2.24122314 1.2703279-2.24122314 3.4843279zm8.36241318 4.2344262v-8.4688525h6.0729918v.9920656h-4.1450579v2.6737377h3.0967438v.9920656h-3.0967438v3.8109836zm7.8804298 0v-8.4688525h6.0729917v.9920656h-4.1450578v2.6737377h3.0967438v.9920656h-3.0967438v3.8109836z"
          transform="matrix(.98768834 .15643447 -.15643447 .98768834 2.892992 -2.311716)"
        />
      </g>
    </svg>
  );
};

const V10 = () => {
  return (
    <svg height="34" viewBox="0 0 78 34" width="78">
      <g fillRule="evenodd" transform="translate(-1)">
        <path
          d="m12.9019535 5.5058085-1.2411075 2.832h2.5304133zm2.6388595 5.796-.9519174-2.088h-3.3015867l-.9157686 2.088h-1.12061161l3.75947111-8.424h.7229752l3.8076694 8.424zm8.2780661-2.892 2.2653223-5.508h2.2412232v8.4h-1.8797356v-6.732l-2.7473058 6.744h-.6627272l-2.6991075-6.744v6.732h-1.1688099v-8.4h2.4581157zm6.8923636 2.892v-8.4h6.2175868v.984h-4.2896529v2.58h3.0967438v.984h-3.0967438v2.868h4.5065455v.984zm10.2421488-7.464v2.94h1.2772562c1.1808595 0 1.8556364-.456 1.8556364-1.488 0-.948-.6506777-1.452-1.8556364-1.452zm0 3.876v3.588h-1.9279339v-8.4h3.8920165c1.7351405 0 3.1931405.636 3.1931405 2.268 0 1.416-1.2772562 2.064-2.6388595 2.1v.024c1.0483141.084 1.6989918.528 2.0484298 1.5l.3976363 1.092c.2168926.564.3855868.888.7832232 1.416h-2.1689256c-.2530414-.42-.409686-.816-.5663306-1.248l-.4699339-1.32c-.2650909-.756-.6024793-1.02-1.4459504-1.02zm9.302281-4.812v8.4h-1.9279339v-8.4zm1.7230909 4.296c0-2.64 2.0002314-4.416 4.4824463-4.416 1.5543967 0 2.7232066.684 3.3618347 1.764l-.7109256.78c-.5181323-.876-1.2531571-1.536-2.4099174-1.536-1.5784959 0-2.5786116 1.176-2.5786116 3.276 0 2.112 1.1447108 3.3 2.5786116 3.312 1.1567603.012 1.8917851-.516 2.421967-1.416l.698876.576c-.6386281 1.104-1.807438 1.884-3.4461818 1.884-2.3978678 0-4.3980992-1.596-4.3980992-4.224zm12.2905785-1.692-1.2411074 2.832h2.5304132zm2.6388595 5.796-.9519173-2.088h-3.3015868l-.9157686 2.088h-1.1206116l3.7594711-8.424h.7229752l3.8076694 8.424z"
          transform="matrix(.9961947 .08715574 -.08715574 .9961947 .767673 -3.378994)"
        />
        <path
          d="m22.1859437 29.8021555h-3.675124v-8.4h3.4823306c1.7230909 0 3.120843.6 3.120843 2.076 0 1.224-1.0362645 1.896-2.1930248 1.98v.024c1.2290578.06 2.5424628.6 2.5424628 2.052 0 1.632-1.458 2.268-3.2774876 2.268zm-1.7471901-7.464v2.688h1.108562c1.048314 0 1.6387438-.36 1.6387438-1.356 0-.96-.5663306-1.332-1.6266942-1.332zm1.2049587 3.612h-1.2049587v2.916h1.2411074c1.1808595 0 1.8556364-.372 1.8556364-1.428s-.6747769-1.488-1.8917851-1.488zm8.4588099-1.944-1.2411074 2.832h2.5304132zm2.6388595 5.796-.9519174-2.088h-3.3015867l-.9157686 2.088h-1.1206116l3.7594711-8.424h.7229752l3.8076694 8.424zm3.6269256 0v-8.4h1.9279339v7.416h4.1209587v.984zm10.8446281-5.796-1.2411074 2.832h2.5304132zm2.6388595 5.796-.9519173-2.088h-3.3015868l-.9157686 2.088h-1.1206116l3.7594711-8.424h.7229752l3.8076694 8.424zm9.7481157-3.324v-5.076h1.1567604v8.412h-.759124l-5.3500165-6.348v6.336h-1.16881v-8.4h1.867686zm2.8919009-.78c0-2.64 2.0002314-4.416 4.4824462-4.416 1.5543967 0 2.7232066.684 3.3618347 1.764l-.7109256.78c-.5181322-.876-1.253157-1.536-2.4099173-1.536-1.5784959 0-2.5786116 1.176-2.5786116 3.276 0 2.112 1.1447107 3.3 2.5786116 3.312 1.1567603.012 1.8917851-.516 2.4219669-1.416l.698876.576c-.6386281 1.104-1.807438 1.884-3.4461818 1.884-2.3978677 0-4.3980991-1.596-4.3980991-4.224zm9.3986776 4.104v-8.4h6.2175868v.984h-4.2896529v2.58h3.0967438v.984h-3.0967438v2.868h4.5065455v.984z"
          transform="matrix(.99254615 -.12186934 .12186934 .99254615 -2.759218 6.091492)"
        />
        <path
          d="m-.09652275 23.3537465c0-2.496 1.95203305-4.32 4.39809917-4.32s4.39809917 1.824 4.39809917 4.32-1.95203305 4.32-4.39809917 4.32-4.39809917-1.824-4.39809917-4.32zm2.15687603 0c0 2.196.71092562 3.456 2.24122314 3.456s2.24122314-1.26 2.24122314-3.456-.71092562-3.456-2.24122314-3.456-2.24122314 1.26-2.24122314 3.456zm8.36241322 4.2v-8.4h6.0729917v.984h-4.1450578v2.652h3.0967438v.984h-3.0967438v3.78zm7.8804298 0v-8.4h6.0729917v.984h-4.1450579v2.652h3.0967438v.984h-3.0967438v3.78z"
          transform="matrix(.74314483 -.66913061 .66913061 .74314483 -12.508527 14.121664)"
        />
      </g>
    </svg>
  );
};

const V11 = () => {
  return (
    <svg height="39" viewBox="0 0 78 39" width="78">
      <g fillRule="evenodd" transform="translate(0 -1)">
        <path
          d="m22.1560646 7.32751376-1.236 2.83200004h2.52zm2.628 5.79600004-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.42400004h.72l3.792 8.42400004zm8.244-2.892 2.256-5.50800004h2.232v8.40000004h-1.872v-6.73200004l-2.736 6.74400004h-.66l-2.688-6.74400004v6.73200004h-1.164v-8.40000004h2.448zm6.864 2.892v-8.40000004h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.86800004h4.488v.984zm10.2-7.46400004v2.94h1.272c1.176 0 1.848-.456 1.848-1.488 0-.948-.648-1.452-1.848-1.452zm0 3.876v3.58800004h-1.92v-8.40000004h3.876c1.728 0 3.18.636 3.18 2.268 0 1.416-1.272 2.064-2.628 2.1v.024c1.044.084 1.692.528 2.04 1.50000004l.396 1.092c.216.564.384.888.78 1.416h-2.16c-.252-.42-.408-.816-.564-1.248l-.468-1.32c-.264-.75600004-.6-1.02000004-1.44-1.02000004zm9.264-4.812v8.40000004h-1.92v-8.40000004zm1.716 4.296c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.11200004 1.14 3.30000004 2.568 3.31200004 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.884-3.432 1.884-2.388 0-4.38-1.596-4.38-4.22400004zm12.24-1.692-1.236 2.83200004h2.52zm2.628 5.79600004-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.42400004h.72l3.792 8.42400004z"
          transform="matrix(.99026807 .1391731 -.1391731 .99026807 1.711246 -6.624928)"
        />
        <path
          d="m3.21555249 28.9778656h-3.66v-8.4h3.468c1.716 0 3.108.6 3.108 2.076 0 1.224-1.032 1.896-2.184 1.98v.024c1.224.06 2.532.6 2.532 2.052 0 1.632-1.452 2.268-3.264 2.268zm-1.74-7.464v2.688h1.104c1.044 0 1.632-.36 1.632-1.356 0-.96-.564-1.332-1.62-1.332zm1.2 3.612h-1.2v2.916h1.236c1.176 0 1.848-.372 1.848-1.428s-.672-1.488-1.884-1.488zm8.42400001-1.944-1.23600001 2.832h2.52000001zm2.628 5.796-.948-2.088h-3.28800001l-.912 2.088h-1.116l3.74400001-8.424h.72l3.792 8.424zm3.612 0v-8.4h1.92v7.416h4.104v.984zm10.8-5.796-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm9.708-3.324v-5.076h1.152v8.412h-.756l-5.328-6.348v6.336h-1.164v-8.4h1.86zm2.88-.78c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.884-3.432 1.884-2.388 0-4.38-1.596-4.38-4.224zm9.36 4.104v-8.4h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.984z"
          transform="matrix(.92718385 .37460659 -.37460659 .92718385 11.418345 -9.186561)"
        />
        <path
          d="m47.048596 23.1629475c0-2.496 1.944-4.32 4.38-4.32s4.38 1.824 4.38 4.32-1.944 4.32-4.38 4.32-4.38-1.824-4.38-4.32zm2.148 0c0 2.196.708 3.456 2.232 3.456s2.232-1.26 2.232-3.456-.708-3.456-2.232-3.456-2.232 1.26-2.232 3.456zm8.328 4.2v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78zm7.848 0v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78z"
          transform="matrix(.95105652 .30901699 -.30901699 .95105652 10.056892 -17.170821)"
        />
      </g>
    </svg>
  );
};

const V12 = () => {
  return (
    <svg height="35" viewBox="0 0 80 35" width="80">
      <g fillRule="evenodd" transform="translate(0 -1)">
        <path
          d="m4.63491778 9.17893758-1.236 2.83200002h2.52zm2.628 5.79600002-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.42400002h.72l3.792 8.42400002zm8.24400002-2.892 2.256-5.50800002h2.232v8.40000002h-1.872v-6.73200002l-2.736 6.74400002h-.66l-2.688-6.74400002v6.73200002h-1.164v-8.40000002h2.448zm6.864 2.892v-8.40000002h6.192v.984h-4.272v2.58000002h3.084v.984h-3.084v2.868h4.488v.984zm10.2-7.46400002v2.94000002h1.272c1.176 0 1.848-.45600002 1.848-1.48800002 0-.948-.648-1.452-1.848-1.452zm0 3.87600002v3.588h-1.92v-8.40000002h3.876c1.728 0 3.18.636 3.18 2.268 0 1.41600002-1.272 2.06400002-2.628 2.10000002v.024c1.044.084 1.692.528 2.04 1.5l.396 1.092c.216.564.384.888.78 1.416h-2.16c-.252-.42-.408-.816-.564-1.248l-.468-1.32c-.264-.756-.6-1.02-1.44-1.02zm9.264-4.81200002v8.40000002h-1.92v-8.40000002zm1.716 4.29600002c0-2.64000002 1.992-4.41600002 4.464-4.41600002 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.27600002 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.884-3.432 1.884-2.388 0-4.38-1.596-4.38-4.224zm12.24-1.69200002-1.236 2.83200002h2.52zm2.628 5.79600002-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.42400002h.72l3.792 8.42400002z"
          transform="matrix(.98162718 .190809 -.190809 .98162718 2.620091 -5.660809)"
        />
        <path
          d="m23.592728 30.6881645h-3.66v-8.4h3.468c1.716 0 3.108.6 3.108 2.076 0 1.224-1.032 1.896-2.184 1.98v.024c1.224.06 2.532.6 2.532 2.052 0 1.632-1.452 2.268-3.264 2.268zm-1.74-7.464v2.688h1.104c1.044 0 1.632-.36 1.632-1.356 0-.96-.564-1.332-1.62-1.332zm1.2 3.612h-1.2v2.916h1.236c1.176 0 1.848-.372 1.848-1.428s-.672-1.488-1.884-1.488zm8.424-1.944-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm3.612 0v-8.4h1.92v7.416h4.104v.984zm10.8-5.796-1.236 2.832h2.52zm2.628 5.796-.948-2.088h-3.288l-.912 2.088h-1.116l3.744-8.424h.72l3.792 8.424zm9.708-3.324v-5.076h1.152v8.412h-.756l-5.328-6.348v6.336h-1.164v-8.4h1.86zm2.88-.78c0-2.64 1.992-4.416 4.464-4.416 1.548 0 2.712.684 3.348 1.764l-.708.78c-.516-.876-1.248-1.536-2.4-1.536-1.572 0-2.568 1.176-2.568 3.276 0 2.112 1.14 3.3 2.568 3.312 1.152.012 1.884-.516 2.412-1.416l.696.576c-.636 1.104-1.8 1.884-3.432 1.884-2.388 0-4.38-1.596-4.38-4.224zm9.36 4.104v-8.4h6.192v.984h-4.272v2.58h3.084v.984h-3.084v2.868h4.488v.984z"
          transform="matrix(.98480775 -.17364818 .17364818 .98480775 -3.844313 9.035634)"
        />
        <path
          d="m.94565279 22.4500841c0-2.496 1.944-4.32 4.38-4.32s4.38 1.824 4.38 4.32-1.944 4.32-4.38 4.32-4.38-1.824-4.38-4.32zm2.148 0c0 2.196.708 3.456 2.232 3.456s2.232-1.26 2.232-3.456-.708-3.456-2.232-3.456-2.232 1.26-2.232 3.456zm8.32800001 4.2v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78zm7.848 0v-8.4h6.048v.984h-4.128v2.652h3.084v.984h-3.084v3.78z"
          transform="matrix(.9612617 -.27563736 .27563736 .9612617 -5.679384 4.489252)"
        />
      </g>
    </svg>
  );
};

class AmericanOffBalanceIcon extends React.Component {
  constructor(props) {
    super(props);

    this.svgs = [V1, V2, V3, V4, V5, V6, V7, V8, V9, V10, V11, V12];

    this.state = {
      index: 11,
    };
  }

  componentDidMount() {
    if (this.props.animate) {
      this.timer = setInterval(this.handleMouseEnter, this.props.animate);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <figure onMouseEnter={!this.props.animate ? this.handleMouseEnter : null}>
        {React.createElement(this.svgs[this.state.index])}
      </figure>
    );
  }

  // Internal

  generateRandomIndex() {
    const max = this.svgs.length - 1;
    return Math.floor(Math.random() * max) + 1;
  }

  // Event Handlers

  handleMouseEnter = () => {
    const newIndex = this.generateRandomIndex();
    this.setState({
      index: newIndex,
    });
  };
}

export default AmericanOffBalanceIcon;
