import React, { Component } from 'react';

export default class Styleguide extends Component {
  render() {
    return (
      <div style={{ paddingLeft: "20px", paddingRight: "20px", fontFamily: 'sans-serif' }} className="nav-pad">
        <Section heading="Typography">
          <Style label="Heading 1" variant={["bold","caps"]} className="font--h1 bold uppercase" />
          <Style label="Heading 1" variant={["regular","titlecase"]} className="font--h1" />
          <Style label="Heading 2" variant={["bold","caps"]} className="font--h2 bold uppercase" />
          <Style label="Heading 3" variant={["bold","titlecase"]} className="font--h3" />
          <Style label="Tab Label" variant={["bold","titlecase"]} className="font--label bold uppercase" />
          <Style label="Body" className="font--body"/>
          <Style label="Figure 8% $34.06 Trillion" variant={["bold","titlecase"]} className="font--number" />
          <Style label="Figure Axis" variant={["bold","titlecase"]} className="font--number--axis" />
          <Style label="Legend Label: " className="font--label--small" />
          <Style label="Label" className="font--label" />

          <Style label="Huge Heading" variant={["caps"]} className="font--huge bold uppercase" />
        </Section>
        <Section heading="Color">
          <Color className="bg--spending" label="spending" />
          <Color className="bg--revenue" label="revenue" />
          <Color className="bg--user" label="user" />
          <Color className="bg--debt" label="debt" />
          <Color className="bg--black" label="black" />
          <Color className="bg--white" label="white" />
        </Section>
      </div>
    );
  }
}

const Section = ({ heading, children }) => (
  <section>
    <h1>{heading}</h1>

    <ul className="reset-list" style={{ display: "flex", flexWrap: "wrap" }}>
      {children}
    </ul>
  </section>
)

const Style = ({ label, variant, className, copy }) => (
  <li style={{ padding: "2rem" }}>
    <div className={className} >{label}</div>
    { copy && <p className={className}>{copy}</p>}
    <div style={{ padding: "10px" }}>
      { variant && <p className="font--label" style={{ color: "gray" }}>{variant.join(', ')}</p>}
      <p className="font--label">
        <span style={{ color: "#fff", backgroundColor: "rgba(30,30,30,.5)", padding: "4px" }}>{className}</span>
      </p>
    </div>
  </li>
)

const Color = ({ label, className }) => (
  <li style={{ padding: "2rem" }}>
    <div className={className} style={{ width: "4em", height: "4em" }}></div>
    <div>{label}</div>
  </li>
)