export const DATA_STRUCTURES_PATH = "data-structures/";
export const BUDGET_KEY = "Budget Model (orig) - Budget Projections";
export const ECON_KEY =
  "Economic Model (orig) - Budget Calculator Baseline Values";
export const DEFAULT_BUDGET_TITLE = "US Federal Budget";

export default {
  DATA_STRUCTURES_PATH,
  BUDGET_KEY,
  ECON_KEY,
  DEFAULT_BUDGET_TITLE,
};

export const CBO_LABEL = "Before";
export const YOU_LABEL = "After";

export const maritalStatuses = {
  SINGLE: "Single",
  MARRIED: "Married",
};
