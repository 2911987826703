export default (cats,startYear,endYear) => {
	const
		DEFAULT_GROWTH_RATE = "CBO_GROWTH",
		DEFAULT_GROWTH_RATE_ADJUSTMENT = 0,
		DEFAULT_GROWTH_ADDITION = 0,
		DEFAULT_DOLLAR_ADDITION = 0,
		DEFAULT_DOLLAR_ADDITIONS = {}

	let i = startYear,
		l = endYear

	for (;i<=l;i++) {
		DEFAULT_DOLLAR_ADDITIONS[i] = DEFAULT_DOLLAR_ADDITION;
	}

	const defaulted = {}

	cats.forEach(({parent, key, bottom=false}) => {
		if (parent) {
			defaulted[key] = {
				key,
				parent,
				bottom,
				additions: DEFAULT_DOLLAR_ADDITIONS,
				growthRate: DEFAULT_GROWTH_RATE,
				growthRateAdjustment: DEFAULT_GROWTH_RATE_ADJUSTMENT,
				growthRateAddition: DEFAULT_GROWTH_ADDITION
			}
		}
	})

	defaulted.policiesEnacted = []

	return defaulted;
}