import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import find from "lodash/find";
import sortBy from "lodash/sortBy";

import CategoryHeader from "./CategoryHeader";
import CategorySnapshot from "./CategorySnapshot";
import AnimatedRect from "./AnimatedRect";

import ReactTooltip from "react-tooltip";

import {
  sumCategories,
  childCategories,
  flattenTree,
  categoryUrl,
} from "../categoryFunctions";

import difference from "../difference";

import {
  setGrowthRate,
  setGrowthRateAdjustment,
  setGrowthRateAddition,
  updateGrowthRateAddition,
} from "../Actions";

const idPrefix = "subcattooltip_";

const categoryHasPolicyEnacted = (policiesEnacted, categoryPolicies) => {
  let hasPolicy = false;
  if (categoryPolicies && policiesEnacted) {
    policiesEnacted.forEach((d) => {
      categoryPolicies.some((i) => {
        return d.id === i.id;
      });
    });
  }
  return hasPolicy;
};

class Category extends Component {
  constructor(props) {
    super(props);
    this.barRefs = this.props.subCats.reduce((r, d) => {
      r[d.key] = createRef();
      return r;
    }, {});
  }

  render() {
    let {
      subCats,
      genre,
      categoryTitle,
      categories,
      userOptions,
      baselineOptions,
      baseline,
      user,
      budgetOptionsByCategory,
      onGrowthRateChange,
      onGrowthSliderChange,
      onGrowthRateAdjustmentChange,
      calcKey,
      definitions,
      displayYear,
      growthKey = categoryTitle,
      userChangeMade,
    } = this.props;

    const startYear = baseline.startYear;

    const sortedSubCats = sortBy(
      subCats,
      (d) => baseline.BUDGET[d.key].values[startYear]
    )
      .reverse()
      .map((d) => {
        d.label = definitions[d.key];
        d.url = categoryUrl(calcKey, genre, d.label);
        d.genre = genre;
        const hasChanges =
            Object.values(
              difference(userOptions[d.key], baselineOptions[d.key])
            ).length > 0,
          hasPolicies = categoryHasPolicyEnacted(
            userOptions.policiesEnacted,
            budgetOptionsByCategory[d.key]
          );
        d.hasChanges = hasChanges || hasPolicies;
        return d;
      });

    const subCategoryLinks = sortedSubCats.reduce((r, d, i) => {
      r.push(
        Subcategory({
          ...d,
          comma: i < sortedSubCats.length - 1,
          barRef: this.barRefs[d.key],
        })
      );

      return r;
    }, []);

    const flatCats = flattenTree(categories),
      children = childCategories(categoryTitle, flatCats).map((d) => d.key),
      cboValue = sumCategories(children, baseline.BUDGET, displayYear),
      yourValue = sumCategories(children, user.BUDGET, displayYear),
      parentKey = find(flatCats, (d) => d.key === categoryTitle).parent;

    const growthRateValue = userOptions[growthKey].growthRate,
      growthRateAdjustmentValue = userOptions[growthKey].growthRateAdjustment,
      growthRateAdditionValue = userOptions[growthKey].growthRateAddition,
      parentGrowthRateAdditionValue = userOptions[parentKey].growthRateAddition;

    return (
      <div className={`category-wrap`}>
        <div className="Category category-grouping outer-container">
          <CategoryHeader
            title={
              <h3 className={`heading font--h3 bold color--${genre}`}>
                {definitions[categoryTitle]}
              </h3>
            }
            onGrowthRateChange={onGrowthRateChange}
            onGrowthRateAdjustmentChange={onGrowthRateAdjustmentChange}
            growthRateAdjustmentValue={growthRateAdjustmentValue}
            growthRateValue={growthRateValue}
            onGrowthSliderChange={onGrowthSliderChange}
            growthRateAdditionValue={growthRateAdditionValue}
            parentGrowthRateAdditionValue={parentGrowthRateAdditionValue}
            tooltipClass={`color--${genre}`}
            toggleSettings
          />
          <CategorySnapshot
            cboValue={cboValue}
            yourValue={yourValue}
            genre={genre}
            year={displayYear}
          />
          <SubcategoryBars
            userChangeMade={userChangeMade}
            cats={sortedSubCats}
            genre={genre}
            barRefs={this.barRefs}
          />
          <div className="subcategories font--label">{subCategoryLinks}</div>
        </div>
        <SubcategoryTooltips
          cats={sortedSubCats}
          user={user.BUDGET}
          baseline={baseline.BUDGET}
          displayYear={displayYear}
          genre={genre}
        />
      </div>
    );
  }
}

class SubcategoryBars extends Component {
  constructor() {
    super();
    this.state = {
      toPage: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    //rebuild tooltips if we added the user bar
    if (
      this.props.userChangeMade &&
      this.props.userChangeMade !== prevProps.userChangeMade
    ) {
      ReactTooltip.rebuild();
    }
  }

  onClick(url) {
    return () => {
      this.setState({
        toPage: url,
      });
    };
  }

  render() {
    const { cats, barRefs, userChangeMade } = this.props;
    const barHeight = 36;
    let currentUserX = 0,
      currentBaselineX = 0;

    return this.state.toPage ? (
      <Redirect to={this.state.toPage} />
    ) : (
      <div className="SubcategoryBars">
        <svg
          viewBox={`0 -1 102 ${barHeight * 2 + 3}`}
          strokeWidth="1"
          preserveAspectRatio="none"
          height={barHeight}
          width={"100%"}
          className="subcategory-bars font--label--small"
        >
          {cats.map((d, i) => {
            //const isAffected = d.userRadius !== d.baselineRadius;
            const showUserBar = userChangeMade;
            const rect = (
              <g key={d.label}>
                <g key={d.label + "baseline"}>
                  <AnimatedRect
                    forwardRef={barRefs[d.key]}
                    data-tip
                    data-for={idPrefix + d.key}
                    onClick={this.onClick(d.url)}
                    vectorEffect="non-scaling-stroke"
                    className={`baseline darken-on-hover fill--${d.genre} stroke--white`}
                    width={d.baselineRadius}
                    height={showUserBar ? barHeight : barHeight * 2 + 3}
                    x={currentBaselineX}
                    y={0}
                  />
                </g>
                {showUserBar && (
                  <g key={d.label + "user"}>
                    <AnimatedRect
                      forwardRef={barRefs[d.key]}
                      data-tip
                      data-for={idPrefix + d.key}
                      onClick={this.onClick(d.url)}
                      vectorEffect="non-scaling-stroke"
                      className={`user darken-on-hover fill--user stroke--white`}
                      width={d.userRadius}
                      height={barHeight}
                      x={currentUserX}
                      y={barHeight + 1}
                    />
                  </g>
                )}
              </g>
            );
            currentUserX += d.userRadius;
            currentBaselineX += d.baselineRadius;

            return rect;
          })}
        </svg>
      </div>
    );
  }
}

const Subcategory = ({
  calcKey,
  url,
  label,
  key,
  genre,
  comma,
  barRef,
  hasChanges,
}) => {
  const onMouseEnter = () => {
    //ReactTooltip.show(barRef.current)
    //barRef.current.classList.add('is-hovered')
  };
  const onMouseLeave = () => {
    //ReactTooltip.hide(barRef.current)
    //barRef.current.classList.remove('is-hovered')
  };
  return (
    <Link
      className={`Subcategory font--label color--${genre}`}
      to={url}
      key={label}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className={hasChanges ? "bg--userLight" : ""}>{label}</span>
      {comma && ", "}
    </Link>
  );
};

const SubcategoryTooltips = ({ cats, baseline, user, displayYear, genre }) => {
  return (
    <div>
      {cats.map((d) => {
        const userValue = user[d.key].values[displayYear],
          baselineValue = baseline[d.key].values[displayYear];

        return (
          <ReactTooltip
            className="chart-tooltip"
            id={idPrefix + d.key}
            key={d.key}
            place="top"
            effect="solid"
            type="info"
          >
            <div className={`font--h4 bold color--${genre}`}>{d.label}</div>
            <CategorySnapshot
              cboValue={baselineValue}
              yourValue={userValue}
              genre={genre}
              year={displayYear}
            />
          </ReactTooltip>
        );
      })}
    </div>
  );
};

Category.contextTypes = {
  store: PropTypes.object,
};
const mapDispatchToProps = (dispatch) => {
  return {
    setGrowthRate: (value, category) => {
      dispatch(setGrowthRate(value, category));
    },
    setGrowthRateAddition: (value, category) => {
      dispatch(setGrowthRateAddition(value, category));
    },
    setGrowthRateAdjustment: (value, category) => {
      dispatch(setGrowthRateAdjustment(value, category));
    },
    updateGrowthRateAddition: (value, category) => {
      dispatch(updateGrowthRateAddition(value, category));
    },
  };
};
const mapStateToProps = (state) => ({
  ...state.calculator,
  displayYear: state.session.displayYear,
});

export default connect(mapStateToProps, mapDispatchToProps)(Category);
