import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { loadCalc } from "../Actions.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { formatDollarsFull } from '../formatDollars.js';

const loadCalcFromDatabaseIfNeeded = (currentKey,newKey,databasor,loadCalc) => {
  if (currentKey !== newKey) {
    console.log('loading from database')

    databasor.ref(`/calculators/${newKey}`).once('value').then((snapshot) => {
      if (snapshot.val()) {
        const calc = snapshot.val().calc;
        loadCalc(calc);
      }
    });
  }
}

class Tables extends Component {
  componentWillMount() {
    loadCalcFromDatabaseIfNeeded(this.props.calcKey,this.props.match.params.calcKey,this.props.databasor,this.props.loadCalc)
  }

  render() {
    const { baseline, user } = this.props

    return (
      <div className="nav-pad font--number">
        <Tabs >
          <TabList>
            <Tab>Budget</Tab>
            <Tab>Econ</Tab>
          </TabList>
          <TabPanel>
            <Table
              dataKey={'BUDGET'}
              baseline={baseline}
              user={user}
              format={formatDollarsFull} />
          </TabPanel>
          <TabPanel>
            <Table
              dataKey={'ECON'}
              baseline={baseline}
              user={user} />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

Tables.contextTypes = {
  store: PropTypes.object
}
const mapStateToProps = (state) => ({
  calcKey: state.calculator.calcKey,
  user: state.calculator.user,
  baseline: state.calculator.baseline
})
const mapDispatchToProps = (dispatch) => {
  return {
    loadCalc: (calc) => {
      dispatch(loadCalc(calc))
    }
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(Tables);

const Table = ({ dataKey, baseline, user, format=(d)=>d }) => {
  const
    userbudget = user[dataKey],
    baselinebudget = baseline[dataKey],
    keys = Object.keys(userbudget),
    years = Object.keys(userbudget[keys[0]].values)
  return (
    <table className="BudgetTable">
      <tbody>
        <tr className="font--label--small">
          <td>year</td>
          {keys.map((d) => <td key={d}>{d}</td>)}
        </tr>
        {years.map((y) => (
          <tr key={y}>
              <td>{y}</td>
              {keys.map((key) => <td key={key}>
                <div>{format(baselinebudget[key].values[y])}</div>
                <div className="color--user">{baselinebudget[key].values[y] !== userbudget[key].values[y] && format(userbudget[key].values[y])}</div>
              </td>)}
            </tr>
        ))}
      </tbody>
    </table>
  )
}