import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import Modal from "react-modal";
import Account from "./Account";

import { newCalc, newCalcFrom, resetSession } from "../Actions.js";

import ViewBudget from "components/report";
import Tutorial from "./Tutorial";

import throttle from "lodash/throttle";
import ScrollMemory from "react-router-scroll-memory";

//components
import Nav from "./Nav";
import Edit from "./Edit";
import EditBudgetMetaModal from "./EditBudgetMetaModal";
import Authenticate from "./Authenticate";
import AuthenticateOrSkip from "./AuthenticateOrSkip";
import Admin from "./Admin.js";
import Home from "./Home";
import Styleguide from "./Styleguide";
import Tables from "./Tables";

import { authenticator, databasor } from "fb";

if (document.getElementById("App")) {
  Modal.setAppElement("#App");
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      loggedInUser: null,
      firstAuthComplete: false,
      editBudgetMetaOpen: false,
      authenticateOpen: false,
      authenticateSkipOpen: false,
      isMobile: null,
    };
    this.toggleEditBudgetMeta = this.toggleEditBudgetMeta.bind(this);
    this.toggleAuthenticate = this.toggleAuthenticate.bind(this);
    this.toggleAuthenticateSkip = this.toggleAuthenticateSkip.bind(this);
    this.resized = this.resized.bind(this);
  }

  resized = throttle(() => {
    const breakpoint = 768;
    const isMobile = !window.matchMedia(`(min-width: ${breakpoint}px)`).matches;

    if (this.state.isMobile !== isMobile) {
      this.setState({
        isMobile,
      });
    }
  }, 100);

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.initialCalcKey = this.props.calcKey;

    window.addEventListener("resize", this.resized);
    this.resized();

    const comp = this;

    this.unregisterAuthObserver = authenticator.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.

        const userRef = databasor.ref("users/" + user.uid);

        userRef
          .update({
            displayName: user.displayName,
          })
          .then(() => {
            userRef.on("value", (snapshot) => {
              let userUpdate;
              if (snapshot.val()) {
                userUpdate = {
                  loggedInUser: {
                    ...user,
                    roles: snapshot.val().roles,
                  },
                  firstAuthComplete: true,
                };
              } else {
                userUpdate = {
                  loggedInUser: null,
                  firstAuthComplete: false,
                };
              }

              comp.setState(userUpdate);
            });
          });
        this.state.authenticateOpen && this.toggleAuthenticate();
      } else {
        // No user is signed in.
        comp.setState({
          loggedInUser: null,
          firstAuthComplete: true,
        });
      }
    });
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
    window.removeEventListener("resize", this.resized);
  }

  toggleAuthenticate() {
    this.setState({
      authenticateOpen: !this.state.authenticateOpen,
    });
  }
  toggleAuthenticateSkip() {
    this.setState({
      authenticateSkipOpen: !this.state.authenticateSkipOpen,
    });
  }
  toggleEditBudgetMeta() {
    this.setState({
      editBudgetMetaOpen: !this.state.editBudgetMetaOpen,
    });
  }

  render() {
    const { loggedInUser, firstAuthComplete } = this.state;
    const { newCalc, newCalcFrom } = this.props;

    return (
      <Router>
        <div className="App">
          <ScrollMemory />
          <Switch>
            <Route
              path="/:calcKey/edit"
              render={() => (
                <Nav
                  showHelp={true}
                  loggedInUser={loggedInUser}
                  firstAuthComplete={firstAuthComplete}
                  toggleAuthenticate={this.toggleAuthenticate}
                />
              )}
            />
            <Route
              path=""
              render={() => (
                <Nav
                  loggedInUser={loggedInUser}
                  firstAuthComplete={firstAuthComplete}
                  toggleAuthenticate={this.toggleAuthenticate}
                />
              )}
            />
          </Switch>
          <Route exact path={"/"} component={Home} />
          <Route
            path={"/new/:version?"}
            render={({ match }) => {
              //If we got a new key redirect to edit
              if (
                this.initialCalcKey &&
                this.props.calcKey !== this.initialCalcKey
              ) {
                this.initialCalcKey = this.props.calcKey;
                return <Redirect to={`/${this.props.calcKey}/edit`} />;
              }

              //if not, generate new calc which will trigger redirect above when the store updates
              newCalc(match.params.version);
              return <div>loading</div>;
            }}
          />
          <Route
            path={"/newfrom/:calcKey"}
            render={({ match }) => {
              console.log(match.params.calcKey, this.props.calcKey);
              if (
                this.props.calcKey &&
                this.props.calcKey !== match.params.calcKey
              ) {
                return <Redirect to={`/${this.props.calcKey}/edit`} />;
              }

              //if not, generate new calc from the old which will trigger redirect above when the store updates
              newCalcFrom(match.params.calcKey);
              return <div>loading</div>;
            }}
          />
          <Route
            path={"/:calcKey/edit"}
            render={({ match }) => {
              return (
                <Edit
                  isMobile={this.state.isMobile}
                  match={match}
                  loggedInUser={loggedInUser}
                  firstAuthComplete={firstAuthComplete}
                  toggleEditBudgetMeta={this.toggleEditBudgetMeta}
                  toggleAuthenticateSkip={this.toggleAuthenticateSkip}
                />
              );
            }}
          />
          <Route
            path={"/:calcKey/view"}
            render={(props) => {
              this.initialCalcKey = props.match.params.calcKey;
              return (
                <ViewBudget
                  {...props}
                  isMobile={this.state.isMobile}
                  toggleEditBudgetMeta={this.toggleEditBudgetMeta}
                  toggleAuthenticateSkip={this.toggleAuthenticateSkip}
                />
              );
            }}
          />
          <Route
            path={"/:calcKey/tables"}
            render={(props) => (
              <Tables match={props.match} databasor={databasor} />
            )}
          />

          <Route
            exact
            path={"/account"}
            render={() => (
              <Account
                authenticator={authenticator}
                firstAuthComplete={firstAuthComplete}
                loggedInUser={loggedInUser}
              />
            )}
          />

          <Route
            exact
            path={"/admin"}
            render={() =>
              loggedInUser && loggedInUser.roles.includes("admin") ? (
                <Admin />
              ) : (
                <div className="nav-pad">
                  You don't have permission to access this page.
                </div>
              )
            }
          />

          <Route exact path={"/tutorial"} component={Tutorial} />

          <Route exact path={"/styleguide"} component={Styleguide} />

          <Authenticate
            toggle={this.toggleAuthenticate}
            isOpen={this.state.authenticateOpen}
          />

          <AuthenticateOrSkip
            toggle={this.toggleAuthenticateSkip}
            isOpen={this.state.authenticateSkipOpen}
            skipTo={`/${this.props.calcKey}/view`}
          />

          <EditBudgetMetaModal
            toggle={this.toggleEditBudgetMeta}
            isOpen={this.state.editBudgetMetaOpen}
          />
        </div>
      </Router>
    );
  }
}
App.contextTypes = {
  store: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    calcKey: state.calculator.calcKey,
    calcAuthorUid: state.calculator.authorUid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newCalc: (version) => {
      dispatch(resetSession);
      dispatch(newCalc(version));
    },
    newCalcFrom: (calcKey) => {
      dispatch(newCalcFrom(calcKey));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
