export default {
	spending: {
		primary: "#2e07a9",
		muted: "#eae6f6"
	},
	revenue: {
		primary: "#1a8446",
		muted: "#ddede3"
	},
	user: "#36c4ee"
}