import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from './icons/DeleteIcon';

import firebase from "firebase/app";
import 'firebase/database';
const { database } = firebase;


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
});

class FeaturedCalcsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.calcs ? Object.values(this.props.calcs) : null,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      items: props.calcs ? Object.values(props.calcs) : null
    })
  }

  updateOrder(items) {
    const updates = {}
    items.forEach((d,i) => {
      updates[`meta/featuredCalculators/${d.calcKey}`] = {
        ...d,
        order: i
      }
    })
    database().ref().update(updates);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });

    this.updateOrder(items)
  }

  onDeleteClick(calcKey) {
    return () => {
      database().ref(`meta/featuredCalculators/${calcKey}`).remove()
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.items && this.state.items.map((item, index) => {
                return (
                  <Draggable
                    key={item.calcKey}
                    draggableId={item.calcKey}
                    index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.calcKey}
                        <button onClick={this.onDeleteClick(item.calcKey)}>
                          <DeleteIcon />
                        </button>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default FeaturedCalcsAdmin;