export default function(datasets) {

	const
		normalize = require("../../../normalize.js"),
		operations = require("../../../operations.js")(datasets)

	const data = {
		//Defense
		"DEFENSE_OPERATIONS": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "DEFENSE_OPERATIONS",
			"normalize": normalize.billions
		},
		"DEFENSE_OTHER": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "DEFENSE_OTHER",
			"normalize": normalize.billions
		},
		"DEFENSE_PERSONNEL": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "DEFENSE_PERSONNEL",
			"normalize": normalize.billions
		},
		"DEFENSE_PROCUREMENT": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "DEFENSE_PROCUREMENT",
			"normalize": normalize.billions
		},
		"DEFENSE_RTDE": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "DEFENSE_RTDE",
			"normalize": normalize.billions
		},

		//Non-Defense
		"EDUCATION": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "EDUCATION",
			"normalize": normalize.billions
		},
		"ENERGY": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "ENERGY",
			"normalize": normalize.billions
		},
		"ENVIRONMENT": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "ENVIRONMENT",
			"normalize": normalize.billions
		},
		"TRANSPORTATION": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "TRANSPORTATION",
			"normalize": normalize.billions
		},
		"INTERNATIONAL": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "INTERNATIONAL",
			"normalize": normalize.billions
		},
		"JUSTICE": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "JUSTICE",
			"normalize": normalize.billions
		},
		"SCIENCE": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "SCIENCE",
			"normalize": normalize.billions
		},
		"VA_DISCRETIONARY": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "VA_DISCRETIONARY",
			"normalize": normalize.billions
		},
		"OTHER_DISCRETIONARY": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "OTHER_DISCRETIONARY",
			"normalize": normalize.billions
		},

		//Health Care
		"MEDICARE": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "MEDICARE",
			"normalize": normalize.billions
		},
		"MEDICAID": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "MEDICAID",
			"normalize": normalize.billions
		},
		"CHIP": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "CHIP",
			"normalize": normalize.billions
		},
		"ACA": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "ACA",
			"normalize": normalize.billions
		},

		//Social Security
		SOCIAL_SECURITY_ALL: {
			"sheet": "cbo_10yr_accountprojections",
			"column": "SOCIAL_SECURITY_ALL",
			"normalize": normalize.billions
		},

		//Income security programs
		"REFUNDABLE_TAX_CREDITS": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "REFUNDABLE_TAX_CREDITS",
			"normalize": normalize.billions
		},
		"VA_MANDATORY": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "VA_MANDATORY",
			"normalize": normalize.billions
		},
		"UNEMPLOYMENT": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "UNEMPLOYMENT",
			"normalize": normalize.billions
		},
		"SUPPLEMENTAL_SECURITY": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "SUPPLEMENTAL_SECURITY",
			"normalize": normalize.billions
		},
		"NUTRITIONAL_ASSISTANCE": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "NUTRITIONAL_ASSISTANCE",
			"normalize": normalize.billions
		},

		//Other
		"AGRICULTURE": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "AGRICULTURE",
			"normalize": normalize.billions
		},
		"OTHER_MANDATORY": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "OTHER_MANDATORY",
			"normalize": normalize.billions
		},

		"Mandatory: All Exc. HC, SS, FS, and Refundable Tax Credits": {
			"formula": function() {
				return operations.sumCols([{
						dataset: "CBO_10",
						column: "UNEMPLOYMENT",
					},
					{
						dataset: "CBO_10",
						column: "SUPPLEMENTAL_SECURITY"
					},
					{
						dataset: "CBO_10",
						column: "VA_MANDATORY"
					},
					{
						dataset: "CBO_10",
						column: "AGRICULTURE"
					},
					{
						dataset: "CBO_10",
						column: "OTHER_MANDATORY"
					}
				]);
			}
		},

		"PERSONAL_INCOME_TAXES": {
			"sheet": "cbo_10yr_accountprojections",
			"normalize": normalize.billions
		},
		"CORPORATE_INCOME_TAXES": {
			"sheet": "cbo_10yr_accountprojections",
			"normalize": normalize.billions
		},
		"OTHER_TAXES": {
			"sheet": "cbo_10yr_accountprojections",
			"normalize": normalize.billions
		},

		"SOCIAL_SECURITY_TAXES": {
			"sheet": "cbo_10yr_accountprojections",
			"normalize": normalize.billions
		},
		"MEDICARE_TAXES": {
			"sheet": "cbo_10yr_accountprojections",
			"normalize": normalize.billions,
		},
		"OTHER_PAYROLL_TAXES": {
			"sheet": "cbo_10yr_accountprojections",
			"normalize": normalize.billions
		},
		"Net Debt": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "DEBT",
			"normalize": normalize.billions
		},
		"Surplus: Total": {
			"sheet": "cbo_10yr_accountprojections",
			"column": "SURPLUS/DEFICIT",
			"normalize": normalize.billions
		},
		"Other Debt Additions": {
			"formula": function() {
				var delta = datasets.CBO_10["Net Debt"].values,
					add = datasets.CBO_10["Surplus: Total"].values,
					results = {};

				Object.keys(delta).forEach(year => {
					if (delta[year - 1]) {
						results[year] = delta[year] - delta[year - 1] + add[year];
					}
				})

				return results;
			}
		}
	}

	return data
}