import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BudgetChange from './BudgetChange';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Tooltip from 'react-tooltip';
import Measure from 'react-measure';

const pullCats = (cats,changes,definitions, genre, baseline, user, displayYear) => {
  return cats.reduce((r,d) => {
    if (!changes[d]) return r

    r.push(
      <BudgetChange
        key={d}
        catKey={d}
        changes={changes[d]}
        genre={genre}
        definitions={definitions}
        includeSnapshot={true}
        includeLink={false}
        baseline={baseline}
        user={user}
        displayYear={displayYear}
        />
    )
    return r;
  }, [])
}

const reduceChanges = (categories, changes, definitions, genre, baseline, user, displayYear) => {
  //shallow econ
  if (Array.isArray(categories)) {
    return pullCats(categories, changes, definitions, genre, baseline, user, displayYear);
  }

  return Object.keys(categories).reduce((r,d) => {
    if (Array.isArray(categories[d])) {
      const pulledCats = pullCats(categories[d], changes, definitions, genre, baseline, user, displayYear);
      return r.concat(pulledCats)
    } else if (typeof categories[d] === "object") {
      return r.concat(reduceChanges(categories[d], changes, definitions, baseline, user, displayYear))
    } else {
      console.log("what?",categories[d])
      return r
    }
  }, [])
}

class UnconnectedBudgetChanges extends Component {
  static contextTypes = {
    store: PropTypes.object
  }

  constructor() {
    super()
    this.state = {
      top: null
    }
  }
  render() {
    const {
      changes,
      categories,
      className="",
      definitions,
      baseline,
      user,
      displayYear,
      isMobile,
    } = this.props

    const
      disc = reduceChanges(categories.SPENDING.DISCRETIONARY, changes, definitions, 'spending', baseline, user, displayYear),
      mand = reduceChanges(categories.SPENDING.MANDATORY, changes, definitions, 'spending', baseline, user, displayYear),
      rev = reduceChanges(categories.REVENUE.ALL_REVENUE, changes, definitions, 'revenue', baseline, user, displayYear),
      econ = reduceChanges(categories.ECONOMY, changes, definitions, 'economy', baseline, user, displayYear)

    const changeCounts = [rev.length, mand.length + disc.length, econ.length]
    const maxChanges = Math.max(...changeCounts);
    const defaultIndex = changeCounts.indexOf(maxChanges);

    return (
      <Measure
        offset
        onResize={({ offset }) => {
          this.setState({
            top: offset.top - (isMobile ? 64 : 0)
          })
        }}>
        {({ measureRef }) => (
        <div ref={measureRef}>
          <Tabs
            className={`BudgetChanges ${className}`}
            defaultIndex={defaultIndex}
            onSelect={() => {
              window.scroll(0,this.state.top)
            }}>
            <TabList className="genre-tabs reset-list">
              <Tab className="genre-tab revenue">
                <span className="font--label--alt">Revenue</span>
                <span className={`change-count bg--white bold color--revenue font--number--small`}>{rev.length}</span>
              </Tab>
              <Tab className="genre-tab spending">
                <span className="font--label--alt">Spending</span>
                <span className={`change-count bg--white bold color--spending font--number--small`}>{mand.length + disc.length}</span>
              </Tab>
              <Tab className="genre-tab economy">
                <span className="font--label--alt">Economy</span>
                <span className={`change-count bg--white bold color--economy font--number--small`}>{econ.length}</span>
              </Tab>
            </TabList>
            <TabPanel>
              <div className="BudgetChanges-genre page-pad--large">
                {rev.length ? rev : <div className="font--h2">No revenue changes were made</div>}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="BudgetChanges-genre page-pad--large">
                {mand.length || disc.length ? (
                  [...mand,
                  ...disc]
                ) : <div className="font--h2">No spending changes were made</div>}
              </div>
            </TabPanel>
            <TabPanel>
              <div  className="BudgetChanges-genre page-pad--large">
                {econ.length ? econ : <div className="font--h2">No economy changes were made</div>}
              </div>
            </TabPanel>
            <Tooltip />
          </Tabs>
        </div>)}
      </Measure>
    )
  }
}
const mapBudgetStateToProps = (state) => ({
  definitions: state.calculator.definitions,
  categories: state.calculator.categories,
  baseline: state.calculator.baseline,
  user: state.calculator.user,
  displayYear: state.session.displayYear
})

export default connect(mapBudgetStateToProps)(UnconnectedBudgetChanges);