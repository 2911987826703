export default function(datasets) {
	const
		normalize = require("../../../normalize.js"),
		operations = require("../../../operations.js")(datasets),
		formulas = require("../../../formulas.js")(datasets),
		startYear = datasets.startYear,
		kickoff = formulas.kickoff

	const data = {
		"Original Average Wage Index": {
			"sheet": "ssa_6g6",
			"column": "Average wage index"
		},
		"Modified Average Wage Index": {
			"formula": function() {
				const results = kickoff(data["Original Average Wage Index"].values)

				return results
			},
			"update": function(year) {
				const
					results = this.values,
					LPIbase = datasets.ECON_BASE["Labor Productivity Growth Rate (Nominal)"].values,
					LPIbench = datasets.ECON_BENCH["Labor Productivity Index (Nominal)"].values,
					LPIcbo = datasets.ECON_CBO_ASSUMED["Labor Productivity Index (Nominal)"].values,
					OAWI = data["Original Average Wage Index"].values

				if (year <= datasets.startYear + 10) {
					results[year] = OAWI[year] * (LPIbench[year] / LPIcbo[year])
				} else {
					results[year] = results[year-1] * (1 + LPIbase[year])
				}
			}
		},
		"SS Total Population": {
			"sheet": "ssa_4b3",
			"column": "OASDI Beneficiaries (1000s)",
			"normalize": normalize.thousands
		},
		"SS Death Rate": {
			"sheet": "ssa_5a1",
			"column": "65 and over death rate (per 100000)",
			"normalize": normalize.percent100k
		},
		"New Enrollees": {
			"formula": function() {
				const rates = operations.sumValuesConst(operations.negate(data["SS Death Rate"].values), 1);
				const adjusted = operations.productValues([operations.yearShift(data["SS Total Population"].values, -1),rates])
				const results = operations.differenceValues(data["SS Total Population"].values, [adjusted]);

				return results
			}
		},
		"Current Enrollees": {
			"formula": function() {
				return operations.differenceValues(data["SS Total Population"].values, [data["New Enrollees"].values])
			}
		},
		"Ratio Current to Previous Total": {
			"formula": function() {
				return operations.quotientValues(data["Current Enrollees"].values, operations.yearShift(data["SS Total Population"].values, -1))
			}
		},
		"Outlays for New Enrollees": {
			"values": {},
			"update": function(year) {
				if (year > startYear + 10) {
					const
						FRA = datasets.TECHNICAL_ASSUMPTIONS["Full Retirement Age (FRA)"],
						ARA = datasets.TECHNICAL_ASSUMPTIONS["Average Retirement Age (ARA)"],
						gap = FRA - ARA

					//this.values[year] = data["Modified Average Wage Index"].values[year - 7] * data["New Enrollees"].values[year] * datasets.TECHNICAL_ASSUMPTIONS["Social Security Replacement"]
					this.values[year] = data["Modified Average Wage Index"].values[year] *
						(1 - (Math.min(gap,3)*60/9 + Math.max(0,(gap - 3)*5))/100) *
						datasets.TECHNICAL_ASSUMPTIONS["Social Security Replacement"] *
						data["New Enrollees"].values[year]
				}
			}
		},
		"Outlays for OASI Enrollees": {
			"values": {},
			"update": function(year) {
				if (year > startYear + 10) {
					this.values[year] = data["Outlays: OASI"].values[year - 1] *
						data["Ratio Current to Previous Total"].values[year] *
						(1 + datasets.ECON_BENCH.INFLATION_RATE.values[year])
				}
			}
		},
		"Long-Term OASI": {
			"values": {},
			"update": function(year) {
				if (year > startYear + 10) {
					this.values[year] = data["Outlays for New Enrollees"].values[year] + data["Outlays for OASI Enrollees"].values[year]
				}
			}
		},
		"10-YR OASI": {
			"formula": function() {
				return datasets.CBO_10.SOCIAL_SECURITY_ALL.values;
				//TODO Add in changes
			}
		},
		"Outlays: OASI": {
			//Was 2 cols one before user % change, one after
			"values": {},
			"update": function(year) {
				if (year <= startYear + 10) {
					this.values[year] = data["10-YR OASI"].values[year] * datasets.BUDGET_INITIAL["INFLATION_RATIO"].values[year]
				}
				if (year > startYear + 10) {
					this.values[year] = data["Long-Term OASI"].values[year]
				}
			}
		},
	}

	return data
}