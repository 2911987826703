module.exports = {
  REVENUE: "revenue",
  ALL_REVENUE: "Revenue",
  INDIVIDUAL_TAXES: "Personal Income Taxes",
  PERSONAL_INCOME_TAXES: "Personal Income Taxes",
  PAYROLL_TAXES: "Payroll Taxes",
  SOCIAL_SECURITY_TAXES: "Social Security Payroll Taxes",
  MEDICARE_TAXES: "Medicare Payroll Taxes",
  OTHER_PAYROLL_TAXES: "Other Payroll Taxes",
  CORPORATE_TAXES: "Corporate Income Taxes",
  CORPORATE_INCOME_TAXES: "Corporate Income Taxes",
  OTHER_REVENUE: "Other",
  OTHER_TAXES: "Other Taxes",

  SPENDING: "spending",
  MANDATORY: "Mandatory Spending",
  SOCIAL_SECURITY: "Social Security",
  SOCIAL_SECURITY_ALL: "Social Security Retirement & Disability",
  HEALTH_CARE: "Health Care",
  MEDICARE: "Medicare",
  MEDICAID: "Medicaid",
  CHIP: "Children's Health Insurance Program (CHIP)",
  ACA: "Affordable Care Act (ACA)",
  INCOME_SECURITY: "Income Security Programs",
  REFUNDABLE_TAX_CREDITS: "Refundable Tax Credits",
  VA_MANDATORY: "Veteran Benefits (mandatory)",
  UNEMPLOYMENT: "Unemployment Insurance",
  SUPPLEMENTAL_SECURITY: "Supplemental Security Income (SSI)",
  NUTRITIONAL_ASSISTANCE: "Nutritional assistance programs",
  OTHER_MANDATORY_SPENDING: "Other Mandatory Spending",
  AGRICULTURE: "Agriculture",
  OTHER_MANDATORY: "Other Mandatory",

  DISCRETIONARY: "Discretionary Spending",
  NONDEFENSE: "Non-Defense",
  EDUCATION: "Education",
  ENERGY: "Energy",
  ENVIRONMENT: "Environment",
  TRANSPORTATION: "Transportation",
  INTERNATIONAL: "International Programs",
  JUSTICE: "Justice",
  SCIENCE: "Science and Technology",
  VA_DISCRETIONARY: "Veteran Benefits (discretionary)",
  OTHER_DISCRETIONARY: "Other Discretionary",
  DEFENSE: "Defense",
  DEFENSE_OPERATIONS: "Defense Operations",
  DEFENSE_OTHER: "Other Defense",
  DEFENSE_PERSONNEL: "Defense Personnel",
  DEFENSE_PROCUREMENT: "Defense Procurement",
  DEFENSE_RTDE: "Defense RTD&E",

  ECONOMY: "Economy",
  TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE:
    "Total Factor Productivity Growth Rate",
  LABOR_FORCE_GROWTH_RATE: "Labor Force Growth Rate",
  UNEMPLOYMENT_RATE: "Unemployment Rate",
  INFLATION_RATE: "Inflation Rate",
  REAL_INTEREST_RATE: "Real Interest Rate",

  CBO_GROWTH: "CBO Baseline Growth Rate",
  ZERO_GROWTH: "Zero Growth",
  INFLATION_GROWTH: "Grow at Inflation",
  GDP_GROWTH: "Grow at Nominal GDP",
  HISTORICAL_GROWTH: "Historical Rate",
  CBO_RATE: "CBO Baseline Rate",
  ZERO_RATE: "Zero Rate",
  HISTORICAL_RATE: "Historical Rate",

  NET_DEBT: "Net Debt",
  SURPLUS: "Surplus/Deficit",
  TOTAL_REVENUE: "Total Revenue",
  TOTAL_SPENDING: "Total Spending",
  NOMINAL_GDP: "Nominal GDP",
  REAL_GDP: "Real GDP",
  "Real GDP Growth Rate": "Real GDP Growth Rate",
  "Nominal GDP": "Nominal GDP",
  "Net Interest": "Net Interest",

  "Mandatory: Total": "Total Mandatory Spending",
  "Discretionary: Total": "Total Discretionary Spending",
  "Outlays: Non-Interest": "Non-interest spending",
  "Capital Growth": "Capital Services Growth Rate",

  growthRate: "Base Growth Rate",
  growthRateAdjustment: "Growth Rate Adjustment",
  growthRateAddition: "Growth Rate Addition",
  additions: "Dollar Additions",
};
