import React, { Component } from "react";
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import Tooltip from "react-tooltip";

import EconGrowthRateSelect from './EconGrowthRateSelect';
import EconTrendChart from "./EconTrendChart";
import CategoryHeader from "./CategoryHeader";
import CategorySnapshot from "./CategorySnapshot";
import CategoryTrail from './CategoryTrail';
import { formatPercentage, formatPercentageDelta, formatDollars, formatDollarsDelta } from "../formatDollars";
import { setGrowthRate, setGrowthRateAdjustment } from '../Actions';
import CategoryMeta from '../categorymeta';
import camelCase from '../camelCase.js';

class Econ extends Component {
	constructor() {
		super()
		this.onGrowthRateChange = this.onGrowthRateChange.bind(this)
	}
	componentDidMount() {
		Tooltip.rebuild()
	}
	onGrowthRateChange(category) {
		return (value) => {
		  this.props.setGrowthRate(value,category)
		}
	}
	render() {
		const {
			baseline,
			user,
			userOptions,
			setGrowthRateAdjustment,
			categories,
			definitions,
			displayYear
		} = this.props

		const cats = [
				<div className="EconSettingsPair" key="GDP">{[
					{
						column: "Real GDP Growth Rate",
						unit: "percentage",
						description: "The percent change in GDP after accounting for inflation."
					},
					{
						column: "Nominal GDP",
						unit: "dollars",
						description: "The total dollar value of goods and services produced in the year."
					}
				].map((item) => (
				<div className="EconSetting category-wrap" key={item.column}>
					<CategoryHeader title={<h3 className="font--h3 bold color--economy">{item.column}</h3>} />
					<CategorySnapshot
						cboValue={baseline.ECON[item.column].values[displayYear]}
						yourValue={user.ECON[item.column].values[displayYear]}
						format={item.unit === "dollars" ? formatDollars : (d) => formatPercentage(100*d)}
						deltaFormat={item.unit === "dollars" ? formatDollarsDelta : (d) => formatPercentageDelta(100*d)}
						genre="economy"
						year={displayYear} />
					<div className="category-description category-description--has-copy font--body color--black">
						<EconTrendChart column={item.column} />
						{item.description}
					</div>
				</div>)
			)}</div>,
			...categories.ECONOMY.map((cat) => (
				<EconSetting
					key={cat}
					catKey={cat}
					title={definitions[cat]}
					onGrowthRateChange={this.onGrowthRateChange(cat)}
					onGrowthRateAdjustmentChange={(value) => {
						setGrowthRateAdjustment(value,cat)
					}}
					baseline={baseline.ECON}
					user={user.ECON}
					userValue={userOptions[cat]}
					displayYear={displayYear} />
			))
		]

		return (
			<div className="Group economy shadow bg--white">
				<div className="Group-header outer-container">
					<h2 className="genre-heading font--h2 color--economy bold">Economy</h2>
				</div>
				<CategoryTrail>{cats}</CategoryTrail>
			</div>
	  )
	}
}
Econ.contextTypes = {
	store: PropTypes.object
}
const mapStateToProps = (state) => ({
  baseline: state.calculator.baseline,
  user: state.calculator.user,
  userOptions: state.calculator.userOptions,
  categories: state.calculator.categories,
  definitions: state.calculator.definitions,
  displayYear: state.session.displayYear
})
const mapDispatchToProps = (dispatch,ownProps) => {
	return {
		setGrowthRate: (value,category) => {
			dispatch(setGrowthRate(value,category))
		},
		setGrowthRateAdjustment: (value, category) => {
			dispatch(setGrowthRateAdjustment(value,category))
		}
	}
}
export default connect(mapStateToProps,mapDispatchToProps)(Econ)

const EconSetting = ({
	title,
	onGrowthRateChange,
	onGrowthRateAdjustmentChange,
	baseline,
	user,
	catKey,
	userValue,
	format=(d) => formatPercentage(100*d),
	formatDelta=(d) => formatPercentageDelta(100*d),
	displayYear,
	description = CategoryMeta[catKey] || ""
}) => {
	const settings = onGrowthRateChange && onGrowthRateAdjustmentChange ? (
		<div className="GrowthRateSettings">
			<div className="GrowthRateSetting">
				<EconGrowthRateSelect
					label={title.toLowerCase().indexOf("growth") === -1 ? "Adjust rate" : undefined}
					category={catKey}
					onChange={onGrowthRateChange}
					onGrowthRateAdjustmentChange={onGrowthRateAdjustmentChange}
					growthRateAdjustmentValue={userValue.growthRateAdjustment}
					value={userValue.growthRate} />
			</div>
		</div>
	) : null;

	const
		userValues = user[catKey].values,
		baselineValues = baseline[catKey].values

	return (
		<div className="EconSetting category-wrap outer-container">
			<div id={camelCase(title)} className="anchor">{title}</div>
			<CategoryHeader
				title={<h3 className="font--h3 bold color--economy">{title}</h3>}
				settings={settings} />
			<CategorySnapshot
				cboValue={baselineValues[displayYear]}
				yourValue={userValues[displayYear]}
				format={format}
				deltaFormat={formatDelta}
				genre="economy"
				year={displayYear} />
			<div className={`category-description ${description ? "category-description--has-copy" : ""} font--body color--black`}>
				<EconTrendChart column={catKey} />
				{description}
			</div>
		</div>
	)
}