import React from "react";
import { connect } from "react-redux";
import imageSrc from "assets/16.2.png";
import { formatPercentage } from "formatDollars";
import { useSprings, a } from "react-spring";
import styles from "components/embed/entry-point/entry-point.module.scss";

function increaseDecrease(value) {
  return value > 0 ? "increased" : "decreased";
}
function raiseLower(value) {
  return value > 0 ? "raised" : "lowered";
}

const Final = ({ reset, year, baseline, user }) => {
  const userGdp = user.ECON["Nominal GDP"].values[year];
  const baselineGdp = baseline.ECON["Nominal GDP"].values[year];

  const yourSpending = user.BUDGET.TOTAL_SPENDING.values[year],
    baselineSpending = baseline.BUDGET.TOTAL_SPENDING.values[year],
    spendingDelta =
      (100 * (yourSpending - baselineSpending)) / baselineSpending,
    yourRevenue = user.BUDGET.TOTAL_REVENUE.values[year],
    baselineRevenue = baseline.BUDGET.TOTAL_REVENUE.values[year],
    revenueDelta = (100 * (yourRevenue - baselineRevenue)) / baselineRevenue;

  const yourDebt = Math.round(
    (100 * user.BUDGET.NET_DEBT.values[year]) / userGdp
  );
  const baselineDebt = Math.round(
    (100 * baseline.BUDGET.NET_DEBT.values[year]) / baselineGdp
  );
  const debtDelta = yourDebt - baselineDebt;

  const heading = "Your Results";

  const [props] = useSprings(4, (index) => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: Math.pow(1.5, index) * 500,
  }));

  return (
    <div className={`${styles.container} Final Report-footer-module bg--user`}>
      <a.div style={props[1]} className={styles.heading}>
        {heading}
      </a.div>
      <a.div style={props[2]} className={styles.body}>
        Your budget in <span className="Embed-landing-body-value">{year}</span>{" "}
        {increaseDecrease(spendingDelta)} spending by{" "}
        <span className="Embed-landing-body-value">
          {formatPercentage(Math.abs(spendingDelta))}
        </span>{" "}
        and {increaseDecrease(revenueDelta)} revenue by{" "}
        <span className="Embed-landing-body-value">
          {formatPercentage(Math.abs(revenueDelta))}
        </span>
        . Those choices {raiseLower(debtDelta)} the debt by{" "}
        <span className="Embed-landing-body-value">
          {formatPercentage(Math.abs(debtDelta))}
        </span>
        . To make more detailed changes, create a budget with our fully
        interactive budget calculator.
      </a.div>

      <a.div className={styles.linkContainer} style={props[3]}>
        <a
          href="https://calculator.americaoffbalance.org"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.link} hover-bg--trueblack hover-color--user`}
        >
          Go to calculator
        </a>
        <button className={styles.secondaryLink} onClick={reset}>
          Start Over
        </button>
      </a.div>

      <div className={styles.image}>
        <a.img style={props[0]} src={imageSrc} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.calculator.user,
    baseline: state.calculator.baseline,
    year: state.calculator.baseline.endYear,
  };
};
export default connect(mapStateToProps)(Final);
