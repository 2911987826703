export default (datasets) => {

  const startYear = datasets.startYear

  const data = {
    "MATUREDEBT_SHARE": {
      "sheet": "maturedebt_share",
      "columm": "MATUREDEBT_SHARE"
    },
    "NEWDEBT_SHARE": {
      "formula": () => {
        return {
          [startYear]: 0
        }
      },
      "update": function(year) {
        const ND = datasets.BUDGET.NET_DEBT.values
        this.values[year] = (ND[year - 1] - ND[datasets.startYear - 1])/ND[year - 1]
      }
    },
    "DEBT_USER_RATE_SHARE": {
      "formula": () => {
        const year = startYear
        return {
          [year]: data["NEWDEBT_SHARE"].values[year] + data["MATUREDEBT_SHARE"].values[year] * (1 - data["NEWDEBT_SHARE"].values[year])
        }
      },
      "update": function(year) {
        this.values[year] = data["NEWDEBT_SHARE"].values[year] +
          data["MATUREDEBT_SHARE"].values[year] * (1 - data["NEWDEBT_SHARE"].values[year])
      }
    },
    "R_BASELINE": {
      "formula": () => {
        const year = startYear
        return {
          [year]: datasets.ECON_BENCH["Baseline Nominal Interest Rate on Public Debt"].values[year]
        }
      },
      "update": function(year) {
        this.values[year] = datasets.ECON_BENCH["Baseline Nominal Interest Rate on Public Debt"].values[year]
      }
    },
    "R_USER": {
     "formula": () => {
        const year = startYear
        return {
          [year]: datasets.ECON_BENCH["Nominal Interest Rate on Public Debt"].values[year]
        }
      },
      "update": function(year) {
        this.values[year] = datasets.ECON_BENCH["Nominal Interest Rate on Public Debt"].values[year]
      }
    },
    "R_WEIGHT": {
      "formula": () => {
        const year = startYear
        return {
          [year]: data["R_USER"].values[year] *
            data["DEBT_USER_RATE_SHARE"].values[year] +
            data["R_BASELINE"].values[year] *
            (1 - data["DEBT_USER_RATE_SHARE"].values[year])
        }
      },
      "update": function(year) {
        this.values[year] = data["R_USER"].values[year] *
          data["DEBT_USER_RATE_SHARE"].values[year] +
          data["R_BASELINE"].values[year] *
          (1 - data["DEBT_USER_RATE_SHARE"].values[year])
      }
    }
  }

  return data
}