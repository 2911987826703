export default function(datasets) {

	const normalize = require("../../../normalize.js")

	return {
		"TOTAL_FACTOR_PRODUCTIVITY_GROWTH_RATE": {
			"sheet": "cbo_lt_econ",
			"normalize": normalize.percent
		},
		"Labor Productivity Rate": {
			"sheet": "cbo_lt_econ",
			"normalize": normalize.percent
		},
		"Growth of Average Hours Worked": {
			"sheet": "cbo_lt_econ",
			"normalize": normalize.percent
		},
		"Labor Force Growth": {
			"sheet": "cbo_lt_econ",
			"normalize": normalize.percent
		},
		"UNEMPLOYMENT_RATE": {
			"sheet": "cbo_lt_econ",
			"normalize": normalize.percent
		},
		"Real GDP": {
			"sheet": "cbo_lt_econ",
			"normalize": normalize.billions
		},
		"Growth of the CPI-U": {
			"sheet": "cbo_lt_econ",
			"normalize": normalize.percent
		},
		"Growth of the GDP Price Index": {
			"sheet": "cbo_lt_econ",
			"normalize": normalize.percent
		},
		"Nominal Interest Rate on 10-Year Treasury Notes and the OASDI trust funds": {
			"sheet": "cbo_lt_econ",
			"normalize": normalize.percent
		},
		"Nominal Interest Rate on Publicly Held Debt": {
			"sheet": "cbo_lt_econ",
			"normalize": normalize.percent
		},
		"Population": {
			"sheet": "cbo_lt_econ",
			"normalize": normalize.millions
		},
		"Nominal GDP": 	{
			"sheet": "cbo_lt_econ",
			"normalize": normalize.billions
		}
	}
}