import React from "react";
import Progress from "components/embed/questions/progress";
import Status from "./status";
import Question from "./question";
import styles from "./questions.module.scss";
import { a, useTransition, config } from "react-spring";

const Questions = ({ questions, index, nextIndex }) => {
  const t = useTransition(questions[index], (item) => item.key, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: {
      opacity: 1,
      transform: "translateX(-100%)",
      pointerEvents: "none",
      position: "absolute",
    },
    config: config.fast,
  });

  return (
    <div className={styles.questions}>
      <div className={styles.question}>
        {t.map(({ item, props, key }) => {
          return (
            <a.div className={styles.questionWrap} style={props} key={key}>
              <Question {...item} nextIndex={nextIndex} />
            </a.div>
          );
        })}
      </div>
      <div className={styles.status}>
        <Status />
      </div>
      <Progress current={index} total={questions.length} />
    </div>
  );
};

export default Questions;
