import React from "react";
import {
  formatDollars,
  formatDollarsDelta,
  formatPercentage,
  formatPercentageDelta,
} from "../formatDollars";
import { PolicyInfo } from "./Policy";
import CategorySnapshot from "./CategorySnapshot";
import HeroChart from "./HeroChart";

const BudgetChange = ({
  catKey,
  changes,
  genre,
  definitions,
  baseline,
  user,
  displayYear,
}) => {
  const sections = [];
  const category = definitions[catKey];

  const isEcon = genre.toUpperCase() === "ECONOMY";
  const rateSetting = changes.growthRate
    ? isEcon
      ? changes.growthRate.replace("GROWTH", "RATE")
      : changes.growthRate
    : null;
  const fallbackRate = isEcon ? "CBO_RATE" : "CBO_GROWTH";

  if (
    rateSetting ||
    changes.growthRateAdjustment ||
    changes.growthRateAddition ||
    changes.additions
  ) {
    if (rateSetting || changes.growthRateAdjustment) {
      sections.push(
        <div className="BudgetChange-section" key="growthRate">
          <div className="faded font--label--small">
            {isEcon ? "New Rate" : "New Growth Rate"}
          </div>
          <div>
            <span>{definitions[rateSetting ? rateSetting : fallbackRate]}</span>
            <span className="font--number">
              {" "}
              {formatPercentageDelta(changes.growthRateAdjustment)}
            </span>
          </div>
        </div>
      );
    }
    if (changes.growthRateAddition) {
      sections.push(
        <div className="BudgetChange-section" key="growthRateAddition">
          <div className="faded font--label--small">Overall Change</div>
          <div className="font--number">
            {`${formatPercentageDelta(changes.growthRateAddition)}`}
          </div>
        </div>
      );
    }
    if (changes.additions) {
      sections.push(
        <div className="BudgetChange-section" key="additions">
          <div className="faded font--label--small">Year by year changes</div>
          <div className="">
            <ul className="reset-list">
              {Object.keys(changes.additions).reduce((r, d) => {
                r.push(
                  <li
                    className="BudgetChange-item"
                    style={{ display: "flex", alignItems: "flex-end" }}
                    key={d}
                  >
                    <span className="font--label--small">{d}</span>&nbsp;
                    <span className="font--number">
                      {formatDollarsDelta(changes.additions[d])}
                    </span>
                  </li>
                );
                return r;
              }, [])}
            </ul>
          </div>
        </div>
      );
    }
  }

  if (changes.policiesEnacted && changes.policiesEnacted.length) {
    sections.push(
      <div className="BudgetChange-section" key="policies">
        <div className="faded font--label--small">Policies Enacted</div>
        {changes.policiesEnacted.map((policy) => {
          return (
            <div className="BudgetChange-item" key={policy.id}>
              <PolicyInfo policy={policy} definitions={definitions} />
            </div>
          );
        })}
      </div>
    );
  }

  const section = Object.keys(user.ECON).includes(catKey) ? "ECON" : "BUDGET";

  return (
    <div className="BudgetChange">
      <div className={`BudgetChange-title font--h3 color--${genre}`}>
        {category}
      </div>
      <div className="BudgetChange-snapshot">
        <CategorySnapshot
          cboValue={baseline[section][catKey].values[displayYear]}
          yourValue={user[section][catKey].values[displayYear]}
          genre={genre.toLowerCase()}
          format={
            section === "ECON"
              ? (d) => formatPercentage(100 * d)
              : formatDollars
          }
          deltaFormat={
            section === "ECON"
              ? (d) => formatPercentageDelta(100 * d)
              : formatDollarsDelta
          }
          year={displayYear}
        />
        <HeroChart
          mainColumn={catKey}
          showLegend={false}
          showTitle={false}
          user={user}
          baseline={baseline}
        />
      </div>
      <div className="BudgetChange-changes">{sections}</div>
    </div>
  );
};

export default BudgetChange;
