import React from "react";
import SaveButton from "components/SaveButton";
import "./unsaved.scss";

const Unsaved = ({ toggleAuthenticateSkip }) => {
  return (
    <div className={"Unsaved"}>
      This budget is unsaved!
      <SaveButton
        className="link-button"
        toggleAuthenticateSkip={toggleAuthenticateSkip}
      />
    </div>
  );
};

export default Unsaved;
