import React from "react";

export default ({
  domain,
  hoveredX
}) => {

  const ticks = [...domain]

  return (
    <div className={`XAxis font--number ${hoveredX ? "hovered" : ""}`}>
      <div className={`XAxis-tick-wrap`}>
        {ticks.map((d) => <div className="XAxis-tick" key={d}>{d}</div>)}
      </div>
      <div
        className="XAxis-hover-tick font--number"
        style={hoveredX && {
          left: hoveredX.x,
        }}>
        {hoveredX ? hoveredX.value : " "}
      </div>
    </div>
  )
}