import React, { Component } from 'react';

import { scaleLinear } from 'd3-scale';

import Counter from "./Counter";
import AnimatedRect from "./AnimatedRect";

import { formatDollars, formatPercentage, formatPercentageDelta, formatDollarsDelta } from '../formatDollars';
import { CBO_LABEL, YOU_LABEL } from "Constants";

const
  BAR_HEIGHT = 18,
  BAR_WIDTH = 80

export default class BudgetSnapshot extends Component {
  render() {
    const { user, baseline, year } = this.props

    const
      userGdp = user.ECON['Nominal GDP'].values[year],
      baselineGdp = baseline.ECON['Nominal GDP'].values[year]

    const
      yourSpending = user.BUDGET.TOTAL_SPENDING.values[year],
      baselineSpending = baseline.BUDGET.TOTAL_SPENDING.values[year],
      spendingDelta = (yourSpending - baselineSpending),
      yourRevenue = user.BUDGET.TOTAL_REVENUE.values[year],
      baselineRevenue = baseline.BUDGET.TOTAL_REVENUE.values[year],
      revenueDelta = (yourRevenue - baselineRevenue),
      yourDeficit = user.BUDGET.SURPLUS.values[year],
      baselineDeficit = baseline.BUDGET.SURPLUS.values[year],
      deficitDelta = -(yourDeficit - baselineDeficit)

    const budgetMax = Math.max(
      yourRevenue,
      baselineRevenue,
      yourSpending,
      baselineSpending,
      Math.abs(yourDeficit),
      Math.abs(baselineDeficit)
    )

    const budgetScale = scaleLinear()
      .domain([0,budgetMax])
      .range([0,BAR_WIDTH])

    const
      yourDebt = Math.round(100*user.BUDGET.NET_DEBT.values[year]/userGdp),
      baselineDebt = Math.round(100*baseline.BUDGET.NET_DEBT.values[year]/baselineGdp),
      debtGdpDelta = (yourDebt - baselineDebt)

    return (
      <div className="BudgetSnapshot">
        <ValueSet>
          <Value
            className="Value--large"
            label="Federal Debt"
            baselineValue={baselineDebt}
            yourValue={yourDebt}
            valueClass="debt"
            delta={debtGdpDelta}
            format={(d) => <span>{formatPercentage(d,0)}<span className="font--label--small"> of GDP</span></span>}
            formatDelta={(d) => formatPercentageDelta(d,0)} />
          <Value
            label="Revenue"
            yourValue={yourRevenue}
            baselineValue={baselineRevenue}
            valueClass="revenue"
            delta={revenueDelta}
            scale={budgetScale} />
          <Value
            label="Spending"
            yourValue={yourSpending}
            baselineValue={baselineSpending}
            valueClass="spending"
            delta={spendingDelta}
            scale={budgetScale} />
          <Value
            label={baselineDeficit < 0 ? 'Deficit' : 'Surplus'}
            yourValue={Math.abs(yourDeficit)}
            baselineValue={Math.abs(baselineDeficit)}
            valueClass="debt"
            userX={budgetScale(Math.min(yourSpending,yourRevenue))}
            baselineX={budgetScale(Math.min(baselineSpending,baselineRevenue))}
            userLabel={yourDeficit >= 0 ? "Surplus" : undefined}
            delta={deficitDelta}
            scale={budgetScale} />
        </ValueSet>
      </div>
    );
  }
}

const ValueSet = ({ children, className="" }) => {
  return (
    <div className={`ValueSet budget-header-values reset-list ${className}`}>
      {children}
    </div>
  )
}

const Value = ({ label, baselineValue, yourValue, valueClass, delta, scale, baselineX=0, userX=0, userLabel, format=formatDollars, formatDelta=formatDollarsDelta, className="" }) => {

  const baselineBar = scale ? (
    <svg
      className={`Value-bar baseline`}
      viewBox={`0 0 ${BAR_WIDTH} ${BAR_HEIGHT}`}
      preserveAspectRatio="none"
      height={BAR_HEIGHT}>
      <AnimatedRect x={baselineX} height={BAR_HEIGHT} width={scale(Math.abs(baselineValue))} className={`fill--${valueClass}`} />
    </svg>
  ) : undefined;

  const userBar = scale ? (
    <svg
      className={`Value-bar user`}
      viewBox={`0 0 ${BAR_WIDTH} ${BAR_HEIGHT}`}
      preserveAspectRatio="none"
      height={BAR_HEIGHT}>
      <AnimatedRect x={userX} height={BAR_HEIGHT} width={scale(Math.abs(yourValue))} className="fill--user" />
    </svg>
  ) : undefined

  return (
    <div className={`Value  ${className}`}>
      <div className={`Value-label font--label--alt bold color--black`}>{label}</div>
        <div className={`Value-figure baseline font--number`}>
          <span className="label font--label--small faded">{CBO_LABEL}</span> <Counter className={`color--${valueClass}`} value={baselineValue} format={format} />
        </div>
        {baselineBar}
        <div className={`Value-figure user font--number color--user`}>
          <span className="label font--label--small">{YOU_LABEL}</span> <Counter value={yourValue} format={format} />
        </div>
        {userBar}
        {userLabel && <div className="Value-figure-user-label font--label--small color--user">{userLabel}</div>}
        {!isNaN(delta) && (
          <div className="Value-figure delta font--number--small color--black">
             <Counter value={delta} format={formatDelta} />
          </div>
        )}
    </div>
  )
}