module.exports = function(datasets) {
	const operations = {
		negate: function(values) {
			const results = {}
			Object.keys(values).forEach(key => {
				results[key] = -1*values[key]
			})
			return results;
		},
		yearShift: function(values,shift) {
			const results = {}
			Object.keys(values).forEach(key => {
				const newValue = values[+key + shift];
				if (!isNaN(newValue)) {
					results[key] = newValue
				}
			})
			return results;
		},
		multiply: function(value,factor) {
			return value*factor
		},
		sumCols: function(cols) {
			const keys = cols.map((col) => {
				try {
					return Object.keys(datasets[col.dataset][col.column].values)
				} catch(e) {
					console.log("problem? " + col.column)
					throw e
				}
			})

			const remainingKeys = keys.slice(1);

			const years = keys[0].filter((d) => {
				var keyIsValid = true;
				remainingKeys.every((key) => {
					if (key.indexOf(d) === -1) {
						keyIsValid = false
						return false;
					}
					return true
				})
				return keyIsValid;
			});

			const results = {}
			years.forEach(function(y) {
				results[y] = sum(y)
			})

			return results;

			function sum(year) {
				var result = 0;
				cols.forEach(function(col) {
					result += datasets[col.dataset][col.column].values[year];
				})
				return result
			}
		},
		sumValues: function(terms)
		{
			const results = {}
			Object.keys(terms[0]).forEach(key => {
				const value = terms.reduce((r,d) => (r + d[key]), 0)

				if (value !== null && !isNaN(value)) {
					results[key] = value
				}
			})
			return results;
		},
		differenceValues: function(minuend, subtrahends)
		{
			const results = {}
			Object.keys(minuend).forEach(key => {
				results[key] = subtrahends.reduce((r,d) => (r - d[key]), minuend[key])
			})
			return results;
		},
		productValues: function (terms)
		{
			const results = {}

			Object.keys(terms[0]).forEach(key => {
				results[key] = terms.reduce((r,d) => ( r * d[key] ), 1);
			})
			return results;
		},
		quotientValues: function(dividend,divisor) {
			const results = {}
			Object.keys(dividend).forEach(key => {
				results[key] = dividend[key]/divisor[key]
			})
			return results
		},
		sumValuesConst: function(values, constant) {
			const results = {}
			Object.keys(values).forEach(key => {
				const value = values[key] + constant

				if (typeof value === "number") {
					results[key] = value
				}
			})
			return results
		},
		productValuesConst: function(values, constant)
		{
			const results = {}
			Object.keys(values).forEach(key => {
				const value = values[key] * constant

				if (typeof value === 'number') {
					results[key] = value
				}
			})
			return results
		},
		growth: function(dividend,divisor)
		{
			const results = {}
			Object.keys(dividend).forEach(key => {
				results[key] = (dividend[key]/divisor[key]) - 1
			})
			return results
		},
		yearOverYear: function(thisYear)
		{
			const
				lastYear = operations.yearShift(thisYear, -1)

			return operations.growth(thisYear,lastYear);
		}
	}

	return operations
}