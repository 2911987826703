import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import calculator from "./reducers/calculator";
import session from "./reducers/session";
import throttle from "lodash/throttle";
import { loadState, saveState } from "./localStorage";
import thunk from "redux-thunk";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export default () => {
  const reducers = combineReducers({
    calculator,
    session,
  });

  const persistedState = loadState();
  const store = createStore(
    reducers,
    persistedState,
    composeEnhancers(applyMiddleware(thunk))
  );
  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000)
  );

  return store;
};
