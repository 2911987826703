import React from "react";

import { Link } from "react-router-dom";

import ActionBar from './ActionBar';

export default ({ calcKey }) => (
  <ActionBar className="page">
    <Link
      className="action-link"
      to={`/${calcKey}/edit`}>
      Edit
    </Link>
  </ActionBar>
)